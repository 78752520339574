<template>
    <v-container fill-height>
        <v-row justify="center" align="center">
            <v-col cols="12" class="text-center">
                @cobrox
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'AboutPage',
    }
</script>

<style scoped>

</style>
