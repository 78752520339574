<template>
  <v-autocomplete
      prepend-icon="shop"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      v-model="item"
      :label="$te(label) ? $t(label) : label"
      :loading="loading"
      :error="hasInputErrors('compra')"
      :error-messages="getInputErrors('compra')"
      color="secondary"
      item-color="secondary"
      :multiple="multiple"
      :chips="chips"
      :solo="solo"
      :disabled="disabled"
      :readonly="readonly"
      :clearable="clearable"
      :rules="rules ? rules : isRequired ? required : []"
  ></v-autocomplete>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


import CompraProvider from "../../providers/CompraProvider"

export default {
  name: "CompraCombobox",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {type: [String, Array]},
    rules: {type: Array},
    multiple: {type: Boolean, default: false},
    solo: {type: Boolean, default: false},
    chips: {type: Boolean, default: false},
    readonly: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    isRequired: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
    itemValue: {type: String, default: 'id'},
    itemText: {type: String, default: 'nombre'},
    label:  {type: String, default: 'cobrox.deuda.labels.compra'},
  },
  data() {
    return {
      items: [],
      loading: false
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    fetch() {
      this.loading = true
      CompraProvider.fetchCompra().then(r => {
        this.items = r.data.fetchCompra
      }).catch(err => console.error(err))
          .finally(() => this.loading = false)
    }

  }
}
</script>

<style scoped>

</style>
