
const messages = {
    en: {
       cobrox: {
          quita: {
            name: 'Plan',
            title: 'Plan management',
            subtitle: 'View, search, create, edit and delete Plan',
            creating: 'Creating Plan',
            editing: 'Editing Plan',
            deleting: 'Deleting Plan',
            showing: 'Showing Plan',
            menu: 'Planes',
            labels: {
                moraDesde: 'Mora desde',
                moraHasta: 'Mora hasta',
                cuotaDesde: 'Cuota desde',
                cuotaHasta: 'Cuota hasta',
                quita: 'Modificador',
                planes: 'Planes'
            },

          }
       }
    },
    es: {
       cobrox: {
          quita: {
            name: 'Plan',
            title: 'Administración de Plan',
            subtitle: 'Ver, buscar, crear, editar, y borrar Plan',
            creating: 'Creando Plan',
            editing: 'Modificando Plan',
            deleting: 'Eliminando Plan',
            showing: 'Detalles de Plan',
            menu: 'Plan',
            labels: {
                moraDesde: 'Mora desde',
                moraHasta: 'Mora hasta',
                cuotaDesde: 'Cuota desde',
                cuotaHasta: 'Cuota hasta',
                quita: 'Modificador',
                planes: 'Planes'
            },

          }
       }
    },
    pt: {
       cobrox: {
          quita: {
            name: 'Plan',
            title: 'Administração de Plan',
            subtitle: 'Ver, buscar, criar, editar e usar Plan',
            creating: 'Criando Plan',
            editing: 'Edição Plan',
            deleting: 'Apagando Plan',
            showing: 'Detalhes do Plan',
            menu: 'Plan',
            labels: {
                moraDesde: 'Mora desde',
                moraHasta: 'Mora hasta',
                cuotaDesde: 'Cuota desde',
                cuotaHasta: 'Cuota hasta',
                quita: 'Modificador',
                planes: 'Planes'
            },

          }
       }
    }
}

export default messages
