<template>
  <div v-if="tableView">
    <div>
      <v-simple-table
          dense
      >
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">

            </th>
            <th class="text-left">
              Fecha Cobranza
            </th>
            <th class="text-left">
              Monto Cobrado
            </th>
            <th class="text-left">
              Tipificación
            </th>

          </tr>
          </thead>
          <tbody>
          <tr
              v-for="cobranza in items"
              :key="cobranza.id"
          >
            <td>
              <v-checkbox
                  dense hide-details
                  :key="cobranza.id"
                  v-model="item"
                  :value="cobranza.id"
                  :readonly="readonly"
              ></v-checkbox>
            </td>

            <td> {{ getDateFormat(cobranza.fechaCobranza) }}</td>
            <td>{{ currency }}{{ cobranza.montoCobrado.toLocaleString('es-AR') }}</td>
            <td>{{ cobranza.tipificacion }}</td>
          </tr>
          </tbody>
        </template>

      </v-simple-table>
    </div>
  </div>
  <v-autocomplete
      v-else
      prepend-icon="point_of_sale"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      v-model="item"
      :label="$t('cobrox.convenio.labels.cobranzas')"
      :loading="loading"
      :error="hasInputErrors('cobranzas')"
      :error-messages="getInputErrors('cobranzas')"
      color="secondary"
      item-color="secondary"
      :rules="isRequired ? required : []"
      :multiple="multiple"
      :chips="chips"
      :solo="solo"
      :disabled="disabled"
      :readonly="readonly"
      :clearable="clearable"
      :hide-details="hideDetails"
      :style="{width: width, maxWidth: width}"
      :return-object="returnObject"
  ></v-autocomplete>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


import CobranzaProvider from "../../providers/CobranzaProvider"
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import {DayjsMixin} from "@dracul/dayjs-frontend";

export default {
  name: "CobranzaCombobox",
  mixins: [InputErrorsByProps, RequiredRule, CurrencyMixin, DayjsMixin],
  props: {
    value: {type: [String, Array, Object]},
    multiple: {type: Boolean, default: true},
    solo: {type: Boolean, default: false},
    chips: {type: Boolean, default: false},
    readonly: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    isRequired: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
    hideDetails: {type: Boolean, default: false},
    returnObject: {type: Boolean, default: false},
    itemValue: {type: String, default: 'id'},
    itemText: {type: String, default: 'fechaCobranza'},
    width: {type: String, default: null},
    cuilDeudor: {type: String, default: null},
    dniDeudor: {type: String, default: null},
    propias: {type: Boolean, default: false},
    tableView: {type: Boolean, default: false},
    allSelected: {type: Boolean, default: false},
    notSelectable: {type: Array, default: () => []}
  },
  data() {
    return {
      items: [],
      loading: false
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    cuilDeudor: function () {
      this.fetch()
    },
    dniDeudor: function () {
      this.fetch()
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    fetch() {
      if (this.cuilDeudor) {
        this.fetchByCuil()
      } else if (this.dniDeudor) {
        this.fetchByDni()
      }
    },
    fetchByCuil() {
      this.loading = true
      CobranzaProvider.fetchCobranzaByCuil(this.cuilDeudor, this.propias)
          .then(r => {
            this.items = r.data.fetchCobranzaByCuil
            if (this.allSelected) {
              this.item = this.items.map(deuda => deuda.id).filter( c => !this.notSelectable.includes(c) )
            }
          })
          .catch(err => console.error(err))
          .finally(() => this.loading = false)
    },
    fetchByDni() {
      this.loading = true
      CobranzaProvider.fetchCobranzaByDni(this.dniDeudor, this.propias)
          .then(r => {
            this.items = r.data.fetchCobranzaByDni
            if (this.allSelected) {
              this.item = this.items.map(cobranza => cobranza.id).filter( c => !this.notSelectable.includes(c) )
            }
          })
          .catch(err => console.error(err))
          .finally(() => this.loading = false)
    }

  }
}
</script>

<style scoped>

</style>
