<template>
  <v-card outlined>
    <v-card-title>
      Historial de agencias
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <thead>
        <tr>
          <th>Usuario</th>
          <th>Agencia</th>
          <th>Fecha</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in agenciaHistorico" :key="index">
          <td>{{ item.modificadoPor.username }}</td>
          <td>{{ item.agencia.nombre }}</td>
          <td>{{ getDateTimeFormat(item.fecha) }}</td>

        </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {DayjsMixin} from "@dracul/dayjs-frontend";
export default {
  name: "AgenciaHistoricoSimpleTable",
  mixins: [DayjsMixin],
  props: {
    agenciaHistorico: {type: Array}
  }
}
</script>

<style scoped>

</style>
