<template>
    <v-form ref="form" autocomplete="off" @submit.prevent="save" >
        <v-row>
           
                    <v-col cols="12" sm="6">
                        <v-text-field
                                
                                prepend-icon="badge"
                                name="identificacion"
                                v-model="form.identificacion"
                                :label="$t('cobrox.autogestionLog.labels.identificacion')"
                                :placeholder="$t('cobrox.autogestionLog.labels.identificacion')"
                                :error="hasInputErrors('identificacion')"
                                :error-messages="getInputErrors('identificacion')"
                                color="secondary"
                                
                        ></v-text-field>
                    </v-col>
    
 
                    <v-col cols="12" sm="6">
                        <v-text-field
                                
                                prepend-icon="email"
                                name="email"
                                v-model="form.email"
                                :label="$t('cobrox.autogestionLog.labels.email')"
                                :placeholder="$t('cobrox.autogestionLog.labels.email')"
                                :error="hasInputErrors('email')"
                                :error-messages="getInputErrors('email')"
                                color="secondary"
                                
                        ></v-text-field>
                    </v-col>
    
 
                    <v-col cols="12" sm="6">
                        <v-text-field
                                
                                prepend-icon="phone"
                                name="telefono"
                                v-model="form.telefono"
                                :label="$t('cobrox.autogestionLog.labels.telefono')"
                                :placeholder="$t('cobrox.autogestionLog.labels.telefono')"
                                :error="hasInputErrors('telefono')"
                                :error-messages="getInputErrors('telefono')"
                                color="secondary"
                                
                        ></v-text-field>
                    </v-col>
    
 
                    <v-col cols="12" sm="6">
                        <v-text-field
                                
                                prepend-icon="devices"
                                name="UserAgent"
                                v-model="form.UserAgent"
                                :label="$t('cobrox.autogestionLog.labels.UserAgent')"
                                :placeholder="$t('cobrox.autogestionLog.labels.UserAgent')"
                                :error="hasInputErrors('UserAgent')"
                                :error-messages="getInputErrors('UserAgent')"
                                color="secondary"
                                
                        ></v-text-field>
                    </v-col>
    
 
                    <v-col cols="12" sm="6">
                        <v-text-field
                                
                                prepend-icon="router"
                                name="IP"
                                v-model="form.IP"
                                :label="$t('cobrox.autogestionLog.labels.IP')"
                                :placeholder="$t('cobrox.autogestionLog.labels.IP')"
                                :error="hasInputErrors('IP')"
                                :error-messages="getInputErrors('IP')"
                                color="secondary"
                                
                        ></v-text-field>
                    </v-col>
    
 
                    <v-col cols="12" sm="6">
                        <v-text-field
                                
                                prepend-icon="location_on"
                                name="Geolocation"
                                v-model="form.Geolocation"
                                :label="$t('cobrox.autogestionLog.labels.Geolocation')"
                                :placeholder="$t('cobrox.autogestionLog.labels.Geolocation')"
                                :error="hasInputErrors('Geolocation')"
                                :error-messages="getInputErrors('Geolocation')"
                                color="secondary"
                                
                        ></v-text-field>
                    </v-col>
    
 
                    <v-col cols="12" sm="6">
                        <v-text-field
                                
                                prepend-icon="trip_origin"
                                name="Referer"
                                v-model="form.Referer"
                                :label="$t('cobrox.autogestionLog.labels.Referer')"
                                :placeholder="$t('cobrox.autogestionLog.labels.Referer')"
                                :error="hasInputErrors('Referer')"
                                :error-messages="getInputErrors('Referer')"
                                color="secondary"
                                
                        ></v-text-field>
                    </v-col>
    
 
                    <v-col cols="12" sm="6">
                        <v-text-field
                                
                                prepend-icon="app_shortcut"
                                name="SO"
                                v-model="form.SO"
                                :label="$t('cobrox.autogestionLog.labels.SO')"
                                :placeholder="$t('cobrox.autogestionLog.labels.SO')"
                                :error="hasInputErrors('SO')"
                                :error-messages="getInputErrors('SO')"
                                color="secondary"
                                
                        ></v-text-field>
                    </v-col>
    
        </v-row>
    </v-form>
</template>

<script>

    import {InputErrorsByProps, RequiredRule } from '@dracul/common-frontend'
    
    
    
    

    

    export default {
        name: "AutogestionLogForm",
        mixins: [InputErrorsByProps, RequiredRule    ],
        
        props:{
            value: {
                type: Object,
                required: true
            },
        },
        computed: {
           form: {
                get() { return this.value },
                set(val) {this.$emit('input', val)}
            }
        },
         watch: {
            form: {
                handler(newVal) {
                    this.$emit('input', newVal)
                },
                deep: true
            }
        },
        methods: {
            validate(){
              return this.$refs.form.validate()
            },
            save(){
              this.$emit('save', this.form)
            }
        },
        data(){
            return {
                
            }
        }
    }
</script>

<style scoped>

</style>

