import graphqlClient from "../../../apollo";

class RetencionProvider {

    findRetencion(id) {
        return graphqlClient.query({
            query: require('./gql/Retencion/findRetencion.graphql'),
            variables: {id:id}
        })
    }

    fetchRetencion() {
        return graphqlClient.query({query: require('./gql/Retencion/fetchRetencion.graphql')})
    }

    paginateRetencion(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Retencion/paginateRetencion.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }



    createRetencion(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Retencion/createRetencion.graphql'),
            variables: {input}
        })
    }

    updateRetencion(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Retencion/updateRetencion.graphql'),
            variables: {id, input}
        })
    }

    aprobarRetencion(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Retencion/aprobarRetencion.graphql'),
            variables: {id}
        })
    }

    rechazarRetencion(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Retencion/rechazarRetencion.graphql'),
            variables: {id}
        })
    }

     deleteRetencion(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Retencion/deleteRetencion.graphql'),
            variables: {id}
        })
    }

}

export default new RetencionProvider()


