<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="pa-3">
               <estado-deuda-crud />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import EstadoDeudaCrud from "./EstadoDeudaCrud";
    export default {
        name: "EstadoDeuda",
        components: {EstadoDeudaCrud}
    }
</script>

<style scoped>

</style>

