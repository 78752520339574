import FieldPermissionPage from '../pages/crud/FieldPermissionPage'

const routes = [
       
     {
        name: 'FieldPermissionPage', 
        path: '/crud/fieldpermission', 
        component: FieldPermissionPage,  
        meta: {
            requiresAuth: true,
            permission: "FIELDPERMISSION_MENU"
        }
     }
]

export default routes;
