<template>
  <v-container>
    <v-card
        v-if="userAgencia && pedidoCuenta && (userAgencia.id == pedidoCuenta.agenciaAutorizante.id || $store.getters.hasPermission('PEDIDOCUENTA_UPDATE'))">
      <v-card-title>Pedido de cuenta</v-card-title>
      <v-card-text>
        Se solicita ceder la cuenta con CUIL {{ pedidoCuenta.cuil }}

      </v-card-text>

      <v-card-text>

        <v-form v-on:submit.prevent="updatePedidoCuenta" ref="form">

        <v-radio-group
            v-model="form.estadoPedidoCuenta"
            :readonly="pedidoCuenta.estadoPedidoCuenta != 'PENDIENTE'"
            :disabled="pedidoCuenta.estadoPedidoCuenta != 'PENDIENTE'"
        >
          <v-radio value="APROBADO" label="Aprobar"></v-radio>
          <v-radio value="RECHAZADO" label="Rechazar"></v-radio>
        </v-radio-group>

        <v-select v-if="form.estadoPedidoCuenta === 'RECHAZADO'"
                  :readonly="pedidoCuenta.estadoPedidoCuenta != 'PENDIENTE'"
                  :disabled="pedidoCuenta.estadoPedidoCuenta != 'PENDIENTE'"
                  v-model="form.tipificacionAutorizante"
                  outlined
                  :label="$t('cobrox.pedidoCuenta.labels.tipificacionAutorizante')"
                  :items="['En gestion', 'Con acuerdo', 'Contactado','Otros']"
                  :rules="required"
        >
        </v-select>


        <v-textarea
            :readonly="pedidoCuenta.estadoPedidoCuenta != 'PENDIENTE'"
            :disabled="pedidoCuenta.estadoPedidoCuenta != 'PENDIENTE'"
            outlined
            name="comentarioSolicitante"
            v-model="form.comentarioAutorizante"
            :label="$t('cobrox.pedidoCuenta.labels.comentarios')"
            :placeholder="$t('cobrox.pedidoCuenta.labels.comentarios')"
            color="secondary"
        ></v-textarea>

        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <template v-if="pedidoCuenta.estadoPedidoCuenta === 'PENDIENTE'">

          <v-btn
              :loading="loading"
              color="primary darken-1 onPrimary--text text--lighten-4" class="mx-1"
              @click="updatePedidoCuenta"
          >
            Confirmar
          </v-btn>

        </template>


        <template
            v-else-if="pedidoCuenta.estadoPedidoCuenta === 'RECHAZADO_EN_REVISION' && $store.getters.hasPermission('PEDIDOCUENTA_UPDATE')">

          <v-btn
              :loading="loading"
              color="red darken-1 red--text text--lighten-4" class="mx-1"
              @click="setEstado( 'RECHAZADO')"
          >
            Rechazar
          </v-btn>
          <v-btn
              :loading="loading"
              color="green darken-1 green--text text--lighten-4" class="mx-1"
              @click="setEstado('APROBADO')"
          >
            Aprobar
          </v-btn>
        </template>

        <template v-else>
          <v-chip :color="pedidoCuenta.estadoPedidoCuenta === 'APROBADO' ? 'green white--text' : 'red white--text'">
            {{ pedidoCuenta.estadoPedidoCuenta }}
          </v-chip>
        </template>

        <v-spacer></v-spacer>


      </v-card-actions>
    </v-card>

    <confirm-dialog
        v-if="pedidoCuenta"
        v-model="confirmDialog"
        :title="'Confirmación'"
        :description="'Por favor confirmar estado '+ form.estadoPedidoCuenta"
        @confirmed="updateEstado">
    </confirm-dialog>

  </v-container>
</template>

<script>
import PedidoCuentaProvider from "@/modules/cobrox/providers/PedidoCuentaProvider";
import AgenciaProvider from "@/modules/cobrox/providers/AgenciaProvider";
import {ConfirmDialog} from "@dracul/common-frontend";
import {RequiredRule} from "@dracul/common-frontend";

export default {
  name: "PedidoCuentaRespuestaPage",
  components: {ConfirmDialog},
  mixins: [RequiredRule],
  data() {
    return {
      loading: false,
      pedidoCuenta: null,
      userAgencia: null,
      estado: null,
      confirmDialog: false,
      form: {
        comentarioAutorizante: null,
        estadoPedidoCuenta: null,
        tipificacionAutorizante: null
      }
    }
  },
  computed: {
    getIdPedidoCuentaParam() {
      return this.$route.params.id
    }
  },
  created() {
    this.fetchUserAgencia()
    this.findPedidoCuenta()
  },
  methods: {
    fetchUserAgencia() {
      this.loadingUserAgencia = true
      AgenciaProvider.findMyAgencia()
          .then(r => {
            this.userAgencia = r.data.findMyAgencia
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => this.loadingUserAgencia = false)
    },
    findPedidoCuenta() {
      this.loading = true
      PedidoCuentaProvider.findPedidoCuenta(this.getIdPedidoCuentaParam)
          .then(r => {
            this.pedidoCuenta = r.data.findPedidoCuenta
            this.form.estadoPedidoCuenta = this.pedidoCuenta.estadoPedidoCuenta
            this.form.tipificacionAutorizante = this.pedidoCuenta.tipificacionAutorizante
            this.form.comentarioAutorizante = this.pedidoCuenta.comentarioAutorizante
          })
          .finally(() => this.loading = false)
    },
    setEstado(estado) {
      this.estado = estado
      this.confirmDialog = true
    },
    updatePedidoCuenta() {
      if(this.$refs.form.validate()) {
        this.confirmDialog = true
      }
    },
    updateEstado() {
      this.loading = true
      PedidoCuentaProvider.updatePedidoCuentaEstado(
          this.getIdPedidoCuentaParam,
          this.form.estadoPedidoCuenta,
          this.form.comentarioAutorizante,
          this.form.tipificacionAutorizante
      )
          .then((r) => {
            this.pedidoCuenta = r.data.updatePedidoCuentaEstado
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => this.loading = false)
    },

  }
}
</script>

<style scoped>

</style>
