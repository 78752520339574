<template>
  <crud-create :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @create="create"
               @close="$emit('close')"
  >
    <agencia-form ref="form" v-model="form" :input-errors="inputErrors"/>
  </crud-create>
</template>

<script>

import AgenciaProvider from "../../../../providers/AgenciaProvider";

import {CrudCreate, ClientError} from '@dracul/common-frontend'

import AgenciaForm from "../AgenciaForm";


export default {
  name: "AgenciaCreate",

  components: {AgenciaForm, CrudCreate},

  props: {
    open: {type: Boolean, default: true}
  },

  data() {
    return {
      title: 'cobrox.agencia.creating',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      form: {
        nombre: '',
        telefonoDerivaciones: '',
        telefonoConsultas: '',
        mail: '',
        horario_atencion: '',
        observacion: '',
        usuarios: [],
        derivarDefault: false
      }
    }
  },

  methods: {
    create() {
      if (this.$refs.form.validate()) {
        this.loading = true
        AgenciaProvider.createAgencia(this.form).then(r => {
              if (r) {
                this.$emit('itemCreated', r.data.createAgencia)
                this.$emit('close')
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }

  },
}
</script>

<style scoped>

</style>

