
const messages = {
    en: {
       role: {
            permissions: {
                CUENTA_MENU: "Access to Cuenta",
                CUENTA_SHOW: "View Cuenta",
                CUENTA_CREATE: "Create Cuenta",
                CUENTA_UPDATE: "Modify Cuenta",
                CUENTA_DELETE: "Delete Cuenta",
            }
       }
    },
    es: {
        role: {
            permissions: {
                CUENTA_MENU: "Acceder Cuenta",
                CUENTA_SHOW: "Ver Cuenta",
                CUENTA_CREATE: "Crear Cuenta",
                CUENTA_UPDATE: "Modificar Cuenta",
                CUENTA_DELETE: "Eliminar Cuenta",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                CUENTA_MENU: "Acessar Cuenta",
                CUENTA_SHOW: "Ver Cuenta",
                CUENTA_CREATE: "Criar Cuenta",
                CUENTA_UPDATE: "Modificar Cuenta",
                CUENTA_DELETE: "Eliminar Cuenta",
            }
       }
    }
}

export default messages
    