
const messages = {
    en: {
       role: {
            permissions: {
                ORIGENDEUDA_MENU: "Access to OrigenDeuda",
                ORIGENDEUDA_SHOW: "View OrigenDeuda",
                ORIGENDEUDA_CREATE: "Create OrigenDeuda",
                ORIGENDEUDA_UPDATE: "Modify OrigenDeuda",
                ORIGENDEUDA_DELETE: "Delete OrigenDeuda",
            }
       }
    },
    es: {
        role: {
            permissions: {
                ORIGENDEUDA_MENU: "Acceder OrigenDeuda",
                ORIGENDEUDA_SHOW: "Ver OrigenDeuda",
                ORIGENDEUDA_CREATE: "Crear OrigenDeuda",
                ORIGENDEUDA_UPDATE: "Modificar OrigenDeuda",
                ORIGENDEUDA_DELETE: "Eliminar OrigenDeuda",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                ORIGENDEUDA_MENU: "Acessar OrigenDeuda",
                ORIGENDEUDA_SHOW: "Ver OrigenDeuda",
                ORIGENDEUDA_CREATE: "Criar OrigenDeuda",
                ORIGENDEUDA_UPDATE: "Modificar OrigenDeuda",
                ORIGENDEUDA_DELETE: "Eliminar OrigenDeuda",
            }
       }
    }
}

export default messages
    