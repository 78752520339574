<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col ref="colstage" name="colstage" cols="9" :style="{border: '1px solid grey', overflow: 'auto'}">
        <k-stage v-if="id" :config="configKonva">

          <!--BASE-->
          <k-layer ref="base">
            <k-rect :config="configKonva"></k-rect>
          </k-layer>

          <!--GUIAS-->
          <k-layer v-if="false" ref="base">
            <k-rect :config="configVerticalLine"></k-rect>
          </k-layer>

          <!--ITEMS-->
          <k-layer ref="items">
            <component v-for="(item,index) of items"
                       :key="index"
                       :is="item.type"
                       :config="item"
                       @click="handleItemClick(index)"
                       @dragend="handleItemDragend"
            ></component>
          </k-layer>
        </k-stage>
      </v-col>


      <v-col cols="3" :style="{border: '1px solid grey', overflow: 'auto'}">
        <v-card
            tile
        >

          <v-card-title>
            {{ this.identifier }}
            <v-btn absolute right icon color="blue" @click="saveDoc" :loading="loading">
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn absolute right icon color="red" @click="pdfSample" :loading="loading" class="mr-8">
              <v-icon>picture_as_pdf</v-icon>
            </v-btn>
          </v-card-title>



          <!--CREATE ITEM-->
          <v-card-actions class="mx-3">
            <kinva-item-select v-model="itemToAdd"></kinva-item-select>
            <v-btn icon @click="add" :disabled="itemToAdd === null">
              <v-icon>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text v-if="getItemSelected != null" class="text-center">
            <v-btn small text @click="itemSelected =null">
              <v-icon left>list</v-icon>
              Listar Items
            </v-btn>
          </v-card-text>

          <!--FORM-->
          <v-card-text v-if="getItemSelected != null">
            <kinva-item-form v-model="getItemSelected"></kinva-item-form>
          </v-card-text>

          <v-card-actions v-if="getItemSelected != null">
            <v-spacer></v-spacer>
            <v-btn icon @click="deleteItem"><v-icon>delete</v-icon></v-btn>
          </v-card-actions>

          <!--ITEM LIST-->
          <v-card-text v-if="getItemSelected === null">
            <kinva-item-list :items="items" v-model="itemSelected"></kinva-item-list>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

  </v-container>

</template>

<script>

import KinvaItem from "@/modules/kinva/components/KinvaItem/KinvaItem";
import KinvaItemList from "@/modules/kinva/components/KivaItemList/KinvaItemList";
import KinvaItemSelect from "@/modules/kinva/components/KinvaItemSelect/KinvaItemSelect";
import KinvaItemForm from "@/modules/kinva/components/KinvaItemForm/KinvaItemForm";
import KinvaDocProvider from "@/modules/kinva/providers/KinvaDocProvider";

export default {
  name: "KinvaTemplate",
  components: {KinvaItemForm, KinvaItemSelect, KinvaItemList, KinvaItem},
  props: {
    identifier: {type: String}
  },
  data() {
    return {
      id: null,
      loading: false,
      items: [],
      itemToAdd: null,
      itemSelected: null,
      mounted: false
    }
  },
  mounted() {
    this.mounted = true
    this.fetchDoc()

  },
  computed: {
    colstage() {
      if (this.mounted) {
        return this.$refs.colstage
      }
      return null
    },
    configVerticalLine() {
      return {
        x: 20,
        y: 0,
        width: 2,
        height: 1200,
        fill: 'grey',
        draggable: true
      }
    },
    configKonva() {
      return {
        width: 794,
        height: 1122,
        stroke: 'green',
        strockWidth: 22
      }
    },
    getStageWidth() {
      return this.colstage != undefined ? this.colstage.clientWidth : 300
    },
    getItemSelected: {
      get() {
        if (this.itemSelected != null && this.items[this.itemSelected]) {
          return this.items[this.itemSelected]
        }
        return null
      },
      set(val) {
        if (this.itemSelected && this.items[this.itemSelected]) {
          return this.items[this.itemSelected] = val
        }
      }
    },
    configBase() {
      return {
        x: 0,
        y: 0,
        width: this.configKonva.width,
        height: this.configKonva.height,
        stroke: 'grey',
        strockWidth: 1
      }
    },
    stateStyle() {
      return {
        border: '1px solid black',
        width: this.configKonva.width,
        height: this.configKonva.height
      }
    }
  },
  methods: {
    deleteItem(){
      if(this.itemSelected){
        this.items.splice(this.itemSelected,1)
        this.itemSelected = null
      }
    },
    pdfSample(){
      KinvaDocProvider.generateKinvaDocSamplePdf(this.id)
          .then(r => {
            setTimeout(()=> window.open( r.data.generateKinvaDocSamplePdf),300)
          })
    },
    fetchDoc() {
      if (this.identifier) {
        KinvaDocProvider.findKinvaDocByIdentifier(this.identifier)
            .then(r => {
              this.id = r.data.findKinvaDocByIdentifier.id

              console.log("items", r.data.findKinvaDocByIdentifier.items)
              if (r.data.findKinvaDocByIdentifier.items) {
                r.data.findKinvaDocByIdentifier.items.forEach(item => {
                  switch (item.type) {
                    case 'k-text':
                      this.addText(item)
                      break
                    case 'k-image':
                      this.addImage(item)
                      break
                  }
                })
              }
            })
      }
    },
    saveDoc() {
      if (this.identifier) {
        this.loading = true
        const persistedKeys = ['type', 'title', 'x', 'y', 'width', 'height', 'text', 'image']


        let itemsToSave = this.items.map(item => {
          let itemToPersist = {}
          for (let key in item) {
            // console.log("key",key)
            if (key === 'image') {
              itemToPersist[key] = item[key].src
            } else if (persistedKeys.includes(key)) {
              console.log("key", key)
              itemToPersist[key] = item[key]
            }
          }
          return itemToPersist
        })

        console.log("itemsToSave", itemsToSave)

        if (!itemsToSave) {
          return
        }

        KinvaDocProvider.updateKinvaDoc(this.id, {identifier: this.identifier, items: itemsToSave})
            .then(r => {
              this.id = r.data.updateKinvaDoc.id
            })
            .finally(() => this.loading = false)
      }
    },
    randomString(length = 4) {
      let result = '';
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    handleItemClick(index){
      this.itemSelected = index
    },
    handleItemDragend(event) {
      event.target.VueComponent._props.config.x = event.target.attrs.x
      event.target.VueComponent._props.config.y = event.target.attrs.y
    },
    add() {
      this.itemSelected = null
      switch (this.itemToAdd) {
        case 'k-text':
          this.addText()
          break
        case 'k-image':
          this.addImage()
          break
        case 'k-circle':
          this.addCircle()
          break
        default:
          return
      }
    },
    addText(data) {
      this.items.push({
        type: 'k-text',
        title: data ? data.title : this.randomString(6),
        x: data ? data.x : 20,
        y: data ? data.y : 20,
        width: data ? data.width : this.getStageWidth - 40,
        height: data ? data.height : 100,
        text: data ? data.text : 'Text',
        draggable: true,
        fontFamily: 'Helvetica',
        fontVariant: 'normal',
        fontBold: 'normal',
        fontSize: 18,

      })
    },
    addImage(data) {
      let image = new Image()
      image.src = data ? data.image : window.location.origin + '/img/image.png'
      this.items.push({
            type: 'k-image',
            title: data ? data.title : this.randomString(6),
            x: data ? data.x : 20,
            y: data ? data.y : 20,
            width: data ? data.width : 200,
            height: data ? data.height : 180,
            image: image,
            draggable: true,
          }
      )
    },
    addCircle() {
      this.items.push({
        is: 'k-circle',
        title: this.randomString(6),
        x: 10,
        y: 10,
        radius: 100,
        fill: 'red',
        stroke: 'grey',
        strockWidth: 1,
        draggable: true,
      })
    },

  }
}
</script>

<style scoped>

</style>
