<template>
  <crud-layout :title="title" :subtitle="subtitle">

    <template v-slot:list>
      <persona-list
          ref="list"
          @update="update"
          @delete="remove"
          @show="show"

      />
    </template>

    <add-button v-if="$store.getters.hasPermission('PERSONA_CREATE')" @click="create"></add-button>

    <persona-create v-if="creating"
                    :open="creating"
                    v-on:itemCreated="onItemCreated"
                    v-on:close="creating=false"
    />

    <persona-update v-if="updating"
                    :open="updating"
                    :item="itemToEdit"
                    v-on:itemUpdated="onItemUpdated"
                    v-on:close="updating=false"
    />

    <persona-show v-if="showing"
                  :open="showing"
                  :item="itemToShow"
                  v-on:close="showing=false"
    />

    <persona-delete v-if="deleting"
                    :open="deleting"
                    :item="itemToDelete"
                    v-on:itemDeleted="onItemDeleted"
                    v-on:close="deleting=false"
    />

    <snackbar v-model="flash"/>

  </crud-layout>
</template>

<script>

import PersonaCreate from "../PersonaCreate";
import PersonaUpdate from "../PersonaUpdate";
import PersonaDelete from "../PersonaDelete";
import PersonaShow from "../PersonaShow";
import PersonaList from "../PersonaList";

import {CrudLayout, AddButton, Snackbar} from "@dracul/common-frontend"

export default {
  name: "PersonaCrud",
  components: {
    CrudLayout, AddButton, Snackbar,
    PersonaCreate,
    PersonaUpdate,
    PersonaDelete,
    PersonaShow,
    PersonaList
  },
  data() {
    return {
      title: 'cobrox.persona.title',
      subtitle: 'cobrox.persona.subtitle',
      flash: null,
      creating: false,
      updating: false,
      deleting: false,
      showing: false,
      itemToEdit: null,
      itemToDelete: null,
      itemToShow: null,
    }
  },
  methods: {
    //On
    onItemCreated() {
      this.$refs.list.fetch()
      this.flash = this.$t("common.created")
    },
    onItemUpdated() {
      this.$refs.list.fetch()
      this.flash = this.$t("common.updated")
    },
    onItemDeleted() {
      this.$refs.list.fetch()
      this.flash = this.$t("common.deleted")
    },
    //Open
    create() {
      this.creating = true
    },
    update(item) {
      this.updating = true
      this.itemToEdit = item
    },
    show(item) {
      this.showing = true
      this.itemToShow = item
    },
    remove(item) {
      this.deleting = true
      this.itemToDelete = item
    }
  }

}
</script>


