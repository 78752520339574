<template>
  <crud-create :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @create="create"
               @close="$emit('close')"
  >
    <deuda-form ref="form" v-model="form" :input-errors="inputErrors"/>
  </crud-create>
</template>

<script>

import DeudaProvider from "../../../../providers/DeudaProvider";

import {CrudCreate, ClientError} from '@dracul/common-frontend'

import DeudaForm from "../DeudaForm";


export default {
  name: "DeudaCreate",

  components: {DeudaForm, CrudCreate},

  props: {
    open: {type: Boolean, default: true}
  },

  data() {
    return {
      title: 'cobrox.deuda.creating',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      form: {
        idDeuda: '',
        cartera: '',
        fechaMora: null,
        nombreDeudor: '',
        dniDeudor: '',
        cuilDeudor: '',
        capitalCompra: null,
        capitalTotal: null,
        intereses: null,
        //facturas: [],
        saldoCapitalCompra: null,
        imputadoContable: null,
        imputadoCobranzas: null,
        estado: 'ACTIVO',
        jurisdiccion: 'CABA',
        idClienteColppy: null,
        agencia: null,
        origen: null,
        compra: null,
        cbu: null,
        producto:  null,
        detalleProducto:  null,
        nroProducto:  null,
        fechaOtorgamiento:  null,
        precioCompra:  null,
        debitable: false
      }
    }
  },

  methods: {
    create() {
      if (this.$refs.form.validate()) {
        this.loading = true
        DeudaProvider.createDeuda(this.form).then(r => {
              if (r) {
                this.$emit('itemCreated', r.data.createDeuda)
                this.$emit('close')
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }

  },
}
</script>

<style scoped>

</style>

