<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">
    <v-row>

      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('idDeuda')"
            :readonly="!canEdit('idDeuda')"
            prepend-icon="grid_4x4"
            name="idDeuda"
            type="number"
            v-model="form.idDeuda"
            :label="$t('cobrox.deuda.labels.idDeuda')"
            :placeholder="$t('cobrox.deuda.labels.idDeuda')"
            :error="hasInputErrors('idDeuda')"
            :error-messages="getInputErrors('idDeuda')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>



      <v-col cols="12" sm="6">
        <date-input
            v-if="canView('fechaMora')"
            :readonly="!canEdit('fechaMora')"
            v-model="form.fechaMora"
            :label="$t('cobrox.deuda.labels.fechaMora')"
            :error="hasInputErrors('fechaMora')"
            :error-messages="getInputErrors('fechaMora')"
            :rules="required"
        ></date-input>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('nombreDeudor')"
            :readonly="!canEdit('nombreDeudor')"
            prepend-icon="face"
            name="nombreDeudor"
            v-model="form.nombreDeudor"
            :label="$t('cobrox.deuda.labels.nombreDeudor')"
            :placeholder="$t('cobrox.deuda.labels.nombreDeudor')"
            :error="hasInputErrors('nombreDeudor')"
            :error-messages="getInputErrors('nombreDeudor')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('dniDeudor')"
            :readonly="!canEdit('dniDeudor')"
            prepend-icon="badge"
            name="dniDeudor"
            v-model="form.dniDeudor"
            :label="$t('cobrox.deuda.labels.dniDeudor')"
            :placeholder="$t('cobrox.deuda.labels.dniDeudor')"
            :error="hasInputErrors('dniDeudor')"
            :error-messages="getInputErrors('dniDeudor')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('cuilDeudor')"
            :readonly="!canEdit('cuilDeudor')"
            prepend-icon="contact_page"
            name="cuilDeudor"
            v-model="form.cuilDeudor"
            :label="$t('cobrox.deuda.labels.cuilDeudor')"
            :placeholder="$t('cobrox.deuda.labels.cuilDeudor')"
            :error="hasInputErrors('cuilDeudor')"
            :error-messages="getInputErrors('cuilDeudor')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <estado-combobox
            v-if="canView('estado')"
            :readonly="!canEdit('estado')"
            v-model="form.estado"
            :input-errors="inputErrors"
        />

      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('jurisdiccion')"
            :readonly="!canEdit('jurisdiccion')"
            prepend-icon="flag"
            name="jurisdiccion"
            v-model="form.jurisdiccion"
            :label="$t('cobrox.deuda.labels.jurisdiccion')"
            :placeholder="$t('cobrox.deuda.labels.jurisdiccion')"
            :error="hasInputErrors('jurisdiccion')"
            :error-messages="getInputErrors('jurisdiccion')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('capitalCompra')"
            :readonly="!canEdit('capitalCompra')"
            prepend-icon="request_quote"
            name="capitalCompra"
            v-model.number="form.capitalCompra"
            type="number"
            :label="$t('cobrox.deuda.labels.capitalCompra')"
            :placeholder="$t('cobrox.deuda.labels.capitalCompra')"
            :error="hasInputErrors('capitalCompra')"
            :error-messages="getInputErrors('capitalCompra')"
            color="secondary"
            :rules="zeroOrGreather"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('saldoCapitalCompra')"
            :readonly="!canEdit('saldoCapitalCompra')"
            prepend-icon="price_check"
            name="saldoCapitalCompra"
            v-model.number="form.saldoCapitalCompra"
            type="number"
            :label="$t('cobrox.deuda.labels.saldoCapitalCompra')"
            :placeholder="$t('cobrox.deuda.labels.saldoCapitalCompra')"
            :error="hasInputErrors('saldoCapitalCompra')"
            :error-messages="getInputErrors('saldoCapitalCompra')"
            color="secondary"
            :rules="saldoCapitalCompraRule"
        ></v-text-field>
      </v-col>



      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('precioCompra')"
            :readonly="!canEdit('precioCompra')"
            prepend-icon="request_quote"
            name="precioCompra"
            v-model.number="form.precioCompra"
            type="number"
            :label="$t('cobrox.deuda.labels.precioCompra')"
            :placeholder="$t('cobrox.deuda.labels.precioCompra')"
            :error="hasInputErrors('precioCompra')"
            :error-messages="getInputErrors('precioCompra')"
            color="secondary"
            :rules="zeroOrGreather"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('capitalTotal')"
            :readonly="!canEdit('capitalTotal')"
            prepend-icon="paid"
            name="capitalTotal"
            v-model.number="form.capitalTotal"
            type="number"
            :label="$t('cobrox.deuda.labels.capitalTotal')"
            :placeholder="$t('cobrox.deuda.labels.capitalTotal')"
            :error="hasInputErrors('capitalTotal')"
            :error-messages="getInputErrors('capitalTotal')"
            color="secondary"
            :rules="zeroOrGreather"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('intereses')"
            :readonly="!canEdit('intereses')"
            prepend-icon="attach_money"
            name="intereses"
            v-model.number="form.intereses"
            type="number"
            :label="$t('cobrox.deuda.labels.intereses')"
            :placeholder="$t('cobrox.deuda.labels.intereses')"
            :error="hasInputErrors('intereses')"
            :error-messages="getInputErrors('intereses')"
            color="secondary"
            :rules="zeroOrGreather"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('imputadoContable')"
            :readonly="!canEdit('imputadoContable')"
            prepend-icon="money_off"
            name="imputadoContable"
            v-model.number="form.imputadoContable"
            type="number"
            :label="$t('cobrox.deuda.labels.imputadoContable')"
            :placeholder="$t('cobrox.deuda.labels.imputadoContable')"
            :error="hasInputErrors('imputadoContable')"
            :error-messages="getInputErrors('imputadoContable')"
            color="secondary"
            :rules="zeroOrGreather"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('imputadoCobranzas')"
            :readonly="!canEdit('imputadoCobranzas')"
            prepend-icon="money_off"
            name="imputadoCobranzas"
            v-model.number="form.imputadoCobranzas"
            type="number"
            :label="$t('cobrox.deuda.labels.imputadoCobranzas')"
            :placeholder="$t('cobrox.deuda.labels.imputadoCobranzas')"
            :error="hasInputErrors('imputadoCobranzas')"
            :error-messages="getInputErrors('imputadoCobranzas')"
            color="secondary"
            :rules="zeroOrGreather"
        ></v-text-field>
      </v-col>





      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('idClienteColppy')"
            :readonly="!canEdit('idClienteColppy')"
            prepend-icon="badge"
            name="idClienteColppy"
            v-model.number="form.idClienteColppy"
            type="number"
            :label="$t('cobrox.deuda.labels.idClienteColppy')"
            :placeholder="$t('cobrox.deuda.labels.idClienteColppy')"
            :error="hasInputErrors('idClienteColppy')"
            :error-messages="getInputErrors('idClienteColppy')"
            color="secondary"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <agencia-combobox
            v-if="canView('agencia')"
            :readonly="!canEdit('agencia')"
            v-model="form.agencia"
            :input-errors="inputErrors"
            :rules="required"/>
      </v-col>

      <v-col cols="12" sm="6">
        <compra-combobox
            v-if="canView('compra')"
            :readonly="!canEdit('compra')"
            v-model="form.compra"
            :input-errors="inputErrors"
            :rules="required"></compra-combobox>
      </v-col>

      <v-col cols="12" sm="6">
        <origen-deuda-combobox
            v-if="canView('origen')"
            :readonly="!canEdit('origen')"
            v-model="form.origen"
            :input-errors="inputErrors"></origen-deuda-combobox>
      </v-col>


     <!-- <v-col cols="12" sm="6">
        <factura-combobox readonly v-model="form.facturas" :input-errors="inputErrors"/>
      </v-col>-->


      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('cbu')"
            :readonly="!canEdit('cbu')"
            prepend-icon="account_balance_wallet"
            name="cbu"
            v-model="form.cbu"
            :label="$t('cobrox.deuda.labels.cbu')"
            :placeholder="$t('cobrox.deuda.labels.cbu')"
            :error="hasInputErrors('cbu')"
            :error-messages="getInputErrors('cbu')"
            color="secondary"

        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <producto-combobox
            v-if="canView('producto')"
            :readonly="!canEdit('producto')"
            v-model="form.producto"
            :input-errors="inputErrors"
            item-value="nombre"
            is-required
        ></producto-combobox>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('detalleProducto')"
            :readonly="!canEdit('detalleProducto')"
            prepend-icon="description"
            name="detalleProducto"
            v-model="form.detalleProducto"
            :label="$t('cobrox.deuda.labels.detalleProducto')"
            :placeholder="$t('cobrox.deuda.labels.detalleProducto')"
            :error="hasInputErrors('detalleProducto')"
            :error-messages="getInputErrors('detalleProducto')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('nroProducto')"
            :readonly="!canEdit('nroProducto')"
            prepend-icon="copyright"
            name="nroProducto"
            v-model="form.nroProducto"
            :label="$t('cobrox.deuda.labels.nroProducto')"
            :placeholder="$t('cobrox.deuda.labels.nroProducto')"
            :error="hasInputErrors('nroProducto')"
            :error-messages="getInputErrors('nroProducto')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <date-input
            v-if="canView('fechaOtorgamiento')"
            :readonly="!canEdit('fechaOtorgamiento')"
            v-model="form.fechaOtorgamiento"
            :label="$t('cobrox.deuda.labels.fechaOtorgamiento')"
            :error="hasInputErrors('fechaOtorgamiento')"
            :error-messages="getInputErrors('fechaOtorgamiento')"
        ></date-input>
      </v-col>

      <v-col cols="12" sm="6">
        <v-checkbox
            v-if="canView('debitable')"
            :readonly="!canEdit('debitable')"
            prepend-icon="credit_score"
            name="debitable"
            v-model="form.debitable"
            :label="$t('cobrox.deuda.labels.debitable')"
            :error="hasInputErrors('debitable')"
            :error-messages="getInputErrors('debitable')"
            color="secondary"

        ></v-checkbox>
      </v-col>


    </v-row>
  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'

import EstadoCombobox from "../../../../combobox/EstadoDeudaCombobox";
import {DayjsMixin, DateInput} from "@dracul/dayjs-frontend";
import AgenciaCombobox from "@/modules/cobrox/combobox/AgenciaCombobox/AgenciaCombobox";
import CompraCombobox from "@/modules/cobrox/combobox/CompraCombobox/CompraCombobox";
import OrigenDeudaCombobox from "@/modules/cobrox/combobox/OrigenDeudaCombobox/OrigenDeudaCombobox";
import ProductoCombobox from "@/modules/cobrox/combobox/ProductoCombobox/ProductoCombobox";
import SuretyMixin from "@/modules/surety/mixins/SuretyMixin";

export default {
  name: "DeudaForm",
  created() {
    this.fetchFieldPermission('Deuda')
  },
  mixins: [InputErrorsByProps, RequiredRule, DayjsMixin, SuretyMixin],
  components: {
    ProductoCombobox,
    OrigenDeudaCombobox,
    CompraCombobox,
    AgenciaCombobox,
    EstadoCombobox,
    DateInput
  },
  props: {
    value: {type: Object, required: true},
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    zeroOrGreather() {
      return [
        v => v === 0 || v > 0  || this.$t('common.required'),
      ];
    },
    greaterThanZero() {
      return [
        v => !!v || this.$t('common.required'),
        v => v > 0 || this.$t('common.required'),
      ];
    },
    saldoCapitalCompraRule() {
      return [
        () => (this.form.capitalCompra >= this.form.saldoCapitalCompra) || 'saldoCapitalCompra no puede ser mayor a capitalCompra',
        v => v >= 0 || this.$t('common.required'),
      ]
    },
    fechaCompraRule() {
      return [
        v => !!v || this.$t('common.required'),
        () => {
          if (this.form.fechaCompra < this.form.fechaMora) {
            return 'fechaCompra no puede ser menor a fechaMora'
          }
          return true
        }
      ]
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },

  }
}
</script>

<style scoped>

</style>

