import KinvaDocPage from '../pages/crud/KinvaDocPage'

const routes = [
       
     {
        name: 'KinvaDocPage', 
        path: '/crud/kinvadoc', 
        component: KinvaDocPage,  
        meta: {
            requiresAuth: true,
            permission: "KINVADOC_MENU"
        }
     }
]

export default routes;
