<template>
  <v-container>
    <v-card>
      <v-card-title>Generar Notas de crédito</v-card-title>
      <v-card-text>Esta función buscara las cobranzas negativas en estado "PENDIENTE" (sin facturas asociadas) y luego buscara cobranzas
        positivas de un ciclo anterior con el mismo DNI en estado "PROCESADO" (con facturas asociadas) y generará notas de crédito tomando los valores de las facturas emitidas
        en la cobranza positiva ya facturada del ciclo pasado y reversará los saldos y monto imputado en las deudas correspondientes.
      </v-card-text>
      <v-card-text>
        En el caso que no se encuentre una cobranza positiva ya procesada de un ciclo pasado con el
        mismo monto que la cobranza negativa, es imposible para el sistema identificar la
        distribución de facturación y saldos que se debe reversar, por lo que la cobranza negativa
        se pasa a estado “ERROR_ANULACION”
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn class="primary onPrimary--text" @click="generarNotasDeCredito">Generar notas de crédito</v-btn>
      </v-card-actions>
    </v-card>

    <v-card  class="mt-5">

      <v-card-text v-if="message">
        <v-alert type="info">
          {{message}}
        </v-alert>
      </v-card-text>

      <v-card-text v-if="resultado">
        <!--
        cantidadCobranzasNegativas
        cantidadCobranzasNegativasOk
        montoCobranzasNegativasOk
        cantidadCobranzaNegativaSinFcParaNc
        montoCobranzaNegativaSinFcParaNc
        cantidadCobranzasNegativasError
        montoCobranzasNegativasError-->
        <v-row>
          <v-col cols="12">
            <dashboard-card
                title="Cantidad Cobranzas Negativas"
                :value="resultado.cantidadCobranzasNegativas"
            ></dashboard-card>
          </v-col>
          <v-col cols="4">
            <dashboard-card
                title="Cantidad Cobranzas Procesadas"
                :value="resultado.cantidadCobranzasNegativasOk"
                symbol="$"
            ></dashboard-card>
          </v-col>

          <v-col cols="4">
            <dashboard-card
                title="Cantidad Cobranzas sin FC para NC"
                :value="resultado.cantidadCobranzaNegativaSinFcParaNc"

            ></dashboard-card>
          </v-col>

          <v-col cols="4">
            <dashboard-card
                title="Cantidad Cobranzas en error"
                :value="resultado.cantidadCobranzasNegativasError"
                error
            ></dashboard-card>
          </v-col>

          <v-col cols="4">
            <dashboard-card
                title="Monto Cobranzas Procesadas"
                :value="resultado.montoCobranzasNegativasOk"
                symbol="$"
            ></dashboard-card>
          </v-col>

          <v-col cols="4">
            <dashboard-card
                title="Monto Cobranzas sin FC para NC"
                :value="resultado.montoCobranzaNegativaSinFcParaNc"
                symbol="$"
            ></dashboard-card>
          </v-col>

          <v-col cols="4">
            <dashboard-card
                title="Monto Cobranzas en error"
                :value="resultado.montoCobranzasNegativasError"
                symbol="$"
                error
            ></dashboard-card>
          </v-col>

        </v-row>
      </v-card-text>

      <v-card-text v-if="resultado && resultado.notasDeCredito.length > 0">
        <v-simple-table>
          <thead>
          <tr>
            <th>ID Factura</th>
            <th>Estado</th>
            <th>Tipo</th>
            <th>Monto</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="notaCredito in resultado.notasDeCredito" :key="notaCredito.id">
            <td>{{notaCredito.id}}</td>
            <td>{{notaCredito.estado}}</td>
            <td>{{notaCredito.tipoComprobante}}</td>
            <td>${{notaCredito.monto}}</td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import FacturarProvider from "@/modules/cobrox/providers/FacturarProvider";
import DashboardCard from "@/modules/cobrox/components/DashboardCard/DashboardCard";

export default {
  name: "GenerarNotasCreditoPage",
  components: {DashboardCard},
  data(){
    return {
      resultado: null,
      message: null
    }
  },
  methods: {
    generarNotasDeCredito() {
      FacturarProvider.generarNotasDeCredito().then(r => {
        this.resultado = r.data.generarNotasDeCredito

        if(this.resultado.notasDeCredito.length === 0){
          this.message = "No se encontraron cobranzas negativas con sus correlativas cobranzas positivas facturadas para la generación de notas de crédito"
        }
      })

    }
  }
}
</script>

<style scoped>

</style>
