<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title>Verificación de Pagos de Convenio</v-card-title>
      <v-card-text>
        <v-row row wrap>

          <v-col cols="12">
            <v-row justify="space-between">
              <v-col cols="12" sm="6" md="8">
                <!-- FILTERS HERE -->
                <v-row>
                  <v-col cols="12" sm="3">
                    <v-select v-model="filters[0].value"
                              :items="[{text: 'Si', value:true}, {text: 'No', value:false}]"
                              label="Verificado" @change="fetch" clearable
                    />
                  </v-col>
                  <v-col cols="12" sm="5">
                    <canal-pago-combobox v-model="filters[1].value" @change="fetch" clearable></canal-pago-combobox>
                  </v-col>
                </v-row>

              </v-col>
              <v-col cols="12" sm="6" md="4">
                <search-input @search="performSearch" v-model="search"/>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">

            <v-data-table
                class="mt-3"
                :headers="headers"
                :items="items"
                :search="search"
                :single-expand="false"
                :server-items-length="totalItems"
                :loading="loading"
                :page.sync="pageNumber"
                :items-per-page.sync="itemsPerPage"
                :sort-by.sync="orderBy"
                :sort-desc.sync="orderDesc"
                :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
                @update:page="fetch"
                @update:sort-by="fetch"
                @update:sort-desc="fetch"
                @update:items-per-page="fetch"
            >

              <template v-slot:item.verificado="{ item }">
                <v-chip v-if="item.verificado" class="green white--text">Si</v-chip>
                <v-chip v-else>No</v-chip>
              </template>

              <template v-slot:item.monto="{ item }">
            <span v-if="item.monto">
                 {{ currency }} {{ item.monto.toLocaleString('es-AR') }}
            </span>

              </template>


              <template v-slot:item.fechaPago="{ item }">
                {{ getDateFormat(item.fechaPago) }}
              </template>


              <template slot="no-data">
                <div class="text-xs-center" v-t="'common.noData'"></div>
              </template>

              <template slot="loading">
                <div class="text-xs-center" v-t="'common.loading'"></div>
              </template>

              <template v-slot:item.action="{ item }">
                <v-btn icon color="red" small @click="edit(item)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>

            </v-data-table>
          </v-col>

          <v-dialog v-model="editing" fullscreen>
            <v-card>
              <v-toolbar color="primary onPrimary--text mb-2">
                <v-toolbar-title>
                  Verificar Pago
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="editing = false">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-toolbar>
              <verificar-pago :pago="itemSelected" @updatePago="onPagoUpdated"></verificar-pago>
            </v-card>
          </v-dialog>

        </v-row>
      </v-card-text>
    </v-card>
  </v-container>

</template>

<script>
import ConvenioProvider from "../../providers/ConvenioProvider";

import {SearchInput} from "@dracul/common-frontend"
import {DayjsMixin} from "@dracul/dayjs-frontend";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import VerificarPago from "@/modules/cobrox/components/VerificarPago/VerificarPago.vue";
import CanalPagoCombobox from "@/modules/cobrox/combobox/CanalPagoCombobox/CanalPagoCombobox.vue";


export default {
  name: "ConvenioPagos",
  components: {CanalPagoCombobox, VerificarPago, SearchInput},
  mixins: [DayjsMixin, CurrencyMixin],
  data() {
    return {
      items: [],
      totalItems: null,
      loading: false,
      orderBy: null,
      orderDesc: false,
      itemsPerPage: 25,
      pageNumber: 1,
      search: '',
      filters: [
        {
          field: 'pagos.verificado',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value: false
        },
        {
          field: 'pagos.canalPago',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value: null
        },
        {
          field: 'pagos.informado',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value: true
        }
      ],
      editing: false,
      itemSelected: null,
    }
  },
  computed: {
    headers() {
      return [
        //Entity Headers
        {text: this.$t('cobrox.convenio.labels.verificado'), value: 'verificado'},
        {text: this.$t('cobrox.convenio.labels.dniDeudor'), value: 'dniDeudor'},
        {text: this.$t('cobrox.convenio.labels.cuota'), value: 'cuota'},
        {text: this.$t('cobrox.convenio.labels.monto'), value: 'monto'},
        {text: this.$t('cobrox.convenio.labels.canalPago'), value: 'canalPago'},
        {text: this.$t('cobrox.convenio.labels.fechaPago'), value: 'fechaPago'},
        //Actions
        {text: this.$t('common.actions'), value: 'action', sortable: false},
      ]
    },
    getOrderBy() {
      return (Array.isArray(this.orderBy)) ? this.orderBy[0] : this.orderBy
    },
    getOrderDesc() {
      return (Array.isArray(this.orderDesc)) ? this.orderDesc[0] : this.orderDesc
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    onPagoUpdated() {
      this.fetch()
      this.editing = false
    },
    edit(item) {
      this.editing = true
      this.itemSelected = item
    },
    performSearch() {
      this.pageNumber = 1
      this.fetch()
    },
    fetch() {
      this.loading = true
      ConvenioProvider.paginateConvenioPagos(
          this.pageNumber,
          this.itemsPerPage,
          this.search,
          this.filters,
          this.getOrderBy,
          this.getOrderDesc
      ).then(r => {
        this.items = r.data.paginateConvenioPagos.items
        this.totalItems = r.data.paginateConvenioPagos.totalItems
      }).catch(err => {
        console.error(err)
      }).finally(() => this.loading = false)
    }
  }

}
</script>


