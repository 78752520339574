
const messages = {
    en: {
       cobrox: {
          reporteStock: {
            name: 'ReporteStock',
            title: 'ReporteStock management',
            subtitle: 'View, search, create, edit and delete ReporteStock',
            creating: 'Creating ReporteStock',
            editing: 'Editing ReporteStock',
            deleting: 'Deleting ReporteStock',
            showing: 'Showing ReporteStock',
            menu: 'ReporteStock',
            labels: {
                creadoPor: 'Created By',
                url: 'Archivo',
                data: 'Datos'
            },

          }
       }
    },
    es: {
       cobrox: {
          reporteStock: {
            name: 'ReporteStock',
            title: 'Administración de ReporteStock',
            subtitle: 'Ver, buscar, crear, editar, y borrar ReporteStock',
            creating: 'Creando ReporteStock',
            editing: 'Modificando ReporteStock',
            deleting: 'Eliminando ReporteStock',
            showing: 'Detalles de ReporteStock',
            menu: 'ReporteStock',
            labels: {
                creadoPor: 'Creado por',
                url: 'Archivo',
                data: 'Datos'
            },

          }
       }
    },
    pt: {
       cobrox: {
          reporteStock: {
            name: 'ReporteStock',
            title: 'Administração de ReporteStock',
            subtitle: 'Ver, buscar, criar, editar e usar ReporteStock',
            creating: 'Criando ReporteStock',
            editing: 'Edição ReporteStock',
            deleting: 'Apagando ReporteStock',
            showing: 'Detalhes do ReporteStock',
            menu: 'ReporteStock',
            labels: {
                creadoPor: 'Creado por',
                url: 'Archivo',
                data: 'Datos'
            },

          }
       }
    }
}

export default messages
