<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Reporte de cobranzas por agencia
        </v-card-title>
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" sm="6" md="3">
              <month-picker v-model="date" @input="fetch"></month-picker>
            </v-col>

          </v-row>
        </v-card-text>
      </v-card>

    </v-col>

    <template v-if="report">


      <v-col cols="12" md="4">
        <v-card style="height: 100%" :loading="loading">

          <v-card-text v-if="report.length === 0">
            <v-alert type="info">Sin datos</v-alert>
          </v-card-text>

          <v-card-text v-else>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th>Agencia</th>
                  <th>Cantidad</th>
                  <th>Monto</th>
                  <th :style="{width:'50px'}"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,index) in report" :key="index">
                  <td>{{ item.agencia }}</td>
                  <td>{{ item.cantidad }}</td>
                  <td>${{ item.montoCobrado }}</td>
                  <td><v-btn icon text @click="fetchExport(item.agenciaId)"><v-icon>download</v-icon></v-btn></td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="8">
        <v-card :loading="loading">

          <v-card-text v-if="report.length === 0">
            <v-alert type="info">Sin datos</v-alert>
          </v-card-text>

          <v-card-text v-else>
            <bar-chart v-if="chartdata" :chart-data="chartdata" title="Cobranzas por agencia" @click="onClickChart"></bar-chart>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import {Dayjs} from "@dracul/dayjs-frontend"
import MonthPicker from "@/modules/cobrox/components/MonthPicker/MonthPicker";
import BarChart from "@/modules/cobrox/components/Charts/BarChart";
import {darkColors} from "@/modules/cobrox/utils/colors";
import CobranzaPorAgenciaProvider from "@/modules/cobrox/providers/CobranzaPorAgenciaProvider";

export default {
  name: "CobranzasPorAgencia",
  components: {MonthPicker, BarChart},
  data() {
    return {
      loading: false,
      date: null,
      report: null,
      fileUrl: null
    }
  },
  computed: {
    currentDate() {
      return Dayjs().startOf('month').format("YYYY-MM")
    },
    month() {
      return Dayjs(this.date).format("MM")
    },
    year() {
      return Dayjs(this.date).format("YYYY")
    },
    chartdata() {

      let data = null
      if (this.report.length === 0) {
        data = null
      } else {
        data = {}
        data.labels = this.report.map(i => i.agencia)
        data.datasets = [
          {
            label: 'Monto',
            backgroundColor: darkColors,
            data: this.report.map(i => i.montoCobrado)
          }
        ]
      }

      return data
    },
    getAgenciaId(){

      return nombreAgencia => {
        if(this.report){
          let item = this.report.find(i => nombreAgencia === i.agencia)
          return item.agenciaId
        }

        return null
      }

    }
  },
  created() {
    this.date = this.currentDate
    this.fetch()
  },
  methods: {
    fetch() {
      this.loading = true
      CobranzaPorAgenciaProvider.cobranzaPorAgenciaReport(this.date)
          .then(r => {
            this.report = r.data.cobranzaPorAgenciaReport
          })
          .finally(()=> this.loading = false)
    },
    fetchExport(agenciaId) {
      CobranzaPorAgenciaProvider.cobranzaReporteImputacion(this.date, agenciaId)
          .then(r => {
            let fileUrl = r.data.cobranzaReporteImputacion
            window.location.href = fileUrl;
          })
    },
    onClickChart({item}){
      //Label => NOMBRE AGENCIA
      let labelNombreAgencia = item.length > 0 ? item[0]._model.label : null
      if(labelNombreAgencia){
        let agencia = this.getAgenciaId(labelNombreAgencia)
        this.fetchExport(agencia)
      }

    }
  }
}
</script>

<style scoped>

</style>
