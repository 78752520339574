import graphqlClient from "../../../apollo";

class QuitaProvider {

    findQuita(id) {
        return graphqlClient.query({
            query: require('./gql/Quita/findQuita.graphql'),
            variables: {id:id},
            fetchPolicy: "network-only"
        })
    }

    fetchQuita() {
        return graphqlClient.query({
        query: require('./gql/Quita/fetchQuita.graphql'),
        fetchPolicy: "network-only"
        })
    }
    
    paginateQuita(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Quita/paginateQuita.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }
    
    

    createQuita(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Quita/createQuita.graphql'),
            variables: {input}
        })
    }
    
    updateQuita(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Quita/updateQuita.graphql'),
            variables: {id, input}
        })
    }
    
     deleteQuita(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Quita/deleteQuita.graphql'),
            variables: {id}
        })
    }

}

export default new QuitaProvider()


