
const messages = {
    en: {
       role: {
            permissions: {
                PEDIDOCUENTA_MENU: "Access to PedidoCuenta",
                PEDIDOCUENTA_SHOW: "View PedidoCuenta",
                PEDIDOCUENTA_CREATE: "Create PedidoCuenta",
                PEDIDOCUENTA_UPDATE: "Modify PedidoCuenta",
                PEDIDOCUENTA_DELETE: "Delete PedidoCuenta",
            }
       }
    },
    es: {
        role: {
            permissions: {
                PEDIDOCUENTA_MENU: "Acceder PedidoCuenta",
                PEDIDOCUENTA_SHOW: "Ver PedidoCuenta",
                PEDIDOCUENTA_CREATE: "Crear PedidoCuenta",
                PEDIDOCUENTA_UPDATE: "Modificar PedidoCuenta",
                PEDIDOCUENTA_DELETE: "Eliminar PedidoCuenta",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                PEDIDOCUENTA_MENU: "Acessar PedidoCuenta",
                PEDIDOCUENTA_SHOW: "Ver PedidoCuenta",
                PEDIDOCUENTA_CREATE: "Criar PedidoCuenta",
                PEDIDOCUENTA_UPDATE: "Modificar PedidoCuenta",
                PEDIDOCUENTA_DELETE: "Eliminar PedidoCuenta",
            }
       }
    }
}

export default messages
    