<template>
  <v-data-table
      :items="items"
      :headers="headers"
      :loading="loading"
      :items-per-page="10"
  >

    <template v-slot:item.Capital="{item}">
      {{ currency }}{{ item.Capital.toLocaleString('es-AR') }}
    </template>

    <template v-slot:item.Intereses="{item}">
      {{ currency }}{{ item.Intereses.toLocaleString('es-AR') }}
    </template>

    <template v-slot:item.Total="{item}">
      {{ currency }}{{ item.Total.toLocaleString('es-AR') }}
    </template>

    <template v-slot:item.Recupero="{item}">
      {{ currency }}{{ item.Recupero.toLocaleString('es-AR') }}
    </template>

    <template v-slot:item.EficienciaCapital="{item}">
      {{ (item.Recupero / item.Capital * 100).toFixed(2) }}%
    </template>

    <template v-slot:item.EficienciaTotal="{item}">
      {{ (item.Recupero / item.Total * 100).toFixed(2) }}%
    </template>
  </v-data-table>
</template>
<script>
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";

export default {
  name: 'stock-report-compra-table',
  props: {
    items: {type: Array},
    loading: {type: Boolean}
  },
  mixins: [CurrencyMixin],
  computed: {
    headers() {
      return [
        {text: 'Compra', value: 'Compra'},
        {text: 'Cantidad Personas', value: 'CantidadPersonas'},
        {text: 'Cantidad Deudas', value: 'CantidadDeudas'},
        {text: 'Capital', value: 'Capital'},
        {text: 'Intereses', value: 'Intereses'},
        {text: 'Total', value: 'Total'},

      ]
    }
  },
}
</script>
