<template>
  <crud-update :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @update="update"
               @close="$emit('close')"
  >
    <estado-deuda-form ref="form" v-model="form" :input-errors="inputErrors"/>
  </crud-update>
</template>

<script>

import EstadoDeudaProvider from "../../../../providers/EstadoDeudaProvider";

import {CrudUpdate, ClientError} from '@dracul/common-frontend'

import EstadoDeudaForm from "../EstadoDeudaForm";


export default {
  name: "EstadoDeudaUpdate",

  components: {EstadoDeudaForm, CrudUpdate},

  props: {
    open: {type: Boolean, default: true},
    item: {type: Object, required: true}
  },

  data() {
    return {
      title: 'cobrox.estadoDeuda.editing',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      id: this.item.id,
      form: {
        nombre: this.item.nombre,
        inactiva: this.item.inactiva,
        cancelaSaldos: this.item.cancelaSaldos,
        cancelaSaldosHistorico: this.item.cancelaSaldosHistorico,
        informarBCRA: this.item.informarBCRA,
        rotacionBloqueada: this.item.rotacionBloqueada,
        noRota: this.item.noRota
      }
    }
  },
  methods: {
    update() {
      if (this.$refs.form.validate()) {
        this.loading = true
        EstadoDeudaProvider.updateEstadoDeuda(this.id, this.form).then(r => {
              if (r) {
                this.$emit('itemUpdated', r.data.updateEstadoDeuda)
                this.$emit('close')
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }
  },
}
</script>

<style scoped>

</style>

