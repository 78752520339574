<template>
  <v-row row wrap>

    <v-col cols="12">
      <v-row justify="space-between">
        <v-col cols="12" sm="6" md="8">
            <!-- FILTERS HERE -->
          <v-row>

            <v-col cols="12" md="3">
              <v-btn v-if="$store.getters.hasPermission('PERSONA_IMPORT')" color="purple" dark :to="{name: 'PersonaImportPage'}">IMPORTAR</v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <field-selector :fields="getFields" v-model="fieldsSelected"></field-selector>
            </v-col>
          </v-row>

        </v-col>
        <v-col cols="12" sm="6" md="4">
          <search-input @search="performSearch" v-model="search"/>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">

      <v-data-table
          class="mt-3"
          :headers="headersSelected"
          :items="items"
          :search="search"
          :single-expand="false"
          :server-items-length="totalItems"
          :loading="loading"
          :page.sync="pageNumber"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="orderBy"
          :sort-desc.sync="orderDesc"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
          @update:page="fetch"
          @update:sort-by="fetch"
          @update:sort-desc="fetch"
          @update:items-per-page="fetch"
      >

        <template v-slot:item.situacionLaboral="{ item }">
          {{ item.situacionLaboral ? item.situacionLaboral.nombre : '' }}
        </template>

        <template v-slot:item.agencia="{ item }">
          {{ item.agencia ? item.agencia.nombre : '' }}
        </template>

        <template v-slot:item.fechaNacimiento="{ item }">
          {{ getDateFormat(item.fechaNacimiento) }}
        </template>

        <template v-slot:item.fechaDesbloqueoRotacion="{ item }">
          {{ getDateFormat(item.fechaDesbloqueoRotacion) }}
        </template>

        <template slot="no-data">
          <div class="text-xs-center" v-t="'common.noData'"></div>
        </template>

        <template slot="loading">
          <div class="text-xs-center" v-t="'common.loading'"></div>
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn icon :to="{name: 'PersonaViewPage', params: {id: item.id} }">
            <v-icon>wysiwyg</v-icon>
          </v-btn>
          <edit-button v-if="$store.getters.hasPermission('PERSONA_UPDATE')" @click="$emit('update', item)"/>
          <delete-button v-if="$store.getters.hasPermission('PERSONA_DELETE')" @click="$emit('delete', item)"/>
        </template>

      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import PersonaProvider from "../../../../providers/PersonaProvider";

import {DeleteButton, EditButton, SearchInput} from "@dracul/common-frontend"
import SuretyMixin from "@/modules/surety/mixins/SuretyMixin";
import FieldSelector from "@/modules/cobrox/components/FieldSelector/FieldSelector";
import {DayjsMixin} from "@dracul/dayjs-frontend";


export default {
  name: "PersonaList",
  components: {FieldSelector, DeleteButton, EditButton, SearchInput},
  mixins: [SuretyMixin, DayjsMixin],
  data() {
    return {
      items: [],
      totalItems: null,
      loading: false,
      orderBy: null,
      orderDesc: false,
      itemsPerPage: 10,
      pageNumber: 1,
      search: '',
      filters: [
        /*{
            field: '',
            operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
            value: ''
        }*/
      ],
      fields: ["nombreCompleto", "tipoDocumento", "nroDocumento", "cuil", "sexo", "nacionalidad", "fechaNacimiento", "agencia", "idRapipago", "estado", "rotacionBloqueada", "rotacionBloqueadaPor", "fechaDesbloqueoRotacion", "situacionLaboral", "idClienteColppy", "jurisdiccion","jobId"],
      fieldsSelected: ['estado','nombreCompleto','tipoDocumento','nroDocumento','cuil','agencia'],
    }
  },
  computed: {
    getFields(){
      return this.filterViewFields(this.fields)
    },
    getFieldSelected(){
      return this.filterViewFields(this.fieldsSelected)
    },
    headersSelected() {
      return this.headers.filter(h => this.getFieldSelected.includes(h.value) || h.value === 'action')
    },
    headers() {
      return [
        //Entity Headers
        {text: this.$t('cobrox.persona.labels.estado'), value: 'estado'},
        {text: this.$t('cobrox.persona.labels.nombreCompleto'), value: 'nombreCompleto'},
        {text: this.$t('cobrox.persona.labels.tipoDocumento'), value: 'tipoDocumento'},
        {text: this.$t('cobrox.persona.labels.nroDocumento'), value: 'nroDocumento'},
        {text: this.$t('cobrox.persona.labels.cuil'), value: 'cuil'},
        {text: this.$t('cobrox.persona.labels.sexo'), value: 'sexo'},
        {text: this.$t('cobrox.persona.labels.jurisdiccion'), value: 'jurisdiccion'},
        {text: this.$t('cobrox.persona.labels.nacionalidad'), value: 'nacionalidad'},
        {text: this.$t('cobrox.persona.labels.fechaNacimiento'), value: 'fechaNacimiento'},
        {text: this.$t('cobrox.persona.labels.agencia'), value: 'agencia'},
        {text: this.$t('cobrox.persona.labels.rotacionBloqueada'), value: 'rotacionBloqueada'},
        {text: this.$t('cobrox.persona.labels.rotacionBloqueadaPor'), value: 'rotacionBloqueadaPor'},
        {text: this.$t('cobrox.persona.labels.fechaDesbloqueoRotacion'), value: 'fechaDesbloqueoRotacion'},
        {text: this.$t('cobrox.persona.labels.idRapipago'), value: 'idRapipago'},
        {text: this.$t('cobrox.persona.labels.idClienteColppy'), value: 'idClienteColppy'},
        {text: this.$t('cobrox.persona.labels.situacionLaboral'), value: 'situacionLaboral'},
        {text: 'jobId', value: 'jobId'},
        //Actions
        {text: this.$t('common.actions'), value: 'action', sortable: false},
      ]
    },
    getOrderBy() {
      return (Array.isArray(this.orderBy)) ? this.orderBy[0] : this.orderBy
    },
    getOrderDesc() {
      return (Array.isArray(this.orderDesc)) ? this.orderDesc[0] : this.orderDesc
    }
  },
  created() {
    this.fetchFieldPermission('Persona')
    this.fetch()
  },
  methods: {
    performSearch() {
      this.pageNumber = 1
      this.fetch()
    },
    fetch() {
      this.loading = true
      PersonaProvider.paginatePersona(
          this.pageNumber,
          this.itemsPerPage,
          this.search,
          this.filters,
          this.getOrderBy,
          this.getOrderDesc
      ).then(r => {
        this.items = r.data.paginatePersona.items
        this.totalItems = r.data.paginatePersona.totalItems
      }).catch(err => {
        console.error(err)
      }).finally(() => this.loading = false)
    }
  }

}
</script>


