<template>
  <v-container>
    <v-card>
      <v-card-title>
        Creando Ticket <template v-if="routeDni">({{routeDni}})</template>
      </v-card-title>
      <v-card-text v-if="errorMessage">
        <v-alert type="error">

        <ul>
          <li v-for="(val,idx) in inputErrors" :key="idx">
            {{val}}
          </li>
        </ul>
        </v-alert>
      </v-card-text>
      <v-card-text>
        <redmine-issue-form v-model="form" :custom-fields="customFields" ></redmine-issue-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <submit-button @click="createIssue"></submit-button>
      </v-card-actions>
    </v-card>

  </v-container>
</template>

<script>
import RedmineIssueForm from "@/modules/redmine/components/RedmineIssueForm/RedmineIssueForm";
import RedmineProvider from "@/modules/redmine/providers/RedmineProvider";
import {ClientError, SubmitButton} from "@dracul/common-frontend";

export default {
  name: "RedmineCreateIssuePage",
  components: {RedmineIssueForm, SubmitButton},

  data(){
    return {
      issue: null,
      form: {
        tracker_id: null,
        subject: '',
        description: '',
        custom_fields: []
      },
      customFields: [],
      inputErrors: [],
      errorMessage: null
    }
  },
  watch:{
    'form.tracker_id':{
      handler(){
        this.fetchCustomFields()
      }
    }
  },
  computed: {
    routeDni(){
      return this.$route.params.dni
    }
  },
  methods:{
    createIssue(){
      this.inputErrors = []
      this.errorMessage = ""
      RedmineProvider.createRedmineIssue(this.form)
          .then(r => {
            this.issue = r.data.createRedmineIssue
            this.$router.push({name: 'RedmineIssueViewPage', params: {id: this.issue.id} })
          })
          .catch(error => {
            let clientError = new ClientError(error)

            error.graphQLErrors.forEach(gqlError =>{
              for(let inputError of gqlError.extensions.exception.errors ){
                this.inputErrors.push(inputError)
              }
            })

            //this.inputErrors = clientError.inputErrors
            this.errorMessage = clientError.errorMessage
          })
    },
    injectDni(){
      let index =  this.form.custom_fields.findIndex(i => i.name === 'DNI')
      if(index != -1){
        this.form.custom_fields[index].value = this.routeDni
      }
    },
    fetchCustomFields(){
      RedmineProvider.fetchRedmineCustomFieldsByTracker(this.form.tracker_id)
          .then(r => {
            this.form.custom_fields = r.data.fetchRedmineCustomFieldsByTracker.custom_fields.map(c => ({ id: c.id, name: c.name, value: null, field_format: c.field_format })  )
            this.customFields = r.data.fetchRedmineCustomFieldsByTracker.custom_fields
            this.injectDni()
          })
    }
  }
}
</script>

<style scoped>

</style>
