<template>
  <crud-layout :title="title" :subtitle="subtitle">

    <template v-slot:list>
      <cuenta-list
          ref="list"
          @update="update"
          @delete="remove"
          @show="show"

      />
    </template>

    <add-button v-if="$store.getters.hasPermission('CUENTA_CREATE')" @click="create"></add-button>


    <cuenta-create v-if="creating"
                   :open="creating"
                   v-on:itemCreated="onItemCreated"
                   v-on:close="creating=false"
    />

    <cuenta-update v-if="updating"
                   :open="updating"
                   :item="itemToEdit"
                   v-on:itemUpdated="onItemUpdated"
                   v-on:close="updating=false"
    />

    <cuenta-show v-if="showing"
                 :open="showing"
                 :item="itemToShow"
                 v-on:close="showing=false"
    />

    <cuenta-delete v-if="deleting"
                   :open="deleting"
                   :item="itemToDelete"
                   v-on:itemDeleted="onItemDeleted"
                   v-on:close="deleting=false"
    />

    <snackbar v-model="flash"/>

  </crud-layout>
</template>

<script>

import CuentaCreate from "../CuentaCreate";
import CuentaUpdate from "../CuentaUpdate";
import CuentaDelete from "../CuentaDelete";
import CuentaShow from "../CuentaShow";
import CuentaList from "../CuentaList";

import {CrudLayout, AddButton, Snackbar} from "@dracul/common-frontend"

export default {
  name: "CuentaCrud",
  components: {
    CrudLayout, AddButton, Snackbar,
    CuentaCreate,
    CuentaUpdate,
    CuentaDelete,
    CuentaShow,
    CuentaList
  },
  data() {
    return {
      title: 'cobrox.cuenta.title',
      subtitle: 'cobrox.cuenta.subtitle',
      flash: null,
      creating: false,
      updating: false,
      deleting: false,
      showing: false,
      itemToEdit: null,
      itemToDelete: null,
      itemToShow: null,
    }
  },
  methods: {
    //On
    onItemCreated() {
      this.$refs.list.fetch()
      this.flash = "common.created"
    },
    onItemUpdated() {
      this.$refs.list.fetch()
      this.flash = "common.updated"
    },
    onItemDeleted() {
      this.$refs.list.fetch()
      this.flash = "common.deleted"
    },
    //Open
    create() {
      this.creating = true
    },
    update(item) {
      this.updating = true
      this.itemToEdit = item
    },
    show(item) {
      this.showing = true
      this.itemToShow = item
    },
    remove(item) {
      this.deleting = true
      this.itemToDelete = item
    }
  }

}
</script>


