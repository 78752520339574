<template>
  <v-btn  x-small v-if="visible"
         color="green white--text"
         @click="fetchDoc" class="mb-2">
    Libre deuda
  </v-btn>
</template>

<script>
import FileBuilderProvider from "@/modules/cobrox/providers/FileBuilderProvider";
import DeudaProvider from "@/modules/cobrox/providers/DeudaProvider";

export default {
  name: "LibreDeudaDeudaFileButton",
  props: {
    deuda: {type: Object}
  },
  data() {
    return {
      estadosDeuda: [],
      visible: true,
    }
  },

  mounted() {
    //this.hayDeudasPendientes()
  },
  methods: {
    hayDeudasPendientes() {
      DeudaProvider.hayDeudasPendientes(this.deuda.dniDeudor)
          .then(r => {
            if(!r.data.hayDeudasPendientes){
              this.visible = true
            }
          })
    },
    fetchDoc() {
      FileBuilderProvider.buildLibreDeudaPdf(this.deuda.id)
          .then(r => {
            setTimeout(() => window.open(r.data.buildLibreDeudaPdf), 300)
          })
    },

  }
}
</script>

<style scoped>

</style>
