
const messages = {
    en: {
       cobrox: {
          comision: {
            name: 'Comision',
            title: 'Comision management',
            subtitle: 'View, search, create, edit and delete Comision',
            creating: 'Creating Comision',
            editing: 'Editing Comision',
            deleting: 'Deleting Comision',
            showing: 'Showing Comision',
            menu: 'Comision',
            labels: {
                agencia: 'Agencia',
                compra: 'Compra',
                ratioDesde: 'Ratio Desde',
                ratioHasta: 'Ratio Hasta',
                comision: 'Comisión'
            },

          }
       }
    },
    es: {
       cobrox: {
          comision: {
            name: 'Comisión',
            title: 'Administración de Comisión',
            subtitle: 'Ver, buscar, crear, editar, y borrar Comisión',
            creating: 'Creando Comisión',
            editing: 'Modificando Comisión',
            deleting: 'Eliminando Comisión',
            showing: 'Detalles de Comisión',
            menu: 'Comisión',
            labels: {
                agencia: 'Agencia',
                compra: 'Compra',
                ratioDesde: 'Ratio Desde',
                ratioHasta: 'Ratio Hasta',
                comision: 'Comisión'
            },

          }
       }
    },
    pt: {
       cobrox: {
          comision: {
            name: 'Comision',
            title: 'Administração de Comision',
            subtitle: 'Ver, buscar, criar, editar e usar Comision',
            creating: 'Criando Comision',
            editing: 'Edição Comision',
            deleting: 'Apagando Comision',
            showing: 'Detalhes do Comision',
            menu: 'Comision',
            labels: {
                agencia: 'Agencia',
                compra: 'Compra',
                ratioDesde: 'Ratio Desde',
                ratioHasta: 'Ratio Hasta',
                comision: 'Comisión'
            },

          }
       }
    }
}

export default messages
