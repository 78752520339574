<template>
    <v-card outlined>
      <v-card-title>Calculadora de planes</v-card-title>

      <v-card-text v-if="quitas">

        <template v-if="cardView">
          <v-row>
            <v-col cols="12" sm="3" v-for="(plan,index) in this.planesConQuitaFinal" :key="index">
              <v-card >
                <v-card-title> {{ plan.cuotaDesde }}-{{ plan.cuotaHasta }} Cuotas</v-card-title>
                <v-card-text>
                  <ul>
                    <li>Monto:  {{ currency }}{{ plan.montoTotal.toLocaleString('es-AR') }}</li>
                    <li><span :class="plan.ajuste > 0 ? 'red--text' : 'green--text'">{{plan.ajuste > 0 ? 'Intereses' : 'Quita'}}</span>:  {{ currency }}{{ plan.ajuste.toLocaleString('es-AR') }}</li>
                    <li>Final:  {{ currency }}{{ plan.finalConAjuste.toLocaleString('es-AR') }}</li>
                  </ul>

                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

        </template>


        <v-simple-table v-else :dense="dense">
          <thead>
          <tr>
            <th></th>
            <th v-for="(plan,index) in this.planesConQuitaFinal" :key="index">
              {{ plan.cuotaDesde }}-{{ plan.cuotaHasta }} Cuotas
            </th>
          </tr>
          </thead>
          <tbody>


          <tr>
            <th>Quita</th>
            <td v-for="(plan,index) in this.planesConQuitaFinal" :key="index">
              {{ currency }}{{ plan.ajuste.toLocaleString('es-AR') }}
            </td>
          </tr>
          <tr>
            <th>Final a pagar</th>
            <td v-for="(plan,index) in this.planesConQuitaFinal" :key="index">
              {{ currency }}{{ plan.finalConAjuste.toLocaleString('es-AR') }}
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>


      <v-card-text v-if="detalleEnable && $store.getters.hasPermission('QUITA_CALCULADOR_DETALLE') && quitas">

        <v-expansion-panels v-model="quitaDetail" tile >
          <v-expansion-panel >
            <v-expansion-panel-header>Calculo detallado por deuda</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                  :dense="dense"
                  :items="deudasActivas"
                  :headers="headers"
                  hide-default-footer
                  :items-per-page="-1"
              >

                <template v-slot:item.intereses="{ item }">
                  {{ currency }}{{ item.intereses.toLocaleString('es-AR') }}
                </template>

                <template v-slot:item.quita="{ item }">
                  <v-simple-table dense>
                    <thead>
                    <tr>
                      <th></th>
                      <th v-for="(plan, index) in planesConQuitaPorDeuda(item)" :key="index">
                        {{ plan.cuotaDesde }}-{{ plan.cuotaHasta }} Cuotas ({{ plan.quita }}%)
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th>Intereses</th>
                      <td v-for="(plan, index) in planesConQuitaPorDeuda(item)" :key="index">
                        {{ currency }}{{ plan.intereses.toLocaleString('es-AR') }}
                      </td>
                    </tr>

                    <tr>
                      <th>Quita</th>
                      <td v-for="(plan, index) in planesConQuitaPorDeuda(item)" :key="index">
                        {{ currency }}{{ plan.ajuste.toLocaleString('es-AR') }}
                      </td>
                    </tr>

                    </tbody>
                  </v-simple-table>
                </template>

              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </v-card-text>

    </v-card>

</template>

<script>
import QuitaProvider from "@/modules/cobrox/providers/QuitaProvider";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";

export default {
  name: "CalculadoraQuita",
  props: {
    deudas: {type: Array, required: true},
    detalleEnable: {type: Boolean, default: false},
    dense: {type: Boolean, default: false},
    cardView: {type: Boolean, default: false},
  },
  mixins: [CurrencyMixin],
  data() {
    return {
      quitaDetail: null,
      loading: false,
      quitas: null,
    }
  },
  computed: {
    headers() {
      return [
        {text: this.$t('cobrox.deuda.labels.idDeuda'), value: 'idDeuda', width: '140'},
        {text: this.$t('cobrox.quita.labels.quita'), value: 'quita', width: '120'},
      ]
    },
    deudasActivas() {
      return this.deudas.filter(d => d.estado == 'ACTIVO')
    },
    planesConQuitaFinal() {
     // console.log("QUITA. DeudasActivas" ,this.deudasActivas, "quitas: ", this.quitas )
      let planesFinal = this.deudasActivas.reduce((pf, deuda) => {
        let planesDeuda = this.planesConQuitaPorDeuda(deuda)
        console.log("planesConQuitaFinal. DeudasActivas: " ,this.deudasActivas, " quitas: ", this.quitas, " planesQuita:",  planesDeuda)
        planesDeuda.forEach(planDeuda => {
          let plan = pf.find(plan => plan.cuotaDesde == planDeuda.cuotaDesde && plan.cuotaHasta == planDeuda.cuotaHasta)
          if (plan) {
            plan.capitalTotal += planDeuda.capitalTotal
            plan.intereses += planDeuda.intereses
            plan.montoTotal += planDeuda.capitalTotal + planDeuda.intereses
            plan.ajuste += planDeuda.ajuste
            plan.finalConAjuste += planDeuda.finalConAjuste
            plan.cantidad++
          } else {
            pf.push({
              cantidad: 1,
              cuotaDesde: planDeuda.cuotaDesde,
              cuotaHasta: planDeuda.cuotaHasta,
              capitalTotal: planDeuda.capitalTotal,
              intereses: planDeuda.intereses,
              montoTotal: planDeuda.capitalTotal + planDeuda.intereses,
              ajuste: planDeuda.ajuste,
              finalConAjuste: planDeuda.finalConAjuste
            })
          }
        })

        console.log("PLAN FINAL" ,this.pf)
        return pf
      }, [])

      return planesFinal.filter(plan => plan.cantidad == this.deudasActivas.length)
    },
    planesConQuitaPorDeuda() {
      return deuda => {
        let quita = this.findQuita(deuda.fechaMora)
        if (!quita) {
          console.log("planesConQuitaPorDeuda. Quita no encontrada")
          return []
        }

        let calculos = quita.planes.map(plan => {
          let ajuste = Math.ceil(deuda.intereses * plan.quita / 100)
          let finalConAjuste = deuda.capitalTotal + deuda.intereses
            finalConAjuste += ajuste
          return {
            cuotaDesde: plan.cuotaDesde,
            cuotaHasta: plan.cuotaHasta,
            quita: plan.quita,
            capitalTotal: deuda.capitalTotal,
            intereses: deuda.intereses,
            montoTotal: deuda.capitalTotal + deuda.intereses,
            ajuste: ajuste,
            finalConAjuste: finalConAjuste

          }
        })
        return calculos
      }
    },
    findQuita() {
      return fechaMora => this.quitas.find(q => parseInt(fechaMora) >= parseInt(q.moraDesde)  && parseInt(fechaMora) <= parseInt(q.moraHasta) )
    }
  },
  created() {
    this.fetchQuitas()
  },
  methods: {
    fetchQuitas() {
      this.loading = true;
      QuitaProvider.fetchQuita()
          .then(r => {
            this.quitas = r.data.fetchQuita
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            this.loading = false
          })
    },
    getMontoForCuota(cuota) {
      let plan =  this.planesConQuitaFinal.find(plan => cuota >= plan.cuotaDesde && cuota <= plan.cuotaHasta)
      return plan ? plan.finalConAjuste : 0
    },
    hasPlanForCuota(cuota) {
      return !!this.planesConQuitaFinal.find(plan => cuota >= plan.cuotaDesde && cuota <= plan.cuotaHasta)
    }
  }
}
</script>

<style scoped>

</style>
