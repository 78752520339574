<template>
  <v-row>
    <v-col cols="12" sm="6" md="4">
      <v-list>
        <show-field :value="item.dniDeudor" :label="$t('cobrox.convenio.labels.dniDeudor')" icon="badge"/>
        <show-field :value="item.cuilDeudor" :label="$t('cobrox.convenio.labels.cuilDeudor')" icon="contact_page"/>
        <show-field :value="item.origen" :label="$t('cobrox.convenio.labels.origen')" icon="origin"/>

      </v-list>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-list>
        <show-field :value="String(item.fueraDePauta)" :label="$t('cobrox.convenio.labels.fueraDePauta')"
                    icon="history_edu"/>
        <show-field :value="getDateFormat(item.fechaPrimerPago)" :label="$t('cobrox.convenio.labels.fechaPrimerPago')"
                    icon="event"/>
        <show-field :value="item.deudas.map(i => i.idDeuda).join(', ')" :label="$t('cobrox.convenio.labels.deudas')"
                    icon="account_balance_wallet"/>

        <show-field :value="item.cobranzas.map(i => i.montoCobrado).join(', ')"
                    :label="$t('cobrox.convenio.labels.cobranzas')" icon="point_of_sale"/>
      </v-list>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-list>
        <show-field :value="String(item.cuotas)" :label="$t('cobrox.convenio.labels.cuotas')"
                    icon="format_list_numbered_rtl"/>
        <show-field :value="String(item.montoTotal)" :label="$t('cobrox.convenio.labels.montoTotal')" icon="paid"/>

        <show-field :value="String(item.montoCuota)" :label="$t('cobrox.convenio.labels.montoCuota')" icon="paid"/>
      </v-list>
    </v-col>

  </v-row>
</template>
<script>
import {ShowField} from '@dracul/common-frontend'
import {DayjsMixin} from "@dracul/dayjs-frontend";

export default {
  name: 'ConvenioShowData',
  components: {ShowField},
  mixins: [DayjsMixin],
  props: {
    item: {type: Object, required: true}
  }
}
</script>

