<template>
  <v-card flat>

    <!--SIN CONVENIOS-->
    <v-card-text v-if="convenios.length === 0" class="d-flex flex-column justify-center ">
      <v-card outlined class="mb-3">
        <v-card-title>
          Deudas Sumarizadas
        </v-card-title>
        <v-card-text>
          <deuda-total :deudas="deudas"></deuda-total>
        </v-card-text>
      </v-card>

      <calculadora-quita v-if=" $store.getters.hasPermission('QUITA_CALCULADOR')" :deudas="deudas"
                         card-view></calculadora-quita>

      <v-alert type="info" class="mt-6">No hay convenios cargados para esta persona</v-alert>
      <v-btn class="primary onPrimary--text" @click="create">Crear Convenio</v-btn>



    </v-card-text>

    <!--1 CONVENIO-->
    <v-card-text v-else-if="convenios.length === 1">
      <convenio-card :convenio="getConvenio"/>
    </v-card-text>

    <!--Multiples CONVENIOS-->
    <v-card-text v-else-if="convenios.length > 1">
      <v-alert type="info" dense class="mt-1">Multiples convenios cargados</v-alert>
      <v-row>
        <v-col cols="12" v-for="convenio in convenios" :key="convenio.id">
          <convenio-card :convenio="convenio"/>
        </v-col>
      </v-row>
    </v-card-text>

    <!--Deuda sin CONVENIOS-->

    <v-card-text v-if="convenios.length > 0 && hasDeudaActivaSinConvenio">
      <v-alert type="warning" dense class="mt-1">Se verifican deudas activas sin convenio vigente</v-alert>
      <v-btn class="primary onPrimary--text" @click="create">Crear Convenio</v-btn>
    </v-card-text>


    <convenio-create v-if="creating"
                     :open="creating"
                     v-on:itemCreated="onItemCreated"
                     v-on:close="creating=false"
                     :disable-fields="['cuilDeudor','dniDeudor','montoTotal','montoCuota']"
                     :dni-deudor="dniDeudor"
                     :cuil-deudor="cuilDeudor"
                     :deudas-not-selectable="deudasNotSelectable"
                     :cobranzas-not-selectable="cobranzasNotSelectable"
    />
  </v-card>
</template>

<script>
import ConvenioProvider from "@/modules/cobrox/providers/ConvenioProvider";
import {ClientError} from "@dracul/common-frontend";
import ConvenioCreate from "@/modules/cobrox/pages/crud/ConvenioCrudPage/ConvenioCreate";
import DeudaTotal from "@/modules/cobrox/components/DeudaTotal/DeudaTotal.vue";
import CalculadoraQuita from "@/modules/cobrox/components/CalculadoraQuita/CalculadoraQuita.vue";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import {DayjsMixin} from "@dracul/dayjs-frontend";
import ConvenioCard from "@/modules/cobrox/components/ConvenioCard/ConvenioCard.vue";

export default {
  name: "ConvenioManager",
  components: {ConvenioCard, CalculadoraQuita, DeudaTotal, ConvenioCreate,},
  mixins: [CurrencyMixin, DayjsMixin],
  props: {
    deudas: {type: Array},
    dniDeudor: {type: String},
    cuilDeudor: {type: String}
  },
  data: () => ({
    loading: false,
    errorMessage: '',
    inputErrors: {},
    convenios: [],
    creating: false,
  }),
  created() {
    this.fetchConvenio()
  },
  computed: {
    hasDeudaActiva() {
      return this.deudas.filter(deuda => deuda.estado === 'ACTIVO').length > 0
    },
    getConvenio() {
      return this.convenios.length === 1 ? this.convenios[0] : null
    },
    hasDeudaActivaSinConvenio(){
      return this.deudas.some(deuda => deuda.estado === 'ACTIVO' && !this.convenios.some(c => c.deudas.map(d => d.id).includes(deuda.id)))
    },
    deudasNotSelectable(){
      return this.convenios ? this.convenios.reduce((deudas, convenio) => [...deudas, ...convenio.deudas.map(d => d.id)], []) : []
    },
    cobranzasNotSelectable(){
      return this.convenios ? this.convenios.reduce((cobranzas, convenio) => [...cobranzas, ...convenio.cobranzas.map(c => c.id)], []) : []
    }
  },
  methods: {
    create() {
      this.creating = true
    },
    onItemCreated() {
      this.fetchConvenio()
      this.flash = this.$t("common.created")
    },
    confirmarConvenio() {
      this.loading = true
      ConvenioProvider.createConvenio(this.convenio)
          .then(r => {
            this.convenio = r.data.createConvenio
          })
          .catch(error => {
            let clientError = new ClientError(error)
            this.inputErrors = clientError.inputErrors
            this.errorMessage = clientError.i18nMessage
          })
          .finally(() => {
            this.loading = false;
          });
    },
    fetchConvenio() {
      this.loading = true;
      ConvenioProvider.fetchConvenioByCuil(this.cuilDeudor)
          .then(r => {
            this.convenios = r.data.fetchConvenioByCuil;
          })
          .catch(e => {
            console.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
    },
    findConvenio() {
      this.loading = true;
      ConvenioProvider.findConvenioByCuil(this.cuilDeudor)
          .then(r => {
            if (r.data.findConvenioByCuil) {
              let convenio = r.data.findConvenioByCuil;
              convenio.deudas = convenio.deudas.map(i => i.id)
              convenio.cobranzas = convenio.cobranzas.map(i => i.id)
              this.convenio = convenio;
            }

          })
          .catch(e => {
            console.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
    }
  }
}
</script>

