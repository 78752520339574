<template>
  <v-container fluid>
    <v-card :loading="loading">


      <template v-if="persona">

        <v-card-title>
          {{ persona.nombreCompleto }}

        </v-card-title>
        <v-card-subtitle>{{ persona.tipoDocumento }}: {{ persona.nroDocumento }} / CUIL: {{ persona.cuil }}</v-card-subtitle>


        <v-tabs
            v-model="tab"
            align-with-title
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab>
            Principal
          </v-tab>
          <v-tab >
            Cuenta
          </v-tab>

          <v-tab v-if="$store.getters.hasPermission('CONVENIO_SHOW')">
            Convenio
          </v-tab>

          <v-tab
              v-for="item in getTabItems"
              :key="item"
          >
            {{ item }}
          </v-tab>

          <v-tab v-if="canView('agenciaHistorico')">
            Historial de Agencias
          </v-tab>

          <v-tab>
            Tickets
          </v-tab>


        </v-tabs>


        <v-tabs-items v-model="tab">
          <v-tab-item
              key="principal"
          >
            <v-card-text>
              <persona-main-view :persona="persona"></persona-main-view>
              <v-divider></v-divider>
              <persona-notas-view v-if="canView('notas')" :notas="persona.notas"></persona-notas-view>
              <v-divider></v-divider>
              <add-nota-persona v-if="canView('notas') && canEdit('notas')" :persona-id="persona.id" @notaAdded="p => persona = p"></add-nota-persona>
            </v-card-text>
          </v-tab-item>

          <v-tab-item
              key="cuenta"
          >
            <v-card-text>


              <persona-deudas-view
                  :deudas="deudas"
                  :cuil="persona.cuil"
              />

              <v-container fluid>
              <calculadora-quita v-if="$store.getters.hasPermission('QUITA_CALCULADOR')" :deudas="deudas"></calculadora-quita>
              </v-container>

              <persona-cobranzas-view
                  :cobranzas="cobranzas"
                  :dni-deudor="persona.nroDocumento"
                  :cuil="persona.cuil"
                  @itemCreated="fetchCobranzas"
                  @itemUpdated="fetchCobranzas"
                  @itemDeleted="fetchCobranzas"
              />
            </v-card-text>
          </v-tab-item>

          <v-tab-item
              v-if="$store.getters.hasPermission('CONVENIO_SHOW')"
              key="convenio"
          >
            <v-card-text>
              <convenio-manager
                  v-if="persona"
                  :deudas="deudas"
                  :cuil-deudor="persona.cuil"
                  :dni-deudor="persona.nroDocumento"
              ></convenio-manager>
            </v-card-text>
          </v-tab-item>

          <v-tab-item
              v-if="canView('telefonos')"
              key="telefonos"

          >
            <v-card-text>
              <persona-telefonos-view :contactos="persona.telefonos"></persona-telefonos-view>
            </v-card-text>
          </v-tab-item>

          <v-tab-item
              v-if="canView('emails')"
              key="emails"
          >
            <v-card-text>
              <persona-emails-view :contactos="persona.emails"></persona-emails-view>
            </v-card-text>
          </v-tab-item>

          <v-tab-item
              v-if="canView('domicilios')"
              key="domicilios"
          >
            <v-card-text>
              <persona-domicilios-table-view :domicilios="persona.domicilios"></persona-domicilios-table-view>
            </v-card-text>
          </v-tab-item>

          <v-tab-item
              v-if="canView('cbus')"
              key="cbus"
          >
            <v-card-text>
              <persona-cbus-view :cbus="persona.cbus"></persona-cbus-view>
            </v-card-text>
          </v-tab-item>

          <v-tab-item
              v-if="canView('documentos')"
              key="documentos"
          >
            <v-card-text>
              <persona-documentos-view :documentos="persona.documentos"></persona-documentos-view>
            </v-card-text>
          </v-tab-item>

          <v-tab-item
              v-if="canView('agenciaHistorico')"
              key="Historial Agencia"
          >
            <v-card-text>
              <persona-historico-agencia
                  :agencia="persona.agencia"
                  :historico="persona.agenciaHistorico"
              ></persona-historico-agencia>
            </v-card-text>
          </v-tab-item>

          <v-tab-item
              key="tickets"
          >
            <v-row>
              <v-col cols="12" class="text-right">
                {{ dni }}
                <v-btn :to="{name:'RedmineIssueCreatePageByDni',params: {dni:persona.nroDocumento}}"
                       class="primary OnPrimary--text  mr-3" small>Crear Ticket
                </v-btn>
              </v-col>
              <v-col cols="12">
                <redmine-issues-list :customField="1" :customFieldValue="persona.nroDocumento"></redmine-issues-list>
              </v-col>

              <v-col cols="12">
                <persona-pedidos-cuenta :cuil="persona.cuil"></persona-pedidos-cuenta>
              </v-col>
            </v-row>

          </v-tab-item>



        </v-tabs-items>
      </template>

      <template v-else>
        <v-skeleton-loader
            type="list-item-two-line"
        ></v-skeleton-loader>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import PersonaProvider from "@/modules/cobrox/providers/PersonaProvider";
import PersonaMainView from "@/modules/cobrox/components/PersonaView/PersonaMainView";
import DeudaProvider from "@/modules/cobrox/providers/DeudaProvider";
import CobranzaProvider from "@/modules/cobrox/providers/CobranzaProvider";
import PersonaDeudasView from "@/modules/cobrox/components/PersonaView/PersonaDeudasView";
import PersonaCobranzasView from "@/modules/cobrox/components/PersonaView/PersonaCobranzasView";
import PersonaNotasView from "@/modules/cobrox/components/PersonaView/PersonaNotasView";
import AddNotaPersona from "@/modules/cobrox/components/AddNotaPersona";
import PersonaDomiciliosTableView
  from "@/modules/cobrox/components/PersonaView/PersonaDomiciliosTableView/PersonaDomiciliosTableView";
import PersonaHistoricoAgencia
  from "@/modules/cobrox/components/PersonaView/PersonaHistoricoAgencia/PersonaHistoricoAgencia";
import PersonaTelefonosView from "@/modules/cobrox/components/PersonaView/PersonaTelefonosView";
import PersonaEmailsView from "@/modules/cobrox/components/PersonaView/PersonaEmailsView";
import PersonaCbusView from "@/modules/cobrox/components/PersonaView/PersonaCbusView/PersonaCbusView";
import RedmineIssuesList from "@/modules/redmine/components/RedmineIssuesList/RedmineIssuesList";
import SuretyMixin from "@/modules/surety/mixins/SuretyMixin";
import PersonaDocumentosView from "@/modules/cobrox/components/PersonaView/PersonaDocumentosView/PersonaDocumentosView";
import PersonaPedidosCuenta
  from "@/modules/cobrox/components/PersonaView/PersonaPedidosCuenta/PersonaPedidosCuenta.vue";

import CalculadoraQuita from "@/modules/cobrox/components/CalculadoraQuita/CalculadoraQuita.vue";
import ConvenioManager from "@/modules/cobrox/components/ConvenioManager/ConvenioManager.vue";

export default {
  name: "PersonaView",
  components: {
    ConvenioManager,
    CalculadoraQuita,
    PersonaPedidosCuenta,
    PersonaDocumentosView,
    RedmineIssuesList,
    PersonaCbusView,
    PersonaEmailsView,
    PersonaTelefonosView,
    PersonaHistoricoAgencia,
    PersonaDomiciliosTableView,
    AddNotaPersona,
    PersonaNotasView,
    PersonaCobranzasView,
    PersonaDeudasView,
    PersonaMainView,
  },
  mixins: [SuretyMixin],
  props: {
    id: {type: String},
    dni: {type: String},
    cuil: {type: String},
  },
  data() {
    return {
      loading: false,
      loadingDeudas: false,
      loadingCobranzas: false,
      persona: null,
      deudas: [],
      cobranzas: [],
      tab: null,
      tabItems: [
         'cuenta', 'convenios','telefonos', 'emails', 'domicilios', 'cbus','documentos'
      ],
    }
  },
  computed:{
    getTabItems() {
      return this.filterViewFields(this.tabItems)
    },
  },
  created() {
    this.fetchFieldPermission('Persona')
    if (this.id) {
      this.findPersona()
    } else if (this.dni) {
      this.findPersonaByDni()
    }else if (this.cuil) {
      this.findPersonaByCuil()
    }

  },
  methods: {
    findPersona() {
      this.loading = true

      if (!this.id) return

      PersonaProvider.findPersona(this.id)
          .then(r => {
            this.persona = r.data.findPersona
            this.fetchDeudas()
            this.fetchCobranzas()
          })
          .finally(() => this.loading = false)
    },
    findPersonaByDni() {
      PersonaProvider.findPersonaByNroDocumento(this.dni)
          .then(r => {
            this.persona = r.data.findPersonaByNroDocumento
            this.fetchDeudas()
            this.fetchCobranzas()
          })
          .finally(() => this.loading = false)
    },
    findPersonaByCuil() {
      PersonaProvider.findPersonaByCuil(this.cuil)
          .then(r => {
            this.persona = r.data.findPersonaByCuil
            this.fetchDeudas()
            this.fetchCobranzas()
          })
          .finally(() => this.loading = false)
    },
    fetchDeudas() {
      this.loadingDeudas = true
      if (this.persona) {
        this.fetchDeudasByDni()
      }
    },
    fetchDeudasByCuil() {
      this.loadingDeudas = true
      if (this.persona) {
        DeudaProvider.fetchDeudaByCuil(this.persona.cuil)
            .then(r => {
              this.deudas = r.data.fetchDeudaByCuil
            })
            .finally(() => this.loadingDeudas = false)
      }
    },
    fetchDeudasByDni() {
      this.loadingDeudas = true
      if (this.persona) {
        DeudaProvider.fetchDeudaByDni(this.persona.nroDocumento)
            .then(r => {
              this.deudas = r.data.fetchDeudaByDni
            })
            .finally(() => this.loadingDeudas = false)
      }
    },
    fetchCobranzas() {
      this.loadingCobranzas = true
      if (this.persona) {
        CobranzaProvider.fetchCobranzaByDni(this.persona.nroDocumento)
            .then(r => {
              this.cobranzas = r.data.fetchCobranzaByDni
            })
            .finally(() => this.loadingCobranzas = false)
      }
    },
    fetchFacturas() {

    }
  }
}
</script>

<style scoped>

</style>
