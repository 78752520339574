<template>
    <v-row>
        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="getDateFormat(item.fecha)" :label="$t('cobrox.cajaMensual.labels.fecha')" icon=""/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="String(item.cerrada)" :label="$t('cobrox.cajaMensual.labels.cerrada')" icon="lock"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>

            </v-list>
        </v-col>

    </v-row>
</template>
<script>
    import {ShowField} from '@dracul/common-frontend'
    import {DayjsMixin} from "@dracul/dayjs-frontend";

    export default {
        name: 'CajaMensualShowData',
        components: {ShowField},
        mixins: [DayjsMixin],
        props: {
            item: {type: Object, required: true}
        }
    }
</script>

