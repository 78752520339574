import graphqlClient from "../../../apollo";

class SituacionLaboralProvider {

    findSituacionLaboral(id) {
        return graphqlClient.query({
            query: require('./gql/SituacionLaboral/findSituacionLaboral.graphql'),
            variables: {id:id}
        })
    }

    fetchSituacionLaboral() {
        return graphqlClient.query({query: require('./gql/SituacionLaboral/fetchSituacionLaboral.graphql')})
    }
    
    paginateSituacionLaboral(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/SituacionLaboral/paginateSituacionLaboral.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }
    
    

    createSituacionLaboral(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/SituacionLaboral/createSituacionLaboral.graphql'),
            variables: {input}
        })
    }
    
    updateSituacionLaboral(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/SituacionLaboral/updateSituacionLaboral.graphql'),
            variables: {id, input}
        })
    }
    
     deleteSituacionLaboral(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/SituacionLaboral/deleteSituacionLaboral.graphql'),
            variables: {id}
        })
    }

}

export default new SituacionLaboralProvider()


