import merge from 'deepmerge'
import baseRoutes from '../modules/base/routes'
import cobroxRoutes from '../modules/cobrox/routes'
import redmineRoutes from '../modules/redmine/routes'
import kinvaRoutes from '../modules/kinva/routes'
import suretyRoutes from '../modules/surety/routes'

import {routes as userRoutes} from '@dracul/user-frontend'
import {routes as customRoutes} from '@dracul/customize-frontend'
import {routes as notificationRoutes} from '@dracul/notification-frontend'
import {routes as queueRoutes} from '@dracul/queue-frontend'
import {routes as settingsRoutes} from '@dracul/settings-frontend'
import {routes as mediaRoutes} from '@dracul/media-frontend'

const routes = merge.all([baseRoutes,cobroxRoutes, userRoutes, mediaRoutes, notificationRoutes, customRoutes, queueRoutes, settingsRoutes, redmineRoutes, kinvaRoutes, suretyRoutes])


export default routes;
