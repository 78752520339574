<template>
  <v-container fluid>
    <h3 class="primary--text text-h4">Facturas</h3>
    <v-row v-if="report">

      <v-col v-if="hasContabilidad" cols="12" sm="6" md="4">
        <dashboard-card
            title="Cantidad facturas"
            :value="report.cantidad"
        />
      </v-col>


      <v-col v-if="hasContabilidad" cols="12" sm="6" md="4">
        <dashboard-card
            title="Facturas provisorias"
            :value="report.provisorio"
        />
      </v-col>

      <v-col v-if="hasContabilidad" cols="12" sm="6" md="4">
        <dashboard-card
            title="Facturas aprobadas"
            :value="report.aprobado"
        />
      </v-col>



      <v-col v-if="hasContabilidad" cols="12" sm="6" md="4">
        <dashboard-card
            title="Monto no facturable"
            :value="report.montoNoFacturable"
            :symbol="currency"
        />
      </v-col>

      <v-col v-if="hasContabilidad" cols="12" sm="6" md="4">
        <dashboard-card
            title="Monto facturable"
            :value="report.montoFacturable"
            :symbol="currency"
        />
      </v-col>

      <v-col  v-if="hasContabilidad" cols="12" sm="6" md="4">
        <dashboard-card
            title="Monto total"
            :value="report.monto"
            :symbol="currency"
        />
      </v-col>


    </v-row>
    <div v-else>Sin Datos...</div>
  </v-container>

</template>

<script>
import DashboardCard from "@/modules/cobrox/components/DashboardCard/DashboardCard";
import ReportProvider from "@/modules/cobrox/providers/ReportProvider";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import HasPermissionsMixin from "@/modules/cobrox/mixins/HasPermissionsMixin";

export default {
  name: "FacturaReport",
  components: {DashboardCard},
  mixins: [CurrencyMixin, HasPermissionsMixin],
  data() {
    return {
      report: null,
      loading: false
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.loading = true
      ReportProvider.facturaReport()
          .then(res => {
            this.report = res.data.facturaReport
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
