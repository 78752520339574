<template>
  <v-container>
    <v-card>
      <v-card-title>Generar archivo de Rapipago</v-card-title>
      <v-card-text class="text-center">
        <v-btn v-if="!file" :loading="loading" class="primary OnPrimary-text" @click="generarArchivoRapipago">
          Generar Archivo Rapipago
        </v-btn>
        <v-btn v-if="file" class="green white--text" :href="file" target="_blank"  >
          Descargar Archivo Rapipago
        </v-btn>

      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import RapipagoProvider from "@/modules/cobrox/providers/RapipagoProvider";

export default {
  name: "RapipagoArchivoPage",
  data() {
    return {
      loading: false,
      file: null
    }
  },
  methods: {
    generarArchivoRapipago() {
      this.loading = true
      RapipagoProvider.generarArchivoRapipago()
          .then(r => {
            const baseUrl = process.env.VUE_APP_APIHOST ? process.env.VUE_APP_APIHOST : window.location.origin
            setTimeout(() =>
                {
                  this.file = baseUrl + '/' + r.data.generarArchivoRapipago
                }
            )
          }).finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
