<template>
  <crud-layout :title="title" :subtitle="subtitle">

    <template v-slot:list>
      <agencia-list
          ref="list"
          @update="update"
          @delete="remove"
          @show="show"

      />
    </template>

    <add-button v-if="$store.getters.hasPermission('AGENCIA_CREATE')" @click="create"></add-button>


    <agencia-create v-if="creating"
                    :open="creating"
                    v-on:itemCreated="onItemCreated"
                    v-on:close="creating=false"
    />

    <agencia-update v-if="updating"
                    :open="updating"
                    :item="itemToEdit"
                    v-on:itemUpdated="onItemUpdated"
                    v-on:close="updating=false"
    />

    <agencia-show v-if="showing"
                  :open="showing"
                  :item="itemToShow"
                  v-on:close="showing=false"
    />

    <agencia-delete v-if="deleting"
                    :open="deleting"
                    :item="itemToDelete"
                    v-on:itemDeleted="onItemDeleted"
                    v-on:close="deleting=false"
    />

    <snackbar v-model="flash"/>

  </crud-layout>
</template>

<script>

import AgenciaCreate from "../AgenciaCreate";
import AgenciaUpdate from "../AgenciaUpdate";
import AgenciaDelete from "../AgenciaDelete";
import AgenciaShow from "../AgenciaShow";
import AgenciaList from "../AgenciaList";

import {CrudLayout, AddButton, Snackbar} from "@dracul/common-frontend"

export default {
  name: "AgenciaCrud",
  components: {
    CrudLayout, AddButton, Snackbar,
    AgenciaCreate,
    AgenciaUpdate,
    AgenciaDelete,
    AgenciaShow,
    AgenciaList
  },
  data() {
    return {
      title: 'cobrox.agencia.title',
      subtitle: 'cobrox.agencia.subtitle',
      flash: null,
      creating: false,
      updating: false,
      deleting: false,
      showing: false,
      itemToEdit: null,
      itemToDelete: null,
      itemToShow: null,
    }
  },
  methods: {
    //On
    onItemCreated() {
      this.$refs.list.fetch()
      this.flash = "common.created"
    },
    onItemUpdated() {
      this.$refs.list.fetch()
      this.flash = "common.updated"
    },
    onItemDeleted() {
      this.$refs.list.fetch()
      this.flash = "common.deleted"
    },
    //Open
    create() {
      this.creating = true
    },
    update(item) {
      this.updating = true
      this.itemToEdit = item
    },
    show(item) {
      this.showing = true
      this.itemToShow = item
    },
    remove(item) {
      this.deleting = true
      this.itemToDelete = item
    }
  }

}
</script>


