import CobranzaImportPage from '../pages/imports/CobranzaImportPage'
import CobranzaRapipagoImportPage from '../pages/imports/CobranzaRapipagoImportPage'
import DeudaImportPage from '../pages/imports/DeudaImportPage'
import CompraDeudaImportPage from '../pages/imports/CompraDeudaImportPage'
import AgenciaImportPage from '../pages/imports/AgenciaImportPage'
import PersonaImportPage from '../pages/imports/PersonaImportPage'
import RotarAgenciaPage from '../pages/imports/RotarAgenciaPage'
import ExportDeudaPorDniPage from '../pages/reports/ExportDeudaPorDniPage'
import GenerarFacturasPage from '../pages/processes/GenerarFacturasPage'
import GenerarFacturasMasivoPage from '../pages/processes/GenerarFacturasMasivoPage'
import ReprocesarImputacionCobranzasPage from '../pages/processes/ReprocesarImputacionCobranzasPage'
import RapipagoArchivoPage from '../pages/processes/RapipagoArchivoPage'
import RevertJob from '../pages/processes/RevertJob'
import ReporteBcraPage from '../pages/reports/ReporteBcraPage'

import DeudaReportPage from '../pages/reports/DeudaReportPage'
import EficienciaAgenciaPage from '../pages/reports/EficienciaAgenciaPage'
import LiquidacionAgenciaPage from '../pages/reports/LiquidacionAgenciaPage'
import StockReportPage from '../pages/reports/StockReportPage'
import DashboardPage from '../pages/reports/DashboardPage'
import SaldosPage from '../pages/reports/SaldosPage'
import CobranzasPorAgenciaPage from '../pages/reports/CobranzasPorAgenciaPage'

import RemoveAllPage from '../pages/processes/RemoveAllPage'
import FacturarPage from '../pages/processes/FacturarPage'
import NetearCobranzasPage from '../pages/processes/NetearCobranzasPage'
import GenerarNotasCreditoPage from '../pages/processes/GenerarNotasCreditoPage'
import PersonaViewPage from '../pages/PersonaViewPage'
import CrearPersonaDesdeDeudaPage from '../pages/processes/CrearPersonaDesdeDeudaPage'
import InteresesPage from '../pages/processes/InteresesPage'
import InteresesJobPage from '../pages/processes/InteresesJobPage'
import RotacionAgenciaReportPage from '../pages/reports/RotacionAgenciaReportPage'
import BloquearAgenciaPage from '../pages/imports/BloquearAgenciaPage'
import DashboardComparativoPage from '../pages/dashboards/DashboardComparativoPage'


import PedidoCuentaRespuestaPage from '../pages/processes/PedidoCuentaRespuestaPage'

import McChatbotPage from '../pages/McChatbotPage'
import ConvenioPagos from "@/modules/cobrox/pages/ConvenioPagos";

const routes = [
    {
        name: 'ConvenioPagos',
        path: '/convenio/pagos',
        component: ConvenioPagos,
        meta: {
            requiresAuth: true,
            permission: "CONVENIO_MENU"
        }
    },
    {
        name: 'McChatbotPage', path: '/mc-chatbot', component: McChatbotPage,
        meta: {
            requiresAuth: true,
            permission: "CHATBOT_SHOW"
        }
    },
    {
        name: 'PedidoCuentaRespuestaPage', path: '/pedido-cuenta/:id', component: PedidoCuentaRespuestaPage,
        meta: {
            requiresAuth: true,
            permission: "PEDIDOCUENTA_MY_SHOW"
        }
    },
    {
        name: 'ReporteBcraPage', path: '/reporte/bcra', component: ReporteBcraPage,
        meta: {
            requiresAuth: true,
            permission: "BCRA_REPORTE"
        }
    },

    {
        name: 'DashboardComparativoPage', path: '/dashboard/principal', component: DashboardComparativoPage,
        meta: {
            requiresAuth: true,
            permission: "COBRANZA_SHOW"
        }
    },


    {
        name: 'RapipagoArchivoPage', path: '/rapipago/archivo', component: RapipagoArchivoPage,
        meta: {
            requiresAuth: true,
            permission: "RAPIPAGO"
        }
    },

    {
        name: 'ExportDeudaPorDniPage', path: '/reporte/deuda-por-dni', component: ExportDeudaPorDniPage,
        meta: {
            requiresAuth: true,
            permission: "DEUDA_EXPORT_DNI"
        }
    },



    {
        name: 'PersonaViewPage', path: '/persona/:id', component: PersonaViewPage,
        meta: {requiresAuth: true}
    },

    {
        name: 'PersonaViewPageDni', path: '/persona/dni/:dni', component: PersonaViewPage,
        meta: {requiresAuth: true}
    },

    {
        name: 'PersonaViewPageCuil', path: '/persona/cuil/:cuil', component: PersonaViewPage,
        meta: {requiresAuth: true}
    },

    //PROCESOS
    {
        name: 'RevertJob', path: '/proceso/revertir-job', component: RevertJob,
        meta: {
            requiresAuth: true,
            permission: "PROCESOS"
        }
    },
    {
        name: 'ReprocesarImputacionCobranzasPage', path: '/proceso/reprocesar-imputacion-cobranzas', component: ReprocesarImputacionCobranzasPage,
        meta: {
            requiresAuth: true,
            permission: "PROCESOS"
        }
    },
    {
        name: 'InteresesJobPage', path: '/proceso/intereses/job/:jobid?', component: InteresesJobPage,
        meta: {
            requiresAuth: true,
            permission: "PROCESOS"
        }
    },
    {
        name: 'InteresesPage', path: '/proceso/intereses', component: InteresesPage,
        meta: {
            requiresAuth: true,
            permission: "PROCESOS"
        }
    },

    {
        name: 'CrearPersonaDesdeDeudaPage', path: '/proceso/generar-personas', component: CrearPersonaDesdeDeudaPage,
        meta: {
            requiresAuth: true,
            permission: "PROCESOS"
        }
    },
    {
        name: 'NetearCobranzasPage', path: '/proceso/netear-cobranzas', component: NetearCobranzasPage,
        meta: {
            requiresAuth: true,
            permission: "CONTABILIDAD"
        }
    },
    {
        name: 'GenerarNotasCreditoPage', path: '/proceso/notas-credito', component: GenerarNotasCreditoPage,
        meta: {
            requiresAuth: true,
            permission: "CONTABILIDAD"
        }
    },
    //Imports
    {
        name: 'BloquearAgenciaPage', path: '/importar/bloquear-agencia', component: BloquearAgenciaPage,
        meta: {
            requiresAuth: true,
            permission: "PERSONA_ROTAR_AGENCIA"
        }
    },
    {
        name: 'RotarAgenciaPage', path: '/importar/rotar-agencia', component: RotarAgenciaPage,
        meta: {
            requiresAuth: true,
            permission: "PERSONA_ROTAR_AGENCIA"
        }
    },
    {
        name: 'CompraDeudaImportPage', path: '/importar/compra-deuda-import', component: CompraDeudaImportPage,
        meta: {
            requiresAuth: true,
            permission: "PROCESOS"
        }
    },
    {
        name: 'CobranzaImportPage', path: '/importar/cobranza-import', component: CobranzaImportPage,
        meta: {
            requiresAuth: true,
            permission: "PROCESOS"
        }
    },
    {
        name: 'CobranzaRapipagoImportPage', path: '/importar/cobranza-rapipago-import', component: CobranzaRapipagoImportPage,
        meta: {
            requiresAuth: true,
            permission: "PROCESOS"
        }
    },
    {
        name: 'DeudaImportPage', path: '/importar/deuda-import', component: DeudaImportPage,
        meta: {
            requiresAuth: true,
            permission: "PROCESOS"
        }
    },
    {
        name: 'AgenciaImportPage', path: '/importar/agencia-import', component: AgenciaImportPage,
        meta: {
            requiresAuth: true,
            permission: "PROCESOS"
        }
    },
    {
        name: 'PersonaImportPage', path: '/importar/persona-import', component: PersonaImportPage,
        meta: {
            requiresAuth: true,
            permission: "PROCESOS"
        }
    },

    //Facturar
    {
        name: 'GenerarFacturasPage', path: '/generar-factura/:id', component: GenerarFacturasPage,
        meta: {
            requiresAuth: true,
            permission: "CONTABILIDAD"
        }
    },
    {
        name: 'GenerarFacturasMasivoPage', path: '/generar-facturas', component: GenerarFacturasMasivoPage,
        meta: {
            requiresAuth: true,
            permission: "CONTABILIDAD"
        }
    },
    {
        name: 'GenerarFacturasMasivoJobPage', path: '/generar-facturas/:jobid', component: GenerarFacturasMasivoPage,
        meta: {
            requiresAuth: true,
            permission: "CONTABILIDAD"
        }
    },

    {
        name: 'FacturarPage', path: '/facturar', component: FacturarPage,
        meta: {
            requiresAuth: true,
            permission: "CONTABILIDAD"
        }
    },

    //Reports
    {
        name: 'RotacionAgenciaReportPage', path: '/reporte/rotacion-agencia', component: RotacionAgenciaReportPage,
        meta: {
            requiresAuth: true,
            permission: "REPORTES"
        }
    },
    {
        name: 'RotacionAgenciaReportPageJob', path: '/reporte/rotacion-agencia/:jobid', component: RotacionAgenciaReportPage,
        meta: {
            requiresAuth: true,
            permission: "REPORTES"
        }
    },

    {
        name: 'LiquidacionAgenciaPage', path: '/reporte/liquidacion-agencia', component: LiquidacionAgenciaPage,
        meta: {
            requiresAuth: true,
            permission: "REPORTES"
        }
    },
    {
        name: 'EficienciaAgenciaPage', path: '/reporte/eficiencia-agencia', component: EficienciaAgenciaPage,
        meta: {
            requiresAuth: true,
            permission: "REPORTES"
        }
    },
    {
        name: 'StockReportPage', path: '/reporte/stock', component: StockReportPage,
        meta: {
            requiresAuth: true,
            permission: "REPORTES"
        }
    },
    {
        name: 'DashboardPage',
        path: '/reportes/dashboard',
        component: DashboardPage,
        meta: {
            requiresAuth: true,
            permission: "DASHBOARD"
        }
    },

    {
        name: 'CobranzasPorAgenciaPage',
        path: '/reportes/cobranzas-agencias',
        component: CobranzasPorAgenciaPage,
        meta: {
            requiresAuth: true,
            permission: "REPORTES"
        }
    },
    {
        name: 'DeudaReportPage',
        path: '/reportes/deuda',
        component: DeudaReportPage,
        meta: {
            requiresAuth: true,
            permission: "REPORTES"
        }
    },



    {
        name: 'SaldosPage',
        path: '/reportes/saldos',
        component: SaldosPage,
        meta: {
            requiresAuth: true,
            permission: "CONTABILIDAD"
        }
    },

    //Destroy!
    {
        name: 'RemoveAllPage',
        path: '/remove-all-page',
        component: RemoveAllPage,
        meta: {
            requiresAuth: true,
            permission: "RESTORE_SYSTEM"
        }
    },
]

export default routes;
