<template>
  <v-row row wrap>

    <v-col cols="12">
      <v-row justify="space-between">
        <v-col cols="12" sm="6" md="8">
          <!-- FILTERS HERE -->
          <v-switch v-model="showFilters" label="Mostrar Filtros"/>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <search-input @search="performSearch" v-model="search"/>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">

      <v-data-table
          class="mt-3"
          :headers="headers"
          :items="items"
          :search="search"
          :single-expand="false"
          :server-items-length="totalItems"
          :loading="loading"
          :page.sync="pageNumber"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="orderBy"
          :sort-desc.sync="orderDesc"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
          @update:page="fetch"
          @update:sort-by="fetch"
          @update:sort-desc="fetch"
          @update:items-per-page="fetch"
      >


        <template v-slot:item.creadoPor="{ item }">
          {{ item.creadoPor ? item.creadoPor.username : '' }}
        </template>

        <template v-slot:item.createdAt="{ item }">
          {{ getDateFormat(item.createdAt) }}
        </template>


        <template v-slot:item.description="{ item }">
          <v-edit-dialog
              :return-value.sync="item.description"
              @save="saveDescripcion(item)"
          >
            <v-hover>
              <template v-slot:default="{ hover }">
                <div>
                  <span v-if="hover"> <span v-if="item.description">{{ item.description }}</span><span v-else
                                                                                                       class="grey--text"> sin descripción</span> <v-icon
                      right small>edit</v-icon></span>
                  <span v-else class="mr-7"><span v-if="item.description">{{ item.description }}</span><span v-else
                                                                                                             class="grey--text"> sin descripción</span></span>
                </div>
              </template>
            </v-hover>

            <template v-slot:input>
              <v-text-field
                  v-model="item.description"
                  label="Descripción"
                  single-line
                  counter
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.url="{ item }">
          <v-btn small text :href="item.url" target="_blank" download>
            <v-icon left>download</v-icon>
            Descargar
          </v-btn>
        </template>

        <template v-slot:item.filtros="{ item }">
          <template v-if="item.data.desde">
            <b> Desde: </b><span v-if="item.data.desde">{{ getDateFormat(toDayJs(item.data.desde)) }}</span>
          </template>
          <template v-if="item.data.hasta">
            <b> Hasta: </b><span v-if="item.data.hasta">{{ getDateFormat(toDayJs(item.data.hasta)) }}</span>
          </template>
          <template v-if="item.data.estadosDeuda">
            <b> Estados Deuda: </b><span v-if="item.data.estadosDeuda">{{ item.data.estadosDeuda.join(', ') }}</span>
          </template>
        </template>


        <template slot="no-data">
          <div class="text-xs-center" v-t="'common.noData'"></div>
        </template>

        <template slot="loading">
          <div class="text-xs-center" v-t="'common.loading'"></div>
        </template>

        <template v-if="false" v-slot:item.action="{ item }">
          <show-button @click="$emit('show', item)"/>
          <edit-button v-if="$store.getters.hasPermission('REPORTESTOCK_UPDATE')" @click="$emit('update', item)"/>
          <delete-button v-if="$store.getters.hasPermission('REPORTESTOCK_DELETE')" @click="$emit('delete', item)"/>
        </template>

      </v-data-table>
    </v-col>

  </v-row>
</template>

<script>
import ReporteStockProvider from "../../../../providers/ReporteStockProvider";

import {DeleteButton, EditButton, ShowButton, SearchInput} from "@dracul/common-frontend"
import {DayjsMixin, Dayjs} from "@dracul/dayjs-frontend"


export default {
  name: "ReporteStockList",
  components: {DeleteButton, EditButton, ShowButton, SearchInput},
  mixins: [DayjsMixin],
  data() {
    return {
      items: [],
      totalItems: null,
      loading: false,
      orderBy: 'createdAt',
      orderDesc: true,
      itemsPerPage: 5,
      pageNumber: 1,
      search: '',
      filters: [
        /*{
            field: '',
            operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
            value: ''
        }*/
      ],
      showFilters: false
    }
  },
  computed: {
    toDayJs() {
      return date => Dayjs(date)
    },
    headers() {
      return [
        //Entity Headers
        {text: this.$t('cobrox.reporteStock.labels.creadoPor'), value: 'creadoPor'},
        {text: "Fecha creación", value: 'createdAt'},
        {text: "Descripción", value: 'description'},
        ...(this.showFilters ? [{text: "Filtros", value: 'filtros'}] : []),
        {text: "", value: 'descriptionEdit'},
        {text: this.$t('cobrox.reporteStock.labels.url'), value: 'url'},
        //{text: this.$t('cobrox.reporteStock.labels.data'), value: 'data'},
        //Actions
        // {text: this.$t('common.actions'), value: 'action', sortable: false},
      ]
    },
    getOrderBy() {
      return (Array.isArray(this.orderBy)) ? this.orderBy[0] : this.orderBy
    },
    getOrderDesc() {
      return (Array.isArray(this.orderDesc)) ? this.orderDesc[0] : this.orderDesc
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    performSearch() {
      this.pageNumber = 1
      this.fetch()
    },
    saveDescripcion(item) {
      this.loading = true
      ReporteStockProvider.updateReporteStockDescription(item.id, item.description)
          .then(r => {
            item = r.data.updateReporteStockDescription
          }).catch(err => {
        console.error(err)
      }).finally(() => this.loading = false)
    },
    fetch() {
      this.loading = true
      ReporteStockProvider.paginateReporteStock(
          this.pageNumber,
          this.itemsPerPage,
          this.search,
          this.filters,
          this.getOrderBy,
          this.getOrderDesc
      ).then(r => {
        this.items = r.data.paginateReporteStock.items
        this.totalItems = r.data.paginateReporteStock.totalItems
      }).catch(err => {
        console.error(err)
      }).finally(() => this.loading = false)
    }
  }

}
</script>


