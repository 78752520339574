<template>
    <v-row>
        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.identificacion" :label="$t('cobrox.autogestionLog.labels.identificacion')" icon="badge"/>
                 <show-field :value="item.UserAgent" :label="$t('cobrox.autogestionLog.labels.UserAgent')" icon="devices"/>
                 <show-field :value="item.Referer" :label="$t('cobrox.autogestionLog.labels.Referer')" icon="trip_origin"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.email" :label="$t('cobrox.autogestionLog.labels.email')" icon="email"/>
                 <show-field :value="item.IP" :label="$t('cobrox.autogestionLog.labels.IP')" icon="router"/>
                 <show-field :value="item.SO" :label="$t('cobrox.autogestionLog.labels.SO')" icon="app_shortcut"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.telefono" :label="$t('cobrox.autogestionLog.labels.telefono')" icon="phone"/>
                 <show-field :value="item.Geolocation" :label="$t('cobrox.autogestionLog.labels.Geolocation')" icon="location_on"/>
            </v-list>
        </v-col>

    </v-row>
</template>
<script>
    import {ShowField} from '@dracul/common-frontend'
     
    
    export default {
        name: 'AutogestionLogShowData',
        components: {ShowField},
        
        props: {
            item: {type: Object, required: true}
        }
    }
</script>

