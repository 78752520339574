<template>
    <v-container fill-height>
      <v-row v-if="isRedmineIntegrationEnable">
        <v-col cols="12" class="pa-6">
          <redmine-dashboard open closed :status="redmineStatusList"></redmine-dashboard>
        </v-col>
      </v-row>

      <gallery-menu :nav="getMenu"> </gallery-menu>
    </v-container>
</template>

<script>
import menuconfig from "@/menu-config/menu-config"
import GalleryMenu from "@/layout/GalleryMenu/GalleryMenu";
import RedmineDashboard from "@/modules/redmine/components/RedmineDashboard/RedmineDashboard";

export default {
  name: 'HomePage',
  components: {RedmineDashboard, GalleryMenu},
  data(){
    return {
      menu: menuconfig
    }
  },
  created() {

    this.$store.dispatch('loadSettings')

    if(this.$store.getters.me === null){
      this.$router.push({name: 'login'})
    }
  },
  computed: {
    isRedmineIntegrationEnable(){
      return this.$store.getters.getSettingValue('RedmineIntegration')
    },
    getMenu() {
      return this.$store.getters.isRestoreSystemEnable ? this.menu : this.menu.filter( i => i.permission != 'RESTORE_SYSTEM')
    },
    redmineStatusList(){
      return this.$store.getters.getSettingValue('RedmineDashboardStatusList')
    }
  }
}
</script>

<style scoped>

</style>
