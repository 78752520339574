<template>
  <v-menu :close-on-content-click="false">
    <template v-slot:activator="{on}">
      <v-btn icon v-on="on">
        <v-icon>rule</v-icon>
      </v-btn>
    </template>
    <v-card height="300" :style="{zIndex:10, overflowY: 'scroll'}">
      <v-card-text>
        <v-btn small color="primary OnPrimary--text" @click="selectAll">Todos</v-btn>
        <v-btn class="ml-2" small  @click="selectNone">Ninguno</v-btn>
      </v-card-text>
      <v-card-text>
        <v-checkbox dense v-for="field in fields" :key="field" v-model="localValue" :label="field"
                    :value="field"></v-checkbox>
      </v-card-text>

    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "FieldSelector",
  props: {
    value: {type: Array},
    fields: {type: Array}
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods:{
    selectAll(){
      this.localValue = this.fields
    },
    selectNone(){
      this.localValue = []
    }
  }
}
</script>

<style scoped>

</style>
