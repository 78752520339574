import graphqlClient from "../../../apollo";

class CompraProvider {

    findCompra(id) {
        return graphqlClient.query({
            query: require('./gql/Compra/findCompra.graphql'),
            variables: {id:id}
        })
    }

    fetchCompra() {
        return graphqlClient.query({
            query: require('./gql/Compra/fetchCompra.graphql'),
            fetchPolicy: "network-only"
        })
    }

    paginateCompra(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Compra/paginateCompra.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }



    createCompra(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Compra/createCompra.graphql'),
            variables: {input}
        })
    }

    updateCompra(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Compra/updateCompra.graphql'),
            variables: {id, input}
        })
    }

     deleteCompra(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Compra/deleteCompra.graphql'),
            variables: {id}
        })
    }

}

export default new CompraProvider()


