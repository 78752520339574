<template>
  <agencia-import></agencia-import>
</template>

<script>
import AgenciaImport from "@/modules/cobrox/components/AgenciaImport/AgenciaImport";

export default {
  name: "AgenciaImportPage",
  components: {AgenciaImport}
}
</script>

<style scoped>

</style>
