<template>

  <v-select
      prepend-icon="fact_check"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      v-model="item"
      :label="$t('cobrox.cobranza.labels.cuentaDestino')"
      :loading="loading"
      :error="hasInputErrors('cuentaDestino')"
      :error-messages="getInputErrors('cuentaDestino')"
      color="secondary"
      item-color="secondary"
      :readonly="readonly"
  ></v-select>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'
import CuentaProvider from "@/modules/cobrox/providers/CuentaProvider";


export default {
  name: "CuentaCombobox",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {type: [String, Array]},
    itemText: {type: String, default: 'nombre'},
    itemValue: {type: String, default: 'id'},
    readonly: {type: Boolean, default: false},
  },
  data() {
    return {
      items: [],
      loading: false
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    fetch() {
      this.loading = true
      CuentaProvider.fetchCuenta()
          .then(r => {
            this.items = r.data.fetchCuenta
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>

