import merge from 'deepmerge'
import RedmineIssueMessages from './messages/RedmineIssueMessages'
import RedminePermissionsMessages from './permissions/RedminePermissionsMessages'


const messages = merge.all([
    RedmineIssueMessages,
    RedminePermissionsMessages
])

export default messages;
