<template>

  <v-autocomplete
      prepend-icon="category"
      :items="getItems"
      :item-text="'name'"
      :item-value="'id'"
      v-model="item"
      :label="$t('redmine.issue.labels.tracker')"
      :loading="loading"
      :error="hasInputErrors('tracker')"
      :error-messages="getInputErrors('tracker')"
      color="secondary"
      item-color="secondary"
      :clearable="clearable"
      :multiple="multiple"
      :readonly="readonly"
  ></v-autocomplete>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'
import RedmineProvider from "@/modules/redmine/providers/RedmineProvider";


export default {
  name: "RedmineTrackerCombobox",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {type: [Number, Array]},
    clearable: {type: Boolean, default: false},
    multiple: {type: Boolean, default: false},
    readonly: {type: Boolean, default: false},
    defaultProject: {type: Boolean, default: false},
    project: {type: Number},
    trackersFilter: {type: Array, default: () => []},
  },
  data() {
    return {
      items: [],
      loading: false
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    getItems() {
      return this.items.filter(item => { return !this.trackersFilter.includes(item.name) })
    }
  },
  mounted() {
    if (this.defaultProject) {
      this.fetchDefaultProjectTrackers()
    } else if (this.project) {
      this.fetchProjectTrackers()
    } else {
      this.fetchTrackers()
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    fetchTrackers() {
      this.loading = true
      RedmineProvider.fetchRedmineTrackers()
          .then(r => {
            this.items = r.data.fetchRedmineTrackers
          }).catch(err => console.error(err))
          .finally(() => this.loading = false)
    },
    fetchProjectTrackers() {
      this.loading = true
      RedmineProvider.fetchRedmineProjectTrackers(this.project)
          .then(r => {
            this.items = r.data.fetchRedmineProjectTrackers
          }).catch(err => console.error(err))
          .finally(() => this.loading = false)
    },
    fetchDefaultProjectTrackers() {
      this.loading = true
      RedmineProvider.fetchRedmineDefaultProjectTrackers()
          .then(r => {
            this.items = r.data.fetchRedmineDefaultProjectTrackers
          }).catch(err => console.error(err))
          .finally(() => this.loading = false)
    }

  }
}
</script>

<style scoped>

</style>

