import graphqlClient from "../../../apollo";

class ReporteStockProvider {

    findReporteStock(id) {
        return graphqlClient.query({
            query: require('./gql/ReporteStock/findReporteStock.graphql'),
            variables: {id:id}
        })
    }

    fetchReporteStock() {
        return graphqlClient.query({
            query: require('./gql/ReporteStock/fetchReporteStock.graphql'),
            fetchPolicy: "network-only"
        })
    }

    paginateReporteStock(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/ReporteStock/paginateReporteStock.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }



    createReporteStock(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/ReporteStock/createReporteStock.graphql'),
            variables: {input}
        })
    }

    updateReporteStock(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/ReporteStock/updateReporteStock.graphql'),
            variables: {id, input}
        })
    }

    updateReporteStockDescription(id,description) {
        return graphqlClient.mutate({
            mutation: require('./gql/ReporteStock/updateReporteStockDescription.graphql'),
            variables: {id, description}
        })
    }

     deleteReporteStock(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/ReporteStock/deleteReporteStock.graphql'),
            variables: {id}
        })
    }

}

export default new ReporteStockProvider()


