import graphqlClient from "../../../apollo";

class DashboardComparativoProvider {

    cobradoPorCompra(desde, hasta) {
        return graphqlClient.mutate({
            mutation: require('./gql/DashboardComparativo/cobradoPorCompra.graphql'),
            variables: {desde, hasta}
        })
    }

    cobradoPorAgencia(desde, hasta) {
        return graphqlClient.mutate({
            mutation: require('./gql/DashboardComparativo/cobradoPorAgencia.graphql'),
            variables: {desde, hasta}
        })
    }


}

export default new DashboardComparativoProvider()


