<template>
  <v-card outlined>

    <v-card-title class="pb-0">Cobros vinculados
    </v-card-title>
    <v-card-text class="pb-0">
      <v-list dense width="100%">
        <v-list-item>
          <v-list-item-content class="font-weight-bold grey--text text--darken-2">Cantidad Cobranzas:
          </v-list-item-content>
          <v-list-item-action>
            {{ hasCobranzas ? convenio.cobranzas.length : 0 }}
          </v-list-item-action>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold grey--text text--darken-2">Ultimo Cobro:</v-list-item-content>
          <v-list-item-action>{{ convenio.fechaUltimaCobranza ? getDateFormat(convenio.fechaUltimaCobranza) : '-' }}</v-list-item-action>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold grey--text text--darken-2">Monto Cobrado:</v-list-item-content>
          <v-list-item-action>
            <span v-if="hasCobranzas"> {{ currency }} {{ montoCobrado.toLocaleString('es-AR') }}</span>
            <span v-else>{{ currency }}0</span>
          </v-list-item-action>
        </v-list-item>

      </v-list>

    </v-card-text>

  </v-card>
</template>
<script>
import DeudaTotalMixin from "@/modules/cobrox/mixins/DeudaTotalMixin";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import {DayjsMixin} from "@dracul/dayjs-frontend";

export default {
  name: 'convenio-cobranza-card',
  mixins: [DeudaTotalMixin, CurrencyMixin, DayjsMixin],
  props: {
    convenio: {type: Object},
  },
  computed: {
    montoCobrado() {
      return this.convenio.cobranzas.reduce((total, cobranza) => total + cobranza.montoCobrado, 0)
    },
    ultimaCobranza() {
        return this.convenio.cobranzas[this.convenio.cobranzas.length - 1]
    },
    hasCobranzas() {
      return this.convenio.cobranzas && this.convenio.cobranzas.length > 0
    }
  }
}
</script>
