
const messages = {
    en: {
       role: {
            permissions: {
                DEUDA_MENU: "Access to Deuda",
                DEUDA_SHOW: "View Deuda",
                DEUDA_CREATE: "Create Deuda",
                DEUDA_UPDATE: "Modify Deuda",
                DEUDA_DELETE: "Delete Deuda",
                DEUDA_EXPORT: 'Exportar Deuda',
                DEUDA_EXPORT_AGENCIA: 'Exportar Deuda formato agencia',
                EXPORT_DEUDA_DNI: 'Exportar Deuda por DNI',
                DEUDA_SUMMARY: 'Ver Deuda Sumarizada'
            }
       }
    },
    es: {
        role: {
            permissions: {
                DEUDA_MENU: "Acceder Deuda",
                DEUDA_SHOW: "Ver Deuda",
                DEUDA_CREATE: "Crear Deuda",
                DEUDA_UPDATE: "Modificar Deuda",
                DEUDA_DELETE: "Eliminar Deuda",
                DEUDA_EXPORT: 'Exportar Deuda',
                DEUDA_EXPORT_AGENCIA: 'Exportar Deuda formato agencia',
                EXPORT_DEUDA_DNI: 'Exportar Deuda por DNI',
                DEUDA_SUMMARY: 'Ver Deuda Sumarizada'
            }
       }
    },
    pt: {
       role: {
            permissions: {
                DEUDA_MENU: "Acessar Deuda",
                DEUDA_SHOW: "Ver Deuda",
                DEUDA_CREATE: "Criar Deuda",
                DEUDA_UPDATE: "Modificar Deuda",
                DEUDA_EXPORT: 'Exportar Deuda',
                DEUDA_EXPORT_AGENCIA: 'Exportar Deuda formato agencia',
                EXPORT_DEUDA_DNI: 'Exportar Deuda por DNI',
                DEUDA_SUMMARY: 'Ver Deuda Sumarizada'
            }
       }
    }
}

export default messages
