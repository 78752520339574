<template>
    <v-row>
        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.nombreCompleto" :label="$t('cobrox.persona.labels.nombreCompleto')" icon="face"/>
                 <show-field :value="item.cuil" :label="$t('cobrox.persona.labels.cuil')" icon="folder_shared"/>
                 <show-field :value="item.fechaNacimiento" :label="$t('cobrox.persona.labels.fechaNacimiento')" icon="event"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.tipoDocumento" :label="$t('cobrox.persona.labels.tipoDocumento')" icon="badge"/>
                 <show-field :value="item.sexo" :label="$t('cobrox.persona.labels.sexo')" icon="wc"/>
                 <show-field :value="item.agencia.descripcion" :label="$t('cobrox.persona.labels.agencia')" icon="business"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.nroDocumento" :label="$t('cobrox.persona.labels.nroDocumento')" icon="badge"/>
                 <show-field :value="item.nacionalidad" :label="$t('cobrox.persona.labels.nacionalidad')" icon="flag"/>
                 <show-field :value="item.idRapipago" :label="$t('cobrox.persona.labels.idRapipago')" icon="credit_score"/>
            </v-list>
        </v-col>

    </v-row>
</template>
<script>
    import {ShowField} from '@dracul/common-frontend'
     
    
    export default {
        name: 'PersonaShowData',
        components: {ShowField},
        
        props: {
            item: {type: Object, required: true}
        }
    }
</script>

