<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="pa-3">
               <reporte-stock-crud />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import ReporteStockCrud from "./ReporteStockCrud";
    export default {
        name: "ReporteStock",
        components: {ReporteStockCrud}
    }
</script>

<style scoped>

</style>

