<template>
    <iframe name='customer-chat-iframe' id='customer-chat-iframe'
            src='https://multic.sondeosglobal.com/widget/index.html?messenger=cc1d7e9b-aec1-4d25-8370-17e27bbac31c'
            marginWidth='0' marginHeight='0' frameBorder='0'
            style='display: block; background: transparent; border: none; outline: none; position: fixed; z-index: 999999; overflow: hidden; bottom: 0px; right: 50px; width: 370px; height: 55px; margin: 0px; padding: 0px;'>

    </iframe>


</template>

<script>
export default {
  created() {

    var iframe


    document.addEventListener("DOMContentLoaded", function() {
      iframe = document.getElementById("customer-chat-iframe")
      iframe.style.height="470px";
      iframe.contentWindow.postMessage("scrollChat", "*");
    });

    window.addEventListener('message', function(e) {

      if(e.data == 'minimizeIframe') {
        iframe = document.getElementById("customer-chat-iframe")
        iframe.style.height="50px";
        iframe.contentWindow.postMessage("scrollChat", "*");
      }

      if(e.data == 'maximizeIframe') {
        iframe = document.getElementById("customer-chat-iframe")
        iframe.style.height="470px";
        iframe.contentWindow.postMessage("scrollChat", "*");
      }

    });
  }
}

</script>
