<template>
  <v-card v-if="getDeuda" :loading="loading">
    <v-card-title class="pt-1">{{ getDeuda.nombreDeudor }}</v-card-title>
    <v-card-subtitle>DNI: {{ getDeuda.dniDeudor }} - CUIL: {{ getDeuda.cuilDeudor }}</v-card-subtitle>
    <v-card-text class="py-0">
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-list>
            <show-field
                :value="getDeuda.idDeuda"
                :label="$t('cobrox.deuda.labels.idDeuda')"
                icon="grid_4x4"
            />

            <show-field
                :value="'$'+String(getDeuda.capitalCompra)"
                :label="$t('cobrox.deuda.labels.capitalCompra')"
                icon="request_quote"
            />
            <show-field
                :value="'$'+String(getDeuda.saldoCapitalCompra)"
                :label="$t('cobrox.deuda.labels.saldoCapitalCompra')"
                icon="price_check"
            />

            <!--<show-field :value="getDeuda.facturas.map(i => i.id).join(', ')" :label="$t('cobrox.deuda.labels.facturas')"
                        icon="Facturas"/>-->
          </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-list>
            <show-field
                :value="getDeuda.dniDeudor"
                :label="$t('cobrox.deuda.labels.dniDeudor')"
                icon="badge"
            />

            <show-field :value="'$'+String(getDeuda.capitalTotal)"
                        :label="$t('cobrox.deuda.labels.capitalTotal')"
                        icon="paid"
            />
            <show-field
                :value="'$'+String(getDeuda.intereses)"
                :label="$t('cobrox.deuda.labels.intereses')"
                icon="attach_money"
            />

          </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-list>
            <show-field
                :value="getDateFormat(getDeuda.fechaCompra)"
                :label="$t('cobrox.deuda.labels.fechaCompra')"
                icon="event"
            />
            <show-field
                :value="getDateFormat(getDeuda.fechaMora)"
                :label="$t('cobrox.deuda.labels.fechaMora')"
                icon="date_range"
            />


            <show-field
                v-if="$store.getters.hasPermission('CONTABILIDAD')"
                :value="getDeuda.imputadoContable ? String(getDeuda.imputadoContable): 0"
                :label="$t('cobrox.deuda.labels.imputadoContable')"
                icon="money_off"
            />
          </v-list>
        </v-col>

      </v-row>
    </v-card-text>
    <v-card-actions class="py-0">
      <v-card-title class="primary--text py-0">Deuda</v-card-title>
      <v-spacer></v-spacer>
      <v-card-subtitle class="text-right"> ID: {{ getDeuda.id }}</v-card-subtitle>
    </v-card-actions>
  </v-card>

</template>

<script>
import DeudaProvider from "@/modules/cobrox/providers/DeudaProvider";
import {DayjsMixin} from "@dracul/dayjs-frontend";
import {ShowField} from "@dracul/common-frontend";

export default {
  name: "DeudaDetail",
  props: {
    deudaId: String,
    deuda: Object
  },
  mixins: [DayjsMixin],
  components: {ShowField},
  data() {
    return {
      deudaLocal: null,
      loading: false
    }
  },
  created() {
    if (this.deudaId) {
      this.fetchDeuda()
    }
  },
  computed: {
    getDeuda() {
      if (this.deudaId) {
        return this.deudaLocal
      } else {
        return this.deuda
      }
    }
  },
  methods: {
    fetchDeuda() {
      this.loading = true
      DeudaProvider.findDeuda(this.deudaId)
          .then(res => {
            this.deudaLocal = res.data.findDeuda
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
