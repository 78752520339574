import graphqlClient from "../../../apollo";

class FileBuilderProvider {
    buildLibreDeudaPdf(deudaId) {
        return graphqlClient.mutate({
            mutation: require('./gql/FileBuilder/buildLibreDeudaPdf.graphql'),
            variables: {deudaId}
        })
    }

    buildCertificadoConvenioPdf(ticketId) {
        return graphqlClient.mutate({
            mutation: require('./gql/FileBuilder/buildCertificadoConvenioPdf.graphql'),
            variables: {ticketId}
        })
    }

    buildCertificadoPrecancelacionPdf(ticketId) {
        return graphqlClient.mutate({
            mutation: require('./gql/FileBuilder/buildCertificadoPrecancelacionPdf.graphql'),
            variables: {ticketId}
        })
    }
}

export default new FileBuilderProvider()


