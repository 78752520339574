import graphqlClient from "../../../apollo";

class ComisionProvider {

    findComision(id) {
        return graphqlClient.query({
            query: require('./gql/Comision/findComision.graphql'),
            variables: {id:id},
            fetchPolicy: "network-only"
        })
    }

    fetchComision() {
        return graphqlClient.query({
            query: require('./gql/Comision/fetchComision.graphql'),
            fetchPolicy: "network-only"
        })
    }

    fetchComisionByAgenciaCompra(agencia = null, compra = null) {
        return graphqlClient.query({
            query: require('./gql/Comision/fetchComisionByAgenciaCompra.graphql'),
            variables: {agencia:agencia, compra: compra},
            fetchPolicy: "network-only"
        })
    }

    paginateComision(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Comision/paginateComision.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }



    createComision(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Comision/createComision.graphql'),
            variables: {input}
        })
    }


    createMultiComision(agencia, compra, input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Comision/createMultiComision.graphql'),
            variables: {agencia, compra, input}
        })
    }

    updateComision(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Comision/updateComision.graphql'),
            variables: {id, input}
        })
    }

     deleteComision(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Comision/deleteComision.graphql'),
            variables: {id}
        })
    }

}

export default new ComisionProvider()


