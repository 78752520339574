
const messages = {
    en: {
       role: {
            permissions: {
                FACTURA_MENU: "Access to Factura",
                FACTURA_SHOW: "View Factura",
                FACTURA_CREATE: "Create Factura",
                FACTURA_UPDATE: "Modify Factura",
                FACTURA_DELETE: "Delete Factura",
            }
       }
    },
    es: {
        role: {
            permissions: {
                FACTURA_MENU: "Acceder Factura",
                FACTURA_SHOW: "Ver Factura",
                FACTURA_CREATE: "Crear Factura",
                FACTURA_UPDATE: "Modificar Factura",
                FACTURA_DELETE: "Eliminar Factura",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                FACTURA_MENU: "Acessar Factura",
                FACTURA_SHOW: "Ver Factura",
                FACTURA_CREATE: "Criar Factura",
                FACTURA_UPDATE: "Modificar Factura",
                FACTURA_DELETE: "Eliminar Factura",
            }
       }
    }
}

export default messages
    