
const messages = {
    en: {
       cobrox: {
          manual: { 
            name: 'Manual',
            title: 'Manual management',
            subtitle: 'View, search, create, edit and delete Manual',
            creating: 'Creating Manual',
            editing: 'Editing Manual',
            deleting: 'Deleting Manual',
            showing: 'Showing Manual',
            menu: 'Manual',
            labels: {
                nombre: 'Name',
                link: 'Link'
            },
          
          }
       }
    },
    es: {
       cobrox: {
          manual: { 
            name: 'Manual',
            title: 'Administración de Manual',
            subtitle: 'Ver, buscar, crear, editar, y borrar Manual',
            creating: 'Creando Manual',
            editing: 'Modificando Manual',
            deleting: 'Eliminando Manual',
            showing: 'Detalles de Manual',
            menu: 'Manual',
            labels: {
                nombre: 'Nombre',
                link: 'Link'
            },
          
          }
       }
    },
    pt: {
       cobrox: {
          manual: { 
            name: 'Manual',
            title: 'Administração de Manual',
            subtitle: 'Ver, buscar, criar, editar e usar Manual',
            creating: 'Criando Manual',
            editing: 'Edição Manual',
            deleting: 'Apagando Manual',
            showing: 'Detalhes do Manual',
            menu: 'Manual',
            labels: {
                nombre: 'Nombre',
                link: 'Link'
            },
          
          }
       }
    }
}

export default messages
    