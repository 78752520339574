<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">
    <v-row>

      <v-col cols="12" sm="6">
        <agencia-combobox v-model="form.agencia" :input-errors="inputErrors" clearable/>
      </v-col>


      <v-col cols="12" sm="6">
        <compra-combobox v-model="form.compra" :input-errors="inputErrors" clearable/>
      </v-col>


      <v-col cols="12" sm="12">
        <form-list
            :new-item="{
          agencia: form.agencia,
          compra: form.compra,
          ratioDesde: null,
          ratioHasta:null,
          comision: null
        }"
            v-model="form.multi"
        >

          <template v-slot:default="{item,index}">
            <ratio-form
                v-model="form.multi[index]"
                :agencia="form.agencia"
                :compra="form.compra"
                :key="index"
            ></ratio-form>
          </template>

        </form-list>
      </v-col>


    </v-row>
  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule, FormList} from '@dracul/common-frontend'

import AgenciaCombobox from "../../../../combobox/AgenciaCombobox";
import CompraCombobox from "../../../../combobox/CompraCombobox";
import RatioForm from "./RatioForm";


export default {
  name: "ComisionMultiForm",
  mixins: [InputErrorsByProps, RequiredRule],
  components: {
    RatioForm,
    AgenciaCombobox,
    CompraCombobox,
    FormList
  },
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>

