
const messages = {
    en: {
       cobrox: {
          estadoDeuda: {
            name: 'EstadoDeuda',
            title: 'EstadoDeuda management',
            subtitle: 'View, search, create, edit and delete EstadoDeuda',
            creating: 'Creating EstadoDeuda',
            editing: 'Editing EstadoDeuda',
            deleting: 'Deleting EstadoDeuda',
            showing: 'Showing EstadoDeuda',
            menu: 'EstadoDeuda',
            labels: {
                nombre: 'Nombre',
                inactiva: 'Inactiva imputaciones',
                cancelaSaldos: 'Cancela Saldos',
                cancelaSaldosHistorico: 'Cancela Saldos Histórico',
                informarBCRA: 'Informar BCRA',
                rotacionBloqueada: 'Bloquea Rotación',
                noRota: 'No Rota',
            },

          }
       }
    },
    es: {
       cobrox: {
          estadoDeuda: {
            name: 'EstadoDeuda',
            title: 'Administración de EstadoDeuda',
            subtitle: 'Ver, buscar, crear, editar, y borrar EstadoDeuda',
            creating: 'Creando EstadoDeuda',
            editing: 'Modificando EstadoDeuda',
            deleting: 'Eliminando EstadoDeuda',
            showing: 'Detalles de EstadoDeuda',
            menu: 'EstadoDeuda',
            labels: {
                nombre: 'Nombre',
                inactiva: 'Inactiva imputaciones',
                cancelaSaldos: 'Cancela Saldos',
                cancelaSaldosHistorico: 'Cancela Saldos Histórico',
                informarBCRA: 'Informar BCRA',
                rotacionBloqueada: 'Bloquea Rotación',
                noRota: 'No Rota',
            },

          }
       }
    },
    pt: {
       cobrox: {
          estadoDeuda: {
            name: 'EstadoDeuda',
            title: 'Administração de EstadoDeuda',
            subtitle: 'Ver, buscar, criar, editar e usar EstadoDeuda',
            creating: 'Criando EstadoDeuda',
            editing: 'Edição EstadoDeuda',
            deleting: 'Apagando EstadoDeuda',
            showing: 'Detalhes do EstadoDeuda',
            menu: 'EstadoDeuda',
            labels: {
                nombre: 'Nombre',
                inactiva: 'Inactiva imputaciones',
                cancelaSaldos: 'Cancela Saldos',
                cancelaSaldosHistorico: 'Cancela Saldos Histórico',
                informarBCRA: 'Informar BCRA',
                rotacionBloqueada: 'Bloquea Rotación',
                noRota: 'No Rota',
            },

          }
       }
    }
}

export default messages
