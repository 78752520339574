<template>
<v-container>
  <v-card>
    <v-card-title>Menu ocultos</v-card-title>
    <v-card-subtitle>Seleccionar los elementos que se desean ocultar del menu</v-card-subtitle>
    <v-card-text>
      <hide-menus v-model="hideMenus" @input="updateSetting"></hide-menus>
    </v-card-text>
  </v-card>

</v-container>
</template>

<script>
import HideMenus from "@/modules/base/components/HideMenus/HideMenus";
export default {
  name: "HideMenuPage",
  components: {HideMenus},
  data() {
    return {
      hideMenus: []
    }
  },
  async created() {
    await this.$store.dispatch("loadSettings")
    this.hideMenus = this.getCurrentValueFromSettings()
  },
  methods: {
    getCurrentValueFromSettings() {
      return this.$store.getters.getSettingValue('hiddenMenus');
    },
    updateSetting(){
      this.$store.dispatch('updateSettingValueByKey',{key:'hiddenMenus',valueList:this.hideMenus})
    }
  }
}
</script>

<style scoped>

</style>
