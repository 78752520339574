<template>
  <v-row>

    <v-col cols="12" sm="3">
      <v-text-field
          prepend-icon="source"
          name="depto"
          v-model="form.origen"
          :label="$t('cobrox.persona.labels.origen')"
          :placeholder="$t('cobrox.persona.labels.origen')"
          :error="hasInputErrors('origen')"
          :error-messages="getInputErrors('origen')"
          color="secondary"
          :readonly="readonly"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="3">
      <v-autocomplete
          v-model="form.tipo"
          :search-input.sync="search"
          :items="tipoItems"
          :label="$t('cobrox.persona.labels.tipo')"
          :readonly="readonly"
      >

      </v-autocomplete>

    </v-col>


    <v-col cols="12" sm="3">
      <v-text-field
          prepend-icon="villa"
          name="localidad"
          v-model="form.localidad"
          :label="$t('cobrox.persona.labels.localidad')"
          :placeholder="$t('cobrox.persona.labels.localidad')"
          :error="hasInputErrors('localidad')"
          :error-messages="getInputErrors('localidad')"
          color="secondary"
          :readonly="readonly"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="3">
      <v-text-field
          prepend-icon="holiday_village"
          name="provincia"
          v-model="form.provincia"
          :label="$t('cobrox.persona.labels.provincia')"
          :placeholder="$t('cobrox.persona.labels.provincia')"
          :error="hasInputErrors('provincia')"
          :error-messages="getInputErrors('provincia')"
          color="secondary"
          :readonly="readonly"
      ></v-text-field>
    </v-col>



    <v-col cols="12" sm="3">
      <v-text-field
          prepend-icon="edit_road"
          name="calle"
          v-model="form.calle"
          :label="$t('cobrox.persona.labels.calle')"
          :placeholder="$t('cobrox.persona.labels.calle')"
          :error="hasInputErrors('calle')"
          :error-messages="getInputErrors('calle')"
          color="secondary"
          :readonly="readonly"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="3">
      <v-text-field
          prepend-icon="pin"
          name="numero"
          v-model="form.numero"
          :label="$t('cobrox.persona.labels.numero')"
          :placeholder="$t('cobrox.persona.labels.numero')"
          :error="hasInputErrors('numero')"
          :error-messages="getInputErrors('numero')"
          color="secondary"
          :readonly="readonly"
      ></v-text-field>
    </v-col>


    <v-col cols="12" sm="2">
      <v-text-field
          prepend-icon="apartment"
          name="piso"
          v-model="form.piso"
          :label="$t('cobrox.persona.labels.piso')"
          :placeholder="$t('cobrox.persona.labels.piso')"
          :error="hasInputErrors('piso')"
          :error-messages="getInputErrors('piso')"
          color="secondary"
          :readonly="readonly"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="2">
      <v-text-field
          prepend-icon="door_front"
          name="depto"
          v-model="form.depto"
          :label="$t('cobrox.persona.labels.depto')"
          :placeholder="$t('cobrox.persona.labels.depto')"
          :error="hasInputErrors('depto')"
          :error-messages="getInputErrors('depto')"
          color="secondary"
          :readonly="readonly"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="2">
      <v-text-field
          prepend-icon="mark_as_unread"
          name="codigoPostal"
          v-model="form.codigoPostal"
          :label="$t('cobrox.persona.labels.codigoPostal')"
          :placeholder="$t('cobrox.persona.labels.codigoPostal')"
          :error="hasInputErrors('codigoPostal')"
          :error-messages="getInputErrors('codigoPostal')"
          color="secondary"
          :readonly="readonly"
      ></v-text-field>
    </v-col>

  </v-row>
</template>

<script>
import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'

export default {
  name: "PersonaDomicilioForm",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {
      type: Object,
      required: true
    },
    readonly: {type: Boolean, default:false}
  },
  data() {
    return {
      search: ''
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    tipoItems() {
      return [this.search || '', 'Primario', 'Alternativo', 'Familiar', 'Vecino', 'Trabajo', 'Abogado']
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
}
</script>

<style scoped>

</style>
