
const messages = {
    en: {
       role: {
            permissions: {
                MANUAL_MENU: "Access to Manual",
                MANUAL_SHOW: "View Manual",
                MANUAL_CREATE: "Create Manual",
                MANUAL_UPDATE: "Modify Manual",
                MANUAL_DELETE: "Delete Manual",
            }
       }
    },
    es: {
        role: {
            permissions: {
                MANUAL_MENU: "Acceder Manual",
                MANUAL_SHOW: "Ver Manual",
                MANUAL_CREATE: "Crear Manual",
                MANUAL_UPDATE: "Modificar Manual",
                MANUAL_DELETE: "Eliminar Manual",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                MANUAL_MENU: "Acessar Manual",
                MANUAL_SHOW: "Ver Manual",
                MANUAL_CREATE: "Criar Manual",
                MANUAL_UPDATE: "Modificar Manual",
                MANUAL_DELETE: "Eliminar Manual",
            }
       }
    }
}

export default messages
    