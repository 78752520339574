<template>
        <v-autocomplete
                prepend-icon="source"
                :items="items"
                :item-text="'nombre'"
                :item-value="'id'"
                v-model="item"
                :label="$t('cobrox.deuda.labels.origen')"
                :loading="loading"
                :error="hasInputErrors('origen')"
                :error-messages="getInputErrors('origen')"
                color="secondary"
                item-color="secondary"
                :rules="isRequired ? required : []"
                :multiple="multiple"
                :chips="chips"
                :solo="solo"
                :disabled="disabled"
                :readonly="readonly"
        ></v-autocomplete>

</template>

<script>

    import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'
    
    
    import OrigenDeudaProvider from "../../providers/OrigenDeudaProvider"

    export default {
        name: "OrigenDeudaCombobox",
        mixins: [InputErrorsByProps, RequiredRule],
        props:{
            value: {type: [String, Array]},
            multiple: {type:Boolean, default: false },
            solo: {type:Boolean, default: false},
            chips: {type:Boolean, default: false},
            readonly: {type:Boolean, default:false},
            disabled: {type:Boolean, default: false},
            isRequired: {type:Boolean, default: false },
        },
        data() {
            return {
                items: [],
                loading: false
            }
        },
        computed: {
           item: {
                get() { return this.value },
                set(val) {this.$emit('input', val)}
            }
        },
        mounted() {
         this.fetch()
        },
        methods: {
            validate(){
              return this.$refs.form.validate()
            },
            fetch(){
               this.loading= true
                OrigenDeudaProvider.fetchOrigenDeuda().then(r => {
                    this.items = r.data.fetchOrigenDeuda
                }).catch(err => console.error(err))
                .finally(()=> this.loading = false)
            }
            
        }
    }
</script>

<style scoped>

</style>