<template>
  <v-row row wrap>

    <v-col cols="12">
      <v-row justify="space-between">
        <v-col cols="12" sm="6" md="8">
          <!-- FILTERS HERE -->
          <v-row>

            <v-col cols="12" sm="6" md="4">
              <estado-retencion-combobox v-model="filters[0].value" @input="fetch"></estado-retencion-combobox>
            </v-col>
          </v-row>

        </v-col>
        <v-col cols="12" sm="6" md="4">
          <search-input v-if="false" @search="performSearch" v-model="search"/>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">

      <v-data-table
          class="mt-3"
          :headers="headers"
          :items="items"
          :search="search"
          :single-expand="false"
          :server-items-length="totalItems"
          :loading="loading"
          :page.sync="pageNumber"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="orderBy"
          :sort-desc.sync="orderDesc"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
          @update:page="fetch"
          @update:sort-by="fetch"
          @update:sort-desc="fetch"
          @update:items-per-page="fetch"
      >


        <template v-slot:item.agencia="{ item }">
          {{ item.agencia ? item.agencia.nombre : '' }}
        </template>


        <template v-slot:item.creadoPor="{ item }">
          {{ item.creadoPor ? item.creadoPor.username : '' }}
        </template>

        <template v-slot:item.archivo="{ item }">
          <a v-if="item.archivo" :href="item.archivo.url" target="_blank">Descargar</a>
        </template>


        <template slot="no-data">
          <div class="text-xs-center" v-t="'common.noData'"></div>
        </template>

        <template slot="loading">
          <div class="text-xs-center" v-t="'common.loading'"></div>
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn
              v-if="$store.getters.hasPermission('RETENCION_UPDATE') && item.estado === 'PENDIENTE'"
              color="red white--text"  class="mx-1"
              @click="confirmarRechzarRetencion(item)"
          >Rechazar</v-btn>
          <v-btn
              v-if="$store.getters.hasPermission('RETENCION_UPDATE') && item.estado === 'PENDIENTE'"
              color="blue white--text" class="mx-1"
              @click="confirmarAprobarRetencion(item)"
          >Aprobar</v-btn>

          <show-button v-if="false" @click="$emit('show', item)"/>
          <edit-button v-if="false && $store.getters.hasPermission('RETENCION_UPDATE')" @click="$emit('update', item)"/>
          <delete-button v-if="false && $store.getters.hasPermission('RETENCION_DELETE')" @click="$emit('delete', item)"/>
        </template>

      </v-data-table>
    </v-col>

    <confirm-dialog
        v-model="showAprobar"
        title="APROBAR"
        description="¿Esta seguro de aprobar este pedido de retención?"
        @confirmed="aprobarRetencion"
    ></confirm-dialog>

    <confirm-dialog
        v-model="showRechazar"
        title="RECHAZAR"
        description="¿Esta seguro de rechazar este pedido de retención?"
        @confirmed="rechazarRetencion"
    ></confirm-dialog>


  </v-row>
</template>

<script>
import RetencionProvider from "../../../../providers/RetencionProvider";

import {DeleteButton, EditButton, ShowButton, SearchInput, ConfirmDialog} from "@dracul/common-frontend"
import EstadoRetencionCombobox from "@/modules/cobrox/combobox/EstadoRetencionCombobox/EstadoRetencionCombobox";


export default {
  name: "RetencionList",
  components: {EstadoRetencionCombobox, DeleteButton, EditButton, ShowButton, SearchInput, ConfirmDialog},
  data() {
    return {
      items: [],
      totalItems: null,
      loading: false,
      orderBy: null,
      orderDesc: false,
      itemsPerPage: 5,
      pageNumber: 1,
      search: '',
      filters: [
        {
          field: 'estado',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value: ''
        }
      ],
      showRechazar: false,
      showAprobar: false,
      retencionPorRechazar: null,
      retencionPorAprobar: null,
    }
  },
  computed: {
    headers() {
      return [
        //Entity Headers
        ...( this.$store.getters.hasPermission('RETENCION_SHOW') ? [{text: this.$t('cobrox.retencion.labels.creadoPor'), value: 'creadoPor'}] : []),
        {text: this.$t('cobrox.retencion.labels.agencia'), value: 'agencia'},
        {text: this.$t('cobrox.retencion.labels.estado'), value: 'estado'},
        {text: this.$t('cobrox.retencion.labels.archivo'), value: 'archivo'},
        {text: this.$t('cobrox.retencion.labels.solicitadas'), value: 'solicitadas'},
        {text: this.$t('cobrox.retencion.labels.retenidas'), value: 'retenidas'},
        {text: this.$t('cobrox.retencion.labels.rechazadas'), value: 'rechazadas'},
        {text: this.$t('cobrox.retencion.labels.comentario'), value: 'comentario'},
        //Actions
        ...( this.$store.getters.hasPermission('RETENCION_UPDATE') ? [{text: this.$t('common.actions'), value: 'action', sortable: false}] : []),
      ]
    },
    getOrderBy() {
      return (Array.isArray(this.orderBy)) ? this.orderBy[0] : this.orderBy
    },
    getOrderDesc() {
      return (Array.isArray(this.orderDesc)) ? this.orderDesc[0] : this.orderDesc
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    confirmarRechzarRetencion(item){
      this.retencionPorRechazar = item
      this.showRechazar = true
    },
    rechazarRetencion(){
      this.showRechazar = false
      RetencionProvider
          .rechazarRetencion( this.retencionPorRechazar.id)
          .then(()=> {
            this.fetch()
            this.retencionPorRechazar = null
          })
    },
    confirmarAprobarRetencion(item){
      this.retencionPorAprobar = item
      this.showAprobar = true
    },
    aprobarRetencion(){
      this.showAprobar = false
      RetencionProvider
          .aprobarRetencion( this.retencionPorAprobar.id)
          .then(()=> {
            this.fetch()
            this.retencionPorAprobar = null
          })
    },
    performSearch() {
      this.pageNumber = 1
      this.fetch()
    },
    fetch() {
      this.loading = true
      RetencionProvider.paginateRetencion(
          this.pageNumber,
          this.itemsPerPage,
          this.search,
          this.filters,
          this.getOrderBy,
          this.getOrderDesc
      ).then(r => {
        this.items = r.data.paginateRetencion.items
        this.totalItems = r.data.paginateRetencion.totalItems
      }).catch(err => {
        console.error(err)
      }).finally(() => this.loading = false)
    }
  }

}
</script>


