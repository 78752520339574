<template>
  <v-card class="my-3">
    <v-card-title>Resultado</v-card-title>
    <v-alert type="info" class="mx-3" dense>
      {{ result.mensaje }}
    </v-alert>

    <v-card-text>
      <v-simple-table>
        <thead>
        <tr>
          <th>Total</th>
          <th>No Bloqueados</th>
          <th>Bloqueados</th>
          <th>Rotación General</th>
          <th>Retenidas</th>
          <th>Con Cobranzas</th>
          <th>Capital</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{ result.total }}</td>
          <td>{{ result.noBloqueados }}</td>
          <td>{{ result.bloqueados }}</td>
          <td>{{ result.rotacionGeneral }}</td>
          <td>{{ result.retenidas }}</td>
          <td>{{ result.conCobranzas }}</td>
          <td>{{ result.capitalAcumulado.toLocaleString('es-AR') }}</td>

        </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small v-if="!result.file" :loading="loading" @click="fetchReportWithCsv" color="indigo white--text">
        <v-icon small left>diversity_2</v-icon>
        Generar CSV
      </v-btn>
      <v-btn small v-if="result.file" :href="result.file" color="green white--text">
        <v-icon small left>download</v-icon>
        Descargar CSV
      </v-btn>
    </v-card-actions>

    <v-divider></v-divider>

    <v-card-title>Distribución Asistida</v-card-title>

    <v-card-text>
      <v-row>

        <v-col class="text-center">
          <v-row justify="center" class="flex-column" align="center">
            <div v-if="this.result.bloqueados > 0">
              <v-checkbox
                  name="rotacionForzada"
                  v-model="incluirRotacionBloqueada"
                  label="Incluir deudas con rotación bloqueada"
                  color="secondary"
              ></v-checkbox>
            </div>



          </v-row>

        </v-col>

        <v-col cols="12" v-for="(dist,index) of distribucion" :key="index">
          <v-row justify="center">
            <div>
              <agencia-combobox v-model="dist.agencia"></agencia-combobox>
            </div>
            <div>
              <v-text-field
                  prepend-icon="grid_4x4"
                  name="cantidad"
                  type="number"
                  v-model.number="dist.cantidad"
                  label="cantidad"
                  placeholder="cantidad"
                  color="secondary"
              ></v-text-field>
            </div>
          </v-row>
        </v-col>

        <v-col cols="12" class="text-center">
          <h2>Asignado {{sumaDistricion}} de {{ cantidadPorRotar }}</h2>
          <span class="grey--text">Restan: {{cantidadPorRotar - sumaDistricion}}</span>
        </v-col>

        <v-col cols="12" class="text-center">
          <v-btn color="secondary OnSecondary--text" @click="agregarDistribucion">Agregar</v-btn>
        </v-col>

      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary OnPrimary--text"
             @click="generarJobRotacionAsistida"
             :disabled="agenciasPending || cantidadPorRotar != this.sumaDistricion"
      >EJECUTAR ROTACION ASISTIDA</v-btn>
    </v-card-actions>

  </v-card>

</template>

<script>
import AgenciaCombobox from "@/modules/cobrox/combobox/AgenciaCombobox/AgenciaCombobox";
import ReportProvider from "@/modules/cobrox/providers/ReportProvider";
import JobProvider from "@/modules/cobrox/providers/JobProvider";

export default {
  name: "RotacionAsistidaAgencia",
  components: {AgenciaCombobox},
  props: {
    filters: {type: Object},
    result: {type: Object}
  },
  data() {
    return {
      loading: false,
      incluirRotacionBloqueada: false,
      distribucion: [
        {
          agencia: null,
          cantidad: 0
        }
      ]
    }
  },
  computed: {
    cantidadPorRotar() {
      return this.incluirRotacionBloqueada ? this.result.total : this.result.noBloqueados
    },
    sumaDistricion() {
      return this.distribucion.reduce((a, v) => a + v.cantidad, 0)
    },
    agenciasPending(){
      return this.distribucion.some(i => i.agencia === null)
    }
  },
  methods: {
    agregarDistribucion() {
      this.distribucion.push(
          {
            agencia: null,
            cantidad: 0
          }
      )
    },
    fetchReportWithCsv() {
      this.loading = true
      ReportProvider.rotacionAgenciasReport(this.filters, true)
          .then(r => {
            this.result = r.data.rotacionAgenciasReport
          })
          .catch(e => {
            console.error("fetchReport error", e)
          })
          .finally(() => this.loading = false)
    },
    generarJobRotacionAsistida() {
      this.loading = true
      this.error = null

      if(this.cantidadPorRotar != this.sumaDistricion){
        return
      }

      JobProvider.rotacionAsistida(this.filters, this.distribucion, this.incluirRotacionBloqueada)
          .then(res => {
            this.jobid = res.data.rotacionAsistida
            this.$router.replace({name: 'RotacionAgenciaReportPageJob', params: {jobid: this.jobid}})
          })
          .catch(e => {
            console.error(e)
            this.error = e.message.replace("GraphQL error: Unexpected error value:", "")
          })
          .finally(() => {
            this.loading = false
          })
    },
  }
}
</script>

<style scoped>

</style>
