<template>
  <v-card>
    <v-card-title>
      Procesando rotación de agencias asistida
    </v-card-title>
    <v-card-subtitle>
      JOB ID: {{jobid}}
    </v-card-subtitle>
    <v-card-text>
      <div  class="text-center">
        <v-progress-circular
            color="primary"
            :rotate="360"
            :size="100"
            :width="15"
            :value="(job && job.progress) ? job.progress : 0"
        >
          {{ (job && job.progress) ? job.progress : 0 }}
        </v-progress-circular>
        <div>
          Job progress
        </div>

      </div>
    </v-card-text>
    <v-card-subtitle>Filtros aplicados</v-card-subtitle>
    <v-card-text>
       {{filters}}
    </v-card-text>
    <v-card-subtitle v-if="job">Resultado</v-card-subtitle>
    <v-card-text v-if="job">
      <v-simple-table>
        <thead>
        <tr>
          <th>Agencia</th><th>Cantidad Solicitada</th><th>Cantidad Asignada</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in getDataDistribucion" :key="item.agencia">
          <td>{{nombreAgencia(item.agencia)}}</td>
          <td>{{item.cantidad}}</td>
          <td>{{item.asignado}}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>

</template>

<script>
import {QueueProvider} from "@dracul/queue-frontend";
import AgenciaProvider from "@/modules/cobrox/providers/AgenciaProvider";

export default {
  name: "RotacionAsistidaJob",
  props: {
    jobid: {type: String},
    filters: {type: Object},
    distribucion: {type: Array}
  },
  data() {
    return {
      data: null,
      job: null,
      agencias: []
    }
  },
  mounted() {
    this.fetchAgencia()
    this.fetchJob()
  },
  computed:{
    getDataDistribucion(){
      let data =  (this.job && this.job.data) ? JSON.parse(this.job.data) : null
      return data && data.distribucion ? data.distribucion : null
    },
    nombreAgencia(){
      return id => {
        let e = this.agencias.find(v => v.id.toString() === id.toString())
        return e ? e.nombre : ''
      }
    }
  },
  methods: {

    fetchJob() {
      QueueProvider.findQueue(this.jobid)
          .then(res => {
            this.job = res.data.queueFind

            if (this.job.state === 'DONE') {
              //this.jobid = null
              //this.$router.replace({name: 'RotacionAgenciaReportPage'})
            }

          })
          .finally(() => {
            if (this.job.state !== 'DONE') {
              setTimeout(this.fetchJob, 5000)
            }
          })
    },
    fetchAgencia(){
      AgenciaProvider.fetchAgencia()
          .then(r => {
            this.agencias = r.data.fetchAgencia
          })
    }
  }
}
</script>

<style scoped>

</style>
