import {RedmineIssuesListPage} from "@/modules/redmine/pages/RedmineIssuesListPage";
import {RedmineIssueCreatePage} from "@/modules/redmine/pages/RedmineIssueCreatePage";
import {RedmineIssueViewPage} from "@/modules/redmine/pages/RedmineIssueViewPage";

const routes = [


    {
        name: 'RedmineIssuesListPage', path: '/tickets/lista/:status', component: RedmineIssuesListPage,
        meta: {
            requiresAuth: true,
        }
    },
    {
        name: 'RedmineIssueCreatePage', path: '/tickets/nuevo', component: RedmineIssueCreatePage,
        meta: {
            requiresAuth: true,
        }
    },
    {
        name: 'RedmineIssueCreatePageByDni', path: '/tickets/dni/:dni', component: RedmineIssueCreatePage,
        meta: {
            requiresAuth: true,
        }
    },
    {
        name: 'RedmineIssueViewPage', path: '/tickets/ver/:id', component: RedmineIssueViewPage,
        meta: {
            requiresAuth: true,
        }
    },

]

export default routes;
