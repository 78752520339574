<template>
  <v-select
      v-model="localValue"
      :items="items"
      label="Bloqueo de Rotación"
      clearable
  >

  </v-select>
</template>

<script>
export default {
  name: "FiltroRotacionAgencia",
  props: {
    value: {type:Boolean}
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  data() {
    return {
      items: [
        {text: 'Todo', value: null},
        {text: 'Rotación habilitada', value: false},
        {text: 'Rotación Bloqueada', value: true},
      ]
    }
  }
}
</script>

<style scoped>

</style>
