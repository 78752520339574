<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>Deudor</v-card-title>
        <v-card-text>

          <v-row>

            <v-col cols="12" md="4">
              <show-field
                  v-if="canView('nombreDeudor')"
                  :value="deuda.nombreDeudor"
                  :label="$t('cobrox.deuda.labels.nombreDeudor')"
                  icon="face"
              />
            </v-col>
            <v-col cols="12" md="4">
              <show-field
                  v-if="canView('dniDeudor')"
                  :value="deuda.dniDeudor"
                  :label="$t('cobrox.deuda.labels.dniDeudor')"
                  icon="badge"
              />

            </v-col>
            <v-col cols="12" md="4">
              <show-field
                  v-if="canView('cuilDeudor')"
                  :value="deuda.cuilDeudor"
                  :label="$t('cobrox.deuda.labels.cuilDeudor')"
                  icon="contact_page"
              />
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card>
        <v-card-title>Fechas</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <show-field
                  v-if="canView('fechaMora')"
                  :value="deuda.fechaMora ?  getDateFormat(deuda.fechaMora) : ''"
                  :label="$t('cobrox.deuda.labels.fechaMora')"
                  icon="date_range"
              />
            </v-col>
            <v-col cols="12" md="4">
              <show-field
                  v-if="canView('fechaCompra')"
                  :value="(deuda.compra && deuda.compra.fechaCompra) ? getDateFormat(deuda.compra.fechaCompra) : '' "
                  :label="$t('cobrox.compra.labels.fechaCompra')"
                  icon="event"
              />
            </v-col>
            <v-col cols="12" md="4">
              <show-field
                  v-if="canView('fechaOtorgamiento')"
                  :value="deuda.fechaOtorgamiento?getDateFormat(deuda.fechaOtorgamiento):''"
                  :label="$t('cobrox.deuda.labels.fechaOtorgamiento')"
                  icon="event"
              />
            </v-col>
          </v-row>


        </v-card-text>
      </v-card>
    </v-col>

    <!--PRODUCTO-->
    <v-col cols="12">
      <v-card>
        <v-card-title>Producto</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <show-field
                  v-if="canView('producto')"
                  :value="deuda.producto"
                  :label="$t('cobrox.deuda.labels.producto')"
                  icon="inventory_2"
              />

            </v-col>

            <v-col cols="12" md="4">
              <show-field
                  v-if="canView('detalleProducto')"
                  :value="deuda.detalleProducto"
                  :label="$t('cobrox.deuda.labels.detalleProducto')"
                  icon="description"
              />
            </v-col>

            <v-col cols="12" md="4">
              <show-field
                  v-if="canView('nroProducto')"
                  :value="deuda.nroProducto"
                  :label="$t('cobrox.deuda.labels.nroProducto')"
                  icon="copyright"
              />


            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <!--DEUDA-->
    <v-col cols="12">
      <v-card>
        <v-card-title>Montos</v-card-title>
        <v-card-text>
          <v-row>

            <v-col cols="12" md="4">

              <show-field
                  v-if="canView('capitalTotal')"
                  :value="'$'+ deuda.capitalTotal.toLocaleString('es-AR')"
                  :label="$t('cobrox.deuda.labels.capitalTotal')"
                  icon="attach_money"
              />

              <show-field
                  v-if="canView('imputadoCobranzas')"
                  :value="'$'+deuda.imputadoCobranzas.toLocaleString('es-AR')"
                  :label="$t('cobrox.deuda.labels.imputadoCobranzas')"
                  icon="money_off"
              />

            </v-col>


            <v-col cols="12" md="4">

              <show-field
                  v-if="canView('intereses')"
                  :value="'$'+deuda.intereses.toLocaleString('es-AR')"
                  :label="$t('cobrox.deuda.labels.intereses')"
                  icon="attach_money"
              />


              <show-field
                  v-if="canView('imputadoCobranzas')"
                  :value="'$'+ (deuda.capitalTotal + deuda.intereses - deuda.imputadoCobranzas).toLocaleString('es-AR')"
                  :label="$t('cobrox.deuda.labels.saldo')"
                  icon="currency_exchange"
              />

            </v-col>

            <v-col cols="12" md="4">
              <show-field
                  v-if="canView('capitalTotal') && canView('intereses')"
                  :value="'$'+(deuda.capitalTotal + deuda.intereses).toLocaleString('es-AR')"
                  :label="$t('cobrox.deuda.labels.deudaTotal')"
                  icon="paid"
              />

              <show-field
                  v-if="canView('imputadoContable') && $store.getters.hasPermission('CONTABILIDAD')"
                  :value="'$'+(deuda.imputadoContable).toLocaleString('es-AR')"
                  :label="$t('cobrox.deuda.labels.imputadoContable')"
                  icon="money_off"
              />


            </v-col>

          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <!--CAPITAL COMPRA-->
    <v-col cols="12" v-if="this.$store.getters.hasPermission('DEUDA_SHOW')">
      <v-card>
        <v-card-title>Capital Compra</v-card-title>
        <v-card-text>
          <v-row>


            <v-col cols="12" md="4">
              <show-field
                  v-if="canView('capitalCompra')"
                  :value="'$'+deuda.capitalCompra.toLocaleString('es-AR')"
                  :label="$t('cobrox.deuda.labels.capitalCompra')"
                  icon="request_quote"
              />
            </v-col>

            <v-col cols="12" md="4">
              <show-field
                  v-if="canView('saldoCapitalCompra')"
                  :value="'$'+deuda.saldoCapitalCompra.toLocaleString('es-AR')"
                  :label="$t('cobrox.deuda.labels.saldoCapitalCompra')"
                  icon="price_check"
              />
            </v-col>

            <v-col cols="12" md="4">
              <show-field
                  v-if="canView('saldoCapitalCompraInicial')"
                  :value="'$' + deuda.saldoCapitalCompraInicial.toLocaleString('es-AR')"
                  :label="$t('cobrox.deuda.labels.saldoCapitalCompraInicial')"
                  icon="price_check"
              />

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <!--DATOS-->
    <v-col cols="12">
      <v-card>
        <v-card-title>DATOS</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <show-field
                  v-if="canView('estado')"
                  :value="deuda.estado"
                  :label="$t('cobrox.deuda.labels.estado')"
                  icon="fact_check"
              />
              <show-field
                  v-if="canView('idDeuda')"
                  :value="deuda.idDeuda"
                  :label="$t('cobrox.deuda.labels.idDeuda')"
                  icon="grid_4x4"
              />
            </v-col>
            <v-col cols="12" md="4">
              <show-field
                  v-if="canView('agencia')"
                  :value="deuda.agencia?deuda.agencia.nombre:''"
                  :label="$t('cobrox.deuda.labels.agencia')"
                  icon="business"
              />
              <show-field
                  v-if="canView('cbu')"
                  :value="deuda.cbu"
                  :label="$t('cobrox.deuda.labels.cbu')"
                  icon="account_balance_wallet"
              />
            </v-col>
            <v-col cols="12" md="4">
              <show-field
                  v-if="canView('compra')"
                  :value="deuda.compra?deuda.compra.nombre:''"
                  :label="$t('cobrox.deuda.labels.compra')"
                  icon="shop"
              />

              <show-field
                  v-if="canView('interesBloqueado')"
                  :value="deuda.interesBloqueado?'Si':'No'"
                  :label="$t('cobrox.deuda.labels.interesBloqueado')"
                  icon="block"
              />

            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-col>


    <v-col cols="12"  v-if="canView('cobranzasImputadas')">
      <cobranzas-imputadas-simple-table :cobranzas-imputadas="deuda.cobranzasImputadas"/>
    </v-col>


    <v-col cols="12" v-if="canView('facturas') && $store.getters.hasPermission('CONTABILIDAD')">
      <facturas-simple-table :facturas="deuda.facturas"></facturas-simple-table>
    </v-col>

    <v-col cols="12" v-if="canView('interesHistorico')">
      <interes-historico-simple-table :intereses-historico="deuda.interesHistorico"/>

    </v-col>

    <v-col cols="12" v-if="canView('estadoHistorico')">
      <estado-historico-simple-table :estado-historico="deuda.estadoHistorico"></estado-historico-simple-table>
    </v-col>

    <v-col cols="12" v-if="canView('agenciaHistorico')">
      <agencia-historico-simple-table :agencia-historico="deuda.agenciaHistorico"></agencia-historico-simple-table>
    </v-col>

  </v-row>
</template>

<script>
import {DayjsMixin} from "@dracul/dayjs-frontend";
import {ShowField} from "@dracul/common-frontend";
import FacturasSimpleTable from "@/modules/cobrox/components/FacturasSimpleTable/FacturasSimpleTable";

import InteresHistoricoSimpleTable
  from "@/modules/cobrox/components/InteresHistoricoSimpleTable";

import EstadoHistoricoSimpleTable
  from "@/modules/cobrox/components/EstadoHistoricoSimpleTable";

import CobranzasImputadasSimpleTable
  from "@/modules/cobrox/components/CobranzasImputadasSimpleTable/CobranzasImputadasSimpleTable";
import AgenciaHistoricoSimpleTable
  from "@/modules/cobrox/components/AgenciaHistoricoSimpleTable/AgenciaHistoricoSimpleTable";
import SuretyMixin from "@/modules/surety/mixins/SuretyMixin";

export default {
  name: "DeudaView",
  props: {
    deuda: {type: Object}
  },
  mixins: [DayjsMixin, SuretyMixin],
  created() {
    this.fetchFieldPermission('Deuda')
  },
  components: {
    AgenciaHistoricoSimpleTable,
    CobranzasImputadasSimpleTable,
    EstadoHistoricoSimpleTable,
    InteresHistoricoSimpleTable,
    FacturasSimpleTable,
    ShowField
  },
}
</script>

<style scoped>

</style>
