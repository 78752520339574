import graphqlClient from "../../../apollo";

class EstadoDeudaProvider {

    findEstadoDeuda(id) {
        return graphqlClient.query({
            query: require('./gql/EstadoDeuda/findEstadoDeuda.graphql'),
            variables: {id:id}
        })
    }

    fetchEstadoDeuda() {
        return graphqlClient.query({
            query: require('./gql/EstadoDeuda/fetchEstadoDeuda.graphql')
        })
    }

    paginateEstadoDeuda(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/EstadoDeuda/paginateEstadoDeuda.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }



    createEstadoDeuda(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/EstadoDeuda/createEstadoDeuda.graphql'),
            variables: {input}
        })
    }

    updateEstadoDeuda(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/EstadoDeuda/updateEstadoDeuda.graphql'),
            variables: {id, input}
        })
    }

     deleteEstadoDeuda(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/EstadoDeuda/deleteEstadoDeuda.graphql'),
            variables: {id}
        })
    }

}

export default new EstadoDeudaProvider()


