import graphqlClient from "../../../apollo";

class DistribucionProvider {

    distribuir(idCobranza, porcentajeDistribucion, simular) {
        return graphqlClient.mutate({
            mutation: require('./gql/distribuir.graphql'),
            variables: {idCobranza, porcentajeDistribucion, simular}
        })
    }

    /*distribuirMasivo(porcentajeDistribucion, simular) {
        return graphqlClient.mutate({
            mutation: require('./gql/distribuirMasivo.graphql'),
            variables: {porcentajeDistribucion, simular}
        })
    }*/

}

export default new DistribucionProvider()


