<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">
    <v-row>


      <v-col cols="12" class="text-center">
        <file-upload-express
            @fileUploaded="onFileUploaded"
            auto-submit
        ></file-upload-express>
        <div class="ma-2">Subir archivo con DNIs a retener</div>
      </v-col>


      <v-col cols="12" sm="6">
        <v-textarea
            v-if="false"
            rows="2"
            prepend-icon="description"
            name="comentario"
            v-model="form.comentario"
            :label="$t('cobrox.retencion.labels.comentario')"
            :placeholder="$t('cobrox.retencion.labels.comentario')"
            :error="hasInputErrors('comentario')"
            :error-messages="getInputErrors('comentario')"
            color="secondary"

        ></v-textarea>
      </v-col>

    </v-row>
  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'
import {FileUploadExpress} from '@dracul/media-frontend'


export default {
  name: "RetencionForm",
  mixins: [InputErrorsByProps, RequiredRule],
  components: {FileUploadExpress},
  props: {
    value: {type: Object, required: true},
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    onFileUploaded(file){
        this.form.archivo = file.id
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>

