<template>
  <v-container fluid>
    <cobranzas-por-agencia></cobranzas-por-agencia>
  </v-container>
</template>

<script>
import CobranzasPorAgencia from "@/modules/cobrox/components/CobranzasPorAgencia/CobranzasPorAgencia";

export default {
  name: "CobranzasPorAgenciaPage",
  components: {CobranzasPorAgencia}
}
</script>

<style scoped>

</style>
