
const messages = {
    en: {
       cobrox: {
          cuenta: { 
            name: 'Cuenta',
            title: 'Cuenta management',
            subtitle: 'View, search, create, edit and delete Cuenta',
            creating: 'Creating Cuenta',
            editing: 'Editing Cuenta',
            deleting: 'Deleting Cuenta',
            showing: 'Showing Cuenta',
            menu: 'Cuenta',
            labels: {
                nombre: 'Nombre',
                numero: 'Número',
                alias: 'Alias'
            },
          
          }
       }
    },
    es: {
       cobrox: {
          cuenta: { 
            name: 'Cuenta',
            title: 'Administración de Cuenta',
            subtitle: 'Ver, buscar, crear, editar, y borrar Cuenta',
            creating: 'Creando Cuenta',
            editing: 'Modificando Cuenta',
            deleting: 'Eliminando Cuenta',
            showing: 'Detalles de Cuenta',
            menu: 'Cuenta',
            labels: {
                nombre: 'Nombre',
                numero: 'Número',
                alias: 'Alias'
            },
          
          }
       }
    },
    pt: {
       cobrox: {
          cuenta: { 
            name: 'Cuenta',
            title: 'Administração de Cuenta',
            subtitle: 'Ver, buscar, criar, editar e usar Cuenta',
            creating: 'Criando Cuenta',
            editing: 'Edição Cuenta',
            deleting: 'Apagando Cuenta',
            showing: 'Detalhes do Cuenta',
            menu: 'Cuenta',
            labels: {
                nombre: 'Nombre',
                numero: 'Número',
                alias: 'Alias'
            },
          
          }
       }
    }
}

export default messages
    