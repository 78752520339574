<template>
  <v-select
      v-model="localValue"
      :items="items"
      label="Cobranzas"
      clearable
  >

  </v-select>
</template>

<script>
export default {
  name: "FiltroCobranza",
  props: {
    value: {type:Boolean}
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  data() {
    return {
      items: [
        {text: 'Todo', value: null},
        {text: 'Con Cobranzas', value: true},
        {text: 'Sin Cobranzas', value: false},
      ]
    }
  }
}
</script>

<style scoped>

</style>
