<template>
<crud-layout :title="title" :subtitle="subtitle">

        <template v-slot:list>
            <canal-cobro-list 
                       ref="list"
                       @update="update"
                       @delete="remove"
                       @show="show"
            
            />
        </template>
        
         <add-button v-if="$store.getters.hasPermission('CANALCOBRO_CREATE')" @click="create"></add-button>
      
        <canal-cobro-create v-if="creating" 
                        :open="creating"
                        v-on:itemCreated="onItemCreated" 
                        v-on:close="creating=false" 
        />
        
        <canal-cobro-update v-if="updating" 
                        :open="updating"
                        :item="itemToEdit" 
                        v-on:itemUpdated="onItemUpdated" 
                        v-on:close="updating=false" 
        />
          
        <canal-cobro-show v-if="showing" 
                           :open="showing" 
                           :item="itemToShow"  
                           v-on:close="showing=false" 
         />

        <canal-cobro-delete v-if="deleting" 
                         :open="deleting"
                         :item="itemToDelete"  
                         v-on:itemDeleted="onItemDeleted" 
                         v-on:close="deleting=false" 
        />

        <snackbar v-model="flash"/>

</crud-layout>
</template>

<script>
    
    import CanalCobroCreate from "../CanalCobroCreate";
    import CanalCobroUpdate from "../CanalCobroUpdate";
    import CanalCobroDelete from "../CanalCobroDelete";
    import CanalCobroShow from "../CanalCobroShow";
    import CanalCobroList from "../CanalCobroList";
    
     import {CrudLayout, AddButton, Snackbar} from "@dracul/common-frontend"
     
    export default {
        name: "CanalCobroCrud",
        components: {
            CrudLayout, AddButton, Snackbar,
            CanalCobroCreate, 
            CanalCobroUpdate, 
            CanalCobroDelete, 
            CanalCobroShow,
            CanalCobroList
        },
        data() {
            return {
                title: 'cobrox.canalCobro.title',
                subtitle: 'cobrox.canalCobro.subtitle',
                flash: null,
                creating: false,
                updating: false,
                deleting: false,
                showing: false,
                itemToEdit: null,
                itemToDelete: null,
                itemToShow: null,
            }
        },
        methods: {
            //On
            onItemCreated() {
                this.$refs.list.fetch()
                this.flash= this.$t("common.created")
            },
            onItemUpdated() {
                this.$refs.list.fetch()
                this.flash= this.$t("common.updated")
            },
            onItemDeleted() {
                this.$refs.list.fetch()
                this.flash= this.$t("common.deleted")
            },
            //Open
            create() {
                this.creating = true
            },
            update(item) {
                this.updating = true
                this.itemToEdit = item
            },
            show(item) {
                this.showing = true
                this.itemToShow = item
            },
            remove(item) {
                this.deleting = true
                this.itemToDelete = item
            }
        }
        
    }
</script>


