import graphqlClient from "../../../apollo";

class CobranzaProvider {

    findCobranza(id) {
        return graphqlClient.query({
            query: require('./gql/Cobranza/findCobranza.graphql'),
            variables: {id:id}
        })
    }

    fetchCobranza() {
        return graphqlClient.query({
            query: require('./gql/Cobranza/fetchCobranza.graphql')
        })
    }

    fetchCobranzaByDni(dni, propias) {
        return graphqlClient.query({
            query: require('./gql/Cobranza/fetchCobranzaByDni.graphql'),
            fetchPolicy: "network-only",
            variables: {dni, propias}
        })
    }

    fetchCobranzaByCuil(cuil, propias) {
        return graphqlClient.query({
            query: require('./gql/Cobranza/fetchCobranzaByCuil.graphql'),
            fetchPolicy: "network-only",
            variables: {cuil, propias}
        })
    }

    paginateCobranza(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Cobranza/paginateCobranza.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }



    createCobranza(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Cobranza/createCobranza.graphql'),
            variables: {input}
        })
    }

    updateCobranza(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Cobranza/updateCobranza.graphql'),
            variables: {id, input}
        })
    }

     deleteCobranza(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Cobranza/deleteCobranza.graphql'),
            variables: {id}
        })
    }

    tipificarCobranza(id, tipificacion, convenioId, forzar = false) {
        return graphqlClient.mutate({
            mutation: require('./gql/Cobranza/tipificarCobranza.graphql'),
            variables: {id, tipificacion, convenioId, forzar}
        })
    }

}

export default new CobranzaProvider()


