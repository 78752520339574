<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="true"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
          v-model="date"
          :label="label"
          prepend-icon="mdi-calendar"
          readonly
          v-on="on"
          :solo="solo"
      ></v-text-field>
    </template>
    <v-date-picker
        v-model="date"
        type="month"
        no-title
        scrollable
        @input="menu = false"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "MonthPicker",
  props: {
    value: {type:[String,Object]},
    label: {type:String, default: "Mes"},
    solo: {type: Boolean, default: false}
  },
  data(){
    return {
      menu: false
    }
  },
  computed: {
    date: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
}
</script>

<style scoped>

</style>
