<template>
  <v-row row wrap>

    <v-col cols="12">
      <v-btn
          color="blue darken-2"
          dark small
          @click="exportarFacturas()"
          :loading="exportLoading"
      >
        <v-icon left>file_download</v-icon>
        Exportar
      </v-btn>
      <v-row justify="space-between" align="center">
        <v-col cols="12" sm="12" md="12">
          <!-- FILTERS HERE -->
          <v-row align="center">


            <v-col cols="12" sm="6" md="3">
              <v-switch
                  v-model="showNoFacturables"
                  @change="changeNoFacturables"
                  label="Ver no facturables"
                  hide-details
              ></v-switch>

            </v-col>

            <v-col cols="12" sm="6" md="3">
              <estado-factura-combobox
                  v-model="filters[0].value"
                  @change="fetch"
                  hide-details
              ></estado-factura-combobox>

            </v-col>

            <v-col cols="12" sm="6" md="3">
              <tipo-comprobante-combobox
                  v-model="filters[1].value"
                  @change="fetch"
                  hide-details
              ></tipo-comprobante-combobox>

            </v-col>
            <v-col cols="12" sm="6" md="3">
              <search-input @search="performSearch" v-model="search"/>
            </v-col>
          </v-row>


        </v-col>

      </v-row>
    </v-col>

    <v-col cols="12">

      <v-data-table
          class="mt-3"
          :headers="headers"
          :items="items"
          :search="search"
          :single-expand="false"
          :server-items-length="totalItems"
          :loading="loading"
          :page.sync="pageNumber"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="orderBy"
          :sort-desc.sync="orderDesc"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
          @update:page="fetch"
          @update:sort-by="fetch"
          @update:sort-desc="fetch"
          @update:items-per-page="fetch"
      >

        <template v-slot:item.cobranza="{ item }">

          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="orange"
                  small
                  dark text
                  v-bind="attrs"
                  v-on="on"
              >
                {{ item.cobranza.dniDeudor }}
              </v-btn>
            </template>
            <cobranza-detail :cobranza-id="item.cobranza.id"></cobranza-detail>
          </v-menu>
        </template>


        <template v-slot:item.deuda="{ item }">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="blue darken-3"
                  small
                  dark text
                  v-bind="attrs"
                  v-on="on"
              >
                {{ item.deuda.dniDeudor }}
              </v-btn>
            </template>
            <deuda-detail :deuda-id="item.deuda.id"></deuda-detail>

          </v-menu>

        </template>


        <template v-slot:item.montoNoFacturable="{ item }">
          {{currency}}{{ item.montoNoFacturable }}
        </template>

        <template v-slot:item.montoFacturable="{ item }">
          {{currency}}{{ item.montoFacturable }}
        </template>

        <template v-slot:item.monto="{ item }">
          {{currency}}{{ item.monto }}
        </template>

        <template v-slot:item.fecha="{ item }">
          {{ getDateFormat(item.fecha) }}
        </template>

        <template v-slot:item.emitir="{ item }">
          <!--TODO: Solo mostrar si no hay idFacturaColppy-->

          <v-btn color="indigo" dark x-small fab
                 @click="emitirFacturaConfirm(item.id)">
            <v-icon>done</v-icon>
          </v-btn>
        </template>


        <template slot="no-data">
          <div class="text-xs-center" v-t="'common.noData'"></div>
        </template>

        <template slot="loading">
          <div class="text-xs-center" v-t="'common.loading'"></div>
        </template>

        <template v-slot:item.action="{ item }">
          <show-button @click="$emit('show', item)"/>
          <edit-button v-if="$store.getters.hasPermission('FACTURA_UPDATE')"  @click="$emit('update', item)" />


        </template>

      </v-data-table>
    </v-col>


    <confirm-dialog
        v-model="showEmitirFacturaConfirm"
        title="Emitir factura"
        description="¿Esta seguro de emitir individualmente esta factura?"
        @confirmed="emitirFactura"
    ></confirm-dialog>

    <simple-dialog fullscreen v-model="showEmitirFacturaResult" @close="showEmitirFacturaResult = false">

      <v-alert v-if="emitirFacturaError" type="error">
        {{ emitirFacturaError }}
      </v-alert>

      <loading v-else-if="!emitirFacturaResult" text="common.processing"></loading>


      <template v-else>
        <v-alert v-if="emitirFacturaResult.idFacturaColppy" type="success">
          Se genero la factura en colppy correctamente. ID Factura Colppy: {{ emitirFacturaResult.idFacturaColppy }}
        </v-alert>
        <v-alert v-if="!emitirFacturaResult.idFacturaColppy" type="warning">
          No se genero la factura en colppy.
        </v-alert>
        <factura-show-data :item="emitirFacturaResult"></factura-show-data>
      </template>

    </simple-dialog>

  </v-row>
</template>

<script>
import FacturaProvider from "../../../../providers/FacturaProvider";

import {
  EditButton,
  ShowButton,
  SearchInput,
  ConfirmDialog,
  SimpleDialog,
  Loading
} from "@dracul/common-frontend"
import {DayjsMixin} from "@dracul/dayjs-frontend";
import DeudaDetail from "@/modules/cobrox/components/DeudaDetail/DeudaDetail";
import CobranzaDetail from "@/modules/cobrox/components/CobranzaDetail/CobranzaDetail";
import FacturarProvider from "@/modules/cobrox/providers/FacturarProvider";
import FacturaShowData from "@/modules/cobrox/pages/crud/FacturaPage/FacturaShow/FacturaShowDataCustom";
import EstadoFacturaCombobox from "@/modules/cobrox/combobox/EstadoFacturaCombobox/EstadoFacturaCombobox";
import TipoComprobanteCombobox from "@/modules/cobrox/combobox/TipoComprobanteCombobox/TipoComprobanteCombobox";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";

export default {
  name: "FacturaListCustom",
  components: {
    TipoComprobanteCombobox,
    EstadoFacturaCombobox,
    FacturaShowData,
    CobranzaDetail,
    DeudaDetail,
    EditButton,
    ShowButton,
    SearchInput,
    ConfirmDialog,
    SimpleDialog,
    Loading
  },
  mixins: [DayjsMixin, CurrencyMixin],
  data() {
    return {
      items: [],
      totalItems: null,
      loading: false,
      exportLoading: false,
      orderBy: null,
      orderDesc: false,
      itemsPerPage: 10,
      pageNumber: 1,
      search: '',
      showNoFacturables: false,
      facturaSelected: null,
      showEmitirFacturaConfirm: false,
      showEmitirFacturaResult: false,
      emitirFacturaResult: null,
      emitirFacturaError: null,
      filters: [
        {
          field: 'estado',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value: ''
        },
        {
          field: 'tipoComprobante',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value: ''
        },

      ]
    }
  },
  computed: {
    headers() {
      return [
        //Entity Headers
        {text: this.$t('cobrox.factura.labels.estado'), value: 'estado'},
        {text: this.$t('cobrox.factura.labels.tipoComprobante'), value: 'tipoComprobante'},
        {text: this.$t('cobrox.factura.labels.cobranza'), value: 'cobranza'},
        {text: this.$t('cobrox.factura.labels.deuda'), value: 'deuda'},
        //{text: this.$t('cobrox.factura.labels.monto'), value: 'monto'},
        ...(this.showNoFacturables ? [{text: this.$t('cobrox.factura.labels.montoNoFacturable'), value: 'montoNoFacturable'}] : []),
        {text: this.$t('cobrox.factura.labels.montoFacturable'), value: 'montoFacturable'},
        {text: this.$t('cobrox.factura.labels.estado'), value: 'estado'},
        {text: this.$t('cobrox.factura.labels.lote'), value: 'lote'},
        {text: 'Emitir', value: 'emitir'},
        //Actions
        {text: this.$t('common.actions'), value: 'action', sortable: false},
      ]
    },
    getOrderBy() {
      return (Array.isArray(this.orderBy)) ? this.orderBy[0] : this.orderBy
    },
    getOrderDesc() {
      return (Array.isArray(this.orderDesc)) ? this.orderDesc[0] : this.orderDesc
    },
    getFilters() {
      if (this.showNoFacturables) {
        return this.filters
      }
      return [...this.filters, ...[{field: 'montoFacturable', operator: 'gt', value: '0'}]]

    }
  },
  created() {
    this.fetch()
  },
  methods: {
    exportarFacturas() {
      this.exportLoading = true
      FacturarProvider.exportarFacturas(null,this.getFilters)
          .then(r => {
            let url = r.data.facturaExportar
            console.log(url)
            const link = document.createElement('a')
            link.href = url
            link.click()
          })
          .finally(() => this.exportLoading = false)
    },
    performSearch() {
      this.pageNumber = 1
      this.fetch()
    },
    fetch() {
      this.loading = true
      FacturaProvider.paginateFactura(
          this.pageNumber,
          this.itemsPerPage,
          this.search,
          this.getFilters,
          this.getOrderBy,
          this.getOrderDesc
      ).then(r => {
        this.items = r.data.paginateFactura.items
        this.totalItems = r.data.paginateFactura.totalItems
      }).catch(err => {
        console.error(err)
      }).finally(() => this.loading = false)
    },
    emitirFacturaConfirm(facturaId) {
      this.facturaSelected = facturaId
      this.showEmitirFacturaConfirm = true
    },
    emitirFactura() {
      this.showEmitirFacturaResult = true
      this.emitirFacturaResult = null
      this.emitirFacturaError = null
      FacturarProvider.emitirFactura(this.facturaSelected)
          .then(r => {
            this.emitirFacturaResult = r.data.emitirFactura
            console.log(r)
          })
          .catch((e) => {
            this.emitirFacturaError = e.message.replace("GraphQL error: ", "")
            console.error("emitirFactura", e)
          })
    },
    changeNoFacturables() {
      this.fetch()
    },
  }

}
</script>


