<template>
  <crud-update :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @update="update"
               @close="$emit('close')"
  >
    <deuda-form ref="form" v-model="form" :input-errors="inputErrors"/>
  </crud-update>
</template>

<script>

import DeudaProvider from "../../../../providers/DeudaProvider";

import {CrudUpdate, ClientError} from '@dracul/common-frontend'

import DeudaForm from "../DeudaForm";

import {Dayjs} from "@dracul/dayjs-frontend";

export default {
  name: "DeudaUpdate",

  components: {DeudaForm, CrudUpdate},

  props: {
    open: {type: Boolean, default: true},
    item: {type: Object, required: true}
  },

  data() {
    return {
      title: 'cobrox.deuda.editing',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      id: this.item.id,
      form: {
        idDeuda: this.item.idDeuda,
        cartera: this.item.cartera,
        fechaMora: this.item.fechaMora ? Dayjs(parseInt(this.item.fechaMora)) : null,
        nombreDeudor: this.item.nombreDeudor,
        dniDeudor: this.item.dniDeudor,
        cuilDeudor: this.item.cuilDeudor,
        capitalCompra: this.item.capitalCompra,
        capitalTotal: this.item.capitalTotal,
        intereses: this.item.intereses,
       // facturas: this.item.facturas.map(i => i.id ? i.id : i),
        saldoCapitalCompra: this.item.saldoCapitalCompra,
        imputadoContable: this.item.imputadoContable,
        imputadoCobranzas: this.item.imputadoCobranzas,
        estado: this.item.estado,
        jurisdiccion: this.item.jurisdiccion,
        idClienteColppy: this.item.idClienteColppy,
        agencia: this.item.agencia ? this.item.agencia.id : null,
        origen: this.item.origen ? this.item.origen.id : null,
        compra: this.item.compra ? this.item.compra.id : null,

        cbu: this.item.cbu,
        producto:  this.item.producto,
        detalleProducto:  this.item.detalleProducto,
        nroProducto:  this.item.nroProducto,
        fechaOtorgamiento:  this.item.fechaOtorgamiento ? Dayjs(parseInt(this.item.fechaOtorgamiento)) : null,
        precioCompra:  this.item.precioCompra,
        debitable: this.item.debitable
      }
    }
  },
  methods: {
    update() {
      if (this.$refs.form.validate()) {
        this.loading = true

        if (this.form.idClienteColppy === "") {
          this.form.idClienteColppy = null
        }

        DeudaProvider.updateDeuda(this.id, this.form).then(r => {
              if (r) {
                this.$emit('itemUpdated', r.data.updateDeuda)
                this.$emit('close')
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }
  },
}
</script>

<style scoped>

</style>

