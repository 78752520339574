<template>
<v-container>
  <v-data-table
  :items="historico"
  :headers="headers"
  :items-per-page="-1"
  hide-default-footer
  >
    <template v-slot:item.agencia="{item}">
      {{item.agencia &&  item.agencia.nombre ? item.agencia.nombre : item.agencia}}
    </template>

    <template v-slot:item.modificadoPor="{item}">
      {{item.modificadoPor ? item.modificadoPor.username: ''}}
    </template>

    <template v-slot:item.fecha="{item}">
      {{getDateTimeFormat(item.fecha,true)}}
    </template>

  </v-data-table>
</v-container>
</template>

<script>
import {DayjsMixin} from "@dracul/dayjs-frontend"
export default {
  name: "PersonaHistoricoAgencia",
  mixins: [DayjsMixin],
  props: {
    agencia: Object,
    historico: Array
  },
  computed: {
    headers(){
      return [
        {text:'Agencia',value:'agencia'},
        {text:'Fecha',value:'fecha'},
        {text:'Modificado por',value:'modificadoPor'},
      ]
    }
  }
}
</script>

<style scoped>

</style>
