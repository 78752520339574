import menuConfig from './menu-config'
import menuI18n from './menu-i18n'


export {
    menuConfig,
    menuI18n
}

export default menuConfig
