<template>
  <v-data-table
    :headers="getHeaders"
    :items="items"
    :items-per-page="-1"
    disable-pagination hide-default-footer
  >

    <template v-slot:item.cobrado="{item}">
      <template v-if="item.cobrado != undefined && item.cobrado != null">
        ${{getFixedNumber(item.cobrado)}}
      </template>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "DashboardTable",
  props:{
    headers: {type: Array},
    items: {type: Array},
  },
  computed: {
    getHeaders(){
      return this.headers ? this.headers : [{text:'nombre', value:'nombre'}, {text:'Cobrado', value: 'cobrado'}]
    },
    getFixedNumber(){
      return n => parseFloat(n.toFixed(0)).toLocaleString('es-AR')
    }
  }
}
</script>

<style scoped>

</style>
