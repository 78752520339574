<template>
        <crud-update :open="open"
                 :loading="loading"
                 :title="title"
                 :errorMessage="errorMessage"
                 @update="update"
                 @close="$emit('close')"
    >
         <autogestion-log-form ref="form" v-model="form" :input-errors="inputErrors" @save="update" />
    </crud-update>
</template>

<script>

    import AutogestionLogProvider from "../../../../providers/AutogestionLogProvider";
    
    import {CrudUpdate, ClientError} from '@dracul/common-frontend'
    
    import AutogestionLogForm from "../../../../forms/AutogestionLogForm";
  
    

    export default {
        name: "AutogestionLogUpdate",
        
        components: { AutogestionLogForm, CrudUpdate },
        
        props:{
          open: {type: Boolean, default: true},
          item: {type: Object, required: true}
        },

        data() {
            return {
                title: 'cobrox.autogestionLog.editing',
                errorMessage: '',
                inputErrors: {},
                loading: false,
                id: this.item.id,
                form: {
                    identificacion: this.item.identificacion,
                    email: this.item.email,
                    telefono: this.item.telefono,
                    UserAgent: this.item.UserAgent,
                    IP: this.item.IP,
                    Geolocation: this.item.Geolocation,
                    Referer: this.item.Referer,
                    SO: this.item.SO
                }
            }
        },
        methods: {
            update() {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    AutogestionLogProvider.updateAutogestionLog(this.id, this.form).then(r => {
                            if (r) {
                                this.$emit('itemUpdated',r.data.updateAutogestionLog)
                                this.$emit('close')
                            }
                        }
                    ).catch(error => {
                         let clientError = new ClientError(error)
                         this.inputErrors = clientError.inputErrors
                         this.errorMessage = clientError.i18nMessage
                    }).finally(() => this.loading = false)
                }

            }
        },
    }
</script>

<style scoped>

</style>

