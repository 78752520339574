import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueKonva from 'vue-konva';
import store from './store'
import i18n from './i18n'
import router from "./router";

import apolloClient from './apollo'
import {setGraphQlClientToProviders} from '@dracul/user-frontend'
import {customizationProvider} from '@dracul/customize-frontend'
import {notificationProvider} from '@dracul/notification-frontend'
import {SettingsProvider} from '@dracul/settings-frontend'
import {queueStatsProvider, QueueProvider} from '@dracul/queue-frontend'
setGraphQlClientToProviders(apolloClient)
customizationProvider.setGqlc(apolloClient)
notificationProvider.setGqlc(apolloClient)
notificationProvider.setGqlcWs(apolloClient)
queueStatsProvider.setGqlc(apolloClient)
QueueProvider.setGqlc(apolloClient)
SettingsProvider.setGqlc(apolloClient)

import {FileProvider,UploadProvider,FileMetricsProvider, UserStorageProvider} from '@dracul/media-frontend'
UploadProvider.setGqlc(apolloClient)
FileProvider.setGqlc(apolloClient)
FileMetricsProvider.setGqlc(apolloClient)
UserStorageProvider.setGqlc(apolloClient)

import {Dayjs} from "@dracul/dayjs-frontend";
Dayjs.tz.setDefault("America/Argentina/Buenos_Aires")

Vue.config.productionTip = false

//Restore System
store.dispatch('loadRestoreSystem')

//Load Settings
store.dispatch("loadSettings")

//Customization instances inject
store.commit('setVuetifyInstance', vuetify)

//1. Load from localstore
i18n.locale = store.state.customization.language
//2. Load from backend
store.dispatch('loadCustomizations')
    .then(r => {
      i18n.locale = r.language
    })

Vue.use(VueKonva, { prefix: 'K'});

new Vue({
  vuetify,
  store,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
