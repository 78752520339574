
const messages = {
    en: {
        cobrox: {
            agencia: {
                name: 'Agencia',
                title: 'Agencia management',
                subtitle: 'View, search, create, edit and delete Agencia',
                creating: 'Creating Agencia',
                editing: 'Editing Agencia',
                deleting: 'Deleting Agencia',
                showing: 'Showing Agencia',
                menu: 'Agencia',
                labels: {
                    nombre: 'Nombre',
                    derivarDefault: 'Derivar Default',
                    telefonoDerivaciones: 'Teléfono derivaciones',
                    telefonoConsultas: 'Teléfono consultas',
                    mail: 'Email',
                    horario_atencion: 'Horario',
                    observacion: 'Observación',
                    usuarios: 'Usuarios'
                },

            }
        }
    },
    es: {
        cobrox: {
            agencia: {
                name: 'Agencia',
                title: 'Administración de Agencia',
                subtitle: 'Ver, buscar, crear, editar, y borrar Agencia',
                creating: 'Creando Agencia',
                editing: 'Modificando Agencia',
                deleting: 'Eliminando Agencia',
                showing: 'Detalles de Agencia',
                menu: 'Agencia',
                labels: {
                    nombre: 'Nombre',
                    derivarDefault: 'Derivar Default',
                    telefonoDerivaciones: 'Teléfono derivaciones',
                    telefonoConsultas: 'Teléfono consultas',
                    mail: 'Email',
                    horario_atencion: 'Horario',
                    observacion: 'Observación',
                    usuarios: 'Usuarios'
                },

            }
        }
    },
    pt: {
        cobrox: {
            agencia: {
                name: 'Agencia',
                title: 'Administração de Agencia',
                subtitle: 'Ver, buscar, criar, editar e usar Agencia',
                creating: 'Criando Agencia',
                editing: 'Edição Agencia',
                deleting: 'Apagando Agencia',
                showing: 'Detalhes do Agencia',
                menu: 'Agencia',
                labels: {
                    nombre: 'Nombre',
                    derivarDefault: 'Derivar Default',
                    telefonoDerivaciones: 'Teléfono derivaciones',
                    telefonoConsultas: 'Teléfono consultas',
                    mail: 'Email',
                    horario_atencion: 'Horario',
                    observacion: 'Observación',
                    usuarios: 'Usuarios'
                },

            }
        }
    }
}

export default messages
