import graphqlClient from "../../../apollo";

class LiquidacionProvider {

    liquidacionAgencia(mes, reporteStock, tipificado = false) {
        return graphqlClient.query({
            query: require('./gql/Liquidacion/liquidacionAgencia.graphql'),
            fetchPolicy: "network-only",
            variables: {mes, reporteStock, tipificado}
        })
    }

    liquidacionAgenciaExport(mes, reporteStock, tipificado) {
        return graphqlClient.query({
            query: require('./gql/Liquidacion/liquidacionAgenciaExport.graphql'),
            fetchPolicy: "network-only",
            variables: {mes, reporteStock, tipificado}
        })
    }


}

export default new LiquidacionProvider()


