<template>
  <v-card outlined>
    <v-card-title>
      Incrementos de intereses Historicos
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <thead>
        <tr>
          <th>Fecha</th>
          <th>Tasa Anual</th>
          <th>Tasa Indexación</th>
          <th>Interes anterior</th>
          <th>Interes actualizado</th>
          <th>Diferencia</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(interesHistorico, index) in interesesHistorico" :key="index">
          <td>{{ getDateTimeFormat(interesHistorico.fecha) }}</td>
          <td>{{ interesHistorico.tasaAnual.toLocaleString('es-AR') }}%</td>
          <td>{{ interesHistorico.tasaIndexacion.toLocaleString('es-AR') }}%</td>
          <td>${{ interesHistorico.interesAnterior ? interesHistorico.interesAnterior.toLocaleString('es-AR') : '' }}</td>
          <td>
            ${{ interesHistorico.interesActualizado ? interesHistorico.interesActualizado.toLocaleString('es-AR') : '' }}
          </td>
          <td>{{ interesHistorico.incremento ? interesHistorico.incremento.toLocaleString('es-AR') : '' }}</td>

        </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {DayjsMixin} from "@dracul/dayjs-frontend";
export default {
  name: "IncrementosHistoricosSimpleTable",
  props:{
    interesesHistorico: {type:Array}
  },
  mixins: [DayjsMixin],
}
</script>

<style scoped>

</style>
