<template>
  <persona-view :id="id" :dni="dni" :cuil="cuil"></persona-view>
</template>

<script>
import PersonaView from '../../components/PersonaView'

export default {
  name: "PersonaViewPage",
  components: {PersonaView},
  computed: {
    id() {
      return this.$route.params.id
    },
    dni() {
      return this.$route.params.dni
    },
    cuil() {
      return this.$route.params.cuil
    }
  }
}
</script>

<style scoped>

</style>
