<template>
        <crud-update :open="open"
                 :loading="loading"
                 :title="title"
                 :errorMessage="errorMessage"
                 @update="update"
                 @close="$emit('close')"

    >
         <convenio-form ref="form" v-model="form" :input-errors="inputErrors" @save="update" editing />
    </crud-update>
</template>

<script>

    import ConvenioProvider from "../../../../providers/ConvenioProvider";

    import {CrudUpdate, ClientError} from '@dracul/common-frontend'

    import ConvenioForm from "../../../../forms/ConvenioForm";

    import {Dayjs} from "@dracul/dayjs-frontend";

    export default {
        name: "ConvenioUpdate",

        components: { ConvenioForm, CrudUpdate },

        props:{
          open: {type: Boolean, default: true},
          item: {type: Object, required: true}
        },

        data() {
            return {
                title: 'cobrox.convenio.editing',
                errorMessage: '',
                inputErrors: {},
                loading: false,
                id: this.item.id,
                form: {
                    dniDeudor: this.item.dniDeudor,
                    cuilDeudor: this.item.cuilDeudor,
                    cuotas: this.item.cuotas,
                    montoTotal: this.item.montoTotal,
                    fechaPrimerPago: this.item.fechaPrimerPago?Dayjs(parseInt(this.item.fechaPrimerPago)):null,
                    fueraDePauta: this.item.fueraDePauta,
                    deudas: this.item.deudas ? this.item.deudas.map(i=> i.id?i.id:i) : null,
                    cobranzas: this.item.cobranzas ? this.item.cobranzas.map(i=> i.id?i.id:i) : null,
                    montoCuota: this.item.montoCuota
                }
            }
        },
        methods: {
            update() {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    ConvenioProvider.updateConvenio(this.id, this.form).then(r => {
                            if (r) {
                                this.$emit('itemUpdated',r.data.updateConvenio)
                                this.$emit('close')
                            }
                        }
                    ).catch(error => {
                         let clientError = new ClientError(error)
                         this.inputErrors = clientError.inputErrors
                         this.errorMessage = clientError.i18nMessage
                    }).finally(() => this.loading = false)
                }

            }
        },
    }
</script>

<style scoped>

</style>

