import { render, staticRenderFns } from "./SituacionLaboralCreate.vue?vue&type=template&id=62527959&scoped=true"
import script from "./SituacionLaboralCreate.vue?vue&type=script&lang=js"
export * from "./SituacionLaboralCreate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62527959",
  null
  
)

export default component.exports