<template>
  <crud-show :title="title" :open="open" @close="$emit('close')">
    <v-card-subtitle>ID: {{ item.id }}</v-card-subtitle>
    <v-card-text class="pa-0">
      <deuda-view :deuda="item"></deuda-view>

    </v-card-text>

  </crud-show>
</template>

<script>
import {CrudShow} from '@dracul/common-frontend'
import DeudaView from "@/modules/cobrox/components/DeudaView/DeudaView";

export default {
  name: "DeudaShow",
  components: {DeudaView, CrudShow},
  props: {
    open: {type: Boolean, default: true},
    item: {type: Object, required: true}
  },
  data() {
    return {
      title: 'cobrox.deuda.showing',
    }
  },
}
</script>

