<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">
    <v-row>

      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="business"
            name="nombre"
            v-model="form.nombre"
            :label="$t('cobrox.agencia.labels.nombre')"
            :placeholder="$t('cobrox.agencia.labels.nombre')"
            :error="hasInputErrors('nombre')"
            :error-messages="getInputErrors('nombre')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-checkbox
            prepend-icon="move_up"
            name="derivarDefault"
            v-model="form.derivarDefault"
            :label="$t('cobrox.agencia.labels.derivarDefault')"
            :error="hasInputErrors('derivarDefault')"
            :error-messages="getInputErrors('derivarDefault')"
            color="secondary"

        ></v-checkbox>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="phone"
            name="telefonoDerivaciones"
            v-model="form.telefonoDerivaciones"
            :label="$t('cobrox.agencia.labels.telefonoDerivaciones')"
            :placeholder="$t('cobrox.agencia.labels.telefonoDerivaciones')"
            :error="hasInputErrors('telefonoDerivaciones')"
            :error-messages="getInputErrors('telefonoDerivaciones')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="phone_iphone"
            name="telefonoConsultas"
            v-model="form.telefonoConsultas"
            :label="$t('cobrox.agencia.labels.telefonoConsultas')"
            :placeholder="$t('cobrox.agencia.labels.telefonoConsultas')"
            :error="hasInputErrors('telefonoConsultas')"
            :error-messages="getInputErrors('telefonoConsultas')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="email"
            name="mail"
            v-model="form.mail"
            :label="$t('cobrox.agencia.labels.mail')"
            :placeholder="$t('cobrox.agencia.labels.mail')"
            :error="hasInputErrors('mail')"
            :error-messages="getInputErrors('mail')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="watch_later"
            name="horario_atencion"
            v-model="form.horario_atencion"
            :label="$t('cobrox.agencia.labels.horario_atencion')"
            :placeholder="$t('cobrox.agencia.labels.horario_atencion')"
            :error="hasInputErrors('horario_atencion')"
            :error-messages="getInputErrors('horario_atencion')"
            color="secondary"

        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <user-autocomplete chips multiple v-model="form.usuarios" ></user-autocomplete>
      </v-col>

      <v-col cols="12" sm="12">
        <v-textarea
            rows="2"
            prepend-icon="description"
            name="observacion"
            v-model="form.observacion"
            :label="$t('cobrox.agencia.labels.observacion')"
            :placeholder="$t('cobrox.agencia.labels.observacion')"
            :error="hasInputErrors('observacion')"
            :error-messages="getInputErrors('observacion')"
            color="secondary"

        ></v-textarea>
      </v-col>



    </v-row>
  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'
import {UserAutocomplete} from "@dracul/user-frontend"

export default {
  name: "AgenciaForm",
  mixins: [InputErrorsByProps, RequiredRule],
  components: {UserAutocomplete},
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>

