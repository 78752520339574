<template>
  <crud-create :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @create="create"
               @close="$emit('close')"
  >
    <cobranza-form
      ref="form"
      v-model="form"
      :input-errors="inputErrors"
      :dni-deudor="dniDeudor"
      :cuil="cuil"
      agencyFieldIsReadOnly
      @dniWasWritten="setPersonAgency"
    />
  </crud-create>
</template>

<script>

import CobranzaProvider from "../../../../providers/CobranzaProvider";
import PersonaProvider from "../../../../providers/PersonaProvider";

import {CrudCreate, ClientError} from '@dracul/common-frontend'

import CobranzaForm from "../CobranzaForm";


export default {
  name: "CobranzaCreate",

  components: {CobranzaForm, CrudCreate},

  props: {
    open: {type: Boolean, default: true},
    dniDeudor: {type: String},
    cuil: {type: String},
  },

  data() {
    return {
      title: 'cobrox.cobranza.creating',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      form: {
        fechaCobranza: null,
        montoCobrado: null,
        dniDeudor: '',
        nombreDeudor: '',
        cuilDeudor: '',
        agencia: null,
        canalCobro: null,
        cuentaDestino: null,
        origenCreacion: 'FORMULARIO',
        cbu: '',
        nroProducto: '',
        idRapipago: ''
      }
    }
  },

  methods: {
    getAssignedAgency(dni){
      return new Promise((resolve, reject) => {
        PersonaProvider.findPersonaByNroDocumento(dni).then((r) => {
          const agency = r.data.findPersonaByNroDocumento['agencia'];
          resolve(agency)
        }, (error) =>{
          reject(error)
        })        
      })
    },

    setPersonAgency(){
      if(this.form.dniDeudor){
        this.getAssignedAgency(this.form.dniDeudor).then((personAgency => {
          this.form.agencia = personAgency['id']
        }))
      }
    },

    create() {
      if (this.$refs.form.validate()) {
        this.loading = true

        CobranzaProvider.createCobranza(this.form).then(r => {
            if (r) {
              this.$emit('itemCreated', r.data.createCobranza)
              this.$emit('close')
            }
          }
        ).catch(error => {
          console.error(`ERROR: '${error}'`)

          const clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          console.error(`inputErrors: '${this.inputErrors}'`)

          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }
    }
  },
}
</script>
