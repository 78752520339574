<template>

  <v-select
      prepend-icon="fact_check"
      :items="items"
      v-model="item"
      :label="$t('cobrox.cobranza.labels.estado')"
      :error="hasInputErrors('estado')"
      :error-messages="getInputErrors('estado')"
      color="secondary"
      item-color="secondary"
      @change="$emit('change')"
      clearable
      c

  ></v-select>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


export default {
  name: "EstadoCobranzaCombobox",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {
      type: [String, Array]
    },
  },
  data() {
    return {
      items: ['PENDIENTE','IMPUTADO','IMPOSIBLE_IMPUTAR','PROCESADO','NETEADO','ERROR_ANULACION'],
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },

}
</script>

<style scoped>

</style>

