<template>
        <crud-update :open="open"
                 :loading="loading"
                 :title="title"
                 :errorMessage="errorMessage"
                 @update="update"
                 @close="$emit('close')"
    >
         <caja-mensual-form ref="form" v-model="form" :input-errors="inputErrors" />
    </crud-update>
</template>

<script>

    import CajaMensualProvider from "../../../../providers/CajaMensualProvider";
    
    import {CrudUpdate, ClientError} from '@dracul/common-frontend'
    
    import CajaMensualForm from "../CajaMensualForm";
  
    import {Dayjs} from "@dracul/dayjs-frontend";

    export default {
        name: "CajaMensualUpdate",
        
        components: { CajaMensualForm, CrudUpdate },
        
        props:{
          open: {type: Boolean, default: true},
          item: {type: Object, required: true}
        },

        data() {
            return {
                title: 'cobrox.cajaMensual.editing',
                errorMessage: '',
                inputErrors: {},
                loading: false,
                id: this.item.id,
                form: {
                    fecha: this.item.fecha?Dayjs(parseInt(this.item.fecha)):null,
                    cerrada: this.item.cerrada
                }
            }
        },
        methods: {
            update() {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    CajaMensualProvider.updateCajaMensual(this.id, this.form).then(r => {
                            if (r) {
                                this.$emit('itemUpdated',r.data.updateCajaMensual)
                                this.$emit('close')
                            }
                        }
                    ).catch(error => {
                         let clientError = new ClientError(error)
                         this.inputErrors = clientError.inputErrors
                         this.errorMessage = clientError.i18nMessage
                    }).finally(() => this.loading = false)
                }

            }
        },
    }
</script>

<style scoped>

</style>

