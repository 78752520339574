<template>
  <persona-import></persona-import>
</template>

<script>
import PersonaImport from "@/modules/cobrox/components/PersonaImport/PersonaImport";

export default {
  name: "PersonaImportPage",
  components: {PersonaImport}
}
</script>

<style scoped>

</style>
