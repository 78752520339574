<template>

  <v-select
      prepend-icon="wc"
      :items="items"
      v-model="item"
      :label="$t('cobrox.persona.labels.sexo')"
      :loading="loading"
      :error="hasInputErrors('sexo')"
      :error-messages="getInputErrors('sexo')"
      color="secondary"
      item-color="secondary"
      :rules="isRequired?required:[]"
      :disabled="disabled"
      :readonly="readonly"
  ></v-select>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


export default {
  name: "SexoCombobox",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {type: [String, Array]},
    isRequired: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    readonly: {type: Boolean, default: false}
  },
  data() {
    return {
      items: ['','M', 'F', 'X'],
      loading: false
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style scoped>

</style>

