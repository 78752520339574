<template>
    <v-row>
        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.nombre" :label="$t('cobrox.agencia.labels.nombre')" icon="business"/>
                 <show-field :value="item.mail" :label="$t('cobrox.agencia.labels.mail')" icon="email"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.telefonoDerivaciones" :label="$t('cobrox.agencia.labels.telefonoDerivaciones')" icon="phone"/>
                 <show-field :value="item.horario_atencion" :label="$t('cobrox.agencia.labels.horario_atencion')" icon="watch_later"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.telefonoConsultas" :label="$t('cobrox.agencia.labels.telefonoConsultas')" icon="phone_iphone"/>
                 <show-field :value="item.observacion" :label="$t('cobrox.agencia.labels.observacion')" icon="description"/>
            </v-list>
        </v-col>

    </v-row>
</template>
<script>
    import {ShowField} from '@dracul/common-frontend'
     
    
    export default {
        name: 'AgenciaShowData',
        components: {ShowField},
        
        props: {
            item: {type: Object, required: true}
        }
    }
</script>

