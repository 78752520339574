import { render, staticRenderFns } from "./TipoCombobox.vue?vue&type=template&id=1c472961&scoped=true"
import script from "./TipoCombobox.vue?vue&type=script&lang=js"
export * from "./TipoCombobox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c472961",
  null
  
)

export default component.exports