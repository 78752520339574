<template>
  <v-card outlined>
    <v-card-title class="pb-0">Deudas vinculadas</v-card-title>
    <v-card-text class="pb-0">
      <v-list dense width="100%">
        <v-list-item>
          <v-list-item-content class="font-weight-bold grey--text text--darken-2">Cantidad Deudas:</v-list-item-content>
          <v-list-item-action>{{ convenio.deudas.length }}</v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="font-weight-bold grey--text text--darken-2">Deuda Total:</v-list-item-content>
          <v-list-item-action>{{currency}}{{ deudaTotal.toLocaleString('es-AR') }}</v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="font-weight-bold grey--text text--darken-2">Imputado:</v-list-item-content>
          <v-list-item-action>{{currency}}{{ imputado.toLocaleString('es-AR') }}</v-list-item-action>
        </v-list-item>

      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import DeudaTotalMixin from "@/modules/cobrox/mixins/DeudaTotalMixin";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";

export default {
  name: "ConvenioDeudaCard",
  mixins: [DeudaTotalMixin, CurrencyMixin],
  props:{
    convenio: {type: Object}
  },
  computed:{
    deudas(){
      return this.convenio.deudas
    }
  }
}
</script>

<style scoped>

</style>
