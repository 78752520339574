<template>
  <crud-update :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @update="update"
               @close="$emit('close')"
  >
    <quita-form ref="form" v-model="form" :input-errors="inputErrors" @save="update"/>
  </crud-update>
</template>

<script>

import QuitaProvider from "../../../../providers/QuitaProvider";

import {CrudUpdate, ClientError} from '@dracul/common-frontend'

import QuitaForm from "../QuitaForm";

import {Dayjs} from "@dracul/dayjs-frontend";

export default {
  name: "QuitaUpdate",

  components: {QuitaForm, CrudUpdate},

  props: {
    open: {type: Boolean, default: true},
    item: {type: Object, required: true}
  },

  data() {
    return {
      title: 'cobrox.quita.editing',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      id: this.item.id,
      form: {
        moraDesde: this.item.moraDesde ? Dayjs(parseInt(this.item.moraDesde)) : null,
        moraHasta: this.item.moraHasta ? Dayjs(parseInt(this.item.moraHasta)) : null,
        planes: (this.item.planes.length && this.item.planes.length > 0) ? this.item.planes : []
      }
    }
  },
  methods: {
    update() {
      if (this.$refs.form.validate()) {
        this.loading = true
        QuitaProvider.updateQuita(this.id, this.form).then(r => {
              if (r) {
                this.$emit('itemUpdated', r.data.updateQuita)
                this.$emit('close')
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }
  },
}
</script>

<style scoped>

</style>

