<template>
  <v-form ref="form">
    <v-row>
      <v-col cols="12" md="4">
        <estado-deuda-combobox v-model="form.estados" multiple :item-value="'nombre'"></estado-deuda-combobox>
      </v-col>

      <v-col cols="12" md="4">
        <agencia-combobox v-model="form.agencias" clearable multiple></agencia-combobox>
      </v-col>
      <v-col cols="12" md="4">
        <compra-combobox v-model="form.compras" clearable multiple></compra-combobox>
      </v-col>


      <v-col cols="12" md="4">
        <v-text-field
            type="number"
            v-model.number="form.capitalIndividual.desde"
            label="Capital individual desde"
            placeholder="Capital individual desde"
            color="secondary"
        ></v-text-field>
        <v-text-field
            type="number"
            v-model.number="form.capitalIndividual.hasta"
            label="Capital individual hasta"
            placeholder="Capital individual hasta"
            color="secondary"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
            type="number"
            v-model.number="form.capitalTotal.desde"
            label="Capital total desde"
            placeholder="Capital total desde"
            color="secondary"
        ></v-text-field>
        <v-text-field
            type="number"
            v-model.number="form.capitalTotal.hasta"
            label="Capital total hasta"
            placeholder="Capital total hasta"
            color="secondary"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
            type="number"
            v-model.number="form.cantidad.desde"
            label="Cantidad desde"
            placeholder="Cantidad desde"
            color="secondary"
        ></v-text-field>
        <v-text-field
            type="number"
            v-model.number="form.cantidad.hasta"
            label="Cantidad hasta"
            placeholder="Cantidad hasta"
            color="secondary"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <filtro-cobranza v-model="form.cobranza"/>
        <filtro-rotacion-agencia v-model="form.rotacionBloqueada"></filtro-rotacion-agencia>
      </v-col>


      <v-col cols="12" md="4">
        <date-input
            label="Cobranzas desde"
            v-model="form.fechaUltimaCobranza.desde"
        ></date-input>
        <date-input
            label="Cobranzas hasta"
            v-model="form.fechaUltimaCobranza.hasta"
        ></date-input>
      </v-col>


      <v-col cols="12" md="4">
        <date-input
            label="Fecha de mora desde"
            v-model="form.fechaMora.desde"
        ></date-input>
        <date-input
            label="Fecha de mora hasta"
            v-model="form.fechaMora.hasta"
        ></date-input>
      </v-col>

      <v-col cols="12" md="4">
        <date-input
            label="Ultima rotación agencia desde"
            v-model="form.fechaUltimaRotacion.desde"
        ></date-input>
        <date-input
            label="Ultima rotación agencia hasta"
            v-model="form.fechaUltimaRotacion.hasta"
        ></date-input>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import AgenciaCombobox from "@/modules/cobrox/combobox/AgenciaCombobox/AgenciaCombobox"
import CompraCombobox from "@/modules/cobrox/combobox/CompraCombobox/CompraCombobox"
import EstadoDeudaCombobox from "@/modules/cobrox/combobox/EstadoDeudaCombobox/EstadoDeudaCombobox"
import FiltroCobranza from "@/modules/cobrox/pages/reports/RotacionAgenciaReportPage/FiltroCobranza"
import FiltroRotacionAgencia from "@/modules/cobrox/pages/reports/RotacionAgenciaReportPage/FiltroRotacionAgencia"
import {DateInput} from "@dracul/dayjs-frontend";

export default {
  name: 'RotacionAgenciaForm',
  components: {AgenciaCombobox, CompraCombobox, EstadoDeudaCombobox, FiltroCobranza, FiltroRotacionAgencia, DateInput},
  props: {
    value: {type: Object, required: true},
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
  }
}
</script>
