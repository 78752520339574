export default [
    {
        icon: 'home',
        text: 'base.home',
        link: {name: "home"},
        galleryHide: true
    },
    {
        icon: 'publish',
        text: 'Reportes',
        auth: true,
        permission: ["REPORTES", "DASHBOARD"],
        children: [
            {
                icon: 'space_dashboard',
                text: 'Dashboard Comparativo',
                link: {name: "DashboardComparativoPage"},
                auth: true,
                permission: "DASHBOARD"
            },

            {
                icon: 'dashboard',
                text: 'Dashboard Totales',
                link: {name: "DashboardPage"},
                auth: true,
                permission: "DASHBOARD"
            },
            {
                icon: 'summarize',
                text: 'Cobranzas por Agencia',
                link: {name: "CobranzasPorAgenciaPage"},
                auth: true,
                permission: "REPORTES"
            },
            {
                icon: 'currency_exchange',
                text: 'Histórico Saldos',
                link: {name: "SaldosPage"},
                auth: true,
                permission: "CONTABILIDAD"
            },
            {
                icon: 'rule',
                text: 'Reporte Deuda por DNI',
                link: {name: "ExportDeudaPorDniPage"},
                auth: true,
                permission: "DEUDA_EXPORT_DNI"
            },
            {
                icon: 'flaky',
                text: 'Eficiencia de Agencias',
                link: {name: "EficienciaAgenciaPage"},
                auth: true,
                permission: "REPORTES"
            },
            {
                icon: 'query_stats',
                text: 'Reporte de Stock',
                link: {name: "StockReportPage"},
                auth: true,
                permission: "REPORTES"
            },
            {
                icon: '360',
                text: 'Rotación de agencia asistida',
                link: {name: "RotacionAgenciaReportPage"},
                auth: true,
                permission: "REPORTES"
            },
            {
                icon: 'payments',
                text: 'Liquidación de Agencias',
                link: {name: "LiquidacionAgenciaPage"},
                auth: true,
                permission: "REPORTES"
            },
            {
                icon: 'summarize',
                text: 'Reporte BCRA',
                link: {name: "ReporteBcraPage"},
                auth: true,
                permission: "BCRA_REPORTE"
            },
            {
                icon: 'list_alt',
                text: 'Autogestion Log',
                link: {name: "AutogestionLogCrudPage"},
                auth: true,
                permission: "AUTOGESTIONLOG_MENU"
            },
        ]

    },
    {
        icon: 'account_balance',
        text: 'menu.entities',
        permission: '',
        auth: true,
        children: [
            {
                icon: 'person',
                text: 'cobrox.persona.menu',
                link: {name: "PersonaPage"},
                auth: true,
                permission: "PERSONA_MENU"
            },
            {
                icon: 'paid',
                text: 'cobrox.deuda.menu',
                link: {name: "DeudaPage"},
                auth: true,
                permission: "DEUDA_MENU"
            },
            {
                icon: 'shop',
                text: 'cobrox.cobranza.menu',
                link: {name: "CobranzaPage"},
                auth: true,
                permission: "COBRANZA_MENU"
            },
            {
                icon: 'business',
                text: 'cobrox.agencia.menu',
                link: {name: "AgenciaPage"},
                auth: true,
                permission: "AGENCIA_MENU"
            },
            {
                icon: 'source',
                text: 'cobrox.origenDeuda.menu',
                link: {name: "OrigenDeudaPage"},
                auth: true,
                permission: "ORIGENDEUDA_MENU"
            },
            {
                icon: 'shop',
                text: 'cobrox.compra.menu',
                link: {name: "CompraPage"},
                auth: true,
                permission: "COMPRA_MENU"
            },
            {
                icon: 'receipt',
                text: 'cobrox.factura.menu',
                link: {name: "FacturaPage"},
                auth: true,
                permission: "CONTABILIDAD"
            },
            {
                icon: 'door_front',
                text: 'cobrox.canalCobro.menu',
                link: {name: "CanalCobroPage"},
                auth: true,
                permission: "CANALCOBRO_MENU"
            },
            {
                icon: 'account_balance',
                text: 'cobrox.cuenta.menu',
                link: {name: "CuentaPage"},
                auth: true,
                permission: "CUENTA_MENU"
            },
            {
                icon: 'list',
                text: 'cobrox.estadoDeuda.menu',
                link: {name: "EstadoDeudaPage"},
                auth: true,
                permission: "ESTADODEUDA_MENU"
            },
            {
                icon: 'percent',
                text: 'cobrox.interes.menu',
                link: {name: "InteresesPage"},
                auth: true,
                permission: "INTERES_MENU"
            },
            {
                icon: 'shopping_basket',
                text: 'cobrox.producto.menu',
                link: {name: "ProductoPage"},
                auth: true,
                permission: "PRODUCTO_MENU"
            },
            {
                icon: 'file_open',
                text: 'menu.kinvaDoc',
                link: {name: "KinvaDocPage"},
                auth: true,
                permission: "KINVADOC_MENU"
            },
            {
                icon: 'dynamic_feed',
                text: 'cobrox.retencion.menu',
                link: {name: "RetencionPage"},
                auth: true,
                permission: "RETENCION_SHOW_OWN"
            },
            {
                icon: 'work_outline',
                text: 'cobrox.situacionLaboral.menu',
                link: {name: "SituacionLaboralPage"},
                auth: true,
                permission: "SITUACIONLABORAL_MENU"
            },
            {
                icon: 'calendar_month',
                text: 'cobrox.cajaMensual.menu',
                link: {name: "CajaMensualPage"},
                auth: true,
                permission: "CAJAMENSUAL_MENU"
            },
            {
                icon: 'calculate',
                text: 'cobrox.comision.menu',
                link: {name: "ComisionPage"},
                auth: true,
                permission: "COMISION_MENU"
            },
            {
                icon: 'auto_stories',
                text: 'cobrox.manual.menu',
                link: {name: "ManualPage"},
                auth: true,
                permission: "MANUAL_MENU"
            },
            {
                icon: 'person_add_alt',
                text: 'cobrox.pedidoCuenta.menu',
                link: {name: "PedidoCuentaPage"},
                auth: true,
                permission: "PEDIDOCUENTA_MENU"
            },
            {
                icon: 'bookmark_remove',
                text: 'cobrox.quita.menu',
                link: {name: "QuitaCrudPage"},
                auth: true,
                permission: "QUITA_MENU"
            },
            {
                icon: 'handshake',
                text: 'cobrox.convenio.menu',
                link: {name: "ConvenioCrudPage"},
                auth: true,
                permission: "CONVENIO_MENU"
            },
            {
                icon: 'price_check',
                text: 'menu.convenioPagos',
                link: {name: "ConvenioPagos"},
                auth: true,
                permission: "CONVENIO_MENU"
            },
        ]
    },

    {
        icon: 'confirmation_number',
        text: 'redmine.issue.menu.main',
        permission: '',
        auth: true,
        children: [
            {
                icon: 'list_alt',
                text: 'redmine.issue.menu.open',
                link: {name: "RedmineIssuesListPage", params: {status: 'abierto'}},
                auth: true,
                permission: "REDMINE"
            },
            {
                icon: 'fact_check',
                text: 'redmine.issue.menu.closed',
                link: {name: "RedmineIssuesListPage", params: {status: 'cerrado'}},
                auth: true,
                permission: "REDMINE"
            },
            {
                icon: 'confirmation_number',
                text: 'redmine.issue.menu.new',
                link: {name: "RedmineIssueCreatePage"},
                auth: true,
                permission: "REDMINE"
            },
        ]
    },
    {
        icon: 'publish',
        text: 'Importaciones',
        auth: true,
        permission: "PROCESOS",
        children: [

            {
                icon: 'local_mall',
                text: 'menu.importPurchaseDebts',
                link: {name: "CompraDeudaImportPage"},
                auth: true,
                permission: "PROCESOS"
            },
            {
                icon: 'paid',
                text: 'menu.importDebts',
                link: {name: "DeudaImportPage"},
                auth: true,
                permission: "PROCESOS"
            },
            {
                icon: 'shop',
                text: 'menu.importCollections',
                link: {name: "CobranzaImportPage"},
                auth: true,
                permission: "PROCESOS"
            },
            {
                icon: 'business',
                text: 'menu.importAgencias',
                link: {name: "AgenciaImportPage"},
                auth: true,
                permission: "PROCESOS"
            },
            {
                icon: 'group_add',
                text: 'menu.importPersonas',
                link: {name: "PersonaImportPage"},
                auth: true,
                permission: "PROCESOS"
            },
            {
                icon: 'published_with_changes',
                text: 'menu.rotarAgencia',
                link: {name: "RotarAgenciaPage"},
                auth: true,
                permission: "PERSONA_ROTAR_AGENCIA"
            },
            {
                icon: 'block',
                text: 'menu.bloquearAgencia',
                link: {name: "BloquearAgenciaPage"},
                auth: true,
                permission: "PERSONA_ROTAR_AGENCIA"
            },
            {
                icon: 'offline_bolt',
                text: 'menu.importCobrazaRapipago',
                link: {name: "CobranzaRapipagoImportPage"},
                auth: true,
                permission: "PERSONA_ROTAR_AGENCIA"
            },
            {
                icon: 'history',
                text: 'menu.revertJob',
                link: {name: "RevertJob"},
                auth: true,
                permission: "REVERT_JOB"
            },
        ]
    },

    {
        icon: 'publish',
        text: 'Procesos',
        auth: true,
        permission: "PROCESOS",
        children: [
            {
                icon: 'work',
                text: 'menu.jobs',
                link: {name: "JobsPage"},
                auth: true,
                permission: "PROCESOS"
            },

            {
                icon: 'difference',
                text: 'menu.netearCobranzas',
                link: {name: "NetearCobranzasPage"},
                auth: true,
                permission: "CONTABILIDAD"
            },

            {
                icon: 'confirmation_number',
                text: 'menu.generarNotaCredito',
                link: {name: "GenerarNotasCreditoPage"},
                auth: true,
                permission: "CONTABILIDAD"
            },


            {
                icon: 'receipt_long',
                text: 'menu.generarFacturas',
                link: {name: "GenerarFacturasMasivoPage"},
                auth: true,
                permission: "CONTABILIDAD"
            },

            {
                icon: 'local_activity',
                text: 'menu.facturar',
                link: {name: "FacturarPage"},
                auth: true,
                permission: "CONTABILIDAD"
            },

            {
                icon: 'add_chart',
                text: 'menu.procesarIntereses',
                link: {name: "InteresesJobPage"},
                auth: true,
                permission: "INTERES_MENU"
            },
            {
                icon: 'group_add',
                text: 'menu.generarPersonas',
                link: {name: "CrearPersonaDesdeDeudaPage"},
                auth: true,
                permission: "PROCESOS"
            },
            {
                icon: 'drive_file_rename_outline',
                text: 'menu.reprocesarImputacionCobranzas',
                link: {name: "ReprocesarImputacionCobranzasPage"},
                auth: true,
                permission: "PROCESOS"
            },
            {
                icon: 'article',
                text: 'menu.rapipagoArchivo',
                link: {name: "RapipagoArchivoPage"},
                auth: true,
                permission: "RAPIPAGO"
            },
        ]
    },


    {
        icon: 'person',
        text: 'menu.administration',
        permission: 'SECURITY_ADMIN_MENU',
        children: [
            {
                icon: 'assignment_ind',
                text: 'menu.userdashboard',
                link: {name: "userDashboard"},
                permission: 'SECURITY_DASHBOARD_SHOW'
            },
            {
                icon: 'settings_applications',
                text: 'menu.customization',
                link: {name: "customization"},
                permission: 'CUSTOMIZATION_SHOW'
            },
            {
                icon: 'assignment_ind',
                text: 'user.title',
                link: {name: "userManagement"},
                permission: 'SECURITY_USER_SHOW'
            },
            {
                icon: 'verified_user',
                text: 'role.title',
                link: {name: "roleManagement"},
                permission: 'SECURITY_ROLE_SHOW'
            },
            {
                icon: 'group',
                text: 'group.title',
                link: {name: "groupManagement"},
                permission: 'SECURITY_GROUP_SHOW'
            },
            {
                icon: 'folder_shared',
                text: 'media.userStorage.title',
                link: {name: "UserStoragePage"},
                panel: false,
                permission: "USER_STORAGE_SHOW_ALL"
            },
            {
                icon: 'settings',
                text: 'menu.settings',
                link: {name: "SettingsPage"},
                permission: 'SETTINGS_UPDATE'
            },
            {
                icon: 'hide_source',
                text: 'menu.hideMenu',
                link: {name: "HideMenuPage"},
                permission: 'SETTINGS_UPDATE'
            },
            {
                icon: 'enhanced_encryption',
                text: 'menu.surety',
                link: {name: "FieldPermissionPage"},
                permission: 'FIELDPERMISSION_MENU'
            },
        ]
    },
    {
        icon: 'restore',
        text: 'menu.restore',
        link: {name: "RemoveAllPage"},
        permission: 'RESTORE_SYSTEM',
    }


]
