<template>
  <crud-update :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @update="update"
               @close="$emit('close')"
  >
    <pedido-cuenta-form ref="form" v-model="form" :input-errors="inputErrors"/>
  </crud-update>
</template>

<script>

import PedidoCuentaProvider from "../../../../providers/PedidoCuentaProvider";

import {CrudUpdate, ClientError} from '@dracul/common-frontend'

import PedidoCuentaForm from "../PedidoCuentaForm";


export default {
  name: "PedidoCuentaUpdate",

  components: {PedidoCuentaForm, CrudUpdate},

  props: {
    open: {type: Boolean, default: true},
    item: {type: Object, required: true}
  },

  data() {
    return {
      title: 'cobrox.pedidoCuenta.editing',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      id: this.item.id,
      form: {
        cuil: this.item.cuil,
        comentarioSolicitante: this.item.comentarioSolicitante,
        comentarioAutorizante: this.item.comentarioAutorizante,
        agenciaSolicitante: this.item.agenciaSolicitante ? this.item.agenciaSolicitante.id : null,
        agenciaAutorizante: this.item.agenciaAutorizante ? this.item.agenciaAutorizante.id : null,
        creadoPor: this.item.creadoPor ? this.item.creadoPor.id : null,
        estadoPedidoCuenta: this.item.estadoPedidoCuenta
      }
    }
  },
  methods: {
    update() {
      if (this.$refs.form.validate()) {
        this.loading = true
        PedidoCuentaProvider.updatePedidoCuenta(this.id, this.form).then(r => {
              if (r) {
                this.$emit('itemUpdated', r.data.updatePedidoCuenta)
                this.$emit('close')
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }
  },
}
</script>

<style scoped>

</style>

