import graphqlClient from "../../../apollo";

class AgenciaProvider {

    findMyAgencia() {
        return graphqlClient.query({
            query: require('./gql/Agencia/findMyAgencia.graphql'),
            fetchPolicy: "network-only"
        })
    }


    findAgencia(id) {
        return graphqlClient.query({
            query: require('./gql/Agencia/findAgencia.graphql'),
            variables: {id:id},
            fetchPolicy: "network-only"
        })
    }

    fetchAgencia() {
        return graphqlClient.query({
            query: require('./gql/Agencia/fetchAgencia.graphql'),
            fetchPolicy: "network-only"
        })
    }

    paginateAgencia(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Agencia/paginateAgencia.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }



    createAgencia(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Agencia/createAgencia.graphql'),
            variables: {input}
        })
    }

    updateAgencia(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Agencia/updateAgencia.graphql'),
            variables: {id, input}
        })
    }

     deleteAgencia(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Agencia/deleteAgencia.graphql'),
            variables: {id}
        })
    }

}

export default new AgenciaProvider()


