import EstadoDeudaProvider from "@/modules/cobrox/providers/EstadoDeudaProvider";

export default {
    computed: {
        cantidad() {
            return this.deudas.filter(deuda => !this.cancelaSaldo(deuda.estado)).length
        },
        capital() {
            return this.deudas.filter(deuda => !this.cancelaSaldo(deuda.estado)).reduce((a, v) => a + v.capitalTotal, 0)
        },
        intereses() {
            return this.deudas.filter(deuda => !this.cancelaSaldo(deuda.estado)).reduce((a, v) => a + v.intereses, 0)
        },
        deudaTotal() {
            return this.capital + this.intereses
        },
        imputado() {
            return this.deudas.filter(deuda => !this.cancelaSaldo(deuda.estado)).reduce((a, v) => a + v.imputadoCobranzas, 0)
        },
        final() {
            return this.deudaTotal - this.imputado
        },
        cancelaSaldo(){
            return estadoDeuda => {
                if(!this.estadosDeuda) return false

                let estado = this.estadosDeuda.find(i => i.nombre === estadoDeuda)
                if(estado && estado.cancelaSaldos){
                    return true
                }
                return false
            }
        }
    },
    data() {
        return {
            estadosDeuda: null
        }
    },
    created() {
        this.fetchEstadosDueda()
    },
    methods: {
        fetchEstadosDueda() {
            EstadoDeudaProvider.fetchEstadoDeuda()
                .then(r => {
                    this.estadosDeuda = r.data.fetchEstadoDeuda
                })
        }
    }
}
