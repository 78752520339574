<template>
  <v-container>
    <v-row align="center" justify="center">

      <v-col cols="12" sm="8" md="6" class="text-center">
        <v-card>

          <v-card-title>
            Importar Cobranzas

            <v-btn  text absolute right link small href="/files/CobrosEjemplo.csv" target="_blank">
             <v-icon left>download</v-icon> CSV de ejemplo
            </v-btn>
          </v-card-title>



          <v-card-text>
            <file-upload-express
                @fileUploaded="onFileUploaded"
                auto-submit
            ></file-upload-express>
            <template v-if="!file">
              <h4  class="subtitle-1">Subir archivo .csv</h4>

              <div class="text-left">

                <h3 class="text-left primary--text">Campos del csv</h3><br>


                <ul class="text-left">
                  <li><b>fechaCobranza</b> <span class="red--text">*</span></li>
                  <li><b>montoCobrado</b> <span class="red--text">*</span></li>
                  <li><b>dniDeudor</b> <span class="red--text">*</span></li>
                  <li><b>cuilDeudor</b></li>
                  <!--<li><b>nombreDeudor</b></li>-->

                  <li><b>idRapipago</b> (Requerido<span class="red--text">*</span> si el canalCobro es Rapipago)</li>
                  <li><b>cbu</b> (Requerido<span class="red--text">*</span> si el canalCobro es tipo DEBITABLE)</li>
                  <li><b>nroProducto</b> </li>
                  <li><b>canalCobro</b> <span class="red--text">*</span></li>
                  <ul>
                    <li><b>Opciones</b>: [ {{getCanalesCobro}} ]</li>
                  </ul>
                  <li><b>agencia</b> (Nombre de la agencia)</li>
                  <li><b>estado</b> [PENDIENTE, PROCESADO] (Estado PROCESADO omite la imputación y facturación)</li>
                  <li><b>cuentaDestino</b></li>
                  <ul>
                    <li> <b>Default</b>: cuentaDestinoDefault del canal de cobro </li>
                    <li> <b>Opciones</b>: [ {{getCuentas}} ]</li>
                  </ul>

                </ul>

              </div>

            </template>

          </v-card-text>


          <v-card-text v-if="file && !jobId">
          <v-alert type="info">
            Archivo subido exitosamente. Generando tarea de importación en segundo plano.
          </v-alert>
          <v-progress-circular indeterminate></v-progress-circular>
          </v-card-text>

          <v-card-text v-if="jobId">
            <v-alert type="success">
              Tarea de importación en segundo plano generada con éxito.<br> Id: {{ jobId }}
            </v-alert>
          </v-card-text>

          <v-card-actions v-if="jobId">
            <v-spacer></v-spacer>
            <v-btn
                :to="{name:'JobPage',params:{id: jobId}}"
                color="indigo" dark
            >
              Supervisar tarea
            </v-btn>
          </v-card-actions>

        </v-card>

      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import {FileUploadExpress} from '@dracul/media-frontend'
import JobProvider from "@/modules/cobrox/providers/JobProvider";
import CanalCobroProvider from "@/modules/cobrox/providers/CanalCobroProvider";
import CuentaProvider from "@/modules/cobrox/providers/CuentaProvider";

export default {
  name: "CobranzaImport",
  components: {FileUploadExpress},
  data() {
    return {
      file: null,
      jobId: null,
      canalesCobro: [],
      cuentas: []
    }
  },
  created() {
    this.fetchCanalCobro()
    this.fetchCuenta()
  },
  computed: {
    getCanalesCobro(){
      return this.canalesCobro.map(i => i.nombre).join(" | ")
    },
    getCuentas(){
      return this.cuentas.map(i => i.nombre).join(" | ")
    }
  },
  methods: {
    fetchCanalCobro(){
      CanalCobroProvider.fetchCanalCobro()
          .then(r => {
            this.canalesCobro = r.data.fetchCanalCobro
          })
    },
    fetchCuenta(){
      CuentaProvider.fetchCuenta()
          .then(r => {
            this.cuentas = r.data.fetchCuenta
          })
    },
    onFileUploaded(file) {
      this.file = file
      this.createJob(this.file.id)
    },
    createJob(fileId) {
      JobProvider.importarCobranza(fileId)
          .then(res => {
            this.jobId = res.data.importarCobranza
          })
    }
  }
}
</script>

<style scoped>

</style>
