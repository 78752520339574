<template>
    <v-data-table
    :items="domicilios"
    :headers="headers"
    :items-per-page="100"
    hide-default-footer
    >
      <template v-slot:item.piso="{item}">
        {{item.piso}}{{item.depto}}
      </template>

    </v-data-table>
</template>

<script>
export default {
  name: "PersonaDomiciliosTableView",
  props: {
    domicilios: Array
  },
  computed: {
    headers(){
      return [
        {text: this.$t('cobrox.persona.labels.origen'), value: 'origen', align:'center'},
        {text: this.$t('cobrox.persona.labels.tipo'), value: 'tipo', align:'center'},
        {text: this.$t('cobrox.persona.labels.localidad'), value: 'localidad', align:'center'},
        {text: this.$t('cobrox.persona.labels.provincia'), value: 'provincia', align:'center'},
        {text: this.$t('cobrox.persona.labels.calle'), value: 'calle', align:'center'},
        {text: this.$t('cobrox.persona.labels.numero'), value: 'numero', align:'center'},
        {text: this.$t('cobrox.persona.labels.piso')+'/'+ this.$t('cobrox.persona.labels.depto'), value: 'piso', align:'center'},
        {text: this.$t('cobrox.persona.labels.codigoPostal'), value: 'codigoPostal', align:'center'},
      ]
    }
  }
}
</script>

<style scoped>

</style>
