import graphqlClient from "../../../apollo";

class KinvaDocProvider {

    findKinvaDoc(id) {
        return graphqlClient.query({
            query: require('./gql/KinvaDoc/findKinvaDoc.graphql'),
            variables: {id:id},
            fetchPolicy: 'no-cache'
        })
    }

    findKinvaDocByIdentifier(identifier) {
        return graphqlClient.query({
            query: require('./gql/KinvaDoc/findKinvaDocByIdentifier.graphql'),
            variables: {identifier},
            fetchPolicy: 'no-cache'
        })
    }


    fetchKinvaDoc() {
        return graphqlClient.query({query: require('./gql/KinvaDoc/fetchKinvaDoc.graphql')})
    }

    paginateKinvaDoc(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/KinvaDoc/paginateKinvaDoc.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }



    createKinvaDoc(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/KinvaDoc/createKinvaDoc.graphql'),
            variables: {input}
        })
    }

    updateKinvaDoc(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/KinvaDoc/updateKinvaDoc.graphql'),
            variables: {id, input}
        })
    }

     deleteKinvaDoc(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/KinvaDoc/deleteKinvaDoc.graphql'),
            variables: {id}
        })
    }

    generateKinvaDocSamplePdf(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/KinvaDoc/generateKinvaDocSamplePdf.graphql'),
            variables: {id}
        })
    }

}

export default new KinvaDocProvider()


