<script>
import {Bar, mixins} from 'vue-chartjs'
import 'chartjs-plugin-labels';

export default {
  extends: Bar,
  name: "BarChart",
  mixins: [mixins.reactiveProp],
  props: {
    options: {
      type: Object, default: () => (
          {
            responsive: true,
            maintainAspectRatio: false,
            //type: Object,
            plugins: {
              labels: {
                render: 'percentage',
                fontColor: 'white'
              }
            },
          }
      )
    },
    title: String
  },
  mounted() {
    this.options.title = {display: true, text: this.title, fontSize: '18'};

    this.options.onClick = (event, item) => {
      this.$emit('click',{event,item})
    }

    console.log("options",this.options)
    this.renderChart(this.chartData, this.options)
  },

}
</script>

<style scoped>

</style>
