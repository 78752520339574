<template>
  <v-card outlined>
    <v-card-title>Convenio </v-card-title>
    <v-card-subtitle>
      <v-chip small>ID: {{convenio.id}}</v-chip>
      <v-chip small class="ml-2">CREADO: {{getDateFormat(convenio.createdAt)}}</v-chip>
      <v-chip v-if="convenio.vigente" small class="ml-2 green lighten-3">Vigente </v-chip>
      <v-chip v-else small class="ml-2 red lighten-3">Vencido: {{getDateFormat(convenio.fechaVencimiento)}}</v-chip>
    </v-card-subtitle>

    <v-card-text class="d-flex justify-center">

      <v-row>
        <v-col cols="12" sm="4">
          <v-card outlined>
            <v-card-title class="pb-0">Plan</v-card-title>
            <v-card-text class="pb-0">
              <v-list dense width="100%">
                <v-list-item>
                  <v-list-item-content class="font-weight-bold grey--text text--darken-2">Cuotas:</v-list-item-content>
                  <v-list-item-action>{{ convenio.cuotas }}</v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content class="font-weight-bold grey--text text--darken-2">Valor Cuota:
                  </v-list-item-content>
                  <v-list-item-action>{{ currency }}{{ convenio.montoCuota.toLocaleString('es-AR') }}
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content class="font-weight-bold grey--text text--darken-2">Monto Total:
                  </v-list-item-content>
                  <v-list-item-action>{{ currency }}{{ convenio.montoTotal.toLocaleString('es-AR') }}
                  </v-list-item-action>
                </v-list-item>

              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">
          <convenio-cobranza-card :convenio="convenio"/>
        </v-col>
        <v-col cols="12" sm="4">
          <convenio-deuda-card :convenio="convenio"></convenio-deuda-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header><span>Deudas vinculadas</span></v-expansion-panel-header>
          <v-expansion-panel-content>
            <deuda-combobox :value="convenio.deudas.map(i => i.id)"
                            multiple
                            :dni-deudor="convenio.dniDeudor"
                            :cuil-deudor="convenio.cuilDeudor"
                            table-view
                            only-active
                            readonly
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Cobranzas vinculadas</v-expansion-panel-header>
          <v-expansion-panel-content>
            <cobranza-combobox v-if="convenio.cobranzas" :value="convenio.cobranzas.map(i => i.id)"
                               multiple
                               :dni-deudor="convenio.dniDeudor"
                               :cuil-deudor="convenio.cuilDeudor"
                               :propias="true"
                               table-view
                               only-active
                               readonly
            />
            <span v-else class="grey--text font-italic">No hay cobranzas vinculadas</span>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>


    </v-card-text>

    <v-card-text v-if="convenio.exito">
      <v-card outlined>
        <v-card-title class="pb-0">Datos EXITO</v-card-title>
        <v-card-text>
          <v-simple-table>
            <tbody>
            <tr>
              <th>Nombre</th>
              <td>{{ convenio.exito.nombre }}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{{ convenio.exito.email }}</td>
            </tr>
            <tr>
              <th>Telefono</th>
              <td>{{ convenio.exito.telefono }}</td>
            </tr>
            <tr>
              <th>IP</th>
              <td>{{ convenio.exito.ip }}</td>
            </tr>
            <tr>
              <th>UserAgent</th>
              <td>{{ convenio.exito.userAgent }}</td>
            </tr>
            </tbody>

          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-card-text>

  </v-card>
</template>
<script>
import ConvenioCobranzaCard from "@/modules/cobrox/components/ConvenioCard/ConvenioCobranzaCard.vue"
import ConvenioDeudaCard from "@/modules/cobrox/components/ConvenioCard/ConvenioDeudaCard.vue"
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import {DayjsMixin} from "@dracul/dayjs-frontend";
import DeudaCombobox from "@/modules/cobrox/combobox/DeudaCombobox";
import CobranzaCombobox from "@/modules/cobrox/combobox/CobranzaCombobox";

export default {
  name: 'convenio-card',
  components: {CobranzaCombobox, DeudaCombobox, ConvenioCobranzaCard, ConvenioDeudaCard},
  mixins: [CurrencyMixin, DayjsMixin],
  props: {
    convenio: {type: Object}
  }
}
</script>
