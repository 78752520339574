<template>
  <k-circle v-if="is === 'k-circle'" :config="config" @dragend="handleDragend"  v-on:dragmove="handleDragmove"></k-circle>
  <component v-else :is="is"  :config="config" @dragend="handleDragend"  v-on:dragmove="handleDragmove"></component>
</template>

<script>

export default {
  name: "KinvaItem",
  props:{
    is: {type: String},
    config: {type: Object}
  },
  methods:{
    handleDragend(event){
        console.log("ITEM HandleDragend",event)
    },
    handleDragmove(event){
      console.log("handleDragmove",event)
    }
  }
}
</script>

<style scoped>

</style>
