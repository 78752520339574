<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">
    <v-row>

      <v-col cols="12" sm="6">
        <date-input
            v-model="form.fechaDesde"
            :label="$t('cobrox.interes.labels.fechaDesde')"
            :rules="required"
            :error="hasInputErrors('fechaDesde')"
            :error-messages="getInputErrors('fechaDesde')"
        ></date-input>
      </v-col>


      <v-col cols="12" sm="6">
        <date-input
            v-model="form.fechaHasta"
            :label="$t('cobrox.interes.labels.fechaHasta')"
            :rules="required"
            :error="hasInputErrors('fechaHasta')"
            :error-messages="getInputErrors('fechaHasta')"
        ></date-input>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="attach_money"
            name="importeDesde"
            v-model.number="form.importeDesde"
            type="number"
            :label="$t('cobrox.interes.labels.importeDesde')"
            :placeholder="$t('cobrox.interes.labels.importeDesde')"
            :error="hasInputErrors('importeDesde')"
            :error-messages="getInputErrors('importeDesde')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="attach_money"
            name="importeHasta"
            v-model.number="form.importeHasta"
            type="number"
            :label="$t('cobrox.interes.labels.importeHasta')"
            :placeholder="$t('cobrox.interes.labels.importeHasta')"
            :error="hasInputErrors('importeHasta')"
            :error-messages="getInputErrors('importeHasta')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="percent"
            name="tasa"
            v-model.number="form.tasa"
            type="number"
            :label="$t('cobrox.interes.labels.tasa')"
            :placeholder="$t('cobrox.interes.labels.tasa')"
            :error="hasInputErrors('tasa')"
            :error-messages="getInputErrors('tasa')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>

    </v-row>
  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


import {DayjsMixin, DateInput} from "@dracul/dayjs-frontend";


export default {
  name: "InteresForm",
  mixins: [InputErrorsByProps, RequiredRule, DayjsMixin],
  components: {DateInput},
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  },
  data() {
    return {
      fechaDesdeDateMenu: false,
      fechaHastaDateMenu: false
    }
  }
}
</script>

<style scoped>

</style>

