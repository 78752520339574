<script>

import {Bar, mixins} from 'vue-chartjs'
import 'chartjs-plugin-labels';


export default {
  mixins: [mixins.reactiveProp],
  extends: Bar,
  props: {
    label: {type: String},
    data: {type: Array},
    options: {
      type: Object,
      default: function () {
        return {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            labels: {
              render: () => '',
              fontColor: 'grey'
            }
          }
        }
      }
    }
  },
  computed: {
    getData() {
      return {
        labels: this.data.map(i => i.nombre),
        datasets: [
          {label: 'Mes en curso', data: this.data.map(i => i.cobradoActual), backgroundColor: 'blue'},
          {label: 'Mes pasado parcial', data: this.data.map(i => i.cobradoPasado), backgroundColor: 'teal'},
          {label: 'Mes pasado deudaTotal', data: this.data.map(i => i.cobradoPasadoTotal), backgroundColor: 'indigo'},
        ]
      }
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
</script>

