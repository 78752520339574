import {Dayjs} from "@dracul/dayjs-frontend";

export default {
    computed: {
        tiempoRestante(){
            return fechaCreacion => {
                let diffInSeconds = this.calcularTiempoRestante(fechaCreacion)
                return this.secondsToDayHourMinSec(diffInSeconds)
            }
        },
    },
    methods: {
        calcularTiempoRestante(fechaCreacion){
            let pedidoCuentaExpiracionValue = this.$store.getters.getSettingValue('pedidoCuentaExpiracionValue');
            let pedidoCuentaExpiracionUnit = this.$store.getters.getSettingValue('pedidoCuentaExpiracionUnit');

            let now = Dayjs();
            let expirationDate = Dayjs(parseInt(fechaCreacion)).add(pedidoCuentaExpiracionValue, pedidoCuentaExpiracionUnit)
            let diffInSeconds = expirationDate.diff(now, 'seconds')
            return diffInSeconds
        },
        secondsToDayHourMinSec(inputSeconds){
            let days = Math.floor(inputSeconds / (60 * 60 * 24));
            let hours = Math.floor((inputSeconds % (60 * 60 * 24)) / (60 * 60));
            let minutes = Math.floor((inputSeconds % (60 * 60)) / 60);
            let seconds = Math.floor(inputSeconds % 60);
            let result = (days > 0 ? `${days}d ` : '') + (hours > 0 ? `${hours}h ` : '') + (minutes > 0 ? `${minutes}m ` : '') + (seconds > 0 ? `${seconds}s ` : '');
            return result
        },
    }
}
