import FieldPermissionProvider from "@/modules/surety/providers/FieldPermissionProvider";

export default {
    data() {
        return {
            loadingPermission: false,
            suretyFields: [],
        }
    },
    computed: {
        getViewFields() {
            return this.suretyFields.filter(i => i.view).map(i => i.name)
        },
        getEditFields() {
            return this.suretyFields.filter(i => i.edit).map(i => i.name)
        },
        canView() {
            return fieldName => {
                let field = this.suretyFields.find(i => i.name === fieldName)
                return field ? field.view : false
            }

        },
        canEdit() {
            return fieldName => {
                let field = this.suretyFields.find(i => i.name === fieldName)
                return field ? field.edit : false
            }
        },
        filterViewFields() {
            return fieldList => {
                return fieldList.filter(field => this.canView(field))
            }

        },
    },
    methods: {
        fetchFieldPermission(entity) {
            this.loadingPermission = true
            FieldPermissionProvider.findFieldPermissionByEntityWithMyRole(entity)
                .then(r => {
                    this.suretyFields = r.data.findFieldPermissionByEntityWithMyRole.fields
                })
                .finally(() => this.loadingPermission = false)
        }
    }
}
