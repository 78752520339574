
const messages = {
    en: {
       role: {
            permissions: {
                ESTADODEUDA_MENU: "Access to EstadoDeuda",
                ESTADODEUDA_SHOW: "View EstadoDeuda",
                ESTADODEUDA_CREATE: "Create EstadoDeuda",
                ESTADODEUDA_UPDATE: "Modify EstadoDeuda",
                ESTADODEUDA_DELETE: "Delete EstadoDeuda",
            }
       }
    },
    es: {
        role: {
            permissions: {
                ESTADODEUDA_MENU: "Acceder EstadoDeuda",
                ESTADODEUDA_SHOW: "Ver EstadoDeuda",
                ESTADODEUDA_CREATE: "Crear EstadoDeuda",
                ESTADODEUDA_UPDATE: "Modificar EstadoDeuda",
                ESTADODEUDA_DELETE: "Eliminar EstadoDeuda",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                ESTADODEUDA_MENU: "Acessar EstadoDeuda",
                ESTADODEUDA_SHOW: "Ver EstadoDeuda",
                ESTADODEUDA_CREATE: "Criar EstadoDeuda",
                ESTADODEUDA_UPDATE: "Modificar EstadoDeuda",
                ESTADODEUDA_DELETE: "Eliminar EstadoDeuda",
            }
       }
    }
}

export default messages
    