<template>
  <v-card class="mb-3">
    <v-card-title>Sumarizado Cobranzas</v-card-title>
    <v-card-text>
      <v-simple-table>
        <tbody>
        <tr>
          <th>Cantidad Cobranzas Sumarizadas</th>
          <td>{{distribucion.resultSumarizado.cantidadSumarizadas}}</td>
        </tr>
        <tr>
          <th>Monto Cobranzas Sumarizadas</th>
          <td>{{currency}}{{distribucion.resultSumarizado.montoSumarizado.toLocaleString('es-Ar')}}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";

export default {
  name: "ResultadoSumarizado",
  props: {
    distribucion: Object
  },
  mixins: [CurrencyMixin]
}
</script>

<style scoped>

</style>
