<template>
      <cobranza-detail :cobranza-id="item.id"/>
</template>

<script>
import CobranzaDetail from "@/modules/cobrox/components/CobranzaDetail/CobranzaDetail";

export default {
  name: "CobranzaShowCustom",
  components: {CobranzaDetail},
  props: {
    open: {type: Boolean, default: true},
    item: {type: Object, required: true}
  },
  data() {
    return {
      title: 'cobrox.cobranza.showing',
    }
  },
}
</script>

