<template>
  <v-card class="mb-3">
    <v-card-title>Cobranzas con Error</v-card-title>
    <v-card-text>
      <v-simple-table>
        <thead>
        <tr>
          <th>ID</th>
          <th>DNI</th>
          <th>Monto</th>
          <th>Error</th>
          <th>Mensaje</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="cobranzaError in distribucion.listaCobranzasConError" :key="cobranzaError.id">
          <td>{{cobranzaError.id}}</td>
          <td>{{cobranzaError.dniDeudor}}</td>
          <td>{{cobranzaError.montoCobrado}}</td>
          <td>{{cobranzaError.errorName}}</td>
          <td>{{cobranzaError.errorMessage}}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>

</template>

<script>
export default {
  name: "ResultadoCobranzasConError",
  props: {
    distribucion: Object
  }
}
</script>

<style scoped>

</style>
