<template>
  <crud-create :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @create="create"
               @close="$emit('close')"
  >
    <quita-form ref="form" v-model="form" :input-errors="inputErrors" @save="create"/>
  </crud-create>
</template>

<script>

import QuitaProvider from "../../../../providers/QuitaProvider";

import {CrudCreate, ClientError} from '@dracul/common-frontend'

import QuitaForm from "../QuitaForm";


export default {
  name: "QuitaCreate",

  components: {QuitaForm, CrudCreate},

  props: {
    open: {type: Boolean, default: true}
  },

  data() {
    return {
      title: 'cobrox.quita.creating',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      form: {
        moraDesde: null,
        moraHasta: null,
        planes: [
          {cuotaDesde: 1, cuotaHasta: 2, quita: 0},
          {cuotaDesde: 3, cuotaHasta: 5, quita: 0},
          {cuotaDesde: 6, cuotaHasta: 6, quita: 0},
          {cuotaDesde: 7, cuotaHasta: 12, quita: 0},
        ]
      }
    }
  },

  methods: {
    create() {
      if (this.$refs.form.validate()) {
        this.loading = true
        QuitaProvider.createQuita(this.form).then(r => {
              if (r) {
                this.$emit('itemCreated', r.data.createQuita)
                this.$emit('close')
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }

  },
}
</script>

<style scoped>

</style>

