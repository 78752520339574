<template>
    <crud-show fullscreen :title="title" :open="open"  @close="$emit('close')">
      <v-card-subtitle>ID: {{ item.id }}</v-card-subtitle>
        <v-card-text>
            <factura-show-data :item="item" />
        </v-card-text>

  </crud-show>
</template>

<script>
    import FacturaShowData from "./FacturaShowDataCustom";
    import {CrudShow} from '@dracul/common-frontend'

    export default {
        name: "FacturaShow",
        components: {CrudShow, FacturaShowData},
        props: {
            open: {type: Boolean, default: true},
            item: {type: Object, required: true}
        },
        data() {
            return {
                title: 'cobrox.factura.showing',
            }
        },
    }
</script>

