<template>
  <v-autocomplete
      :items="items"
      v-model="item"
      :loading="loading"
      color="secondary"
      item-color="secondary"
      :rules="isRequired ? required : []"
      :multiple="multiple"
      :chips="chips"
      :solo="solo"
      :disabled="disabled"
      :readonly="readonly"
      :clearable="clearable"
      :hide-details="hideDetails"
      :style="{width: width, maxWidth: width}"
      :return-object="returnObject"
      label="Item por agregar"
  ></v-autocomplete>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'

export default {
  name: "KinvaItemSelect",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {type: [String, Array]},
    multiple: {type: Boolean, default: false},
    solo: {type: Boolean, default: false},
    chips: {type: Boolean, default: false},
    readonly: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    isRequired: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
    hideDetails: {type: Boolean, default: false},
    returnObject: {type: Boolean, default: false},
    itemValue: {type: String, default: 'id'},
    width: {type: String, default: null},
  },
  data() {
    return {
      items: [
        {text: 'Texto', value: 'k-text'},
        {text: 'Imagen', value: 'k-image'},
      //  {text: 'Circle', value: 'k-circle'},
      ],
      loading: false
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },

  methods: {
  }
}
</script>

<style scoped>

</style>
