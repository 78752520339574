import graphqlClient from "../../../apollo";

class RapipagoProvider {
    generarArchivoRapipago(form) {
        return graphqlClient.mutate({
            mutation: require('./gql/generarArchivoRapipago.graphql'),
            variables: form
        })
    }

}

export default new RapipagoProvider()


