<template>
  <v-card>
    <v-card-title
        :class="error ? 'red--text text--darken-3' : 'grey--text text--darken-3'"
    >
      {{ title }}
    </v-card-title>
    <v-card-text
        :class="{'red--text': error, 'text--darken-3': error, 'text-h4 text-center':true,  }"
    >
      {{ symbol }}{{ getValue }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DashboardCard",
  props: {
    title: String,
    value: [Number,String],
    symbol: String,
    error: {type: Boolean, default: false},
    isString: {type: Boolean, default: false},
  },
  computed: {
    getValue() {
      if(this.isString){
        return this.value
      }else{
        return Math.round(this.value).toLocaleString('es-Ar')
      }
    }
  }
}
</script>

<style scoped>

</style>
