
const messages = {
    en: {
       cobrox: {
          factura: {
            name: 'Factura',
            title: 'Factura management',
            subtitle: 'View, search, create, edit and delete Factura',
            creating: 'Creating Factura',
            editing: 'Editing Factura',
            deleting: 'Deleting Factura',
            showing: 'Showing Factura',
            menu: 'Factura',
            labels: {
                cobranza: 'Cobranza',
                deuda: 'Deuda',
                monto: 'Monto',
                montoNoFacturable: 'Monto no facturable',
                montoFacturable: 'Monto facturable',
                estado: 'Estado',
                tipo: 'Tipo',
                lote: 'Lote',
                fechaComprobante: 'Fecha comprobante',
                fechaVencimiento: 'Fecha vencimiento',
                tipoComprobante: 'Tipo de Comprobante',
                letra: 'Letra',
                puntoVenta: 'Punto de Venta',
                numeroComprobante: 'Numero comprobante',
                cuitCliente: 'CUIT Cliente',
                dniCliente: 'DNI Cliente',
                razonSocialCliente: 'Razon social del cliente',
                descripcionVenta: 'Descripción de venta',
                subtotalGravadoIVA105: 'Subtotal gravado IVA 10,5%',
                subtotalGravadoIVA21: 'Subtotal gravado IVA 21%',
                subtotalGravadoIVA27: 'Subtotal gravado IVA 27%',
                totalNetoNoGravado: 'Total Neto No Gravado',
                percepcionIVA: 'Percepcion de IVA',
                jurisdiccionIIBB: 'Jurisdiccion de IIBB',
                totalComprobante: 'Total Comprobante',
                cuentaIngreso: 'Cuenta Ingreso',
                medioCobro: 'Medio de Cobro',
                centroCosto1: 'Centro de costo 1',
                centroCosto2: 'Centro de Costo 2',
                periodoDesde: 'Periodo Desde',
                periodoHasta: 'Periodo Hasta',
                cbu: 'CBU',
                cae: 'CAE',
                nroFactura: 'Numero Factura',
                importeIva: 'Importe Iva',
                netoGravado: 'Neto Gravado',
                nombreCuenta: 'Nombre cuenta',
                idFacturaColppy: 'idFacturaColppy',
                idClienteColppy: 'idClienteColppy'
            },

          }
       }
    },
    es: {
       cobrox: {
          factura: {
            name: 'Factura',
            title: 'Administración de Factura',
            subtitle: 'Ver, buscar, crear, editar, y borrar Factura',
            creating: 'Creando Factura',
            editing: 'Modificando Factura',
            deleting: 'Eliminando Factura',
            showing: 'Detalles de Factura',
            menu: 'Factura',
            labels: {
                cobranza: 'Cobranza',
                deuda: 'Deuda',
                monto: 'Monto',
                montoNoFacturable: 'Monto no facturable',
                montoFacturable: 'Monto facturable',
                estado: 'Estado',
                tipo: 'Tipo',
                lote: 'Lote',
                fechaComprobante: 'Fecha comprobante',
                fechaVencimiento: 'Fecha vencimiento',
                tipoComprobante: 'Tipo de Comprobante',
                letra: 'Letra',
                puntoVenta: 'Punto de Venta',
                numeroComprobante: 'Numero comprobante',
                cuitCliente: 'CUIT Cliente',
                dniCliente: 'DNI Cliente',
                razonSocialCliente: 'Razon social del cliente',
                descripcionVenta: 'Descripción de venta',
                subtotalGravadoIVA105: 'Subtotal gravado IVA 10,5%',
                subtotalGravadoIVA21: 'Subtotal gravado IVA 21%',
                subtotalGravadoIVA27: 'Subtotal gravado IVA 27%',
                totalNetoNoGravado: 'Total Neto No Gravado',
                percepcionIVA: 'Percepcion de IVA',
                jurisdiccionIIBB: 'Jurisdiccion de IIBB',
                totalComprobante: 'Total Comprobante',
                cuentaIngreso: 'Cuenta Ingreso',
                medioCobro: 'Medio de Cobro',
                centroCosto1: 'Centro de costo 1',
                centroCosto2: 'Centro de Costo 2',
                periodoDesde: 'Periodo Desde',
                periodoHasta: 'Periodo Hasta',
                cbu: 'CBU',
                cae: 'CAE',
                nroFactura: 'Numero Factura',
                importeIva: 'Importe Iva',
                netoGravado: 'Neto Gravado',
                nombreCuenta: 'Nombre cuenta',
                idFacturaColppy: 'idFacturaColppy',
                idClienteColppy: 'idClienteColppy'
            },

          }
       }
    },
    pt: {
       cobrox: {
          factura: {
            name: 'Factura',
            title: 'Administração de Factura',
            subtitle: 'Ver, buscar, criar, editar e usar Factura',
            creating: 'Criando Factura',
            editing: 'Edição Factura',
            deleting: 'Apagando Factura',
            showing: 'Detalhes do Factura',
            menu: 'Factura',
            labels: {
                cobranza: 'Cobranza',
                deuda: 'Deuda',
                monto: 'Monto',
                montoNoFacturable: 'Monto no facturable',
                montoFacturable: 'Monto facturable',
                estado: 'Estado',
                tipo: 'Tipo',
                lote: 'Lote',
                fechaComprobante: 'Fecha comprobante',
                fechaVencimiento: 'Fecha vencimiento',
                tipoComprobante: 'Tipo de Comprobante',
                letra: 'Letra',
                puntoVenta: 'Punto de Venta',
                numeroComprobante: 'Numero comprobante',
                cuitCliente: 'CUIT Cliente',
                dniCliente: 'DNI Cliente',
                razonSocialCliente: 'Razon social del cliente',
                descripcionVenta: 'Descripción de venta',
                subtotalGravadoIVA105: 'Subtotal gravado IVA 10,5%',
                subtotalGravadoIVA21: 'Subtotal gravado IVA 21%',
                subtotalGravadoIVA27: 'Subtotal gravado IVA 27%',
                totalNetoNoGravado: 'Total Neto No Gravado',
                percepcionIVA: 'Percepcion de IVA',
                jurisdiccionIIBB: 'Jurisdiccion de IIBB',
                totalComprobante: 'Total Comprobante',
                cuentaIngreso: 'Cuenta Ingreso',
                medioCobro: 'Medio de Cobro',
                centroCosto1: 'Centro de costo 1',
                centroCosto2: 'Centro de Costo 2',
                periodoDesde: 'Periodo Desde',
                periodoHasta: 'Periodo Hasta',
                cbu: 'CBU',
                cae: 'CAE',
                nroFactura: 'Numero Factura',
                importeIva: 'Importe Iva',
                netoGravado: 'Neto Gravado',
                nombreCuenta: 'Nombre cuenta',
                idFacturaColppy: 'idFacturaColppy',
                idClienteColppy: 'idClienteColppy'
            },

          }
       }
    }
}

export default messages
