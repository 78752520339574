<template>
  <crud-layout :title="title" :subtitle="subtitle">

    <template v-slot:list>
      <deuda-list
          ref="list"
          @update="update"
          @delete="remove"
          @show="show"

      />
    </template>

    <add-button v-if="$store.getters.hasPermission('DEUDA_CREATE')" @click="create"></add-button>

    <deuda-create v-if="creating"
                  :open="creating"
                  v-on:itemCreated="onItemCreated"
                  v-on:close="creating=false"
    />

    <deuda-update v-if="updating"
                  :open="updating"
                  :item="itemToEdit"
                  v-on:itemUpdated="onItemUpdated"
                  v-on:close="updating=false"
    />

    <deuda-show v-if="showing"
                :open="showing"
                :item="itemToShow"
                v-on:close="showing=false"
    />

    <deuda-delete v-if="deleting"
                  :open="deleting"
                  :item="itemToDelete"
                  v-on:itemDeleted="onItemDeleted"
                  v-on:close="deleting=false"
    />

    <snackbar v-model="flash"/>

  </crud-layout>
</template>

<script>

import DeudaCreate from "../DeudaCreate";
import DeudaUpdate from "../DeudaUpdate";
import DeudaDelete from "../DeudaDelete";
import DeudaShow from "../DeudaShow";
import DeudaList from "../DeudaListCustom";

import {CrudLayout, AddButton, Snackbar} from "@dracul/common-frontend"

export default {
  name: "DeudaCrud",
  components: {
    CrudLayout, AddButton, Snackbar,
    DeudaCreate,
    DeudaUpdate,
    DeudaDelete,
    DeudaShow,
    DeudaList
  },
  data() {
    return {
      title: 'cobrox.deuda.title',
      subtitle: 'cobrox.deuda.subtitle',
      flash: null,
      creating: false,
      updating: false,
      deleting: false,
      showing: false,
      itemToEdit: null,
      itemToDelete: null,
      itemToShow: null,
    }
  },
  methods: {
    //On
    onItemCreated() {
      this.$refs.list.fetch()
      this.flash = this.$t("common.created")
    },
    onItemUpdated() {
      this.$refs.list.fetch()
      this.flash =  this.$t("common.updated")
    },
    onItemDeleted() {
      this.$refs.list.fetch()
      this.flash =  this.$t("common.deleted")
    },
    //Open
    create() {
      this.creating = true
    },
    update(item) {
      this.updating = true
      this.itemToEdit = item
    },
    show(item) {
      this.showing = true
      this.itemToShow = item
    },
    remove(item) {
      this.deleting = true
      this.itemToDelete = item
    }
  }

}
</script>


