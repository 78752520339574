import graphqlClient from "../../../apollo";

class EficienciaProvider {

    eficienciaAgencia(mes, reporteStock) {
        return graphqlClient.query({
            query: require('./gql/Eficiencia/eficienciaAgencia.graphql'),
            fetchPolicy: "network-only",
            variables: {mes, reporteStock}
        })
    }


}

export default new EficienciaProvider()


