
const messages = {
    en: {
       role: {
            permissions: {
                INTERES_MENU: "Access to Interes",
                INTERES_SHOW: "View Interes",
                INTERES_CREATE: "Create Interes",
                INTERES_UPDATE: "Modify Interes",
                INTERES_DELETE: "Delete Interes",
            }
       }
    },
    es: {
        role: {
            permissions: {
                INTERES_MENU: "Acceder Interes",
                INTERES_SHOW: "Ver Interes",
                INTERES_CREATE: "Crear Interes",
                INTERES_UPDATE: "Modificar Interes",
                INTERES_DELETE: "Eliminar Interes",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                INTERES_MENU: "Acessar Interes",
                INTERES_SHOW: "Ver Interes",
                INTERES_CREATE: "Criar Interes",
                INTERES_UPDATE: "Modificar Interes",
                INTERES_DELETE: "Eliminar Interes",
            }
       }
    }
}

export default messages
    