
const messages = {
    en: {
       role: {
            permissions: {
                PRODUCTO_MENU: "Access to Producto",
                PRODUCTO_SHOW: "View Producto",
                PRODUCTO_CREATE: "Create Producto",
                PRODUCTO_UPDATE: "Modify Producto",
                PRODUCTO_DELETE: "Delete Producto",
            }
       }
    },
    es: {
        role: {
            permissions: {
                PRODUCTO_MENU: "Acceder Producto",
                PRODUCTO_SHOW: "Ver Producto",
                PRODUCTO_CREATE: "Crear Producto",
                PRODUCTO_UPDATE: "Modificar Producto",
                PRODUCTO_DELETE: "Eliminar Producto",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                PRODUCTO_MENU: "Acessar Producto",
                PRODUCTO_SHOW: "Ver Producto",
                PRODUCTO_CREATE: "Criar Producto",
                PRODUCTO_UPDATE: "Modificar Producto",
                PRODUCTO_DELETE: "Eliminar Producto",
            }
       }
    }
}

export default messages
    