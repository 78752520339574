<template>
  <crud-create :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @create="create"
               @close="$emit('close')"
  >
    <persona-form ref="form" v-model="form" :input-errors="inputErrors"/>
  </crud-create>
</template>

<script>

import PersonaProvider from "../../../../providers/PersonaProvider";

import {CrudCreate, ClientError} from '@dracul/common-frontend'

import PersonaForm from "../PersonaForm";


export default {
  name: "PersonaCreate",

  components: {PersonaForm, CrudCreate},

  props: {
    open: {type: Boolean, default: true}
  },

  data() {
    return {
      title: 'cobrox.persona.creating',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      form: {
        nombreCompleto: '',
        tipoDocumento: 'DNI',
        nroDocumento: '',
        cuil: '',
        sexo: null,
        nacionalidad: '',
        fechaNacimiento: '',
        telefonos: [],
        emails: [],
        domicilios: [],
        cbus: [],
        documentos: [],
        agencia: null,
        idRapipago: '',
        estado: 'ACTIVO',
        rotacionBloqueada: false,
        situacionLaboral: null,
        jurisdiccion: null,
        idClienteColppy: null
      }
    }
  },

  methods: {
    create() {
      if (this.$refs.form.validate()) {
        this.loading = true
        PersonaProvider.createPersona(this.form).then(r => {
              if (r) {
                this.$emit('itemCreated', r.data.createPersona)
                this.$emit('close')
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }

  },
}
</script>

<style scoped>

</style>

