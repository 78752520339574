<template>
    <crud-show :title="title" :open="open"  @close="$emit('close')">

        <v-card-text>
            <kinva-doc-show-data :item="item" />
        </v-card-text>

  </crud-show>
</template>

<script>
    import KinvaDocShowData from "./KinvaDocShowData";
    import {CrudShow} from '@dracul/common-frontend'
 
    export default {
        name: "KinvaDocShow",
        components: {CrudShow, KinvaDocShowData},
        props: {
            open: {type: Boolean, default: true},
            item: {type: Object, required: true}
        },
        data() {
            return {
                title: 'kinva.kinvaDoc.showing',
            }
        },
    }
</script>

