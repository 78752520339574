<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="pa-3">
               <pedido-cuenta-crud />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import PedidoCuentaCrud from "./PedidoCuentaCrud";
    export default {
        name: "PedidoCuenta",
        components: {PedidoCuentaCrud}
    }
</script>

<style scoped>

</style>

