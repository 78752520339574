<template>
  <v-card class="mb-3">
    <v-card-title>Lista de facturas</v-card-title>
    <v-data-table
    :items="items"
    :headers="headers"
    :items-per-page="-1"
    hide-default-footer
    >

      <template v-slot:item.montoNoFacturable="{ item }">
        {{currency}}{{ item.montoNoFacturable.toLocaleString('es-AR') }}
      </template>

      <template v-slot:item.montoFacturable="{ item }">
        {{currency}}{{ item.montoFacturable.toLocaleString('es-AR') }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "FacturasList",
  props: {
    items: {type: Array}
  },
  computed: {
    headers(){
      return [
        {text: this.$t('cobrox.factura.labels.tipo'), value: 'tipo'},
        {text: this.$t('cobrox.factura.labels.tipoComprobante'), value: 'tipoComprobante'},
        {text: this.$t('cobrox.factura.labels.dniCliente'), value: 'dniCliente'},
        {text: this.$t('cobrox.factura.labels.cobranza'), value: 'cobranza'},
        {text: this.$t('cobrox.factura.labels.deuda'), value: 'deuda'},
        {text: this.$t('cobrox.factura.labels.montoNoFacturable'), value: 'montoNoFacturable'},
        {text: this.$t('cobrox.factura.labels.montoFacturable'), value: 'montoFacturable'},

       // {text: this.$t('cobrox.factura.labels.estado'), value: 'estado'},
       // {text: this.$t('cobrox.factura.labels.lote'), value: 'lote'},
      ]
    }
  }
}
</script>

<style scoped>

</style>
