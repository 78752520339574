import graphqlClient from "../../../apollo";

class CanalCobroProvider {

    findCanalCobro(id) {
        return graphqlClient.query({
            query: require('./gql/CanalCobro/findCanalCobro.graphql'),
            variables: {id:id}
        })
    }

    fetchCanalCobro() {
        return graphqlClient.query({query: require('./gql/CanalCobro/fetchCanalCobro.graphql')})
    }
    
    paginateCanalCobro(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/CanalCobro/paginateCanalCobro.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }
    
    

    createCanalCobro(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/CanalCobro/createCanalCobro.graphql'),
            variables: {input}
        })
    }
    
    updateCanalCobro(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/CanalCobro/updateCanalCobro.graphql'),
            variables: {id, input}
        })
    }
    
     deleteCanalCobro(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/CanalCobro/deleteCanalCobro.graphql'),
            variables: {id}
        })
    }

}

export default new CanalCobroProvider()


