<template>
    <v-card width="100%">
      <v-card-title class="text-h4">Tickets</v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-for="item in items" :key="item.title" cols="12" sm="3" md="3">
            <v-card outlined>
              <v-card-title class="text-h6">{{item.title}}</v-card-title>
              <v-card-text class="text-h3 text-center">{{item.quantity}}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

</template>

<script>
import RedmineProvider from "@/modules/redmine/providers/RedmineProvider";

export default {
  name: "RedmineDashboard",
  props: {
    open: {type: Boolean, default: true},
    closed: {type: Boolean, default: true},
    status: {type: Array},
  },
  data() {
    return {
      items: []
    }
  },
  async mounted() {
    if (this.open) {
      await this.countOpen()
    }
    if (this.closed) {
      await this.countClosed()
    }
    if (this.status && this.status.length > 0) {
      for(let status of this.status){
        await this.countByStatus(status)
      }
    }
  },
  computed: {
    getUsername() {
      return this.$store.getters.me.username
    }
  },
  methods: {
    async countOpen() {
      let r = await RedmineProvider.fetchRedmineIssuesByAuthor(0, 1, null, null, 'open', this.getUsername)
      this.items.push({title: 'Abiertos', quantity: r.data.fetchRedmineIssuesByAuthor.total_count})
    },
    async countClosed() {
      let r = await RedmineProvider.fetchRedmineIssuesByAuthor(0, 1, null, null, 'closed', this.getUsername)
      this.items.push({title: 'Cerrados', quantity: r.data.fetchRedmineIssuesByAuthor.total_count})
    },
    async countByStatus(status) {
      let r = await RedmineProvider.fetchRedmineIssuesByAuthor(0, 1, null, null, status, this.getUsername)
      this.items.push({title: status, quantity: r.data.fetchRedmineIssuesByAuthor.total_count})
    },

  }
}
</script>

<style scoped>

</style>
