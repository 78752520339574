<template>
  <v-container>
    <v-row align="center" justify="center">

      <v-col cols="12" sm="8" md="6" class="text-center">
        <v-card>

          <v-card-title>
            Importar Deudas

            <v-btn  text absolute right link small href="/files/DeudasEjemplo.csv" target="_blank">
              <v-icon left>download</v-icon> CSV de ejemplo
            </v-btn>
          </v-card-title>

          <v-card-text class="text-right">
            <v-btn  text  link small href="/files/DeudasActualizacionEjemplo.csv" target="_blank">
              <v-icon left>download</v-icon> CSV de ejemplo Actualización
            </v-btn>
          </v-card-text>


          <v-card-text>
            <file-upload-express
                @fileUploaded="onFileUploaded"
                auto-submit
            ></file-upload-express>
            <template v-if="!file">
              <h4  class="subtitle-1">Subir archivo .csv</h4>
              <p class="text-left text-decoration-underline">Campos requeridos del csv</p>
              <ul class="text-left">
                <li><b>idDeuda</b> <span class="red--text">*</span></li>
                <li><b>fechaCompra</b> <span class="red--text">*</span></li>
                <li><b>nombreDeudor</b> <span class="red--text">*</span></li>
                <li><b>dniDeudor</b> <span class="red--text">*</span></li>
                <li><b>cuilDeudor</b> <span class="red--text">*</span></li>
                <li><b>fechaOtorgamiento</b></li>
                <li><b>producto</b><span class="red--text">*</span></li>
                <li><b>detalleProducto</b><span class="red--text">*</span></li>
                <li><b>nroProducto</b><span class="red--text">*</span></li>
                <li><b>capitalCompra</b> <span class="red--text">*</span></li>
                <li><b>precioCompra</b> <span class="red--text">*</span></li>
                <li><b>capitalTotal</b> <span class="red--text">*</span></li>
                <li><b>intereses</b> <span class="red--text">*</span></li>
                <li><b>cuilDeudor</b></li>
                <li><b>cbu</b></li>
                <li><b>estado</b></li>
                <ul>
                  <li><b>Default</b>: ACTIVO </li>
                  <li><b>Opciones</b>: [ {{getEstadoDeuda}} ]</li>
                </ul>
                <li><b>jurisdiccion</b></li>
                <ul>
                  <li><b>Default</b>: CABA </li>
                  <li><b>Opciones</b>: [ {{getJurisdicciones}} ]</li>
                </ul>

                <li><b>cartera</b></li>
                <li><b>fechaMora</b></li>
                <li><b>saldoCapitalCompra</b> (Si no se ingresa se toma el valor de capitalCompra)</li>
                <li><b>agencia</b> (Nombre)</li>
                <li><b>compra</b> (Nombre)</li>
                <li><b>origen</b> (Nombre)</li>

              </ul>
              <br>
              <v-alert dense type="warning">Si el csv contiene registros con <b>idDeuda</b> existentes en la base de datos solo se actualizaran los campos:  <b>estado, fechaMora, capitalTotal, capitalCompra, intereses, origen, debitable, producto, detalleProducto, nroProducto, fechaOtorgamiento, cuilDeudor</b>. Si el estado es ({{getEstadoDeudaCancelaSaldos}})  el saldoCapitalCompra se pondra en $0</v-alert>
            </template>
          </v-card-text>


          <v-card-text v-if="file && !jobId">
          <v-alert type="info">
            Archivo subido exitosamente. Generando tarea de importación en segundo plano.
          </v-alert>
          <v-progress-circular indeterminate></v-progress-circular>
          </v-card-text>

          <v-card-text v-if="jobId">
            <v-alert type="success">
              Tarea de importación en segundo plano generada con éxito.<br> Id: {{ jobId }}
            </v-alert>
          </v-card-text>

          <v-card-actions v-if="jobId">
            <v-spacer></v-spacer>
            <v-btn
                :to="{name:'JobPage',params:{id: jobId}}"
                color="indigo" dark
            >
              Supervisar tarea
            </v-btn>
          </v-card-actions>

        </v-card>

      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import {FileUploadExpress} from '@dracul/media-frontend'
import JobProvider from "@/modules/cobrox/providers/JobProvider";
import EstadoDeudaProvider from "@/modules/cobrox/providers/EstadoDeudaProvider";

export default {
  name: "DeudaImport",
  components: {FileUploadExpress},
  data() {
    return {
      file: null,
      jobId: null,
      estadosDeuda: [],
      jurisdicciones: ['CABA' , 'Buenos Aires' , 'Catamarca' , 'Chaco' , 'Chubut' , 'Córdoba' , 'Corrientes' , 'Entre Ríos' , 'Formosa' , 'Jujuy' , 'La Pampa' , 'La Rioja' , 'Neuquén' , 'Mendoza' , 'Misiones' , 'Río NegroSalta' , 'San Juan' , 'San Luis' , 'Santa Cruz' , 'Santa Fe' , 'Santiago del Estero' , 'Tierra del Fuego' , 'Tucumán']
    }
  },
  created() {
    this.fetchEstadoDeuda()
  },
  computed: {
    getJurisdicciones(){
      return this.jurisdicciones.join(" | ")
    },
    getEstadoDeuda(){
    return this.estadosDeuda.map(e => e.nombre).join(" | ")
    },
    getEstadoDeudaCancelaSaldos(){
      return this.estadosDeuda.filter(e => e.cancelaSaldos).map(e => e.nombre).join(" | ")
    }
  },
  methods: {
    fetchEstadoDeuda(){
      EstadoDeudaProvider.fetchEstadoDeuda()
      .then(r => {
        this.estadosDeuda = r.data.fetchEstadoDeuda
      })
    },
    onFileUploaded(file) {
      this.file = file
      this.createJob(this.file.id)
    },
    createJob(fileId) {
      JobProvider.importarDeuda(fileId)
          .then(res => {
            this.jobId = res.data.importarDeuda
          })
    }
  }
}
</script>

<style scoped>

</style>
