<template>
  <v-row>
    <v-col cols="12" v-for="(contacto,index) in contactos" :key="index">
      <v-divider></v-divider>
      <v-row>

        <v-col cols="12" sm="4">
          <field-view  :value="contacto.origen"
                       :label="$t('cobrox.persona.labels.origen')"
                       icon="source"
          />
        </v-col>

        <v-col cols="12" sm="4">
          <field-view  :value="contacto.tipo"
                       :label="$t('cobrox.persona.labels.tipo')"
                       icon="view_list"
          />
        </v-col>


        <v-col cols="12" sm="4">
          <field-view  :value="contacto.mail"
                       :label="$t('cobrox.persona.labels.mail')"
                       icon="mail"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import FieldView from "@/modules/cobrox/components/FieldView/FieldView";
export default {
  name: "PersonaEmailsView",
  components: {FieldView},
  props: {
    contactos: Array
  }
}
</script>

<style scoped>

</style>
