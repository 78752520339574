<template>
  <v-card outlined>
    <v-card-title>
      Cobranzas Imputadas
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <thead>
        <tr>
          <th>Cobranza</th>
          <th>Monto Total</th>
          <th>Monto Proporcional</th>
          <th>Fecha</th>
          <th>Imputado</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in cobranzasImputadas" :key="index">
          <td>{{ item.cobranza }}</td>
          <td>$ {{ item.montoTotal.toLocaleString('es-AR') }}</td>
          <td>$ {{ item.montoProporcional.toLocaleString('es-AR') }}</td>
          <td>{{ getDateFormat(item.fecha) }}</td>
          <td>{{ getDateTimeCustomFormat(item.fechaImputacion,'YYYY-MM','America/Argentina/Buenos_Aires') }}</td>

        </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {DayjsMixin} from "@dracul/dayjs-frontend";

export default {
  name: "CobranzasImputadasSimpleTable",
  mixins: [DayjsMixin],
  props: {
    cobranzasImputadas: {type: Array}
  }
}
</script>

<style scoped>

</style>
