<template>
    <v-row>
        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="getDateFormat(item.fechaDesde)" :label="$t('cobrox.interes.labels.fechaDesde')" icon="event"/>
                 <show-field :value="String(item.importeHasta)" :label="$t('cobrox.interes.labels.importeHasta')" icon="attach_money"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="getDateFormat(item.fechaHasta)" :label="$t('cobrox.interes.labels.fechaHasta')" icon="event"/>
                 <show-field :value="String(item.tasa)" :label="$t('cobrox.interes.labels.tasa')" icon="percent"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="String(item.importeDesde)" :label="$t('cobrox.interes.labels.importeDesde')" icon="attach_money"/>
            </v-list>
        </v-col>

    </v-row>
</template>
<script>
    import {ShowField} from '@dracul/common-frontend'
    import {DayjsMixin} from "@dracul/dayjs-frontend"; 
    
    export default {
        name: 'InteresShowData',
        components: {ShowField},
        mixins: [DayjsMixin],
        props: {
            item: {type: Object, required: true}
        }
    }
</script>

