import { render, staticRenderFns } from "./InteresForm.vue?vue&type=template&id=67f5bf99&scoped=true"
import script from "./InteresForm.vue?vue&type=script&lang=js"
export * from "./InteresForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f5bf99",
  null
  
)

export default component.exports