<template>
  <v-card class="mb-3">
    <v-card-title>Notas de crédito</v-card-title>
    <v-card-text>
      <v-simple-table>
        <tbody>
        <tr>
          <th>Cantidad Cobranzas negativas</th>
          <td>{{distribucion.resultNotasCredito.cantidadCobranzasNegativas}}</td>
        </tr>
        <tr>
          <th>Cantidad Cobranzas procesadas</th>
          <td>{{distribucion.resultNotasCredito.cantidadCobranzasNegativasOk}}</td>
        </tr>

        <tr>
          <th>Cantidad Cobranzas sin FC</th>
          <td>{{distribucion.resultNotasCredito.cantidadCobranzaNegativaSinFcParaNc}}</td>
        </tr>
        <tr>
          <th>Cantidad Cobranzas en error</th>
          <td>{{distribucion.resultNotasCredito.cantidadCobranzasNegativasError}}</td>
        </tr>


        <tr>
          <th>Monto Cobranzas sin FC</th>
          <td>{{currency}}{{distribucion.resultNotasCredito.montoCobranzaNegativaSinFcParaNc.toLocaleString('es-Ar')}}</td>
        </tr>

        <tr>
          <th>Monto Cobranzas procesadas</th>
          <td>{{currency}}{{distribucion.resultNotasCredito.montoCobranzasNegativasOk.toLocaleString('es-Ar')}}</td>
        </tr>

        <tr>
          <th>Monto Cobranzas en error</th>
          <td>{{currency}}{{distribucion.resultNotasCredito.montoCobranzasNegativasError.toLocaleString('es-Ar')}}</td>
        </tr>

        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";

export default {
  name: "ResultadoNotaCredito",
  props: {
    distribucion: Object
  },
  mixins: [CurrencyMixin]
}
</script>

<style scoped>

</style>
