<template>
  <v-row>

    <v-col cols="12" sm="3">
      <v-text-field
          prepend-icon="source"
          name="depto"
          v-model="form.origen"
          :label="$t('cobrox.persona.labels.origen')"
          :placeholder="$t('cobrox.persona.labels.origen')"
          :error="hasInputErrors('origen')"
          :error-messages="getInputErrors('origen')"
          color="secondary"
          :readonly="readonly"
      ></v-text-field>
    </v-col>

    <v-col cols="12" sm="3">
      <v-text-field
          prepend-icon="description"
          name="depto"
          v-model="form.descripcion"
          :label="$t('cobrox.persona.labels.descripcion')"
          :placeholder="$t('cobrox.persona.labels.descripcion')"
          :error="hasInputErrors('descripcion')"
          :error-messages="getInputErrors('descripcion')"
          color="secondary"
          :readonly="readonly"
      ></v-text-field>
    </v-col>


    <v-col cols="12" sm="3">
      <v-text-field
          prepend-icon="cbu"
          name="mail"
          type="number"
          v-model="form.cbu"
          :label="$t('cobrox.persona.labels.cbu')"
          :placeholder="$t('cobrox.persona.labels.cbu')"
          :error="hasInputErrors('cbu')"
          :error-messages="getInputErrors('cbu')"
          color="secondary"
          :readonly="readonly"
      ></v-text-field>
    </v-col>




  </v-row>
</template>

<script>
import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'

export default {
  name: "PersonaCbuForm",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {
      type: Object,
      required: true
    },
    readonly: {type: Boolean, default:false}
  },
  data(){
    return {
      search: ''
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    tipoItems(){
      return [this.search || '','Primario', 'Alternativo','Familiar', 'Vecino', 'Trabajo', 'Abogado']
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
}
</script>

<style scoped>

</style>
