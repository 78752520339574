<template>
  <v-container>
    <v-row align="center" justify="center">

      <v-col cols="12" sm="8" md="6" class="text-center">
        <v-card>

          <v-card-title>
            Importar Personas

            <v-btn text absolute right link small href="/files/PersonasEjemplo.csv" target="_blank">
              <v-icon left>download</v-icon>
              CSV de ejemplo
            </v-btn>
          </v-card-title>


          <v-card-text>
            <file-upload-express
                @fileUploaded="onFileUploaded"
                auto-submit
            ></file-upload-express>
            <template v-if="!file">
              <h4 class="subtitle-1">Subir archivo .csv</h4>

              <div class="text-left">

                <h3 class="text-left primary--text">Campos del csv</h3><br>


                <ul class="text-left">
                  <li><b>nombreCompleto</b> <span class="red--text">*</span></li>
                  <li><b>tipoDocumento</b><span class="red--text">*</span>
                    <ul>
                      <li>DNI</li>
                    </ul>
                  </li>

                  <li><b>nroDocumento</b><span class="red--text">*</span></li>
                  <li><b>cuil</b><span class="red--text">*</span></li>
                  <li><b>sexo</b>
                    <ul>
                      <li>M</li>
                      <li>F</li>
                      <li>X</li>
                    </ul>
                  </li>
                  <li><b>nacionalidad</b></li>
                  <li><b>fechaNacimiento</b></li>
                  <li><b>agencia</b> (Nombre)</li>
                  <li><b>estado</b>(ACTIVO | QUIEBRA | FALLECIDO)</li>
                  <li><b>idRapipago</b></li>
                  <li><b>situacionLaboral</b></li>
                  <li><b>telefono</b></li>
                  <li><b>telefono.tipo</b></li>
                  <li><b>telefono.origen</b></li>
                  <li><b>email</b></li>
                  <li><b>email.tipo</b></li>
                  <li><b>email.origen</b></li>
                  <li><b>domicilio.tipo</b></li>
                  <li><b>domicilio.origen</b></li>
                  <li><b>domicilio.calle</b></li>
                  <li><b>domicilio.numero</b></li>
                  <li><b>domicilio.piso</b></li>
                  <li><b>domicilio.depto</b></li>
                  <li><b>domicilio.localidad</b></li>
                  <li><b>domicilio.provincia</b></li>
                  <li><b>domicilio.codigoPostal</b></li>
                  <li><b>cbu</b></li>
                  <li><b>cbu.descripcion</b></li>
                  <li><b>cbu.origen</b></li>

                </ul>
                <br>
                <v-alert dense type="warning">Si el csv contiene registros con <b>dni</b> existentes en la base de datos
                  se actualizaran los campos:
                  <b>
                    nombreCompleto,
                    tipoDocumento,
                    cuil,
                    sexo,
                    nacionalidad,
                    fechaNacimiento,
                    jurisdiccion,
                    idClienteColppy,
                    situacionLaboral,
                    agencia,
                    idRapipago,
                    estado,
                    telefonos,
                    emails,
                    domicilios,
                    cbus

                  </b>
                </v-alert>

              </div>

            </template>

          </v-card-text>


          <v-card-text v-if="file && !jobId">
            <v-alert type="info">
              Archivo subido exitosamente. Generando tarea de importación en segundo plano.
            </v-alert>
            <v-progress-circular indeterminate></v-progress-circular>
          </v-card-text>

          <v-card-text v-if="jobId">
            <v-alert type="success">
              Tarea de importación en segundo plano generada con éxito.<br> Id: {{ jobId }}
            </v-alert>
          </v-card-text>

          <v-card-actions v-if="jobId">
            <v-spacer></v-spacer>
            <v-btn
                :to="{name:'JobPage',params:{id: jobId}}"
                color="indigo" dark
            >
              Supervisar tarea
            </v-btn>
          </v-card-actions>

        </v-card>

      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import {FileUploadExpress} from '@dracul/media-frontend'
import JobProvider from "@/modules/cobrox/providers/JobProvider";

export default {
  name: "PersonaImport",
  components: {FileUploadExpress},
  data() {
    return {
      file: null,
      jobId: null,
    }
  },
  methods: {
    onFileUploaded(file) {
      this.file = file
      this.createJob(this.file.id)
    },
    createJob(fileId) {
      JobProvider.importarPersona(fileId)
          .then(res => {
            this.jobId = res.data.importarPersona
          })
    },

  }
}
</script>

<style scoped>

</style>
