
const messages = {
    en: {
        role: {
            permissions: {
                REDMINE: "REDMINE",
                REDMINE_VIEW_TICKETS: "Redmine - View tickets",
                REDMINE_VIEW_MY_TICKETS: "Redmine - View my tickets",
                REDMINE_VIEW_TICKETS_COMMENTS: "Redmine - View ticket comments",
                REDMINE_CREATE_TICKETS: "Redmine - Create tickets",
                REDMINE_CREATE_AGENCY_TICKETS: "Redmine - Create agency tickets",
            }
        }
    },
    es: {
        role: {
            permissions: {
                REDMINE: "REDMINE",
                REDMINE_VIEW_TICKETS: "Redmine - Ver tickets",
                REDMINE_VIEW_MY_TICKETS: "Redmine - Ver mis tickets",
                REDMINE_VIEW_TICKETS_COMMENTS: "Redmine - Ver comentarios de tickets",
                REDMINE_CREATE_TICKETS: "Redmine - Crear tickets",
                REDMINE_CREATE_AGENCY_TICKETS: "Redmine - Crear tickets de agencia",
            }
        }
    },
    pt: {
        role: {
            permissions: {
                REDMINE: "REDMINE",
                REDMINE_VIEW_TICKETS: "Redmine - Ver tickets",
                REDMINE_VIEW_MY_TICKETS: "Redmine - Ver mis tickets",
                REDMINE_VIEW_TICKETS_COMMENTS: "Redmine - Ver comentarios de tickets",
                REDMINE_CREATE_TICKETS: "Redmine - Crear tickets",
                REDMINE_CREATE_AGENCY_TICKETS: "Redmine - Crear tickets de agencia",
            }
        }
    }
}

export default messages
