<template>
  <kinva-template :identifier="identifier"></kinva-template>
</template>

<script>
import KinvaTemplate from "@/modules/kinva/components/KinvaTemplate/KinvaTemplate";

export default {
  name: "KinvaTemplatePage",
  components: {KinvaTemplate},
  computed: {
    identifier() {
      return this.$route.params.identifier
    }
  }
}
</script>

<style scoped>

</style>
