<template>

  <v-select
      prepend-icon="wc"
      :items="items"
      v-model="item"
      :label="$t('cobrox.convenio.labels.canalPago')"
      :loading="loading"
      :error="hasInputErrors('canalPago')"
      :error-messages="getInputErrors('canalPago')"
      color="secondary"
      item-color="secondary"
      :rules="isRequired?required:[]"
      :disabled="disabled"
      :readonly="readonly"
      @change="$emit('change', item)"
      :clearable="clearable"
  ></v-select>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


export default {
  name: "CanalPagoCombobox",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {type: [String, Array]},
    isRequired: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
    readonly: {type: Boolean, default: false}
  },
  data() {
    return {
      items: ['TRANSFERENCIA BANCARIA', 'RAPIPAGO', 'MERCADO PAGO'],
      loading: false
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style scoped>

</style>

