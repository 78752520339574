<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="pa-3">
               <interes-crud />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import InteresCrud from "./InteresCrud";
    export default {
        name: "Interes",
        components: {InteresCrud}
    }
</script>

<style scoped>

</style>

