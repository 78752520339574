<template>
  <div>
    <v-form ref="form" autocomplete="off" @submit.prevent="submit">
      <v-text-field
          name="importeDesde"
          v-model.number="form.tasa"
          type="number"
          :error="hasInputErrors('tasa')"
          :error-messages="getInputErrors('tasa')"
          color="secondary"
          :rules="required"
          :readonly="!editing"
          @click="editing = true"
          :style="{width:'75px'}"
          class="text-center"
          solo hide-details
          @focusout="submit"
      >
        <template v-slot:append-outer>
          <v-btn v-if="editing"
                 color="green white--text"
                 fab  x-small @click="submit" absolute
          >
            <v-icon>done</v-icon>
          </v-btn>
        </template>
      </v-text-field>

    </v-form>
  </div>


</template>

<script>


import InteresProvider from "@/modules/cobrox/providers/InteresProvider";
import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'

export default {
  name: "InteresTasaForm",
  mixins: [InputErrorsByProps, RequiredRule],
  data() {
    return {
      editing: false,
      form: {
        tasa: this.currentTasa,
      },
    }
  },
  props: {
    id: {type: String, required: true},
    currentTasa: {type: Number, default: 0},
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    submit() {
      this.update()
    },
    update() {
      InteresProvider.updateInteresTasa(this.id, this.form.tasa)
          .then(r => {
            this.$emit("updated", r.data.updateInteresTasa)
            this.editing =  false
          })
    }
  },

}
</script>

<style scoped>

</style>

