<template>
  <v-simple-table class="text-left">
    <thead>
    <tr>
      <th>IdFactura</th>
      <th>Deuda</th>
      <th>DniDeudor</th>
      <th>MontoNoFacturable</th>
      <th>MontoFacturable</th>
      <th>Monto</th>

    </tr>
    </thead>

    <tbody>
    <template v-if="facturas">
      <tr v-for="(factura, index) in facturas" :key="index">
        <td>{{factura.id}}</td>

        <td>
          <v-menu :close-on-content-click="false" offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="blue darken-3"
                  small
                  dark text
                  v-bind="attrs"
                  v-on="on"
              >
                {{ factura.deuda.nombreDeudor }}
              </v-btn>
            </template>
            <deuda-detail :deuda-id="factura.deuda.id"></deuda-detail>
          </v-menu>
        </td>


        <td>{{ factura.deuda.dniDeudor }}</td>
        <td>${{ factura.montoNoFacturable }}</td>
        <td>${{ factura.montoFacturable }}</td>
        <td>${{ factura.monto }}</td>

      </tr>
    </template>

    </tbody>
  </v-simple-table>
</template>

<script>
import DeudaDetail from "@/modules/cobrox/components/DeudaDetail/DeudaDetail";

export default {
  name: "FacturasTableWithDeuda",
  components: {DeudaDetail},
  props: {
    facturas: Array
  }
}
</script>

<style scoped>

</style>
