
const messages = {
    en: {
       cobrox: {
          autogestionLog: {
            name: 'AutogestionLog',
            title: 'AutogestionLog management',
            subtitle: 'View, search, create, edit and delete AutogestionLog',
            creating: 'Creating AutogestionLog',
            editing: 'Editing AutogestionLog',
            deleting: 'Deleting AutogestionLog',
            showing: 'Showing AutogestionLog',
            menu: 'AutogestionLog',
            labels: {
                createdAt: 'Creado',
                nombre: 'name',
                identificacion: 'Identification',
                email: 'Email',
                telefono: 'Teléfono',
                UserAgent: 'UserAgent',
                IP: 'IP',
                Geolocation: 'Geolocation',
                Referer: 'Referer',
                SO: 'Sistema Operativo'
            },

          }
       }
    },
    es: {
       cobrox: {
          autogestionLog: {
            name: 'AutogestionLog',
            title: 'Administración de AutogestionLog',
            subtitle: 'Ver, buscar, crear, editar, y borrar AutogestionLog',
            creating: 'Creando AutogestionLog',
            editing: 'Modificando AutogestionLog',
            deleting: 'Eliminando AutogestionLog',
            showing: 'Detalles de AutogestionLog',
            menu: 'AutogestionLog',
            labels: {
                createdAt: 'Creado',
                nombre: 'Nombre',
                identificacion: 'Identificación (DNI/CUIL)',
                email: 'Email',
                telefono: 'Teléfono',
                UserAgent: 'UserAgent',
                IP: 'IP',
                Geolocation: 'Geolocation',
                Referer: 'Referer',
                SO: 'Sistema Operativo'
            },

          }
       }
    },
    pt: {
       cobrox: {
          autogestionLog: {
            name: 'AutogestionLog',
            title: 'Administração de AutogestionLog',
            subtitle: 'Ver, buscar, criar, editar e usar AutogestionLog',
            creating: 'Criando AutogestionLog',
            editing: 'Edição AutogestionLog',
            deleting: 'Apagando AutogestionLog',
            showing: 'Detalhes do AutogestionLog',
            menu: 'AutogestionLog',
            labels: {
                identificacion: 'Identificación (DNI/CUIL)',
                email: 'Email',
                telefono: 'Teléfono',
                UserAgent: 'UserAgent',
                IP: 'IP',
                Geolocation: 'Geolocation',
                Referer: 'Referer',
                SO: 'Sistema Operativo'
            },

          }
       }
    }
}

export default messages
