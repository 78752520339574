<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">
    <v-row>

      <v-col cols="12" sm="6">
    <!--    <cobranza-combobox v-model="form.cobranza" :input-errors="inputErrors"/>-->
        <v-text-field
            prepend-icon="paid"
            name="cobranza"
            v-model="form.cobranza"
            :label="$t('cobrox.factura.labels.cobranza')"
            :placeholder="$t('cobrox.factura.labels.cobranza')"
            :error="hasInputErrors('cobranza')"
            :error-messages="getInputErrors('cobranza')"
            :rules="required"
            color="secondary"
            persistent-hint
            hint="Ingresar ID de cobranza"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <!--<deuda-combobox v-model="form.deuda" :input-errors="inputErrors"/>-->

        <v-text-field
            prepend-icon="local_atm"
            name="deuda"
            v-model="form.deuda"
            :label="$t('cobrox.factura.labels.deuda')"
            :placeholder="$t('cobrox.factura.labels.deuda')"
            :error="hasInputErrors('deuda')"
            :error-messages="getInputErrors('deuda')"
            :rules="required"
            color="secondary"
            persistent-hint
            hint="Ingresar ID de deuda"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="monetization_on"
            name="monto"
            v-model.number="form.monto"
            type="number"
            :label="$t('cobrox.factura.labels.monto')"
            :placeholder="$t('cobrox.factura.labels.monto')"
            :error="hasInputErrors('monto')"
            :error-messages="getInputErrors('monto')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="money_off"
            name="montoNoFacturable"
            v-model.number="form.montoNoFacturable"
            type="number"
            :label="$t('cobrox.factura.labels.montoNoFacturable')"
            :placeholder="$t('cobrox.factura.labels.montoNoFacturable')"
            :error="hasInputErrors('montoNoFacturable')"
            :error-messages="getInputErrors('montoNoFacturable')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="attach_money"
            name="montoFacturable"
            v-model.number="form.montoFacturable"
            type="number"
            :label="$t('cobrox.factura.labels.montoFacturable')"
            :placeholder="$t('cobrox.factura.labels.montoFacturable')"
            :error="hasInputErrors('montoFacturable')"
            :error-messages="getInputErrors('montoFacturable')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <estado-combobox v-model="form.estado" :input-errors="inputErrors"/>
      </v-col>

      <v-col cols="12" sm="6">
        <tipo-combobox v-model="form.tipo" :input-errors="inputErrors"/>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="vpn_key"
            name="lote"
            v-model="form.lote"
            :label="$t('cobrox.factura.labels.lote')"
            :placeholder="$t('cobrox.factura.labels.lote')"
            :error="hasInputErrors('lote')"
            :error-messages="getInputErrors('lote')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="badge"
            name="cuitCliente"
            v-model="form.cuitCliente"
            :label="$t('cobrox.factura.labels.cuitCliente')"
            :placeholder="$t('cobrox.factura.labels.cuitCliente')"
            :error="hasInputErrors('cuitCliente')"
            :error-messages="getInputErrors('cuitCliente')"
            color="secondary"

        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="badge"
            name="dniCliente"
            v-model="form.dniCliente"
            :label="$t('cobrox.factura.labels.dniCliente')"
            :placeholder="$t('cobrox.factura.labels.dniCliente')"
            :error="hasInputErrors('dniCliente')"
            :error-messages="getInputErrors('dniCliente')"
            color="secondary"

        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <date-input
            v-model="form.fechaComprobante"
            :label="$t('cobrox.factura.labels.fechaComprobante')"
            :error="hasInputErrors('fechaComprobante')"
            :error-messages="getInputErrors('fechaComprobante')"
        ></date-input>

      </v-col>


      <v-col cols="12" sm="6">
        <date-input
            v-model="form.fechaVencimientoDateMenu"
            :label="$t('cobrox.factura.labels.fechaVencimiento')"
            :error="hasInputErrors('fechaVencimiento')"
            :error-messages="getInputErrors('fechaVencimiento')"
        ></date-input>

      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="format_list_numbered"
            name="tipoComprobante"
            v-model="form.tipoComprobante"
            :label="$t('cobrox.factura.labels.tipoComprobante')"
            :placeholder="$t('cobrox.factura.labels.tipoComprobante')"
            :error="hasInputErrors('tipoComprobante')"
            :error-messages="getInputErrors('tipoComprobante')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="sort_by_alpha"
            name="letra"
            v-model="form.letra"
            :label="$t('cobrox.factura.labels.letra')"
            :placeholder="$t('cobrox.factura.labels.letra')"
            :error="hasInputErrors('letra')"
            :error-messages="getInputErrors('letra')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="store"
            name="puntoVenta"
            v-model="form.puntoVenta"
            :label="$t('cobrox.factura.labels.puntoVenta')"
            :placeholder="$t('cobrox.factura.labels.puntoVenta')"
            :error="hasInputErrors('puntoVenta')"
            :error-messages="getInputErrors('puntoVenta')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="pin"
            name="numeroComprobante"
            v-model="form.numeroComprobante"
            :label="$t('cobrox.factura.labels.numeroComprobante')"
            :placeholder="$t('cobrox.factura.labels.numeroComprobante')"
            :error="hasInputErrors('numeroComprobante')"
            :error-messages="getInputErrors('numeroComprobante')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="badge"
            name="cuitCliente"
            v-model="form.cuitCliente"
            :label="$t('cobrox.factura.labels.cuitCliente')"
            :placeholder="$t('cobrox.factura.labels.cuitCliente')"
            :error="hasInputErrors('cuitCliente')"
            :error-messages="getInputErrors('cuitCliente')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="person"
            name="razonSocialCliente"
            v-model="form.razonSocialCliente"
            :label="$t('cobrox.factura.labels.razonSocialCliente')"
            :placeholder="$t('cobrox.factura.labels.razonSocialCliente')"
            :error="hasInputErrors('razonSocialCliente')"
            :error-messages="getInputErrors('razonSocialCliente')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="description"
            name="descripcionVenta"
            v-model="form.descripcionVenta"
            :label="$t('cobrox.factura.labels.descripcionVenta')"
            :placeholder="$t('cobrox.factura.labels.descripcionVenta')"
            :error="hasInputErrors('descripcionVenta')"
            :error-messages="getInputErrors('descripcionVenta')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="request_quote"
            name="subtotalGravadoIVA105"
            v-model.number="form.subtotalGravadoIVA105"
            type="number"
            :label="$t('cobrox.factura.labels.subtotalGravadoIVA105')"
            :placeholder="$t('cobrox.factura.labels.subtotalGravadoIVA105')"
            :error="hasInputErrors('subtotalGravadoIVA105')"
            :error-messages="getInputErrors('subtotalGravadoIVA105')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="request_quote"
            name="subtotalGravadoIVA21"
            v-model.number="form.subtotalGravadoIVA21"
            type="number"
            :label="$t('cobrox.factura.labels.subtotalGravadoIVA21')"
            :placeholder="$t('cobrox.factura.labels.subtotalGravadoIVA21')"
            :error="hasInputErrors('subtotalGravadoIVA21')"
            :error-messages="getInputErrors('subtotalGravadoIVA21')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="request_quote"
            name="subtotalGravadoIVA27"
            v-model.number="form.subtotalGravadoIVA27"
            type="number"
            :label="$t('cobrox.factura.labels.subtotalGravadoIVA27')"
            :placeholder="$t('cobrox.factura.labels.subtotalGravadoIVA27')"
            :error="hasInputErrors('subtotalGravadoIVA27')"
            :error-messages="getInputErrors('subtotalGravadoIVA27')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="monetization_on"
            name="totalNetoNoGravado"
            v-model.number="form.totalNetoNoGravado"
            type="number"
            :label="$t('cobrox.factura.labels.totalNetoNoGravado')"
            :placeholder="$t('cobrox.factura.labels.totalNetoNoGravado')"
            :error="hasInputErrors('totalNetoNoGravado')"
            :error-messages="getInputErrors('totalNetoNoGravado')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="request_quote"
            name="percepcionIVA"
            v-model.number="form.percepcionIVA"
            type="number"
            :label="$t('cobrox.factura.labels.percepcionIVA')"
            :placeholder="$t('cobrox.factura.labels.percepcionIVA')"
            :error="hasInputErrors('percepcionIVA')"
            :error-messages="getInputErrors('percepcionIVA')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="flag"
            name="jurisdiccionIIBB"
            v-model="form.jurisdiccionIIBB"
            :label="$t('cobrox.factura.labels.jurisdiccionIIBB')"
            :placeholder="$t('cobrox.factura.labels.jurisdiccionIIBB')"
            :error="hasInputErrors('jurisdiccionIIBB')"
            :error-messages="getInputErrors('jurisdiccionIIBB')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="local_atm"
            name="totalComprobante"
            v-model.number="form.totalComprobante"
            type="number"
            :label="$t('cobrox.factura.labels.totalComprobante')"
            :placeholder="$t('cobrox.factura.labels.totalComprobante')"
            :error="hasInputErrors('totalComprobante')"
            :error-messages="getInputErrors('totalComprobante')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="account_balance_wallet"
            name="cuentaIngreso"
            v-model="form.cuentaIngreso"
            :label="$t('cobrox.factura.labels.cuentaIngreso')"
            :placeholder="$t('cobrox.factura.labels.cuentaIngreso')"
            :error="hasInputErrors('cuentaIngreso')"
            :error-messages="getInputErrors('cuentaIngreso')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="payment"
            name="medioCobro"
            v-model="form.medioCobro"
            :label="$t('cobrox.factura.labels.medioCobro')"
            :placeholder="$t('cobrox.factura.labels.medioCobro')"
            :error="hasInputErrors('medioCobro')"
            :error-messages="getInputErrors('medioCobro')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="card_travel"
            name="centroCosto1"
            v-model="form.centroCosto1"
            :label="$t('cobrox.factura.labels.centroCosto1')"
            :placeholder="$t('cobrox.factura.labels.centroCosto1')"
            :error="hasInputErrors('centroCosto1')"
            :error-messages="getInputErrors('centroCosto1')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="card_travel"
            name="centroCosto2"
            v-model="form.centroCosto2"
            :label="$t('cobrox.factura.labels.centroCosto2')"
            :placeholder="$t('cobrox.factura.labels.centroCosto2')"
            :error="hasInputErrors('centroCosto2')"
            :error-messages="getInputErrors('centroCosto2')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <date-input
            v-model="form.periodoDesde"
            :label="$t('cobrox.factura.labels.periodoDesde')"
            :error="hasInputErrors('periodoDesde')"
            :error-messages="getInputErrors('periodoDesde')"
        ></date-input>

      </v-col>

      <v-col cols="12" sm="6">
        <date-input
            v-model="form.periodoHasta"
            :label="$t('cobrox.factura.labels.periodoHasta')"
            :error="hasInputErrors('periodoHasta')"
            :error-messages="getInputErrors('periodoHasta')"
        ></date-input>

      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="account_balance"
            name="cbu"
            v-model="form.cbu"
            :label="$t('cobrox.factura.labels.cbu')"
            :placeholder="$t('cobrox.factura.labels.cbu')"
            :error="hasInputErrors('cbu')"
            :error-messages="getInputErrors('cbu')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="fact_check"
            name="cae"
            v-model="form.cae"
            :label="$t('cobrox.factura.labels.cae')"
            :placeholder="$t('cobrox.factura.labels.cae')"
            :error="hasInputErrors('cae')"
            :error-messages="getInputErrors('cae')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="receipt"
            name="nroFactura"
            v-model="form.nroFactura"
            :label="$t('cobrox.factura.labels.nroFactura')"
            :placeholder="$t('cobrox.factura.labels.nroFactura')"
            :error="hasInputErrors('nroFactura')"
            :error-messages="getInputErrors('nroFactura')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="request_quote"
            name="importeIva"
            v-model.number="form.importeIva"
            type="number"
            :label="$t('cobrox.factura.labels.importeIva')"
            :placeholder="$t('cobrox.factura.labels.importeIva')"
            :error="hasInputErrors('importeIva')"
            :error-messages="getInputErrors('importeIva')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="price_check"
            name="netoGravado"
            v-model.number="form.netoGravado"
            type="number"
            :label="$t('cobrox.factura.labels.netoGravado')"
            :placeholder="$t('cobrox.factura.labels.netoGravado')"
            :error="hasInputErrors('netoGravado')"
            :error-messages="getInputErrors('netoGravado')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="account_balance"
            name="nombreCuenta"
            v-model="form.nombreCuenta"
            :label="$t('cobrox.factura.labels.nombreCuenta')"
            :placeholder="$t('cobrox.factura.labels.nombreCuenta')"
            :error="hasInputErrors('nombreCuenta')"
            :error-messages="getInputErrors('nombreCuenta')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="tag"
            name="idFacturaColppy"
            v-model="form.idFacturaColppy"
            :label="$t('cobrox.factura.labels.idFacturaColppy')"
            :placeholder="$t('cobrox.factura.labels.idFacturaColppy')"
            :error="hasInputErrors('idFacturaColppy')"
            :error-messages="getInputErrors('idFacturaColppy')"
            color="secondary"

        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="tag"
            name="idClienteColppy"
            v-model="form.idClienteColppy"
            :label="$t('cobrox.factura.labels.idClienteColppy')"
            :placeholder="$t('cobrox.factura.labels.idClienteColppy')"
            :error="hasInputErrors('idClienteColppy')"
            :error-messages="getInputErrors('idClienteColppy')"
            color="secondary"

        ></v-text-field>
      </v-col>

    </v-row>
  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


import EstadoCombobox from "./EstadoCombobox";
import {DayjsMixin, DateInput} from "@dracul/dayjs-frontend";
import TipoCombobox from "@/modules/cobrox/pages/crud/FacturaPage/FacturaForm/TipoCombobox";

// import CobranzaCombobox from "./CobranzaCombobox";
// import DeudaCombobox from "./DeudaCombobox";

export default {
  name: "FacturaForm",
  mixins: [InputErrorsByProps, RequiredRule, DayjsMixin],
  components: {
    TipoCombobox,
    // CobranzaCombobox,
    // DeudaCombobox,
    EstadoCombobox,
    DateInput
  },
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style scoped>

</style>

