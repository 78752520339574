<template>
      <crud-delete :open="open"
                 :loading="loading"
                 :title="title"
                 :errorMessage="errorMessage"
                 @delete="remove"
                 @close="$emit('close')"
    >

        <v-alert v-if="errorMessage" type="error">{{errorMessage}}</v-alert>

        <v-card-text>
          <cobranza-show-data :item="item" />
        </v-card-text>

        <v-card-text>
            <v-row justify="center">
                <span class="title">{{areYouSure}}</span>
            </v-row>
        </v-card-text>

    </crud-delete>
</template>

<script>
    //Provider
    import CobranzaProvider from "../../../../providers/CobranzaProvider";

    //Show Data
    import CobranzaShowData from "../CobranzaShow/CobranzaShowData";

    //Common
    import {CrudDelete, ClientError} from '@dracul/common-frontend'

    export default {
        name: "CobranzaDelete",

        components: {CobranzaShowData, CrudDelete},

        props: {
          open: {type: Boolean, default: true},
          item: {type: Object, required: true}
        },

        data() {
            return {
                modal: false,
                title: 'cobrox.cobranza.deleting',
                areYouSure: this.$t('common.areYouSureDeleteRecord'),
                errorMessage: '',
                loading: false,
            }
        },
        methods: {
            remove() {
                this.loading = true
                CobranzaProvider.deleteCobranza(this.item.id).then(result => {
                            if (result.data.deleteCobranza.success) {
                                this.$emit('itemDeleted',result.data.deleteCobranza)
                                this.$emit('close')
                            }else{
                                this.errorMessage = 'Error on Delete'
                            }
                        }
                    ).catch(error =>{
                        let clientError = new ClientError(error)
                        this.errorMessage = clientError.showMessage
                }).finally(() => this.loading = false)
            },
        },
    }
</script>


