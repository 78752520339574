<template>
    <crud-create :open="open"
                 :loading="loading"
                 :title="title"
                 :errorMessage="errorMessage"
                 @create="create"
                 @close="$emit('close')"
    >
        <reporte-stock-form ref="form" v-model="form" :input-errors="inputErrors" />
    </crud-create>
</template>

<script>

    import ReporteStockProvider from "../../../../providers/ReporteStockProvider";
    
    import {CrudCreate, ClientError} from '@dracul/common-frontend'
    
    import ReporteStockForm from "../ReporteStockForm";
    
    


    export default {
        name: "ReporteStockCreate",
         
        components: { ReporteStockForm, CrudCreate },
        
        props:{
          open: {type: Boolean, default: true}
        },
        
        data() {
            return {
                title: 'cobrox.reporteStock.creating',
                errorMessage: '',
                inputErrors: {},
                loading: false,
                form: {
                    creadoPor: null,
                    url: '',
                    data: ''
                }
            }
        },
        
        methods: {
            create() {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    ReporteStockProvider.createReporteStock(this.form).then(r => {
                            if (r) {
                                this.$emit('itemCreated',r.data.createReporteStock)
                                this.$emit('close')
                            }
                        }
                    ).catch(error => {
                         let clientError = new ClientError(error)
                         this.inputErrors = clientError.inputErrors
                         this.errorMessage = clientError.i18nMessage
                    }).finally(() => this.loading = false)
                }

            }

        },
    }
</script>

<style scoped>

</style>

