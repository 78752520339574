<template>

  <v-select
      prepend-icon="map"
      :items="items"
      v-model="item"
      :label="$t('cobrox.persona.labels.jurisdiccion')"
      :loading="loading"
      :error="hasInputErrors('jurisdiccion')"
      :error-messages="getInputErrors('jurisdiccion')"
      color="secondary"
      item-color="secondary"
      :rules="required"
      :disabled="disabled"
      :readonly="readonly"
  ></v-select>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


export default {
  name: "JurisdiccionCombobox",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {
      type: [String, Array]
    },
    disabled: {type: Boolean, default: false},
    readonly: {type: Boolean, default: false},
  },
  data() {
    return {
      items: ['CABA',
        'Buenos Aires', 'Catamarca', 'Chaco', 'Chubut', 'Córdoba', 'Corrientes',
        'Entre Ríos', 'Formosa', 'Jujuy', 'La Pampa', 'La Rioja', 'Neuquén', 'Mendoza',
        'Misiones', 'Río Negro', 'Salta', 'San Juan', 'San Luis', 'Santa Cruz', 'Santa Fe',
        'Santiago del Estero', 'Tierra del Fuego', 'Tucumán'],
      loading: false
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style scoped>

</style>

