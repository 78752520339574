<template>
  <v-container>
    <v-card>
      <v-card-title>Incrementar intereses</v-card-title>
      <v-card-actions v-if="!getJobId" class="text-center justify-center">
        <v-btn :disabled="getJobId" @click="incrementarIntereses">Iniciar</v-btn>
      </v-card-actions>
      <v-card-text>
        <div v-if="job" class="text-center">
          <v-progress-circular
              color="primary"
              :rotate="360"
              :size="100"
              :width="15"
              :value="job.progress"
          >
            {{ job.progress }}
          </v-progress-circular>
          <div>
            Job progress
          </div>

        </div>

      </v-card-text>

      <v-card-text v-if="job">
        <v-col cols="12">
          <v-list>
            <show-field :value="job.info" :label="$t('queue.queue.labels.info')" icon="info"/>
            <show-field v-if="job.output" :value="job.output" :label="$t('queue.queue.labels.output')"
                        icon="logout"/>
            <show-field v-if="job.error" :value="job.error" :label="$t('queue.queue.labels.error')" icon="error"/>

          </v-list>
        </v-col>
      </v-card-text>

      <v-card-text v-if="job">
        <v-card outlined>
          <v-card-title>No Procesado</v-card-title>
          <v-card-text>
            <v-simple-table v-if="job && job.data">
              <thead>
              <tr>
                <th>Motivo</th>
                <th>No Procesados</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th>Interes Bloqueado</th>
                <td>{{ getJobData.interesBloqueado }}</td>
              </tr>
              <tr>
                <th>Sin tasa interes</th>
                <td>{{ getJobData.interesNoEncontrado }}</td>
              </tr>
              <tr>
                <th>Sin fecha mora</th>
                <td>{{ getJobData.sinFechaMora }}</td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>

      </v-card-text>

      <v-card-text v-if="job">
          <v-card outlined>
            <v-card-title>Procesado</v-card-title>
            <v-card-text>
              <v-simple-table v-if="job && job.data">
                <thead>
                <tr>
                  <th></th>
                  <th>Interes total</th>
                  <th>Incremento</th>
                  <th>Cantidad</th>
                </tr>
                </thead>
                <tbody>


                <tr v-for="(interes, key) in getJobData.intereses" :key="key">
                  <th>{{ key }}</th>
                  <td>{{ interes.interesTotal.toLocaleString() }}</td>
                  <td>{{ interes.incrementoTotal.toLocaleString() }}</td>
                  <td>{{ interes.cantidadTotal }}</td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td>{{ getJobData.interesTotal.toLocaleString() }}</td>
                  <td>{{ getJobData.incrementoTotal.toLocaleString() }}</td>
                  <td>{{ getJobData.cantidadTotal }}</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-card-text>
    </v-card>


    <v-row>
      <v-col cols="12">

      </v-col>


    </v-row>

  </v-container>
</template>

<script>
import JobProvider from "@/modules/cobrox/providers/JobProvider";
import {QueueProvider} from "@dracul/queue-frontend";
import {ShowField} from "@dracul/common-frontend";

export default {
  name: "InteresesJobPage",
  components: {ShowField},
  data() {
    return {
      loading: false,
      jobid: null,
      job: null,
      timeout: null,
      error: null
    }
  },
  computed: {
    getJobId() {
      return this.$route.params.jobid ? this.$route.params.jobid : this.jobid
    },
    getJobData() {
      return JSON.parse(this.job.data)
    },
    getJob() {
      return JSON.parse(this.job)
    }
  },
  created() {
    if (this.getJobId) {
      this.fetchJob()
    } else {
      this.checkJob()
    }
  },
  methods: {
    incrementarIntereses() {
      this.loading = true
      this.error = null
      this.distribucion = null
      JobProvider.incrementarIntereses()
          .then(res => {
            this.jobid = res.data.incrementarIntereses
            this.$router.replace({name: 'InteresesJobPage', params: {jobid: this.jobid}})
            this.fetchJob()
          })
          .catch(e => {
            console.error(e)
            this.error = e.message.replace("GraphQL error: Unexpected error value:", "")
          })
          .finally(() => {
            this.loading = false
          })
    },
    checkJob() {
      QueueProvider.queueFindByTopicAndState('IncrementarIntereses', 'WORKING').then(res => {
        let rjob = res.data.queueFindByTopicAndState
        if (rjob) {
          this.$router.replace({name: 'InteresesJobPage', params: {jobid: rjob.id}})
          this.fetchJob()
        }
      })
    },
    fetchJob() {
      QueueProvider.findQueue(this.getJobId).then(res => {
        this.job = res.data.queueFind

        if (this.job.state === 'DONE') {
          // this.jobid = null
          // this.$router.replace({name: 'InteresesJobPage'})
        } else {
          setTimeout(this.fetchJob, 5000)
        }

      })
    }
  }
}
</script>

<style scoped>

</style>
