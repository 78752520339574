import graphqlClient from "../../../apollo";

class CuentaProvider {

    findCuenta(id) {
        return graphqlClient.query({
            query: require('./gql/Cuenta/findCuenta.graphql'),
            variables: {id:id}
        })
    }

    fetchCuenta() {
        return graphqlClient.query({query: require('./gql/Cuenta/fetchCuenta.graphql')})
    }
    
    paginateCuenta(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Cuenta/paginateCuenta.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }
    
    

    createCuenta(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Cuenta/createCuenta.graphql'),
            variables: {input}
        })
    }
    
    updateCuenta(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Cuenta/updateCuenta.graphql'),
            variables: {id, input}
        })
    }
    
     deleteCuenta(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Cuenta/deleteCuenta.graphql'),
            variables: {id}
        })
    }

}

export default new CuentaProvider()


