<template>
  <crud-layout :title="title" :subtitle="subtitle">

    <template v-slot:list>
      <factura-list
          ref="list"
          @update="update"
          @delete="remove"
          @show="show"

      />
    </template>

    <add-button v-if="$store.getters.hasPermission('FACTURA_CREATE')" @click="create"></add-button>

    <factura-create v-if="creating"
                    :open="creating"
                    v-on:itemCreated="onItemCreated"
                    v-on:close="creating=false"
    />

    <factura-update v-if="updating"
                    :open="updating"
                    :item="itemToEdit"
                    v-on:itemUpdated="onItemUpdated"
                    v-on:close="updating=false"
    />

    <factura-show v-if="showing"
                  :open="showing"
                  :item="itemToShow"
                  v-on:close="showing=false"
    />

    <factura-delete v-if="deleting"
                    :open="deleting"
                    :item="itemToDelete"
                    v-on:itemDeleted="onItemDeleted"
                    v-on:close="deleting=false"
    />

    <snackbar v-model="flash"/>

  </crud-layout>
</template>

<script>

import FacturaCreate from "../FacturaCreate";
import FacturaUpdate from "../FacturaUpdate";
import FacturaDelete from "../FacturaDelete";
import FacturaShow from "../FacturaShow";
import FacturaList from "../FacturaListCustom";

import {CrudLayout, AddButton, Snackbar} from "@dracul/common-frontend"

export default {
  name: "FacturaCrud",
  components: {
    CrudLayout, AddButton, Snackbar,
    FacturaCreate,
    FacturaUpdate,
    FacturaDelete,
    FacturaShow,
    FacturaList
  },
  data() {
    return {
      title: 'cobrox.factura.title',
      subtitle: 'cobrox.factura.subtitle',
      flash: null,
      creating: false,
      updating: false,
      deleting: false,
      showing: false,
      itemToEdit: null,
      itemToDelete: null,
      itemToShow: null,
    }
  },
  methods: {
    //On
    onItemCreated() {
      this.$refs.list.fetch()
      this.flash = "common.created"
    },
    onItemUpdated() {
      this.$refs.list.fetch()
      this.flash = "common.updated"
    },
    onItemDeleted() {
      this.$refs.list.fetch()
      this.flash = "common.deleted"
    },
    //Open
    create() {
      this.creating = true
    },
    update(item) {
      this.updating = true
      this.itemToEdit = item
    },
    show(item) {
      this.showing = true
      this.itemToShow = item
    },
    remove(item) {
      this.deleting = true
      this.itemToDelete = item
    }
  }

}
</script>


