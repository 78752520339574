<template>
  <v-row row wrap>

    <v-col cols="12">
      <v-row justify="space-between">
        <v-col cols="12" sm="6" md="8">
          <!-- FILTERS HERE -->
          <v-row>
            <v-col cols="12" sm="3">
              <estado-pedido-cuenta-combobox v-model="filters[0].value" @input="fetch"
                                             clearable></estado-pedido-cuenta-combobox>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <search-input @search="performSearch" v-model="search"/>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">

      <v-data-table
          class="mt-3"
          :headers="headers"
          :items="items"
          :search="search"
          :single-expand="false"
          :server-items-length="totalItems"
          :loading="loading"
          :page.sync="pageNumber"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="orderBy"
          :sort-desc.sync="orderDesc"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
          @update:page="fetch"
          @update:sort-by="fetch"
          @update:sort-desc="fetch"
          @update:items-per-page="fetch"
      >


        <template v-slot:item.agenciaSolicitante="{ item }">
          {{ item.agenciaSolicitante ? item.agenciaSolicitante.nombre : '' }}
        </template>


        <template v-slot:item.agenciaAutorizante="{ item }">
          {{ item.agenciaAutorizante ? item.agenciaAutorizante.nombre : '' }}
        </template>


        <template v-slot:item.creadoPor="{ item }">
          {{ item.creadoPor ? item.creadoPor.username : '' }}
        </template>

        <template v-slot:item.createdAt="{ item }">
          {{ getDateFormat(item.createdAt) }}
        </template>

        <template v-slot:item.tiempoRestante="{ item }">
          <span v-if="item.estadoPedidoCuenta === 'PENDIENTE' && calcularTiempoRestante(item.createdAt) > 0">
           {{ tiempoRestante(item.createdAt) }}
          </span>

        </template>

        <template v-slot:item.estadoPedidoCuenta="{ item }">
          <template v-if="item.estadoPedidoCuenta === 'PENDIENTE' && userAgencia && item.agenciaAutorizante && userAgencia.id == item.agenciaAutorizante.id">

            <v-btn
                small color="blue darken-1 blue--text text--lighten-4" class=""
                :to=" '/pedido-cuenta/' + item.id"
            >
              Resolver Pedido
            </v-btn>

          </template>

          <template v-else-if="item.estadoPedidoCuenta === 'RECHAZADO_EN_REVISION' && $store.getters.hasPermission('PEDIDOCUENTA_UPDATE')">
            <v-chip color="yellow orange--text text--darken-4" class="my-1">RECHAZADO_EN_REVISION</v-chip><br>
            <v-btn
                small color="red darken-1 red--text text--lighten-4" class="mx-1"
                @click="updateEstado(item.id, 'RECHAZADO')"
            >
              Rechazar
            </v-btn>
            <v-btn
                small color="green darken-1 green--text text--lighten-4" class="mx-1"
                @click="updateEstado(item.id, 'APROBADO')"
            >
              Aprobar
            </v-btn>
          </template>

          <template v-else>
            {{ item.estadoPedidoCuenta ? item.estadoPedidoCuenta : '' }}
          </template>

        </template>

        <template slot="no-data">
          <div class="text-xs-center" v-t="'common.noData'"></div>
        </template>

        <template slot="loading">
          <div class="text-xs-center" v-t="'common.loading'"></div>
        </template>

        <template v-slot:item.action="{ item }">
          <show-button @click="$emit('show', item)"/>
          <edit-button v-if="$store.getters.hasPermission('PEDIDOCUENTA_UPDATE')" @click="$emit('update', item)"/>
          <delete-button v-if="$store.getters.hasPermission('PEDIDOCUENTA_DELETE')" @click="$emit('delete', item)"/>
        </template>

      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import PedidoCuentaProvider from "../../../../providers/PedidoCuentaProvider";

import {DeleteButton, EditButton, ShowButton, SearchInput} from "@dracul/common-frontend"
import AgenciaProvider from "@/modules/cobrox/providers/AgenciaProvider";
import EstadoPedidoCuentaCombobox from "@/modules/cobrox/combobox/EstadoPedidoCuentaCombobox/EstadoPedidoCuentaCombobox";
import {DayjsMixin} from "@dracul/dayjs-frontend"
import TiempoRestanteMixin from "@/modules/cobrox/mixins/TiempoRestanteMixin";

export default {
  name: "PedidoCuentaList",
  components: {EstadoPedidoCuentaCombobox, DeleteButton, EditButton, ShowButton, SearchInput},
  mixins: [DayjsMixin, TiempoRestanteMixin],
  data() {
    return {
      items: [],
      totalItems: null,
      loading: false,
      loadingUserAgencia: false,
      orderBy: null,
      orderDesc: false,
      itemsPerPage: 5,
      pageNumber: 1,
      search: '',
      filters: [
        {
          field: 'estadoPedidoCuenta',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value: ''
        }
      ],
      userAgencia: null
    }
  },
  computed: {
    headers() {
      return [
        //Entity Headers
        ...(this.$store.getters.hasPermission('PEDIDOCUENTA_SHOW') ? [{
          text: this.$t('cobrox.pedidoCuenta.labels.creadoPor'),
          value: 'creadoPor'
        }] : []),

        {text: 'Fecha Pedido', value: 'createdAt'},

        ...(this.$store.getters.hasPermission('PEDIDOCUENTA_SHOW') ? [{
          text: this.$t('cobrox.pedidoCuenta.labels.agenciaSolicitante'),
          value: 'agenciaSolicitante'
        }] : []),
        ...(this.$store.getters.hasPermission('PEDIDOCUENTA_SHOW') ? [{
          text: this.$t('cobrox.pedidoCuenta.labels.comentarioSolicitante'),
          value: 'comentarioSolicitante'
        }] : []),

        {text: this.$t('cobrox.pedidoCuenta.labels.cuil'), value: 'cuil'},


        ...(this.$store.getters.hasPermission('PEDIDOCUENTA_SHOW') ? [{
          text: this.$t('cobrox.pedidoCuenta.labels.agenciaAutorizante'),
          value: 'agenciaAutorizante'
        }] : []),

        ...(this.$store.getters.hasPermission('PEDIDOCUENTA_SHOW') ? [{
          text: this.$t('cobrox.pedidoCuenta.labels.tipificacionAutorizante'),
          value: 'tipificacionAutorizante'
        }] : []),

        ...(this.$store.getters.hasPermission('PEDIDOCUENTA_SHOW') ? [{
          text: this.$t('cobrox.pedidoCuenta.labels.comentarioAutorizante'),
          value: 'comentarioAutorizante'
        }] : []),



        {text: 'Tiempo Restante', value: 'tiempoRestante'},
        {text: this.$t('cobrox.pedidoCuenta.labels.estadoPedidoCuenta'), value: 'estadoPedidoCuenta'},
        //Actions
        //{text: this.$t('common.actions'), value: 'action', sortable: false},
      ]
    },
    getOrderBy() {
      return (Array.isArray(this.orderBy)) ? this.orderBy[0] : this.orderBy
    },
    getOrderDesc() {
      return (Array.isArray(this.orderDesc)) ? this.orderDesc[0] : this.orderDesc
    }
  },
  created() {
    this.fetchUserAgencia()
    this.fetch()
  },
  methods: {

    performSearch() {
      this.pageNumber = 1
      this.fetch()
    },
    fetch() {
      this.loading = true
      PedidoCuentaProvider.paginatePedidoCuenta(
          this.pageNumber,
          this.itemsPerPage,
          this.search,
          this.filters,
          this.getOrderBy,
          this.getOrderDesc
      ).then(r => {
        this.items = r.data.paginatePedidoCuenta.items
        this.totalItems = r.data.paginatePedidoCuenta.totalItems
      })
          .catch(err => {
            console.error(err)
          })
          .finally(() => this.loading = false)
    },
    fetchUserAgencia() {
      this.loadingUserAgencia = true
      AgenciaProvider.findMyAgencia()
          .then(r => {
            this.userAgencia = r.data.findMyAgencia
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => this.loadingUserAgencia = false)
    },
    updateEstado(id, estado) {
      this.loading = true
      PedidoCuentaProvider.updatePedidoCuentaEstado(id, estado)
          .then(() => {
            this.fetch()
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => this.loading = false)
    }
  }

}
</script>


