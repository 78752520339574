<template>
    <crud-create :open="open"
                 :loading="loading"
                 :title="title"
                 :errorMessage="errorMessage"
                 @create="create"
                 @close="$emit('close')"
    >
        <comision-multi-form ref="form" v-model="form" :input-errors="inputErrors" />
    </crud-create>
</template>

<script>

    import ComisionProvider from "../../../../providers/ComisionProvider";

    import {CrudCreate, ClientError} from '@dracul/common-frontend'

    import ComisionMultiForm from "@/modules/cobrox/pages/crud/ComisionPage/ComisionForm/ComisionMultiForm";




    export default {
        name: "ComisionMultiCreate",

        components: {ComisionMultiForm, CrudCreate },

        props:{
          open: {type: Boolean, default: true}
        },

        data() {
            return {
                title: 'cobrox.comision.creating',
                errorMessage: '',
                inputErrors: {},
                loading: false,
                form: {
                    agencia: null,
                    compra: null,
                    multi: []
                }
            }
        },
        watch:{
          'form.agencia': {
            handler(){
              this.fetch()
            }
          },
          'form.compra': {
            handler(){
              this.fetch()
            }
          }
        },
      mounted() {
          this.fetch()
      },
      methods: {
            create() {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    ComisionProvider.createMultiComision(this.form.agencia, this.form.compra, this.form.multi).then(r => {
                            if (r) {
                                this.$emit('itemCreated',r.data.createComision)
                                this.$emit('close')
                            }
                        }
                    ).catch(error => {
                         let clientError = new ClientError(error)
                         this.inputErrors = clientError.inputErrors
                         this.errorMessage = clientError.i18nMessage
                    }).finally(() => this.loading = false)
                }

            },
            fetch(){

              ComisionProvider.fetchComisionByAgenciaCompra(this.form.agencia, this.form.compra)
                  .then(r => {
                    const comisiones  = r.data.fetchComisionByAgenciaCompra

                    this.form.multi = comisiones

                  })
            }

        },
    }
</script>

<style scoped>

</style>

