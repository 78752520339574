<template>

    <v-list dense>
      <v-subheader>Items</v-subheader>
      <v-list-item-group
          v-model="localSelected"
          color="primary"
      >
        <v-list-item
            v-for="(item, i) in items"
            :key="i"
        >
          <v-list-item-icon>
            <v-icon v-text="getIcon(item.type)"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
</template>

<script>

export default {
  name: "KinvaItemList",
  props: {
    itemSelected: {type:Number},
    items: {type: Array}
  },

  computed: {
    localSelected:{
      get(){
        return this.itemSelected
      },
      set(val){
        this.$emit('input',val)
      }
    },
    getIcon() {
      return is => {
        switch (is) {
          case 'k-text':
            return 'title'
          case 'k-image':
            return 'image'
          case 'k-circle':
            return 'image'
          default:
            return 'description'
        }
      }

    }
  }
}
</script>

<style scoped>

</style>
