<template>
 <v-row row wrap>

    <v-col cols="12" >
        <v-row justify="space-between">
            <v-col cols="12" sm="6" md="8">
             <!-- FILTERS HERE -->
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <agencia-combobox v-model="filters[0].value" @input="fetch" clearable></agencia-combobox>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <compra-combobox v-model="filters[1].value" @input="fetch" clearable></compra-combobox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" md="4">
            <search-input  @search="performSearch" v-model="search" />
            </v-col>
        </v-row>
    </v-col>

    <v-col cols="12">

       <v-data-table
                class="mt-3"
                :headers="headers"
                :items="items"
                :search="search"
                :single-expand="false"
                :server-items-length="totalItems"
                :loading="loading"
                :page.sync="pageNumber"
                :items-per-page.sync="itemsPerPage"
                :sort-by.sync="orderBy"
                :sort-desc.sync="orderDesc"
                :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
                @update:page="fetch"
                @update:sort-by="fetch"
                @update:sort-desc="fetch"
                @update:items-per-page="fetch"
        >


         <template v-slot:item.agencia="{ item }">
            {{ item.agencia ? item.agencia.nombre : '' }}
         </template>


         <template v-slot:item.compra="{ item }">
            {{ item.compra ? item.compra.nombre : '' }}
         </template>

         <template v-slot:item.ratioDesde="{ item }">
           {{ item.ratioDesde }}
         </template>

         <template v-slot:item.ratioHasta="{ item }">
           {{ item.ratioHasta }}
         </template>

         <template v-slot:item.comision="{ item }">
          {{ item.comision.toLocaleString('es-AR') }}%
         </template>



         <template slot="no-data">
               <div class="text-xs-center" v-t="'common.noData'"></div>
            </template>

            <template slot="loading">
               <div   class="text-xs-center" v-t="'common.loading'"></div>
            </template>

            <template v-slot:item.action="{ item }">
                <show-button  @click="$emit('show', item)" />
                <edit-button v-if="$store.getters.hasPermission('COMISION_UPDATE')"  @click="$emit('update', item)" />
                <delete-button v-if="$store.getters.hasPermission('COMISION_DELETE')" @click="$emit('delete', item)" />
            </template>

        </v-data-table>
    </v-col>
</v-row>
</template>

<script>
   import ComisionProvider from "../../../../providers/ComisionProvider";

   import {DeleteButton, EditButton, ShowButton, SearchInput} from "@dracul/common-frontend"
   import AgenciaCombobox from "@/modules/cobrox/combobox/AgenciaCombobox/AgenciaCombobox";
   import CompraCombobox from "@/modules/cobrox/combobox/CompraCombobox/CompraCombobox";


    export default {
        name: "ComisionList",
        components: {CompraCombobox, AgenciaCombobox, DeleteButton, EditButton, ShowButton, SearchInput},

        data() {
            return {
                items: [],
                totalItems: null,
                loading: false,
                orderBy: null,
                orderDesc: false,
                itemsPerPage: 10,
                pageNumber: 1,
                search: '',
                filters: [
                    {
                        field: 'agencia',
                        operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
                        value: ''
                    },
                  {
                    field: 'compra',
                    operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
                    value: ''
                  },
                ]
            }
        },
        computed: {
          headers () {
            return [
                    //Entity Headers
                    {text: this.$t('cobrox.comision.labels.agencia'), value: 'agencia'},
                    {text: this.$t('cobrox.comision.labels.compra'), value: 'compra'},
                    {text: this.$t('cobrox.comision.labels.ratioDesde'), value: 'ratioDesde'},
                    {text: this.$t('cobrox.comision.labels.ratioHasta'), value: 'ratioHasta'},
                    {text: this.$t('cobrox.comision.labels.comision'), value: 'comision'},
                    //Actions
                    {text: this.$t('common.actions'), value: 'action', sortable: false},
                ]
          },
          getOrderBy(){
              return  (Array.isArray(this.orderBy)) ? this.orderBy[0]: this.orderBy
          },
          getOrderDesc(){
              return  (Array.isArray(this.orderDesc)) ? this.orderDesc[0]: this.orderDesc
          }
        },
        created() {
            this.fetch()
        },
        methods:{
            performSearch(){
                this.pageNumber = 1
                this.fetch()
            },
            fetch() {
                this.loading = true
                ComisionProvider.paginateComision(
                    this.pageNumber,
                    this.itemsPerPage,
                    this.search,
                    this.filters,
                    this.getOrderBy,
                    this.getOrderDesc
                ).then(r => {
                    this.items = r.data.paginateComision.items
                    this.totalItems = r.data.paginateComision.totalItems
                }).catch(err => {
                    console.error(err)
                }).finally(() => this.loading = false)
            }
        }

    }
</script>


