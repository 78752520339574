<template>
    <v-row>
        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.cuil" :label="$t('cobrox.pedidoCuenta.labels.cuil')" icon="short_text"/>
                 <show-field :value="item.creadoPor.username" :label="$t('cobrox.pedidoCuenta.labels.creadoPor')" icon="person"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.agenciaSolicitante.nombre" :label="$t('cobrox.pedidoCuenta.labels.agenciaSolicitante')" icon="business"/>
                 <show-field :value="item.estadoPedidoCuenta" :label="$t('cobrox.pedidoCuenta.labels.estadoPedidoCuenta')" icon="list_alt"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.agenciaAutorizante.nombre" :label="$t('cobrox.pedidoCuenta.labels.agenciaAutorizante')" icon="business"/>
            </v-list>
        </v-col>

    </v-row>
</template>
<script>
    import {ShowField} from '@dracul/common-frontend'
     
    
    export default {
        name: 'PedidoCuentaShowData',
        components: {ShowField},
        
        props: {
            item: {type: Object, required: true}
        }
    }
</script>

