import graphqlClient from "../../../apollo";

class ProductoProvider {

    findProducto(id) {
        return graphqlClient.query({
            query: require('./gql/Producto/findProducto.graphql'),
            variables: {id:id}
        })
    }

    fetchProducto() {
        return graphqlClient.query({
            query: require('./gql/Producto/fetchProducto.graphql'),
            fetchPolicy: "network-only"
        })
    }

    paginateProducto(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Producto/paginateProducto.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }



    createProducto(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Producto/createProducto.graphql'),
            variables: {input}
        })
    }

    updateProducto(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Producto/updateProducto.graphql'),
            variables: {id, input}
        })
    }

     deleteProducto(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Producto/deleteProducto.graphql'),
            variables: {id}
        })
    }

}

export default new ProductoProvider()


