
const messages = {
    en: {
       role: {
            permissions: {
                REPORTESTOCK_MENU: "Access to ReporteStock",
                REPORTESTOCK_SHOW: "View ReporteStock",
                REPORTESTOCK_CREATE: "Create ReporteStock",
                REPORTESTOCK_UPDATE: "Modify ReporteStock",
                REPORTESTOCK_DELETE: "Delete ReporteStock",
            }
       }
    },
    es: {
        role: {
            permissions: {
                REPORTESTOCK_MENU: "Acceder ReporteStock",
                REPORTESTOCK_SHOW: "Ver ReporteStock",
                REPORTESTOCK_CREATE: "Crear ReporteStock",
                REPORTESTOCK_UPDATE: "Modificar ReporteStock",
                REPORTESTOCK_DELETE: "Eliminar ReporteStock",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                REPORTESTOCK_MENU: "Acessar ReporteStock",
                REPORTESTOCK_SHOW: "Ver ReporteStock",
                REPORTESTOCK_CREATE: "Criar ReporteStock",
                REPORTESTOCK_UPDATE: "Modificar ReporteStock",
                REPORTESTOCK_DELETE: "Eliminar ReporteStock",
            }
       }
    }
}

export default messages
    