<template>
  <v-container>
    <v-row justify="center" align-content="center">
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>
            Histórico de saldos
          </v-card-title>

          <v-card-text>
            <date-input v-model="fecha" label="Fecha objetivo"></date-input>
          </v-card-text>

          <v-card-actions class="text-center">
            <v-spacer></v-spacer>
            <v-btn :disabled="!fecha" @click="exportarDeuda" class="primary onPrimary--text">Generar</v-btn>
          </v-card-actions>
        </v-card>

      </v-col>

    </v-row>


  </v-container>
</template>

<script>
import {DateInput} from "@dracul/dayjs-frontend"
import JobProvider from "@/modules/cobrox/providers/JobProvider";

export default {
  name: "SaldosPage",
  components: {DateInput},
  data() {
    return {
      fecha: null
    }
  },
  methods: {
    exportarDeuda() {
      JobProvider.exportarDeuda(this.fecha)
          .then(resp => {
            let jobId = resp.data.exportarDeuda
            this.$router.push({name: 'JobPage', params: {id: jobId} } )
          })
    }
  }
}
</script>

<style scoped>

</style>
