
const messages = {
    en: {
       role: {
            permissions: {
                PERSONA_AGENCIA_SHOW: "View agency people",
                PERSONA_ADD_NOTA: "Add notes to people",
                DEUDA_AGENCIA_SHOW: "View agency debts",
                COBRANZA_AGENCIA_SHOW: "View agency collections",
                RESTORE_SYSTEM: "Restore System",
                DASHBOARD:"DASHBOARD",
                PROCESOS: "PROCESOS",
                REPORTES: "REPORTES",
                INTERNO: "Ver datos INTERNOS",
                CONTABILIDAD: "Ver Contabilidad",
                REVERT_JOB: 'Revertir Job'
            }
       }
    },
    es: {
        role: {
            permissions: {
                PERSONA_AGENCIA_SHOW: "Ver personas de mi agencia",
                PERSONA_ADD_NOTA: "Agregar notas a persona",
                DEUDA_AGENCIA_SHOW: "Ver deudas de mi agencia",
                COBRANZA_AGENCIA_SHOW: "Ver cobranzas de mi agencia",
                RESTORE_SYSTEM: "Restaurar el sistema",
                DASHBOARD:"DASHBOARD",
                PROCESOS: "PROCESOS",
                REPORTES: "REPORTES",
                INTERNO: "Ver datos INTERNOS",
                CONTABILIDAD: "Ver Contabilidad",
                REVERT_JOB: 'Revertir Job'

            }
       }
    },
    pt: {
       role: {
            permissions: {
                PERSONA_AGENCIA_SHOW: "Ver personas de mi agencia",
                PERSONA_ADD_NOTA: "Agregar notas a persona",
                DEUDA_AGENCIA_SHOW: "Ver deudas de mi agencia",
                COBRANZA_AGENCIA_SHOW: "Ver cobranzas de mi agencia",
                RESTORE_SYSTEM: "Restaurar el sistema",
                DASHBOARD:"DASHBOARD",
                PROCESOS: "PROCESOS",
                REPORTES: "REPORTES",
                INTERNO: "Ver datos INTERNOS",
                CONTABILIDAD: "Ver Contabilidad",
                REVERT_JOB: 'Revertir Job'

            }
       }
    }
}

export default messages
