<template>
  <v-card v-if="getCobranza" :loading="loading" flat>

    <v-card-text class="pa-0" v-if="enableDetails">
      <v-card outlined>
        <v-card-subtitle>ID: {{ getCobranza.id }}</v-card-subtitle>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-list>
                <show-field
                    v-if="canView('dniDeudor')"
                    :value="getCobranza.dniDeudor"
                    :label="$t('cobrox.cobranza.labels.dniDeudor')"
                    icon="badge"
                />
                <show-field
                    v-if="canView('cuilDeudor')"
                    :value="getCobranza.cuilDeudor"
                    :label="$t('cobrox.cobranza.labels.cuilDeudor')"
                    icon="contact_page"
                />

                <show-field
                    v-if="canView('agencia')"
                    :value="getCobranza.agencia ? getCobranza.agencia.nombre : ''"
                    :label="$t('cobrox.cobranza.labels.agencia')"
                    icon="business"
                />
                <show-field
                    v-if="canView('estado')"
                    :value="getCobranza.estado"
                    :label="$t('cobrox.cobranza.labels.estado')"
                    icon="toc"
                />
                <show-field
                    v-if="canView('montoCobrado')"
                    :value="'$'+String(getCobranza.montoCobrado)"
                    :label="$t('cobrox.cobranza.labels.montoCobrado')"
                    icon="payments"
                />


              </v-list>
            </v-col>



            <v-col cols="12" sm="4">
              <v-list>
                <show-field
                    v-if="canView('origenCreacion')"
                    :value="getCobranza.origenCreacion"
                    :label="$t('cobrox.cobranza.labels.origenCreacion')"
                    icon="trip_origin"
                />
                <show-field
                    v-if="canView('createdAt')"
                    :value="getDateFormat(getCobranza.createdAt)"
                    :label="$t('cobrox.cobranza.labels.fechaCreacion')"
                    icon="today"
                />

                <show-field
                    v-if="canView('fechaCobranza')"
                    :value="getDateFormat(getCobranza.fechaCobranza)"
                    :label="$t('cobrox.cobranza.labels.fechaCobranza')"
                    icon="event"
                />
                <show-field
                    v-if="canView('fechaImputacion')"
                    :value="getDateFormat(getCobranza.fechaImputacion)"
                    :label="$t('cobrox.cobranza.labels.fechaImputacion')"
                    icon="event_available"
                />


              </v-list>

            </v-col>

            <v-col cols="12" sm="4">
              <v-list>





                <show-field
                    v-if="canView('cuentaDestino')"
                    :value="getCobranza.cuentaDestino"
                    :label="$t('cobrox.cobranza.labels.cuentaDestino')"
                    icon="account_balance"
                />
                <show-field
                    v-if="canView('canalCobro')"
                    :value="getCobranza.canalCobro"
                    :label="$t('cobrox.cobranza.labels.canalCobro')"
                    icon="input"
                />
                <show-field
                    v-if="canView('cbu')"
                    :value="getCobranza.cbu"
                    :label="$t('cobrox.cobranza.labels.cbu')"
                    icon="account_balance_wallet"
                />

                <show-field
                    v-if="canView('nroProducto')"
                    :value="getCobranza.nroProducto"
                    :label="$t('cobrox.cobranza.labels.nroProducto')"
                    icon="copyright"
                />
              </v-list>
            </v-col>


          </v-row>
        </v-card-text>

      </v-card>

    </v-card-text>

    <v-card-text
        v-if="$store.getters.hasPermission('CONTABILIDAD') && enableFacturas && (getFacturasDeudor.length > 0 || getFacturasExtra.length > 0)"
        class="mt-4 pa-0"
    >
      <v-card>
        <v-card-text>
          <h5>Facturas deudor</h5>
          <facturas-table-with-deuda
              :facturas="getFacturasDeudor"
          />

          <template v-if="getFacturasExtra.length > 0">
            <h5>Facturas adicionales</h5>
            <facturas-table-with-deuda
                :facturas="getFacturasExtra"
            />
          </template>

        </v-card-text>

      </v-card>

    </v-card-text>


  </v-card>

</template>

<script>
import {DayjsMixin} from "@dracul/dayjs-frontend";
import {ShowField} from "@dracul/common-frontend";
import FacturasTableWithDeuda from "@/modules/cobrox/components/FacturasTableWithDeuda";
import CobranzaCustomProvider from "@/modules/cobrox/providers/CobranzaCustomProvider";
import SuretyMixin from "@/modules/surety/mixins/SuretyMixin";

export default {
  name: "CobranzaDetail",
  props: {
    cobranzaId: String,
    cobranza: Object,
    enableDetails: {type: Boolean, default: true},
    enableFacturas: {type: Boolean, default: true},
  },
  mixins: [DayjsMixin, SuretyMixin],
  components: {FacturasTableWithDeuda, ShowField},
  data() {
    return {
      cobranzaLocal: null,
      loading: false
    }
  },
  created() {
    this.fetchFieldPermission('Cobranza')
    if (this.cobranzaId) {
      this.fetchCobranza()
    }
  },
  computed: {
    getCobranza() {
      if (this.cobranzaId) {
        return this.cobranzaLocal
      } else {
        return this.cobranza
      }
    },
    getFacturasDeudor() {
      return (this.getCobranza.facturas.length > 0) ? this.getCobranza.facturas.filter(f => f.cobranza.dniDeudor === f.deuda.dniDeudor) : []
    },
    getFacturasExtra() {
      return (this.getCobranza.facturas.length > 0) ? this.getCobranza.facturas.filter(f => f.cobranza.dniDeudor != f.deuda.dniDeudor) : []
    }
  },
  methods: {
    fetchCobranza() {
      this.loading = true
      CobranzaCustomProvider.findCobranzaPopulated(this.cobranzaId)
          .then(res => {
            this.cobranzaLocal = res.data.cobranzaFindPopulated
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
