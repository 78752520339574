<template>
  <v-container fluid>
    <h1>Lotes de facturación</h1>

    <loading v-if="loading" text="Procesando"></loading>

    <v-row v-else-if="lotes.length > 0">
      <v-col cols="12" md="4" v-for="lote in lotes" :key="lote.lote">
        <v-card>
          <v-card-title>
            {{ lote.lote }}
          </v-card-title>


          <v-card-text>
            <v-simple-table>
              <tbody>
              <tr>
                <th>Registros</th>
                <td> {{ lote.cantidad }}</td>
              </tr>

              <tr>
                <th>Facturable</th>
                <td> {{currency}}{{ lote.montoFacturable.toLocaleString('es-Ar') }} ({{ lote.cantidadFacturables }})</td>
              </tr>


              <tr>
                <th>No Facturable</th>
                <td> {{currency}}{{ lote.montoNoFacturable.toLocaleString('es-Ar') }} ({{ lote.cantidadNoFacturables }})</td>
              </tr>

              <tr>
                <th>Provisorios</th>
                <td> {{ lote.provisorio }}</td>
              </tr>

              <tr>
                <th>Aprobados</th>
                <td> {{ lote.aprobado }}</td>
              </tr>

              <tr>
                <th>Procesados</th>
                <td> {{ lote.procesado }}</td>
              </tr>


              </tbody>
            </v-simple-table>
          </v-card-text>

          <v-card-actions>

            <!--ELIMINAR-->
            <v-btn
                v-if="(lote.provisorio > 0 && lote.aprobado === 0 && lote.procesado === 0)"
                color="red darken-2" dark
                @click="eliminarLote(lote.lote)"
            >
              <v-icon left>delete</v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <!--APROBAR-->
            <v-btn
                v-if="(lote.provisorio > 0)"
                color="green darken-2"
                dark
                @click="aprobarLote(lote.lote)"
            >
              <v-icon left>done</v-icon>
              Aprobar
            </v-btn>


            <!--REPROCESAR-->
            <v-btn
                v-if="(lote.aprobado > 0)"
                color="purple darken-2"
                dark
                @click="aprobarLote(lote.lote)"
            >
              <v-icon left>engineering</v-icon>
              Reprocesar
            </v-btn>


            <!--VER-->
            <v-btn
                v-if="(lote.aprobado > 0 || lote.procesado > 0)"
                color="orange darken-2"
                dark
                :to="{name: 'FacturaManagementPage', params: {lote: lote.lote }}"
            >
              <v-icon left>preview</v-icon>
              Ver
            </v-btn>

            <!--EXPORTAR-->

            <v-btn
                v-if="(lote.aprobado > 0 || lote.procesado > 0)"
                color="blue darken-2"
                dark
                @click="exportarFacturas(lote.lote)"
                :loading="exportLoading"
            >
              <v-icon left>file_download</v-icon>
              Exportar
            </v-btn>

          </v-card-actions>

        </v-card>
      </v-col>
    </v-row>

    <confirm-dialog v-model="showConfirmDialog" :title="this.action" :description="getConfirmDialogDescription"
                    @confirmed="onConfirmed"></confirm-dialog>

  </v-container>
</template>

<script>
import FacturarProvider from "@/modules/cobrox/providers/FacturarProvider";
import {ConfirmDialog, Loading} from "@dracul/common-frontend"
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";

export default {
  name: "FacturarPage",
  components: {ConfirmDialog, Loading},
  mixins: [CurrencyMixin],
  data() {
    return {
      loading: false,
      lotes: [],
      loteSelected: null,
      action: null,
      showConfirmDialog: false,
      exportLoading: false
    }
  },
  created() {
    this.fetchEstadoLotes()
  },
  computed: {
    getConfirmDialogDescription() {
      switch (this.action) {
        case "ELIMINAR":
          return 'Esta acción eliminará los registros de factura identificados con el lote ' + this.loteSelected + ' y revertirá los saldos sobre las deudas'
        case "APROBAR":
          return 'Esta acción confirmará los registros de factura identificados con el lote ' + this.loteSelected + ' y los dejará preparados para emitir las facturas'
        default:
          return null
      }
    }
  },
  methods: {
    exportarFacturas(lote) {
      this.exportLoading = true
      FacturarProvider.exportarFacturas(lote)
          .then(r => {
            let url = r.data.facturaExportar
            console.log(url)
            const link = document.createElement('a')
            link.href = url
            link.click()
          })
          .finally(() => this.exportLoading = false)
    },
    fetchEstadoLotes() {
      this.loading = true
      FacturarProvider.fetchLoteStatus()
          .then(r => {
            this.lotes = r.data.loteEstadoFetch
          })
          .finally(() => this.loading = false)
    },
    eliminarLote(lote) {
      this.loteSelected = lote
      this.action = "ELIMINAR"
      this.showConfirmDialog = true
    },
    aprobarLote(lote) {
      this.loteSelected = lote
      this.action = "APROBAR"
      this.showConfirmDialog = true
    },
    onConfirmed() {
      this.showConfirmDialog = false
      switch (this.action) {
        case "ELIMINAR":
          return this.execDeleteLote(this.loteSelected)
        case "APROBAR":
          return this.execAprobarLote(this.loteSelected)
        default:
          return null
      }
    },
    execDeleteLote(lote) {
      this.loading = true
      FacturarProvider.loteEliminar(lote)
          .then(r => {
            let loteObj = r.data.loteEliminar
            console.log("LoteEliminado", lote, "Value:", loteObj)
            this.fetchEstadoLotes()
          })
          .finally(() => this.loading = false)
    },
    execAprobarLote(lote) {
      this.loading = true
      FacturarProvider.loteAprobar(lote)
          .then(r => {
            let loteObj = r.data.loteAprobar.lote
            console.log("LoteAprobado", loteObj)
            this.$router.push({name: 'JobPage', params: {id: r.data.loteAprobar.job}})

            /*let index = this.lotes.findIndex(l => l.lote === lote)
            console.log("index", index)
            this.$set(this.lotes, index, loteObj)*/
          })
          .finally(() => this.loading = false)
    },
  }
}
</script>

<style scoped>

</style>
