<template>
  <div v-if="tableView">
    <div>
      <v-simple-table
          dense
      >
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">

            </th>
            <th class="text-left">
              ID Deuda
            </th>
            <th class="text-left">
              N° Prod
            </th>
            <th class="text-left">
              Producto
            </th>
            <th class="text-left">
              Capital
            </th>
            <th class="text-left">
              Intereses
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="deuda in getDeudas"
              :key="deuda.id"
          >
            <td>
              <v-checkbox
                  dense hide-details
                  :key="deuda.id"
                  v-model="item"
                  :value="deuda.id"
                  :readonly="readonly"
              ></v-checkbox>
            </td>

            <td>{{ deuda.idDeuda }}</td>
            <td>{{ deuda.nroProducto }}</td>
            <td>{{ deuda.producto }}</td>
            <td>{{currency}}{{ deuda.capitalTotal.toLocaleString('es-AR') }}</td>
            <td>{{currency}}{{ deuda.intereses.toLocaleString('es-AR') }}</td>
          </tr>
          </tbody>
        </template>

      </v-simple-table>
    </div>
  </div>
  <v-autocomplete
      v-else
      prepend-icon="account_balance_wallet"
      :items="getDeudas"
      :item-text="itemText"
      :item-value="itemValue"
      v-model="item"
      :label="$t('cobrox.convenio.labels.deudas')"
      :loading="loading"
      :error="hasInputErrors('deudas')"
      :error-messages="getInputErrors('deudas')"
      color="secondary"
      item-color="secondary"
      :rules="isRequired ? required : []"
      :multiple="multiple"
      :chips="chips"
      :solo="solo"
      :disabled="disabled"
      :readonly="readonly"
      :clearable="clearable"
      :hide-details="hideDetails"
      :style="{width: width, maxWidth: width}"
      :return-object="returnObject"
  ></v-autocomplete>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


import DeudaProvider from "../../providers/DeudaProvider"
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";

export default {
  name: "DeudaCombobox",
  mixins: [InputErrorsByProps, RequiredRule, CurrencyMixin],
  props: {
    value: {type: [String, Array, Object]},
    multiple: {type: Boolean, default: true},
    solo: {type: Boolean, default: false},
    chips: {type: Boolean, default: false},
    readonly: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    isRequired: {type: Boolean, default: false},
    clearable: {type: Boolean, default: false},
    hideDetails: {type: Boolean, default: false},
    returnObject: {type: Boolean, default: false},
    itemValue: {type: String, default: 'id'},
    itemText: {type: String, default: 'idDeuda'},
    width: {type: String, default: null},
    cuilDeudor: {type: String, default: null},
    dniDeudor: {type: String, default: null},
    tableView: {type: Boolean, default: false},
    onlyActive: {type: Boolean, default: false},
    allSelected: {type: Boolean, default: false},
    notSelectable: {type: Array, default: () => []},
  },
  data() {
    return {
      items: [],
      loading: false
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$nextTick(() => {
          this.$emit('inputObject', this.deudasSelected)
        })
      }
    },
    deudasSelected(){
      return this.items.filter(deuda => this.item.includes(deuda.id))
    },
    getDeudas(){
      return this.onlyActive ? this.items.filter(i => i.estado === 'ACTIVO') : this.items
    }
  },
  mounted() {
    this.fetch()
  },
  watch:{
    cuilDeudor: function () {
      this.fetch()
    },
    dniDeudor: function () {
      this.fetch()
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    fetch() {
      if (this.cuilDeudor) {
        this.fetchByCuil()
      } else if (this.dniDeudor) {
        this.fetchByDni()
      }
    },
    fetchByCuil() {
      this.loading = true
      DeudaProvider.fetchDeudaByCuil(this.cuilDeudor)
          .then(r => {
            this.items = r.data.fetchDeudaByCuil
            if(this.allSelected){
              this.item = this.getDeudas.map(deuda => deuda.id).filter( d => !this.notSelectable.includes(d) )
            }else{
              this.$nextTick(() => {
                this.$emit('inputObject', this.deudasSelected)
              })
            }
          })
          .catch(err => console.error(err))
          .finally(() => this.loading = false)
    },
    fetchByDni() {
      this.loading = true
      DeudaProvider.fetchDeudaByDni(this.dniDeudor)
          .then(r => {
            this.items = r.data.fetchDeudaByDni
            if(this.allSelected){
              this.item = this.getDeudas.map(deuda => deuda.id)
            }else{
              this.$nextTick(() => {
                this.$emit('inputObject', this.deudasSelected)
              })
            }
          })
          .catch(err => console.error(err))
          .finally(() => this.loading = false)
    }

  }
}
</script>

<style scoped>

</style>
