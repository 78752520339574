import graphqlClient from "../../../apollo";

class RestoreSystemProvider {

    restoreSystemEnable() {
        return graphqlClient.query({
            query: require('./gql/RestoreSystem/restoreSystemEnable.graphql'),
            fetchPolicy: "no-cache"
        })
    }

    restoreSystem(form) {
        return graphqlClient.mutate({
            mutation: require('./gql/RestoreSystem/restoreSystem.graphql'),
            variables: form
        })
    }

}

export default new RestoreSystemProvider()


