
const messages = {
    en: {
       cobrox: {
          retencion: {
            name: 'Retención',
            title: 'Retención management',
            subtitle: 'View, search, create, edit and delete Retención',
            creating: 'Creating Retención',
            editing: 'Editing Retención',
            deleting: 'Deleting Retención',
            showing: 'Showing Retención',
            menu: 'Retención',
            labels: {
                agencia: 'Agencia',
                estado: 'Estado',
                procesado: 'Procesado',
                archivo: 'Archivo',
                comentario: 'Comentario',
                retenidas: 'Retenidas',
                solicitadas: 'Solicitadas',
                rechazadas: 'Rechazadas',
                creadoPor: 'Creado Por'
            },

          }
       }
    },
    es: {
       cobrox: {
          retencion: {
            name: 'Retención',
            title: 'Administración de Retención',
            subtitle: 'Ver, buscar, crear, editar, y borrar Retención',
            creating: 'Creando Retención',
            editing: 'Modificando Retención',
            deleting: 'Eliminando Retención',
            showing: 'Detalles de Retención',
            menu: 'Retención',
            labels: {
                agencia: 'Agencia',
                estado: 'Estado',
                procesado: 'Procesado',
                archivo: 'Archivo',
                comentario: 'Comentario',
                retenidas: 'Retenidas',
                solicitadas: 'Solicitadas',
                rechazadas: 'Rechazadas',
                creadoPor: 'Creado Por'
            },

          }
       }
    },
    pt: {
       cobrox: {
          retencion: {
            name: 'Retención',
            title: 'Administração de Retención',
            subtitle: 'Ver, buscar, criar, editar e usar Retención',
            creating: 'Criando Retención',
            editing: 'Edição Retención',
            deleting: 'Apagando Retención',
            showing: 'Detalhes do Retención',
            menu: 'Retención',
            labels: {
                agencia: 'Agencia',
                estado: 'Estado',
                procesado: 'Procesado',
                archivo: 'Archivo',
                comentario: 'Comentario',
                retenidas: 'Retenidas',
                solicitadas: 'Solicitadas',
                rechazadas: 'Rechazadas',
                creadoPor: 'Creado Por'
            },

          }
       }
    }
}

export default messages
