<template>
  <v-card class="mb-3">
  <v-card-title>Netear Cobranzas</v-card-title>
    <v-card-text>
      <v-simple-table>
        <tbody>
        <tr>
          <th>Cantidad Cobranzas Neteadas</th>
          <td>{{distribucion.resultNeteo.cantidadCobranzasNeteadas}}</td>
        </tr>
        <tr>
          <th>Monto Cobranzas Neteadas</th>
          <td>{{currency}}{{distribucion.resultNeteo.montoNeteado.toLocaleString('es-Ar')}}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";

export default {
  name: "ResultadoNeteo",
  mixins: [CurrencyMixin],
  props: {
    distribucion: Object
  }
}
</script>

<style scoped>

</style>
