<template>
    <v-row>
        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="getDateFormat(item.fechaCobranza)" :label="$t('cobrox.cobranza.labels.fechaCobranza')" icon="event"/>
                 <show-field :value="item.nombreDeudor" :label="$t('cobrox.cobranza.labels.nombreDeudor')" icon="person"/>
                 <show-field :value="item.cbu" :label="$t('cobrox.cobranza.labels.cbu')" icon="account_balance_wallet"/>
                 <show-field :value="item.facturas.map(i => i.id).join(', ')" :label="$t('cobrox.cobranza.labels.facturas')" icon="receipt"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="String(item.montoCobrado)" :label="$t('cobrox.cobranza.labels.montoCobrado')" icon="payments"/>
                 <show-field :value="item.cuilDeudor" :label="$t('cobrox.cobranza.labels.cuilDeudor')" icon="contact_page"/>
                 <show-field :value="item.canalCobro" :label="$t('cobrox.cobranza.labels.canalCobro')" icon="door_front"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.dniDeudor" :label="$t('cobrox.cobranza.labels.dniDeudor')" icon="badge"/>
                 <show-field :value="item.agencia" :label="$t('cobrox.cobranza.labels.agencia')" icon="business"/>
                 <show-field :value="item.cuentaDestino" :label="$t('cobrox.cobranza.labels.cuentaDestino')" icon="account_balance"/>
            </v-list>
        </v-col>

    </v-row>
</template>
<script>
    import {ShowField} from '@dracul/common-frontend'
    import {DayjsMixin} from "@dracul/dayjs-frontend";

    export default {
        name: 'CobranzaShowData',
        components: {ShowField},
        mixins: [DayjsMixin],
        props: {
            item: {type: Object, required: true}
        }
    }
</script>

