import graphqlClient from "../../../apollo";

class StockReportProvider {


    stockReport(desde, hasta, estadosDeuda) {
        return graphqlClient.mutate({
            mutation: require('./gql/StockReport/stockReport.graphql'),
            variables: {desde, hasta, estadosDeuda},
            fetchPolicy: "no-cache"
        })
    }

    stockReportFile(desde, hasta, estadosDeuda, description) {
        return graphqlClient.mutate({
            mutation: require('./gql/StockReport/stockReportFile.graphql'),
            variables: {desde, hasta, estadosDeuda, description}
        })
    }


}

export default new StockReportProvider()


