<template>
  <v-container>
    <v-card>
      <v-card-title>Netear Cobranzas</v-card-title>
      <v-card-text>Esta función buscara las cobranzas negativas en estado "PENDIENTE" (sin facturas generadas) y luego buscara cobranzas
        positivas del mismo DNI en estado "PENDIENTE" (sin facturas generadas) y neteará los montos de las mismas
      </v-card-text>
      <v-card-text>
        Ejemplos:
        <ul>
          <li>Si se registra una cobranza por $1000 para el DNI 123 en estado "PENDIENTE" y luego una cobranza por -$1000 para el mismo DNI
            123 en estado "PENDIENTE", al realizar el neteo quedará entonces una diferencia por $0.
            Ambas cobranzas quedarán en estado "NETEADO" y serán omitidas en la generación de facturas y notas de crédito.
          </li>


          <li>Si se registra una cobranza por $1000 para el DNI 123 en estado "PENDIENTE" y luego una cobranza por -$500 para el mismo DNI
            123 en estado "PENDIENTE", al realizar el neteo quedará entonces una diferencia por $500.
            La cobranza positiva por $1000 y la negativa por $500 quedarán en estado "NETEADO" siendo omitidas en la generación de facturas y notas de crédito.
            A su vez se generará una cobranza positiva de $500 (por la diferencia) en estado "PENDIENTE" por lo que será considerada en la distribución y generación de facturas.
          </li>

          <li>Si se registra una cobranza por -$500 para el DNI 123 en estado "PENDIENTE" y ninguna cobranza positiva en estado "PENDIENTE" para el mismo DNI,
            al realizar el neteo la cobranza negativa seguirá en estado "PENDIENTE" por lo que luego se generará una nota de crédito por $500 si se encuentra una cobranza positiva en estado "FACTURADO" (con facturas generadas)
          </li>


          <li>Si se registran varias cobranzas para el mismo DNI, canalCobro, cuentaDestino y agencia en estado PENDIENTE, se sumarizan en 1 solo registro
          </li>
        </ul>

      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn class="primary onPrimary--text" @click="netearCobranzas">Netear cobranzas</v-btn>
      </v-card-actions>
    </v-card>

    <v-card  class="mt-5">

      <v-card-text v-if="message">
        <v-alert type="info">
          {{message}}
        </v-alert>
      </v-card-text>

      <v-card-text v-if="resultado">
        <v-row>
          <v-col cols="6">
            <dashboard-card
                title="Cantidad Cobranzas Neteadas"
                :value="resultado.cantidadCobranzasNeteadas"
            ></dashboard-card>
          </v-col>
          <v-col cols="6">
            <dashboard-card
                title="Monto Cobranzas Neteadas"
                :value="resultado.montoNeteado"
                :symbol="currency"
            ></dashboard-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="resultado && resultado.cobranzasNeteadas.length > 0">
        <v-simple-table>
          <thead>
          <tr>
            <th>ID</th>
            <th>Estado</th>
            <th>Dni Deudor</th>
            <th>Monto</th>
            <th>Fecha</th>
            <th>Origen</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="cobranzaNeteada in resultado.cobranzasNeteadas" :key="cobranzaNeteada.id">
            <td>{{cobranzaNeteada.id}}</td>
            <td>{{cobranzaNeteada.estado}}</td>
            <td>{{cobranzaNeteada.dniDeudor}}</td>
            <td>{{cobranzaNeteada.montoCobrado}}</td>
            <td>{{ getDateFormat(cobranzaNeteada.fechaCobranza)}}</td>
            <td>{{cobranzaNeteada.origenCreacion}}</td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import FacturarProvider from "@/modules/cobrox/providers/FacturarProvider";
import {DayjsMixin} from "@dracul/dayjs-frontend"
import DashboardCard from "@/modules/cobrox/components/DashboardCard/DashboardCard";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";

export default {
  name: "NetearCobranzasPage",
  components: {DashboardCard},
  data(){
    return {
      resultado: null,
      message: null
    }
  },
  mixins: [DayjsMixin,CurrencyMixin],
  methods: {
    netearCobranzas() {
      this.message = null
      FacturarProvider.netearCobranzas().then(r => {
        this.resultado = r.data.netearCobranzas
        if(this.resultado.cobranzasNeteadas.length === 0){
          this.message = "No se encontraron cobranzas por netear"
        }
      })

    }
  }
}
</script>

<style scoped>

</style>
