<template>
  <v-container fluid>


    <v-card>
      <v-card-title>INTERESES</v-card-title>
      <v-card-subtitle>Parametrización de tasa de interes anual acorde a fecha de mora y capital</v-card-subtitle>
      <div class="v-btn--absolute v-btn--right v-btn--top">
        <v-text-field
        v-model="tasaIndexacion"
        label="Tasa Indexación"
        ></v-text-field>
      </div>
      <v-card-text>
        <v-simple-table>
          <thead>

          </thead>

          <tbody>
          <tr>
            <th>Importe/Fecha</th>
            <th v-for="fecha in getFechas" :key="getDateFormatCompress(fecha.fechaDesde)+'-'+getDateFormatCompress(fecha.fechaHasta)">
              <v-row align="center" class="title">
              {{ getDateFormatCompress(fecha.fechaDesde) }} - {{ getDateFormatCompress(fecha.fechaHasta) }}

                <interes-fecha-form
                    is-editing
                    :current-fecha-desde="unixToDayjs(fecha.fechaDesde)"
                    :current-fecha-hasta="unixToDayjs(fecha.fechaHasta)"
                    @updated="onUpdate"
                ></interes-fecha-form>
              </v-row>

            </th>

            <td>
              <interes-fecha-form
                  @updated="onUpdate"
              ></interes-fecha-form>
            </td>
          </tr>
          <tr v-for="importe in getImportes" :key="importe.importeDesde+'-'+importe.importeHasta">
            <th>
              <v-row align="center" class="title">
              {{ importe.importeDesde }} - {{ importe.importeHasta }}
              <interes-importe-form
                  is-editing
                  :current-importe-desde="importe.importeDesde"
                  :current-importe-hasta="importe.importeHasta"
                  @updated="onUpdate"
              ></interes-importe-form>
              </v-row>
            </th>

            <td v-for="fecha in getFechas" :key="getDateFormatCompress(fecha.fechaDesde)+'-'+getDateFormatCompress(fecha.fechaHasta)">
              <interes-tasa-form
                  @updated="onUpdate"
                  :id="getTasa(importe, fecha).id"
                  :current-tasa="getTasa(importe, fecha).tasa"
              ></interes-tasa-form>
            </td>
            <td></td>
          </tr>
          <tr>
            <interes-importe-form
                @updated="onUpdate"
            ></interes-importe-form>
          </tr>
          </tbody>

        </v-simple-table>
      </v-card-text>
    </v-card>


  </v-container>
</template>

<script>
import InteresProvider from "@/modules/cobrox/providers/InteresProvider";
import {DayjsMixin, Dayjs} from "@dracul/dayjs-frontend"
import InteresFechaForm from "@/modules/cobrox/pages/processes/InteresesPage/InteresFechaForm";
import InteresImporteForm from "@/modules/cobrox/pages/processes/InteresesPage/InteresImporteForm";
import InteresTasaForm from "@/modules/cobrox/pages/processes/InteresesPage/InteresTasaForm";

export default {
  name: "InteresesPage",
  components: {InteresTasaForm, InteresImporteForm, InteresFechaForm},
  mixins: [DayjsMixin],
  data() {
    return {
      loading: false,
      intereses: [],
      fechasMap: new Map(),
      importesMap: [],
      fechaMenu: false,
      importeMenu: false
    }
  },
  computed: {
    unixToDayjs() {
      return unix => {
        return Dayjs(parseInt(unix))
      }
    },
    getDateFormatCompress() {
      return (date) => {
        if (!date)
          return null

        if (Dayjs.isDayjs(date)) {
          return this.getDateStart(date)
        }

        if (/(\d{4})-(\d{2})-(\d{2})/.test(date)) {
          return this.getDateStart(Dayjs(date))
        }

        return this.getDateStart(Dayjs(parseInt(date)).tz())
      }
    },

    getFechas() {
      let fechas =  this.intereses.reduce((a, i) => {
        if (!a.some(v => v.fechaDesde === i.fechaDesde && v.fechaHasta === i.fechaHasta)) {
          a.push({fechaDesde: i.fechaDesde, fechaHasta: i.fechaHasta})
        }
        return a
      }, [])

      fechas.sort((a,b) => (a.fechaDesde > b.fechaDesde) ? 1 : -1)
      return fechas
    },
    getImportes() {
      let importes =  this.intereses.reduce((a, i) => {
        if (!a.some(v => v.importeDesde === i.importeDesde && v.importeHasta === i.importeHasta)) {
          a.push({importeDesde: i.importeDesde, importeHasta: i.importeHasta})
        }
        return a
      }, [])
      importes.sort((a,b) => (a.importeDesde > b.importeDesde) ? 1 : -1)
      return importes
    },
    getTasa() {
      return (importe, fecha) => {

        const findTasa = i => {
          if (i.fechaDesde === fecha.fechaDesde &&
              i.fechaHasta === fecha.fechaHasta &&
              i.importeDesde === importe.importeDesde &&
              i.importeHasta === importe.importeHasta) {
            return true
          }
          return false
        }

        let item = this.intereses.find(findTasa)


        return item
      }
    },
    tasaIndexacion:{
      get(){
        return this.$store.getters.getSettingValue('tasaIndexacion')
      },
      set(val){
        this.$store.dispatch('updateSettingValueByKey',{key:'tasaIndexacion',value: val})
      }
    }
  },
  created() {
    this.$store.dispatch('loadSettings')
    this.fetchInteres()
  }
  ,
  methods: {
    onUpdate(intereses) {
      this.fechaMenu = false
      this.importeMenu = false
      this.intereses = intereses
    },
    getDateStart(dateDayjs) {
      if (dateDayjs.isSame(dateDayjs.startOf('year')))
        return dateDayjs.format("YYYY")

      if (dateDayjs.isSame(dateDayjs.startOf('month')))
        return dateDayjs.format("YYYY/MM")

      return dateDayjs.format("YYYY/MM/DD")

    },
    fetchInteres() {
      this.loading = true
      InteresProvider.fetchInteres()
          .then(r => {
            this.intereses = r.data.fetchInteres
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
