<template>
  <v-container fluid>
    <add-button @click="() => $router.push({name: 'RedmineIssueCreatePage'})"></add-button>
    <redmine-issues-list :status="$route.params.status"></redmine-issues-list>

  </v-container>
</template>

<script>
import {AddButton} from "@dracul/common-frontend";
import RedmineIssuesList from "@/modules/redmine/components/RedmineIssuesList/RedmineIssuesList";

export default {
  name: "RedmineIssuesListPage",
  components: {RedmineIssuesList, AddButton},
  props: {

  },

}
</script>

<style scoped>

</style>
