<template>
  <v-container>
    <v-row align="center" justify="center">

      <v-col cols="12" sm="8" md="8" class="text-center">
        <v-card>

          <v-card-title>
            Importar Compra de Deudas

            <v-btn text absolute right link small href="/files/CompraDeudasEjemplo.csv" target="_blank">
              <v-icon left>download</v-icon>
              CSV de ejemplo
            </v-btn>
          </v-card-title>


          <v-card-text>
            <file-upload-express
                @fileUploaded="onFileUploaded"
                auto-submit
            ></file-upload-express>


            <template v-if="!file">
              <h4 class="subtitle-1">Subir archivo .csv</h4>
              <p class="text-left text-decoration-underline">Mapeo de campos</p>

              <v-simple-table class="text-left">
                <thead>
                <tr>
                  <th>Campo CSV</th>
                  <th>Entidad</th>
                  <th>Campo Entidad</th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(field,i) in fields" :key="i">
                  <td>{{ field.text }} <span v-if="field.mandatory" class="red--text">*</span></td>
                  <td>{{ field.entity }}</td>
                  <td>{{ field.property }}</td>

                </tr>
                </tbody>

              </v-simple-table>
              <v-alert dense type="warning">Si el csv contiene registros con <b>ID DEUDA</b> existentes en la base de
                datos solo se actualizara el campo <b>ESTADO</b>. Si el estado es ({{ getEstadoDeudaCancelaSaldos }}) el
                saldoCapitalCompra se pondra en $0
              </v-alert>

            </template>
          </v-card-text>


          <v-card-text v-if="file && !jobId">
            <v-alert type="info">
              Archivo subido exitosamente. Generando tarea de importación en segundo plano.
            </v-alert>
            <v-progress-circular indeterminate></v-progress-circular>
          </v-card-text>

          <v-card-text v-if="jobId">
            <v-alert type="success">
              Tarea de importación en segundo plano generada con éxito.<br> Id: {{ jobId }}
            </v-alert>
          </v-card-text>

          <v-card-actions v-if="jobId">
            <v-spacer></v-spacer>
            <v-btn
                :to="{name:'JobPage',params:{id: jobId}}"
                color="indigo" dark
            >
              Supervisar tarea
            </v-btn>
          </v-card-actions>

        </v-card>

      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import {FileUploadExpress} from '@dracul/media-frontend'
import JobProvider from "@/modules/cobrox/providers/JobProvider";
import EstadoDeudaProvider from "@/modules/cobrox/providers/EstadoDeudaProvider";

export default {
  name: "CompraDeudaImport",
  components: {FileUploadExpress},
  data() {
    return {
      file: null,
      jobId: null,
      estadosDeuda: [],
      jurisdicciones: ['CABA', 'Buenos Aires', 'Catamarca', 'Chaco', 'Chubut', 'Córdoba', 'Corrientes', 'Entre Ríos', 'Formosa', 'Jujuy', 'La Pampa', 'La Rioja', 'Neuquén', 'Mendoza', 'Misiones', 'Río NegroSalta', 'San Juan', 'San Luis', 'Santa Cruz', 'Santa Fe', 'Santiago del Estero', 'Tierra del Fuego', 'Tucumán'],
      fields: [
        {text: 'ID DEUDA', mandatory: true, entity: 'Deuda', property: 'idDeuda'},
        {text: 'ESTADO', mandatory: true, entity: 'Deuda', property: 'estado'},
        {text: 'FECHA DE COMPRA', mandatory: true, entity: 'Compra', property: 'fechaCompra'},
        {text: 'PORCENTAJE DE COMPRA', mandatory: true, entity: 'Compra', property: 'porcentajeCompra'},

        {text: 'PRODUCTO', mandatory: true, entity: 'Deuda', property: 'producto'},
        {text: 'N° PRODUCTO/CUENTA', mandatory: true, entity: 'Deuda', property: 'nroProducto'},
        {text: 'DETALLE/DESCRIPCION DEL PRODUCTO', mandatory: true, entity: 'Deuda', property: 'detalleProducto'},
        {text: 'FECHA OTORGAMIENTO PRODUCTO', mandatory: false, entity: 'Deuda', property: 'fechaOtorgamiento'},
        {text: 'FECHA MORA', mandatory: true, entity: 'Deuda', property: 'fechaMora'},
        {text: 'CAPITAL', mandatory: true, entity: 'Deuda', property: 'capitalTotal'},
        {text: 'PRECIO DE COMPRA', mandatory: false, entity: 'Deuda', property: 'precioCompra'},
        {text: 'CAPITAL COMPRA', mandatory: true, entity: 'Deuda', property: 'capitalCompra'},
        {text: 'JURISDICCION', mandatory: true, entity: 'Deuda', property: 'jurisdiccion'},


        {text: 'NOMBRE DEUDOR', mandatory: true, entity: 'Persona', property: 'nombreCompleto'},
        {text: 'DNI', mandatory: true, entity: 'Persona', property: 'tipoDocumento'},
        {text: 'Nº DNI', mandatory: true, entity: 'Persona', property: 'nroDocumento'},
        {text: 'ID RAPIPAGO', mandatory: false, entity: 'Persona', property: 'idRapipago'},
        {text: 'CUIL', mandatory: false, entity: 'Persona', property: 'cuil'},
        {text: 'SEXO', mandatory: false, entity: 'Persona', property: 'sexo'},
        {text: 'FECHA NAC', mandatory: false, entity: 'Persona', property: 'fechaNacimiento'},
        {text: 'DIRECCION', mandatory: false, entity: 'Persona.domicilios', property: 'calle'},
        {text: 'Nº', mandatory: false, entity: 'Persona.domicilios', property: 'numero'},
        {text: 'PISO', mandatory: false, entity: 'Persona.domicilios', property: 'piso'},
        {text: 'DEPTO', mandatory: false, entity: 'Persona.domicilios', property: 'depto'},
        {text: 'LOCALIDAD', mandatory: false, entity: 'Persona.domicilios', property: 'localidad'},
        {text: 'PROVINCIA', mandatory: false, entity: 'Persona.domicilios', property: 'provincia'},
        {text: 'CP', mandatory: false, entity: 'Persona.domicilios', property: 'codigoPostal'},
        {text: 'CELULAR', emandatory: false, ntity: 'Persona.telefonos', property: 'telefono'},
        {text: 'CELULAR 2', mandatory: false, entity: 'Persona.telefonos', property: 'telefono'},
        {text: 'FIJO', mandatory: false, entity: 'Persona.telefonos', property: 'telefono'},
        {text: 'CORREO ELECTRONICO', mandatory: false, entity: 'Persona.emails', property: 'mail'},
        {text: 'TEL LABORAL', mandatory: false, entity: 'Persona.telefonos', property: 'telefono'},
        {text: 'ALTERNATIVO', mandatory: false, entity: 'Persona.telefonos', property: 'telefono'},
        {text: 'ALTERNATIVO 2', mandatory: false, entity: 'Persona.telefonos', property: 'telefono'},
        {text: 'SITUACION LABORAL', mandatory: false, entity: 'Persona.situacionLaboral', property: 'situacionLaboral'},

        {text: 'CBU', mandatory: false, entity: 'Persona/Deuda', property: 'cbu'},

        {text: 'AGENCIA', mandatory: true, entity: 'Persona/Deuda', property: 'agencia'},
      ]
    }
  },
  created() {
    this.fetchEstadoDeuda()
  },
  computed: {
    getJurisdicciones() {
      return this.jurisdicciones.join(" | ")
    },
    getEstadoDeuda() {
      return this.estadosDeuda.map(e => e.nombre).join(" | ")
    },
    getEstadoDeudaCancelaSaldos() {
      return this.estadosDeuda.filter(e => e.cancelaSaldos).map(e => e.nombre).join(" | ")
    }
  },
  methods: {
    fetchEstadoDeuda() {
      EstadoDeudaProvider.fetchEstadoDeuda()
          .then(r => {
            this.estadosDeuda = r.data.fetchEstadoDeuda
          })
    },
    onFileUploaded(file) {
      this.file = file
      this.createJob(this.file.id)
    },
    createJob(fileId) {
      JobProvider.importarCompraDeuda(fileId)
          .then(res => {
            this.jobId = res.data.importarCompraDeuda
          })
    }
  }
}
</script>

<style scoped>

</style>
