<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">
    <v-row>

      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="badge"
            name="nombre"
            v-model="form.nombre"
            :label="$t('cobrox.estadoDeuda.labels.nombre')"
            :placeholder="$t('cobrox.estadoDeuda.labels.nombre')"
            :error="hasInputErrors('nombre')"
            :error-messages="getInputErrors('nombre')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-checkbox
            prepend-icon="power_settings_new"
            name="inactiva"
            v-model="form.inactiva"
            :label="$t('cobrox.estadoDeuda.labels.inactiva')"
            :error="hasInputErrors('inactiva')"
            :error-messages="getInputErrors('inactiva')"
            color="secondary"

        ></v-checkbox>
      </v-col>


      <v-col cols="12" sm="6">
        <v-checkbox
            prepend-icon="highlight_off"
            name="cancelaSaldos"
            v-model="form.cancelaSaldos"
            :label="$t('cobrox.estadoDeuda.labels.cancelaSaldos')"
            :error="hasInputErrors('cancelaSaldos')"
            :error-messages="getInputErrors('cancelaSaldos')"
            color="secondary"

        ></v-checkbox>
      </v-col>


      <v-col cols="12" sm="6">
        <v-checkbox
            prepend-icon="history"
            name="cancelaSaldosHistorico"
            v-model="form.cancelaSaldosHistorico"
            :label="$t('cobrox.estadoDeuda.labels.cancelaSaldosHistorico')"
            :error="hasInputErrors('cancelaSaldosHistorico')"
            :error-messages="getInputErrors('cancelaSaldosHistorico')"
            color="secondary"

        ></v-checkbox>
      </v-col>



      <v-col cols="12" sm="6">
        <v-checkbox
            prepend-icon="account_balance"
            name="informarBCRA"
            v-model="form.informarBCRA"
            :label="$t('cobrox.estadoDeuda.labels.informarBCRA')"
            :error="hasInputErrors('informarBCRA')"
            :error-messages="getInputErrors('informarBCRA')"
            color="secondary"

        ></v-checkbox>
      </v-col>

      <v-col cols="12" sm="6">
        <v-checkbox
            prepend-icon="business"
            name="rotacionBloqueada"
            v-model="form.rotacionBloqueada"
            :label="$t('cobrox.estadoDeuda.labels.rotacionBloqueada')"
            :error="hasInputErrors('rotacionBloqueada')"
            :error-messages="getInputErrors('rotacionBloqueada')"
            color="secondary"

        ></v-checkbox>
      </v-col>

      <v-col cols="12" sm="6">
        <v-checkbox
            prepend-icon="sync_disabled"
            name="noRota"
            v-model="form.noRota"
            :label="$t('cobrox.estadoDeuda.labels.noRota')"
            :error="hasInputErrors('noRota')"
            :error-messages="getInputErrors('noRota')"
            color="secondary"

        ></v-checkbox>
      </v-col>

    </v-row>
  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


export default {
  name: "EstadoDeudaForm",
  mixins: [InputErrorsByProps, RequiredRule],

  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>

