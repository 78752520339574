var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('agencia-combobox',{attrs:{"input-errors":_vm.inputErrors,"clearable":""},model:{value:(_vm.form.agencia),callback:function ($$v) {_vm.$set(_vm.form, "agencia", $$v)},expression:"form.agencia"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('compra-combobox',{attrs:{"input-errors":_vm.inputErrors,"clearable":""},model:{value:(_vm.form.compra),callback:function ($$v) {_vm.$set(_vm.form, "compra", $$v)},expression:"form.compra"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('form-list',{attrs:{"new-item":{
        agencia: _vm.form.agencia,
        compra: _vm.form.compra,
        ratioDesde: null,
        ratioHasta:null,
        comision: null
      }},scopedSlots:_vm._u([{key:"default",fn:function({item,index}){return [_c('ratio-form',{key:index,attrs:{"agencia":_vm.form.agencia,"compra":_vm.form.compra},model:{value:(_vm.form.multi[index]),callback:function ($$v) {_vm.$set(_vm.form.multi, index, $$v)},expression:"form.multi[index]"}})]}}]),model:{value:(_vm.form.multi),callback:function ($$v) {_vm.$set(_vm.form, "multi", $$v)},expression:"form.multi"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }