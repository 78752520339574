import { render, staticRenderFns } from "./ResultadoSumarizado.vue?vue&type=template&id=c42d3b64&scoped=true"
import script from "./ResultadoSumarizado.vue?vue&type=script&lang=js"
export * from "./ResultadoSumarizado.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c42d3b64",
  null
  
)

export default component.exports