import graphqlClient from "../../../apollo";

class JobProvider {

    crearPersonaDesdeDeuda() {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobCrearPersonaDesdeDeuda.graphql')
        })
    }

    reprocesarImputacionCobranzas() {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobReprocesarImputacionCobranzas.graphql')
        })
    }

    bcraReporte(filters) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobBcraReporte.graphql'),
            variables: {filters}
        })
    }

    revertJob(jobId) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobRevertJob.graphql'),
            variables: {jobId}
        })
    }

    importarAgencia(fileId) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobImportarAgencia.graphql'),
            variables: {fileId}
        })
    }


    importarPersona(fileId) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobImportarPersona.graphql'),
            variables: {fileId}
        })
    }

    rotarAgenciaPersona(fileId) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobRotarAgenciaPersona.graphql'),
            variables: {fileId}
        })
    }

    bloquearAgenciaPersona(fileId) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobBloquearAgenciaPersona.graphql'),
            variables: {fileId}
        })
    }

    importarCompraDeuda(fileId) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobImportarCompraDeuda.graphql'),
            variables: {fileId}
        })
    }

    importarDeuda(fileId) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobImportarDeuda.graphql'),
            variables: {fileId}
        })
    }

    exportarDeuda(fecha = null, filters = null, search = null) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobExportarDeuda.graphql'),
            variables: {fecha, filters, search}
        })
    }

    exportarConvenio( filters = null, search = null) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobExportarConvenio.graphql'),
            variables: { filters, search}
        })
    }

    exportarDeudaAgencia( filters = null, search = null) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobExportarDeudaAgencia.graphql'),
            variables: {filters, search}
        })
    }


    exportarCobranza(filters, search, agencyFormat = false) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobExportarCobranza.graphql'),
            variables: {filters, search, agencyFormat}
        })
    }

    exportarCobranzaDeuda(filters, search) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobExportarCobranzaDeuda.graphql'),
            variables: {filters, search}
        })
    }

    exportarDeudaPorDni(fileId){
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobExportarDeudaPorDni.graphql'),
            variables: {fileId}
        })
    }

    importarCobranza(fileId) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobImportarCobranza.graphql'),
            variables: {fileId}
        })
    }

    importarCobranzaRapipago(fileId) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobImportarCobranzaRapipago.graphql'),
            variables: {fileId}
        })
    }
    distribuirMasivo(porcentajeDistribucion, simular, netear, sumarizar) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobDistribuirMasivo.graphql'),
            variables: {porcentajeDistribucion, simular, netear, sumarizar}
        })
    }

    incrementarIntereses() {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobIncrementarIntereses.graphql'),
        })
    }

    rotacionAsistida(filters, distribucion, incluirRotacionBloqueada) {
        return graphqlClient.mutate({
            mutation: require('./gql/Jobs/jobRotacionAsistida.graphql'),
            variables: {filters, distribucion, incluirRotacionBloqueada}
        })
    }
}

export default new JobProvider()


