
const messages = {
    en: {
       role: {
            permissions: {
                CAJAMENSUAL_MENU: "Access to CajaMensual",
                CAJAMENSUAL_SHOW: "View CajaMensual",
                CAJAMENSUAL_CREATE: "Create CajaMensual",
                CAJAMENSUAL_UPDATE: "Modify CajaMensual",
                CAJAMENSUAL_DELETE: "Delete CajaMensual",
            }
       }
    },
    es: {
        role: {
            permissions: {
                CAJAMENSUAL_MENU: "Acceder CajaMensual",
                CAJAMENSUAL_SHOW: "Ver CajaMensual",
                CAJAMENSUAL_CREATE: "Crear CajaMensual",
                CAJAMENSUAL_UPDATE: "Modificar CajaMensual",
                CAJAMENSUAL_DELETE: "Eliminar CajaMensual",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                CAJAMENSUAL_MENU: "Acessar CajaMensual",
                CAJAMENSUAL_SHOW: "Ver CajaMensual",
                CAJAMENSUAL_CREATE: "Criar CajaMensual",
                CAJAMENSUAL_UPDATE: "Modificar CajaMensual",
                CAJAMENSUAL_DELETE: "Eliminar CajaMensual",
            }
       }
    }
}

export default messages
    