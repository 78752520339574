<template>
  <div>
    <v-textarea
        v-model="comentario"
        label="Agregar Nota"
        rows="3"
    ></v-textarea>
    <submit-button text="common.add" :loading="loading" @click="addNota"></submit-button>
  </div>
</template>

<script>
import PersonaProvider from "@/modules/cobrox/providers/PersonaProvider";
import { SubmitButton} from "@dracul/common-frontend"
export default {
  name: "AddNotaPersona",
  components: {SubmitButton},
  props: {
    personaId: {type: String, required: true}
  },
  data() {
    return {
      loading: false,
      comentario: ''
    }
  },
  methods: {
    addNota() {
      this.loading = true
      PersonaProvider.addNotaToPersona(this.personaId, {comentario: this.comentario})
          .then(r => {
            this.$emit('notaAdded', r.data.addNotaToPersona)
            this.comentario = ''
          }).finally(()=>this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
