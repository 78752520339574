<template>
    <crud-show :title="title" :open="open"  @close="$emit('close')">

        <v-card-text>
            <convenio-card :convenio="item" />
        </v-card-text>

  </crud-show>
</template>

<script>
    import {CrudShow} from '@dracul/common-frontend'
    import ConvenioCard from "@/modules/cobrox/components/ConvenioCard/ConvenioCard.vue";

    export default {
        name: "ConvenioShow",
        components: {ConvenioCard, CrudShow},
        props: {
            open: {type: Boolean, default: true},
            item: {type: Object, required: true}
        },
        data() {
            return {
                title: 'cobrox.convenio.showing',
            }
        },
    }
</script>

