<template>
  <v-row justify="center" align="center">
    <v-col cols="12" class="py-0 text-center">
      <v-divider></v-divider>
      <span class=" grey--text subtitle-2">CobranzaID: {{cobranza.id}}</span>

    </v-col>
    <v-col cols="12" sm="4" md="3">
      <show-field :value="getDateFormat(cobranza.fechaCobranza)" :label="$t('cobrox.cobranza.labels.fechaCobranza')"
                  icon="fingerprint"/>
    </v-col>

    <v-col cols="12" sm="4" md="2">
      <show-field :value="cobranza.dniDeudor" :label="$t('cobrox.cobranza.labels.dniDeudor')"
                  icon="fingerprint"/>
    </v-col>

    <v-col cols="12" sm="4" md="4">
      <show-field :value="cobranza.nombreDeudor" :label="$t('cobrox.cobranza.labels.nombreDeudor')" icon="person"/>
    </v-col>



    <v-col cols="6" sm="4" md="3">
      <v-card elevation="4" color="red darken-3" dark>
        <v-card-title class="justify-center text-h5">
          ${{ cobranza.montoCobrado }}
        </v-card-title>
        <v-card-subtitle class="text-center">Monto</v-card-subtitle>
      </v-card>
    </v-col>


  </v-row>
</template>

<script>
import {ShowField} from "@dracul/common-frontend"
import {DayjsMixin} from "@dracul/dayjs-frontend";

export default {
  name: "CobranzaView",
  components: {ShowField},
  mixins: [DayjsMixin],
  props: {
    cobranza: Object
  }
}
</script>

<style scoped>

</style>
