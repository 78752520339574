import merge from 'deepmerge'
import PersonaMessages from './messages/PersonaMessages'
import DeudaMessages from './messages/DeudaMessages'
import CobranzaMessages from './messages/CobranzaMessages'
import FacturaMessages from './messages/FacturaMessages'
import CanalCobroMessages from './messages/CanalCobroMessages'
import CuentaMessages from './messages/CuentaMessages'
import EstadoDeudaMessages from './messages/EstadoDeudaMessages'
import AgenciaMessages from './messages/AgenciaMessages'
import OrigenDeudaMessages from './messages/OrigenDeudaMessages'
import CompraMessages from './messages/CompraMessages'
import InteresMessages from './messages/InteresMessages'
import ProductoMessages from './messages/ProductoMessages'
import ReporteStockMessages from './messages/ReporteStockMessages'
import RetencionMessages from './messages/RetencionMessages'
import SituacionLaboralMessages from './messages/SituacionLaboralMessages'
import CajaMensualMessages from './messages/CajaMensualMessages'
import ComisionMessages from './messages/ComisionMessages'
import ManualMessages from './messages/ManualMessages'
import PedidoCuentaMessages from './messages/PedidoCuentaMessages'
import QuitaMessages from './messages/QuitaMessages'
import ConvenioMessages from './messages/ConvenioMessages'
import AutogestionLogMessages from './messages/AutogestionLogMessages'

import PersonaPermissionMessages from './permissions/PersonaPermissionMessages'
import DeudaPermissionMessages from './permissions/DeudaPermissionMessages'
import CobranzaPermissionMessages from './permissions/CobranzaPermissionMessages'
import FacturaPermissionMessages from './permissions/FacturaPermissionMessages'
import CanalCobroPermissionMessages from './permissions/CanalCobroPermissionMessages'
import CuentaPermissionMessages from './permissions/CuentaPermissionMessages'
import EstadoDeudaPermissionMessages from './permissions/EstadoDeudaPermissionMessages'
import AgenciaPermissionMessages from './permissions/AgenciaPermissionMessages'
import OrigenDeudaPermissionMessages from './permissions/OrigenDeudaPermissionMessages'
import CompraPermissionMessages from './permissions/CompraPermissionMessages'
import ExtraPermissionMessages from './permissions/ExtraPermissionMessages'
import InteresPermissionMessages from './permissions/InteresPermissionMessages'
import ProductoPermissionMessages from './permissions/ProductoPermissionMessages'
import ReporteStockPermissionMessages from './permissions/ReporteStockPermissionMessages'
import RetencionPermissionMessages from './permissions/RetencionPermissionMessages'
import SituacionLaboralPermissionMessages from './permissions/SituacionLaboralPermissionMessages'
import CajaMensualPermissionMessages from './permissions/CajaMensualPermissionMessages'
import ComisionPermissionMessages from './permissions/ComisionPermissionMessages'
import ManualPermissionMessages from './permissions/ManualPermissionMessages'
import PedidoCuentaPermissionMessages from './permissions/PedidoCuentaPermissionMessages'
import QuitaPermissionMessages from './permissions/QuitaPermissionMessages'
import ConvenioPermissionMessages from './permissions/ConvenioPermissionMessages'
import AutogestionLogPermissionMessages from './permissions/AutogestionLogPermissionMessages'


const messages = merge.all([
    PersonaMessages,
    DeudaMessages,
    CobranzaMessages,
    FacturaMessages,
    CanalCobroMessages,
    CuentaMessages,
    EstadoDeudaMessages,
    AgenciaMessages,
    OrigenDeudaMessages,
    CompraMessages,
    InteresMessages,
    ProductoMessages,
    ReporteStockMessages,
    RetencionMessages,
    SituacionLaboralMessages,
    CajaMensualMessages,
    ComisionMessages,
    ManualMessages,
    PedidoCuentaMessages,
    QuitaMessages,
    ConvenioMessages,
    AutogestionLogMessages,

    PersonaPermissionMessages,
    DeudaPermissionMessages,
    CobranzaPermissionMessages,
    FacturaPermissionMessages,
    CanalCobroPermissionMessages,
    CuentaPermissionMessages,
    EstadoDeudaPermissionMessages,
    AgenciaPermissionMessages,
    OrigenDeudaPermissionMessages,
    CompraPermissionMessages,
    ExtraPermissionMessages,
    InteresPermissionMessages,
    ProductoPermissionMessages,
    ReporteStockPermissionMessages,
    RetencionPermissionMessages,
    SituacionLaboralPermissionMessages,
    CajaMensualPermissionMessages,
    ComisionPermissionMessages,
    ManualPermissionMessages,
    PedidoCuentaPermissionMessages,
    QuitaPermissionMessages,
    ConvenioPermissionMessages,
    AutogestionLogPermissionMessages

])

export default messages;
