<template>
  <deuda-import></deuda-import>
</template>

<script>
import DeudaImport from "@/modules/cobrox/components/DeudaImport/DeudaImport";

export default {
  name: "DeudaImportPage",
  components: {DeudaImport}
}
</script>

<style scoped>

</style>
