import { render, staticRenderFns } from "./ComisionMultiCreate.vue?vue&type=template&id=61d3272a&scoped=true"
import script from "./ComisionMultiCreate.vue?vue&type=script&lang=js"
export * from "./ComisionMultiCreate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61d3272a",
  null
  
)

export default component.exports