<template>
<crud-layout :title="title" :subtitle="subtitle">

        <template v-slot:list>
            <caja-mensual-list
                       ref="list"
                       @update="update"
                       @delete="remove"
                       @show="show"

            />
        </template>

         <add-button v-if="false && $store.getters.hasPermission('CAJAMENSUAL_CREATE')" @click="create"></add-button>

        <caja-mensual-create v-if="creating"
                        :open="creating"
                        v-on:itemCreated="onItemCreated"
                        v-on:close="creating=false"
        />

        <caja-mensual-update v-if="updating"
                        :open="updating"
                        :item="itemToEdit"
                        v-on:itemUpdated="onItemUpdated"
                        v-on:close="updating=false"
        />

        <caja-mensual-show v-if="showing"
                           :open="showing"
                           :item="itemToShow"
                           v-on:close="showing=false"
         />

        <caja-mensual-delete v-if="deleting"
                         :open="deleting"
                         :item="itemToDelete"
                         v-on:itemDeleted="onItemDeleted"
                         v-on:close="deleting=false"
        />

        <snackbar v-model="flash"/>

</crud-layout>
</template>

<script>

    import CajaMensualCreate from "../CajaMensualCreate";
    import CajaMensualUpdate from "../CajaMensualUpdate";
    import CajaMensualDelete from "../CajaMensualDelete";
    import CajaMensualShow from "../CajaMensualShow";
    import CajaMensualList from "../CajaMensualList";

     import {CrudLayout, AddButton, Snackbar} from "@dracul/common-frontend"

    export default {
        name: "CajaMensualCrud",
        components: {
            CrudLayout, AddButton, Snackbar,
            CajaMensualCreate,
            CajaMensualUpdate,
            CajaMensualDelete,
            CajaMensualShow,
            CajaMensualList
        },
        data() {
            return {
                title: 'cobrox.cajaMensual.title',
                subtitle: 'cobrox.cajaMensual.subtitle',
                flash: null,
                creating: false,
                updating: false,
                deleting: false,
                showing: false,
                itemToEdit: null,
                itemToDelete: null,
                itemToShow: null,
            }
        },
        methods: {
            //On
            onItemCreated() {
                this.$refs.list.fetch()
                this.flash= this.$t("common.created")
            },
            onItemUpdated() {
                this.$refs.list.fetch()
                this.flash= this.$t("common.updated")
            },
            onItemDeleted() {
                this.$refs.list.fetch()
                this.flash= this.$t("common.deleted")
            },
            //Open
            create() {
                this.creating = true
            },
            update(item) {
                this.updating = true
                this.itemToEdit = item
            },
            show(item) {
                this.showing = true
                this.itemToShow = item
            },
            remove(item) {
                this.deleting = true
                this.itemToDelete = item
            }
        }

    }
</script>


