
const messages = {
    en: {
       role: {
            permissions: {
                RETENCION_MENU: "Access to Retencion",
                RETENCION_SHOW: "View Retencion",
                RETENCION_CREATE: "Create Retencion",
                RETENCION_UPDATE: "Modify Retencion",
                RETENCION_DELETE: "Delete Retencion",
            }
       }
    },
    es: {
        role: {
            permissions: {
                RETENCION_MENU: "Acceder Retencion",
                RETENCION_SHOW: "Ver Retencion",
                RETENCION_CREATE: "Crear Retencion",
                RETENCION_UPDATE: "Modificar Retencion",
                RETENCION_DELETE: "Eliminar Retencion",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                RETENCION_MENU: "Acessar Retencion",
                RETENCION_SHOW: "Ver Retencion",
                RETENCION_CREATE: "Criar Retencion",
                RETENCION_UPDATE: "Modificar Retencion",
                RETENCION_DELETE: "Eliminar Retencion",
            }
       }
    }
}

export default messages
    