import RestoreSystemProvider from "@/modules/cobrox/providers/RestoreSystemProvider";

export default {
    state: {
        hitorialDistribuciones: [],
        restoreSystem: false
    },
    getters: {
        getHitorialDistribuciones: (state) => {
            return state.hitorialDistribuciones
        },
        isRestoreSystemEnable: (state) => {
            return state.restoreSystem
        }
    },
    actions: {
        loadRestoreSystem({commit}){
            RestoreSystemProvider.restoreSystemEnable()
                .then(r =>{
                    commit("setRestoreSystem",r.data.restoreSystemEnable)
                })
        }
    },
    mutations: {
        addHitorialDistribuciones(state,val){
            state.hitorialDistribuciones.push(val)
        },
        cleanHitorialDistribuciones(state){
            state.hitorialDistribuciones = []
        },
        setRestoreSystem(state,val){
            state.restoreSystem = val
        }

    }
}
