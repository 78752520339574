<template>
  <crud-update :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @update="update"
               @close="$emit('close')"
               fullscreen
  >
    <persona-form ref="form" v-model="form" :input-errors="inputErrors"/>
  </crud-update>
</template>

<script>

import PersonaProvider from "../../../../providers/PersonaProvider";

import {CrudUpdate, ClientError} from '@dracul/common-frontend'

import PersonaForm from "../PersonaForm";


export default {
  name: "PersonaUpdate",

  components: {PersonaForm, CrudUpdate},

  props: {
    open: {type: Boolean, default: true},
    item: {type: Object, required: true}
  },

  data() {
    return {
      title: 'cobrox.persona.editing',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      id: this.item.id,
      form: {
        nombreCompleto: this.item.nombreCompleto,
        tipoDocumento: this.item.tipoDocumento,
        nroDocumento: this.item.nroDocumento,
        cuil: this.item.cuil,
        sexo: this.item.sexo ? this.item.sexo : null,
        nacionalidad: this.item.nacionalidad,
        fechaNacimiento: this.item.fechaNacimiento,
        telefonos:  this.item.telefonos || [],
        emails:  this.item.emails || [],
        domicilios:  this.item.domicilios || [],
        cbus:  this.item.cbus || [],
        documentos:  this.item.documentos || [],
        agencia: this.item.agencia ? this.item.agencia.id : null,
        idRapipago: this.item.idRapipago,
        estado: this.item.estado,
        rotacionBloqueada: this.item.rotacionBloqueada,
        situacionLaboral: this.item.situacionLaboral ? this.item.situacionLaboral.id : null,
        jurisdiccion:  this.item.jurisdiccion,
        idClienteColppy:  this.item.idClienteColppy
      }
    }
  },
  methods: {
    update() {
      if (this.$refs.form.validate()) {
        this.loading = true

        PersonaProvider.updatePersona(this.id, this.form).then(r => {

                this.$emit('itemUpdated', r.data.updatePersona)
                this.$emit('close')
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }else{
        console.log("No Validate")
        console.log(this.$refs.form.validate())
      }

    }
  },
}
</script>

<style scoped>

</style>

