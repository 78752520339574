<template>
        <v-autocomplete
                prepend-icon="summarize"
                :items="items"
                :item-text="'createdAt'"
                :item-value="itemValue"
                v-model="item"
                :label="$t('cobrox.reporteStock.menu')"
                :loading="loading"
                :error="hasInputErrors('reporteStock')"
                :error-messages="getInputErrors('reporteStock')"
                color="secondary"
                item-color="secondary"
                :rules="isRequired ? required : []"
                :multiple="multiple"
                :chips="chips"
                :solo="solo"
                :disabled="disabled"
                :readonly="readonly"
                :clearable="clearable"
                :hide-details="hideDetails"
                :style="{width: width, maxWidth: width}"
                :return-object="returnObject"
        >
          <template v-slot:item="{item}">
            {{getDateFormat(item.createdAt)}} - {{item.description}}
          </template>
          <template v-slot:selection="{item}">
            {{getDateFormat(item.createdAt)}} - {{item.description}}
          </template>
        </v-autocomplete>

</template>

<script>

    import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


    import ReporteStockProvider from "../../providers/ReporteStockProvider"
    import {DayjsMixin} from "@dracul/dayjs-frontend";

    export default {
        name: "ReporteStockCombobox",
        mixins: [InputErrorsByProps, RequiredRule, DayjsMixin],
        props:{
            value: {type: [String, Array]},
            multiple: {type:Boolean, default: false },
            solo: {type:Boolean, default: false},
            chips: {type:Boolean, default: false},
            readonly: {type:Boolean, default:false},
            disabled: {type:Boolean, default: false},
            isRequired: {type:Boolean, default: false },
            clearable: {type:Boolean, default: false},
            hideDetails: {type: Boolean, default: false},
            returnObject: {type: Boolean, default: false},
            itemValue: {type: String, default: 'id'},
            width: {type: String, default: null},
        },
        data() {
            return {
                items: [],
                loading: false
            }
        },
        computed: {
           item: {
                get() { return this.value },
                set(val) {this.$emit('input', val)}
            }
        },
        mounted() {
         this.fetch()
        },
        methods: {
            validate(){
              return this.$refs.form.validate()
            },
            fetch(){
               this.loading= true
                ReporteStockProvider.fetchReporteStock().then(r => {
                    this.items = r.data.fetchReporteStock
                }).catch(err => console.error(err))
                .finally(()=> this.loading = false)
            }

        }
    }
</script>

<style scoped>

</style>
