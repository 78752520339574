<template>

  <v-select
      prepend-icon="fact_check"
      :items="items"
      v-model="item"
      :label="$t('cobrox.factura.labels.tipoComprobante')"
      :error="hasInputErrors('tipoComprobante')"
      :error-messages="getInputErrors('tipoComprobante')"
      color="secondary"
      item-color="secondary"
      @change="$emit('change')"
      clearable
      :hide-details="hideDetails"

  ></v-select>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


export default {
  name: "TipoComprobanteCombobox",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {
      type: [String, Array]
    },
    hideDetails: {type:Boolean,default:false}
  },
  data() {
    return {
      items: ['FVC','NCV'],
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },

}
</script>

<style scoped>

</style>

