
const messages = {
    en: {
       cobrox: {
          convenio: {
            name: 'Convenio',
            title: 'Convenio management',
            subtitle: 'View, search, create, edit and delete Convenio',
            creating: 'Creating Convenio',
            editing: 'Editing Convenio',
            deleting: 'Deleting Convenio',
            showing: 'Showing Convenio',
            menu: 'Convenio',
            labels: {
                vigente: 'Vigente',
                dniDeudor: 'DNI Deudor',
                cuilDeudor: 'Cuil Deudor',
                cuotas: 'Cuotas',
                montoTotal: 'Monto total',
                fechaPrimerPago: 'Primer Pago',
                fueraDePauta: 'Fuera de Pauta',
                deudas: 'Deudas',
                cobranzas: 'Cobranzas',
                montoCuota: 'Valor cuota',
                origen:'Origen',
                cuota:'Cuota',
                monto:'Monto',
                canalPago:'Canal Pago',
                verificado: 'Verificado',
                fechaPago: 'Fecha Pago',
            },

          }
       }
    },
    es: {
       cobrox: {
          convenio: {
            name: 'Convenio',
            title: 'Administración de Convenio',
            subtitle: 'Ver, buscar, crear, editar, y borrar Convenio',
            creating: 'Creando Convenio',
            editing: 'Modificando Convenio',
            deleting: 'Eliminando Convenio',
            showing: 'Detalles de Convenio',
            menu: 'Convenio',
            labels: {
                vigente: 'Vigente',
                dniDeudor: 'DNI Deudor',
                cuilDeudor: 'Cuil Deudor',
                cuotas: 'Cuotas',
                montoTotal: 'Monto total',
                fechaPrimerPago: 'Primer Pago',
                fueraDePauta: 'Fuera de Pauta',
                deudas: 'Deudas',
                cobranzas: 'Cobranzas',
                montoCuota: 'Valor cuota',
                origen:'Origen',
                cuota:'Cuota',
                monto:'Monto',
                canalPago:'Canal Pago',
                verificado: 'Verificado',
                fechaPago: 'Fecha Pago',
            },

          }
       }
    },
    pt: {
       cobrox: {
          convenio: {
            name: 'Convenio',
            title: 'Administração de Convenio',
            subtitle: 'Ver, buscar, criar, editar e usar Convenio',
            creating: 'Criando Convenio',
            editing: 'Edição Convenio',
            deleting: 'Apagando Convenio',
            showing: 'Detalhes do Convenio',
            menu: 'Convenio',
            labels: {
                vigente: 'Vigente',
                dniDeudor: 'DNI Deudor',
                cuilDeudor: 'Cuil Deudor',
                cuotas: 'Cuotas',
                montoTotal: 'Monto total',
                fechaPrimerPago: 'Primer Pago',
                fueraDePauta: 'Fuera de Pauta',
                deudas: 'Deudas',
                cobranzas: 'Cobranzas',
                montoCuota: 'Valor cuota',
                origen:'Origen',
                cuota:'Cuota',
                monto:'Monto',
                canalPago:'Canal Pago',
                verificado: 'Verificado',
                fechaPago: 'Fecha Pago',
            },

          }
       }
    }
}

export default messages
