export default {
    computed:{
        hasInterno(){
            return this.$store.getters.hasPermission('INTERNO')
        },
        hasContabilidad(){
            return this.$store.getters.hasPermission('CONTABILIDAD')
        }
    }
}
