<template>
  <v-row>
    <v-col cols="12"><h5 class="text-h5">Notas</h5></v-col>
    <v-col cols="12" v-for="(nota,index) in notas" :key="index">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6" sm="4" md="3">
              <v-list-item>
                <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp">
                  <v-avatar>
                    <img :src="nota.usuario.avatarurl ? nota.usuario.avatarurl : require('@/assets/user.png')" />
                  </v-avatar>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title> {{nota.usuario.username}}</v-list-item-title>
                  <v-list-item-subtitle>{{getDateTimeFormat(nota.fecha)}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6" sm="8" md="9" style="border-left:1px solid grey">
              <p>{{nota.comentario}}</p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

    </v-col>
  </v-row>
</template>

<script>
import {DayjsMixin} from "@dracul/dayjs-frontend"
export default {
  name: "PersonaNotasView",
  mixins: [DayjsMixin],
  props: {
    notas: Array
  }
}
</script>

<style scoped>

</style>
