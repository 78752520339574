import { render, staticRenderFns } from "./ServerStatusPage.vue?vue&type=template&id=c45767ce&scoped=true"
import script from "./ServerStatusPage.vue?vue&type=script&lang=js"
export * from "./ServerStatusPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c45767ce",
  null
  
)

export default component.exports