<template>
  <cobranza-import></cobranza-import>
</template>

<script>
import CobranzaImport from "@/modules/cobrox/components/CobranzaImport";

export default {
  name: "CobranzaImportPage",
  components: { CobranzaImport}
}
</script>

<style scoped>

</style>
