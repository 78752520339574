<template>
  <v-container class="text-left" v-if="distribucion">


    <v-card>
      <v-card-title>Distribución proporcional deudas del sujeto</v-card-title>
      <v-card-subtitle>Monto: {{ distribucion.montoDeudor }} ({{ 100 - distribucion.porcentajeDistribucion }}%)
      </v-card-subtitle>
      <v-card-text>
        <facturas-table-with-deuda
            v-if="distribucion.facturasDeudor"
            :facturas="distribucion.facturasDeudor"
        ></facturas-table-with-deuda>
      </v-card-text>
    </v-card>

    <v-card class="mt-3">
      <v-card-title>Distribución adicional</v-card-title>
      <v-card-subtitle>Monto: {{ distribucion.montoPorDistribuir }} ({{ distribucion.porcentajeDistribucion }}%)
      </v-card-subtitle>
      <v-card-text>

        <facturas-table-with-deuda
            v-if="distribucion.facturasDistribuidas"
            :facturas="distribucion.facturasDistribuidas"
        ></facturas-table-with-deuda>

      </v-card-text>

    </v-card>

  </v-container>

</template>

<script>
import FacturasTableWithDeuda from "@/modules/cobrox/components/FacturasTableWithDeuda";

export default {
  name: "DistribucionFacturas",
  components: {FacturasTableWithDeuda},
  props: {
    distribucion: Object
  }
}
</script>

<style scoped>

</style>
