import KinvaTemplatePage from "../pages/KinvaTemplatePage";

const routes = [


    {
        name: 'KinvaTemplatePage', path: '/kinva/template/:identifier', component: KinvaTemplatePage,
        meta: {
            requiresAuth: true,
        }
    },
]

export default routes
