
const messages = {
    en: {
       role: {
            permissions: {
                CANALCOBRO_MENU: "Access to CanalCobro",
                CANALCOBRO_SHOW: "View CanalCobro",
                CANALCOBRO_CREATE: "Create CanalCobro",
                CANALCOBRO_UPDATE: "Modify CanalCobro",
                CANALCOBRO_DELETE: "Delete CanalCobro",
            }
       }
    },
    es: {
        role: {
            permissions: {
                CANALCOBRO_MENU: "Acceder CanalCobro",
                CANALCOBRO_SHOW: "Ver CanalCobro",
                CANALCOBRO_CREATE: "Crear CanalCobro",
                CANALCOBRO_UPDATE: "Modificar CanalCobro",
                CANALCOBRO_DELETE: "Eliminar CanalCobro",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                CANALCOBRO_MENU: "Acessar CanalCobro",
                CANALCOBRO_SHOW: "Ver CanalCobro",
                CANALCOBRO_CREATE: "Criar CanalCobro",
                CANALCOBRO_UPDATE: "Modificar CanalCobro",
                CANALCOBRO_DELETE: "Eliminar CanalCobro",
            }
       }
    }
}

export default messages
    