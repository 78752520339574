<template>
    <v-row>
        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="getDateFormat(item.moraDesde)" :label="$t('cobrox.quita.labels.moraDesde')" icon="event"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="getDateFormat(item.moraHasta)" :label="$t('cobrox.quita.labels.moraHasta')" icon="event"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                
            </v-list>
        </v-col>

    </v-row>
</template>
<script>
    import {ShowField} from '@dracul/common-frontend'
    import {DayjsMixin} from "@dracul/dayjs-frontend"; 
    
    export default {
        name: 'QuitaShowData',
        components: {ShowField},
        mixins: [DayjsMixin],
        props: {
            item: {type: Object, required: true}
        }
    }
</script>

