<template>

  <v-select
      prepend-icon="fact_check"
      :items="items"
      v-model="item"
      :label="$t('cobrox.deuda.labels.estado')"
      :loading="loading"
      :error="hasInputErrors('estado')"
      :error-messages="getInputErrors('estado')"
      color="secondary"
      item-color="secondary"
      :clearable="clearable"
      :multiple="multiple"
      :item-value="itemValue"
      :hide-details="hideDetails"
      :readonly="readonly"
  ></v-select>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'
import EstadoDeudaProvider from "@/modules/cobrox/providers/EstadoDeudaProvider";


export default {
  name: "EstadoDeudaCombobox",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {type: [String, Array]},
    clearable: {type:Boolean, default: false},
    multiple: {type:Boolean, default: false},
    itemValue: {type:String, default: 'id'},
    hideDetails: {type:Boolean, default: false},
    readonly: {type:Boolean, default: false},
  },
  data() {
    return {
      items: [],
      loading: false
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    fetch() {
      this.loading = true
      EstadoDeudaProvider.fetchEstadoDeuda()
          .then(r => {
            this.items = r.data.fetchEstadoDeuda.map(e => e.nombre)
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>

