
const messages = {
    en: {
       role: {
            permissions: {
                SITUACIONLABORAL_MENU: "Access to SituacionLaboral",
                SITUACIONLABORAL_SHOW: "View SituacionLaboral",
                SITUACIONLABORAL_CREATE: "Create SituacionLaboral",
                SITUACIONLABORAL_UPDATE: "Modify SituacionLaboral",
                SITUACIONLABORAL_DELETE: "Delete SituacionLaboral",
            }
       }
    },
    es: {
        role: {
            permissions: {
                SITUACIONLABORAL_MENU: "Acceder SituacionLaboral",
                SITUACIONLABORAL_SHOW: "Ver SituacionLaboral",
                SITUACIONLABORAL_CREATE: "Crear SituacionLaboral",
                SITUACIONLABORAL_UPDATE: "Modificar SituacionLaboral",
                SITUACIONLABORAL_DELETE: "Eliminar SituacionLaboral",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                SITUACIONLABORAL_MENU: "Acessar SituacionLaboral",
                SITUACIONLABORAL_SHOW: "Ver SituacionLaboral",
                SITUACIONLABORAL_CREATE: "Criar SituacionLaboral",
                SITUACIONLABORAL_UPDATE: "Modificar SituacionLaboral",
                SITUACIONLABORAL_DELETE: "Eliminar SituacionLaboral",
            }
       }
    }
}

export default messages
    