<template>
  <v-container>
    <v-card>
      <v-card-title>Revertir Job</v-card-title>

      <v-card-text class="">
        <p>Los jobs que se pueden revertir son:</p>
        <ul>
          <li>importarCobranza</li>
          <li>importarCobranzaRapipago</li>
          <li>ImportarDeuda</li>
          <li>ImportarPersona</li>
          <li>ImportarCompraDeuda</li>
        </ul>
      </v-card-text>

      <v-card-text>
        <v-alert type="info">Solo se pueden revertir la creación de deudas, personas y cobranzas nuevas. No es posible revertir actualizaciones de campos.</v-alert>
      </v-card-text>

      <v-card-text class="text-center">
        <v-text-field v-model="jobId" label="JobId" @input="fetchJob">
        </v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="!isValidJob" :loading="loading" class="green white--text" target="_blank" @click="revertJob">
          Revertir
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-container>
</template>

<script>
import JobProvider from "@/modules/cobrox/providers/JobProvider";
import {QueueProvider} from "@dracul/queue-frontend"

export default {
  name: "RevertJob",
  data() {
    return {
      jobId: null,
      loading: false,
      job: null,
      revertedJob: null
    }
  },
  computed: {
    isValidJob() {
      if (this.job && ['importarCobranza','importarCobranzaRapipago','ImportarDeuda','ImportarPersona','ImportarCompraDeuda'].includes(this.job.topic)) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    revertJob() {
      if (this.jobId) {
        this.loading = true
        JobProvider.revertJob(this.jobId)
            .then(r => {
              this.revertedJob = r.data.revertJob
              this.$router.push({name: 'JobPage', params: {id: this.revertedJob}})
            })
            .finally(() => this.loading = false)
      }

    },
    fetchJob() {
      if (this.jobId) {
        QueueProvider.findQueue(this.jobId)
            .then(r => {
              this.job = r.data.queueFind
            })

      }
    }
  }
}
</script>

<style scoped>

</style>
