
const messages = {
    en: {
       cobrox: {
          deuda: {
            name: 'Deuda',
            title: 'Deuda management',
            subtitle: 'View, search, create, edit and delete Deuda',
            creating: 'Creating Deuda',
            editing: 'Editing Deuda',
            deleting: 'Deleting Deuda',
            showing: 'Showing Deuda',
            menu: 'Deuda',
            labels: {
                idDeuda: 'ID Deuda',
                cartera: 'Cartera',
                fechaCompra: 'Buy Date',
                fechaMora: 'Fecha de mora',
                nombreDeudor: 'Nombre Deudor',
                dniDeudor: 'DNI Deudor',
                cuilDeudor: 'CUIL Deudor',
                capitalCompra: 'Captital Compra',
                capitalTotal: 'Capital Total',
                intereses: 'Intereses',
                facturas: 'Facturas',
                saldoCapitalCompra: 'Saldo capital compra',
                saldoCapitalCompraInicial: 'Capital compra inicial',
                imputadoContable: 'Imputado Contable',
                imputadoCobranzas: 'Imputado',
                estado: 'Estado',
                jurisdiccion: 'Jurisdicción',
                idClienteColppy: 'idClienteColppy',
                saldoDeuda: 'Saldo',
                total: "Total",
                agencia: "Agencia",
                origen: 'Origen',
                compra: 'Compra',
                cbu: 'CBU',
                producto:  'Producto',
                detalleProducto:  'Detalle Producto',
                nroProducto:  'Nro Producto',
                fechaOtorgamiento:  'Fecha Otorgamiento',
                fechaUltimoEstado:  'Fecha Ultimo Estado',
                precioCompra:  'Precio Compra',
                deuda: 'Deuda',
                saldo: 'Saldo',
                interesBloqueado: 'Interes Bloqueado',
                debitable: 'Debitable',
                situacionJudicial: 'Situación Judicial',
            },

          }
       }
    },
    es: {
       cobrox: {
          deuda: {
            name: 'Deuda',
            title: 'Administración de Deuda',
            subtitle: 'Ver, buscar, crear, editar, y borrar Deuda',
            creating: 'Creando Deuda',
            editing: 'Modificando Deuda',
            deleting: 'Eliminando Deuda',
            showing: 'Detalles de Deuda',
            menu: 'Deuda',
            labels: {
                idDeuda: 'ID Deuda',
                cartera: 'Cartera',
                fechaCompra: 'Fecha de compra',
                fechaMora: 'Fecha de mora',
                nombreDeudor: 'Nombre Deudor',
                dniDeudor: 'DNI Deudor',
                cuilDeudor: 'CUIL Deudor',
                capitalCompra: 'Captital Compra',
                capitalTotal: 'Capital Total',
                intereses: 'Intereses',
                facturas: 'Facturas',
                saldoCapitalCompra: 'Saldo capital compra',
                saldoCapitalCompraInicial: 'Capital compra inicial',
                imputadoContable: 'Imputado Contable',
                imputadoCobranzas: 'Imputado',
                estado: 'Estado',
                jurisdiccion: 'Jurisdicción',
                idClienteColppy: 'idClienteColppy',
                saldoDeuda: 'Saldo',
                total: "Total",
                agencia: "Agencia",
                origen: 'Origen',
                compra: 'Compra',
                cbu: 'CBU',
                producto:  'Producto',
                detalleProducto:  'Detalle Producto',
                nroProducto:  'Nro Producto',
                fechaOtorgamiento:  'Fecha Otorgamiento',
                fechaUltimoEstado:  'Fecha Ultimo Estado',
                precioCompra:  'Precio Compra',
                deuda: 'Deuda',
                saldo: 'Saldo',
                interesBloqueado: 'Interes Bloqueado',
                debitable: 'Debitable',
                situacionJudicial: 'Situación Judicial',
            },

          }
       }
    },
    pt: {
       cobrox: {
          deuda: {
            name: 'Deuda',
            title: 'Administração de Deuda',
            subtitle: 'Ver, buscar, criar, editar e usar Deuda',
            creating: 'Criando Deuda',
            editing: 'Edição Deuda',
            deleting: 'Apagando Deuda',
            showing: 'Detalhes do Deuda',
            menu: 'Deuda',
            labels: {
                idDeuda: 'ID Deuda',
                cartera: 'Cartera',
                fechaCompra: 'Fecha de compra',
                fechaMora: 'Fecha de mora',
                nombreDeudor: 'Nombre Deudor',
                dniDeudor: 'DNI Deudor',
                cuilDeudor: 'CUIL Deudor',
                capitalCompra: 'Captital Compra',
                capitalTotal: 'Capital Total',
                intereses: 'Intereses',
                facturas: 'Facturas',
                saldoCapitalCompra: 'Saldo capital compra',
                saldoCapitalCompraInicial: 'Capital compra inicial',
                imputadoContable: 'Imputado Contable',
                imputadoCobranzas: 'Imputado',
                estado: 'Estado',
                jurisdiccion: 'Jurisdicción',
                idClienteColppy: 'idClienteColppy',
                saldoDeuda: 'Saldo',
                total: "Total",
                agencia: "Agencia",
                origen: 'Origen',
                compra: 'Compra',
                cbu: 'CBU',
                producto:  'Producto',
                detalleProducto:  'Detalle Producto',
                nroProducto:  'Nro Producto',
                fechaOtorgamiento:  'Fecha Otorgamiento',
                fechaUltimoEstado:  'Fecha Ultimo Estado',
                precioCompra:  'Precio Compra',
                deuda: 'Deuda',
                saldo: 'Saldo',
                interesBloqueado: 'Interes Bloqueado',
                debitable: 'Debitable',
                situacionJudicial: 'Situación Judicial',
            },

          }
       }
    }
}

export default messages
