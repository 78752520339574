const messages = {
    en: {
        role: {
            permissions: {
                COBRANZA_MENU: "Access to Cobranza",
                COBRANZA_SHOW: "View Cobranza",
                COBRANZA_CREATE: "Create Cobranza",
                COBRANZA_UPDATE: "Modify Cobranza",
                COBRANZA_DELETE: "Delete Cobranza",
                COBRANZA_ESTADO: "Estado de Cobranzas",
                COBRANZA_EXPORT: "Exportar Cobranzas",
                COBRANZA_EXPORT_IMPUTATION: "Exportar Cobranzas imputadas",
                COBRANZA_EXPORT_AGENCIA: "Exportar Cobranzas Agencia",
                COBRANZA_FILTRO_FECHA_INGRESO: "Ver filtro por fecha de ingreso de Cobranza"
            }
        }
    },
    es: {
        role: {
            permissions: {
                COBRANZA_MENU: "Acceder Cobranza",
                COBRANZA_SHOW: "Ver Cobranza",
                COBRANZA_CREATE: "Crear Cobranza",
                COBRANZA_UPDATE: "Modificar Cobranza",
                COBRANZA_DELETE: "Eliminar Cobranza",
                COBRANZA_ESTADO: "Estado de Cobranzas",
                COBRANZA_EXPORT: "Exportar Cobranzas",
                COBRANZA_EXPORT_IMPUTATION: "Exportar Cobranzas imputadas",
                COBRANZA_EXPORT_AGENCIA: "Exportar Cobranzas Agencia",
                COBRANZA_FILTRO_FECHA_INGRESO: "Ver filtro por fecha de ingreso de Cobranza"
            }
        }
    },
    pt: {
        role: {
            permissions: {
                COBRANZA_MENU: "Acessar Cobranza",
                COBRANZA_SHOW: "Ver Cobranza",
                COBRANZA_CREATE: "Criar Cobranza",
                COBRANZA_UPDATE: "Modificar Cobranza",
                COBRANZA_DELETE: "Eliminar Cobranza",
                COBRANZA_ESTADO: "Estado de Cobranzas",
                COBRANZA_EXPORT: "Exportar Cobranzas",
                COBRANZA_EXPORT_IMPUTATION: "Exportar Cobranzas imputadas",
                COBRANZA_EXPORT_AGENCIA: "Exportar Cobranzas Agencia",
                COBRANZA_FILTRO_FECHA_INGRESO: "Ver filtro por fecha de ingreso de Cobranza"
            }
        }
    }
}

export default messages
