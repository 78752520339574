<template>
  <v-data-table
      :items="documentos"
      :headers="headers"
      :items-per-page="10"
      hide-default-footer
  >
    <template v-slot:item.link="{item}">
      <v-btn small color="secondary OnSecondary--text" :href="item.link" target="_blank" download>Descargar</v-btn>
    </template>

  </v-data-table>
</template>

<script>
export default {
  name: "PersonaDocumentosView",
  props: {
    documentos: Array
  },
  computed: {
    headers(){
      return [
        {text: this.$t('cobrox.persona.labels.nombre'), value: 'nombre', align:'center'},
        {text: this.$t('cobrox.persona.labels.link'), value: 'link', align:'center'},
      ]
    }
  }
}
</script>

<style scoped>

</style>
