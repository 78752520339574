<template>
  <crud-create :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @create="create"
               @close="$emit('close')"
  >
    <compra-form ref="form" v-model="form" :input-errors="inputErrors"/>
  </crud-create>
</template>

<script>

import CompraProvider from "../../../../providers/CompraProvider";

import {CrudCreate, ClientError} from '@dracul/common-frontend'

import CompraForm from "../CompraForm";


export default {
  name: "CompraCreate",

  components: {CompraForm, CrudCreate},

  props: {
    open: {type: Boolean, default: true}
  },

  data() {
    return {
      title: 'cobrox.compra.creating',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      form: {
        nombre: '',
        nombreLegal: '',
        fechaCompra: null,
        porcentajeCompra: null,
        cuit: '',
        direccionLegal: '',
        orden: null,
        debitable: false
      }
    }
  },

  methods: {
    create() {
      if (this.$refs.form.validate()) {
        this.loading = true
        CompraProvider.createCompra(this.form).then(r => {
              if (r) {
                this.$emit('itemCreated', r.data.createCompra)
                this.$emit('close')
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }

  },
}
</script>

<style scoped>

</style>

