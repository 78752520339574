<template>
  <v-menu
      v-model="menu"
      offset-x :close-on-content-click="false" nudge-bottom="20"
  >
    <template v-slot:activator="{on}">
      <v-btn text icon v-on="on" :small="isEditing">
        <v-icon>{{ isEditing ? 'edit' : 'add' }}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-form ref="form" autocomplete="off" @submit.prevent="submit">
          <v-row>

            <v-col v-if="errorMessage" cols="12">
              <v-alert type="error">{{ errorMessage }}</v-alert>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field

                  prepend-icon="attach_money"
                  name="importeDesde"
                  v-model.number="form.importeDesde"
                  type="number"
                  :label="$t('cobrox.interes.labels.importeDesde')"
                  :placeholder="$t('cobrox.interes.labels.importeDesde')"
                  :error="hasInputErrors('importeDesde')"
                  :error-messages="getInputErrors('importeDesde')"
                  color="secondary"
                  :rules="required"
              ></v-text-field>
            </v-col>


            <v-col cols="12" sm="6">
              <v-text-field

                  prepend-icon="attach_money"
                  name="importeHasta"
                  v-model.number="form.importeHasta"
                  type="number"
                  :label="$t('cobrox.interes.labels.importeHasta')"
                  :placeholder="$t('cobrox.interes.labels.importeHasta')"
                  :error="hasInputErrors('importeHasta')"
                  :error-messages="getInputErrors('importeHasta')"
                  color="secondary"
                  :rules="required"
              ></v-text-field>
            </v-col>


            <v-col cols="12" class="text-right">
              <v-btn class="red darken-2 white--text" @click="remove">Eliminar</v-btn>
              <submit-button @click="submit"></submit-button>
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>

import {InputErrorsByProps, RequiredRule, SubmitButton} from '@dracul/common-frontend'

import {DayjsMixin} from "@dracul/dayjs-frontend";
import InteresProvider from "@/modules/cobrox/providers/InteresProvider";


export default {
  name: "InteresImporteForm",
  data() {
    return {
      menu: false,
      errorMessage: null,
      form: {
        importeDesde: this.currentImporteDesde,
        importeHasta: this.currentImporteHasta
      },
      importeDesdeDateMenu: false,
      importeHastaDateMenu: false
    }
  },
  props: {
    isEditing: {type:Boolean, default: false},
    currentImporteDesde: {type: Number},
    currentImporteHasta: {type: Number},
  },
  mixins: [InputErrorsByProps, RequiredRule, DayjsMixin],
  components: { SubmitButton},
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    submit() {
      this.errorMessage = null
      if (this.isEditing) {
        this.update()
      } else {
        this.create()
      }
    },
    create() {
      InteresProvider.createInteresByImporte(this.form)
          .then(r => {
            this.$emit("updated", r.data.createInteresByImporte)
            this.menu = false
          })
          .catch(e => {
            this.errorMessage = e.message.replace('GraphQL error:','')
          })
    },
    update() {
      InteresProvider.updateInteresByImporte({
        currentImporteDesde: this.currentImporteDesde,
        currentImporteHasta: this.currentImporteHasta,
        newImporteDesde: this.form.importeDesde,
        newImporteHasta: this.form.importeHasta
      })
          .then(r => {
            this.$emit("updated", r.data.updateInteresByImporte)
            this.menu = false
          })
          .catch(e => {
            this.errorMessage = e.message.replace('GraphQL error:','')
          })
    },
    remove() {
      InteresProvider.deleteInteresByImporte({importeDesde: this.currentImporteDesde, importeHasta: this.currentImporteHasta})
          .then(r => {
            this.$emit("updated", r.data.deleteInteresByImporte)
            this.menu = false
          })
          .catch(e => {
            this.errorMessage = e.message.replace('GraphQL error:','')
          })
    },
  },

}
</script>

<style scoped>

</style>

