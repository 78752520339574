<template>
  <v-container fluid>

    <v-toolbar>
      <v-toolbar-title>AGENCIAS</v-toolbar-title>
    </v-toolbar>

    <v-row class="mt-2">
      <v-col cols="12" sm="12">
        <v-card>
          <dashboard-bar-chart
              v-if="cobradoPorAgenciaMesActual.length > 0 && cobradoPorAgenciaMesPasadoParcial.length > 0 && cobradoPorAgenciaMesPasadoTotal.length > 0"
              :chart-data="getAgenciaChartData"
          ></dashboard-bar-chart>
        </v-card>

      </v-col>

      <v-col cols="12" sm="4">
        <v-card>
          <v-card-title>Mes en curso</v-card-title>
          <dashboard-table :items="cobradoPorAgenciaMesActual" :headers="getHeadersAgencia"></dashboard-table>
        </v-card>

      </v-col>
      <v-col cols="12" sm="4">
        <v-card>
          <v-card-title>Mes pasado parcial</v-card-title>
          <dashboard-table :items="cobradoPorAgenciaMesPasadoParcial" :headers="getHeadersAgencia"></dashboard-table>

        </v-card>

      </v-col>
      <v-col cols="12" sm="4">
        <v-card>
          <v-card-title>Mes pasado completo</v-card-title>
          <dashboard-table :items="cobradoPorAgenciaMesPasadoTotal" :headers="getHeadersAgencia"></dashboard-table>
        </v-card>

      </v-col>
    </v-row>


    <!--    COMPRA-->
    <v-toolbar class="mt-6">
      <v-toolbar-title>COMPRAS</v-toolbar-title>
    </v-toolbar>

    <v-row class="mt-2">

      <v-col cols="12" sm="12">
        <v-card>
          <dashboard-bar-chart
              v-if="cobradoPorCompraMesActual.length > 0 && cobradoPorCompraMesPasadoParcial.length > 0 && cobradoPorCompraMesPasadoTotal.length > 0"
              :chart-data="getCompraChartData"
          ></dashboard-bar-chart>
        </v-card>

      </v-col>

      <v-col cols="12" sm="4">
        <v-card>
          <v-card-title>Mes en curso</v-card-title>
          <dashboard-table :items="cobradoPorCompraMesActual" :headers="getHeadersCompra"></dashboard-table>
        </v-card>

      </v-col>
      <v-col cols="12" sm="4">
        <v-card>
          <v-card-title>Mes pasado parcial</v-card-title>
          <dashboard-table :items="cobradoPorCompraMesPasadoParcial" :headers="getHeadersCompra"></dashboard-table>

        </v-card>

      </v-col>
      <v-col cols="12" sm="4">
        <v-card>
          <v-card-title>Mes pasado completo</v-card-title>
          <dashboard-table :items="cobradoPorCompraMesPasadoTotal" :headers="getHeadersCompra"></dashboard-table>
        </v-card>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {DayjsMixin, Dayjs} from "@dracul/dayjs-frontend";
import DashboardComparativoProvider from "@/modules/cobrox/providers/DashboardComparativoProvider";
import DashboardTable from "@/modules/cobrox/pages/dashboards/DashboardComparativoPage/DashboardTable";
import DashboardBarChart from "@/modules/cobrox/pages/dashboards/DashboardComparativoPage/DashboardBarChart";

export default {
  name: "DashboardComparativoPage",
  components: {DashboardBarChart, DashboardTable},
  mixins: [DayjsMixin],
  data() {
    return {
      cobradoPorAgenciaMesActual: [],
      cobradoPorAgenciaMesPasadoParcial: [],
      cobradoPorAgenciaMesPasadoTotal: [],

      agencias: [],

      cobradoPorCompraMesActual: [],
      cobradoPorCompraMesPasadoParcial: [],
      cobradoPorCompraMesPasadoTotal: [],
      compras: [],
    }
  },
  computed: {

    getHeadersAgencia() {
      return [{text: 'Agencia', value: 'agencia'}, {text: 'Cobrado', value: 'cobrado'}]
    },
    getHeadersCompra() {
      return [{text: 'Compra', value: 'compra'}, {text: 'Cobrado', value: 'cobrado'}]
    },
    getAgenciaChartData() {
      let data = []
      let counter = 0

      this.cobradoPorAgenciaMesActual.forEach(a => {
        counter++
        if (counter < 15) {
          let nombre = a.agencia
          let cobradoActual = a.cobrado
          let p = this.cobradoPorAgenciaMesPasadoParcial.find(i => i.agencia === nombre)
          let cobradoPasado = p ? p.cobrado : 0

          let pt = this.cobradoPorAgenciaMesPasadoTotal.find(i => i.agencia === nombre)
          let cobradoPasadoTotal = pt ? pt.cobrado : 0

          data.push({
            nombre,
            cobradoActual,
            cobradoPasado,
            cobradoPasadoTotal
          })
        }

      })


      let chartData = {
        labels: data.map(i => i.nombre),
        datasets: [
          {label: 'Mes en curso', data: data.map(i => i.cobradoActual), backgroundColor: 'blue'},
          {label: 'Mes pasado parcial', data: data.map(i => i.cobradoPasado), backgroundColor: 'teal'},
          {label: 'Mes pasado deudaTotal', data: data.map(i => i.cobradoPasadoTotal), backgroundColor: 'indigo'},
        ]
      }

      return chartData
    },
    getCompraChartData() {
      let data = []
      let counter = 0
      this.cobradoPorCompraMesActual.forEach(a => {
        counter++
        if (counter < 15) {
          let nombre = a.compra
          let cobradoActual = a.cobrado
          let p = this.cobradoPorCompraMesPasadoParcial.find(i => i.compra === nombre)
          let cobradoPasado = p ? p.cobrado : 0

          let pt = this.cobradoPorCompraMesPasadoTotal.find(i => i.compra === nombre)
          let cobradoPasadoTotal = pt ? pt.cobrado : 0

          data.push({
            nombre,
            cobradoActual,
            cobradoPasado,
            cobradoPasadoTotal
          })
        }
      })

      let chartData = {
        labels: data.map(i => i.nombre),
        datasets: [
          {label: 'Mes en curso', data: data.map(i => i.cobradoActual), backgroundColor: 'blue'},
          {label: 'Mes pasado parcial', data: data.map(i => i.cobradoPasado), backgroundColor: 'teal'},
          {label: 'Mes pasado deudaTotal', data: data.map(i => i.cobradoPasadoTotal), backgroundColor: 'indigo'},
        ]
      }

      return chartData

    }
  },
  created() {
    this.fetchAll()
  },
  methods: {
    async fetchAll() {
      await this.fetchCobradoPorAgenciaMesActual()
      await this.fetchCobradoPorAgenciaMesPasadoParcial()
      await this.fetchCobradoPorAgenciaMesPasadoTotal()
      this.fillAgencias()
      this.sortAgenciaArrays()
      this.totalAgencias()


      await this.fetchCobradoPorCompraMesActual()
      await this.fetchCobradoPorCompraMesPasadoParcial()
      await this.fetchCobradoPorCompraMesPasadoTotal()
      this.fillCompras()
      this.sortCompraArrays()
      this.totalCompras()
    },
    totalAgencias() {
      let total = 0
      total = this.cobradoPorAgenciaMesActual.reduce((a,v) => a+v.cobrado,0)
      this.cobradoPorAgenciaMesActual.push({agencia: 'TOTAL', cobrado: total})

      total = this.cobradoPorAgenciaMesPasadoParcial.reduce((a,v) => a+v.cobrado,0)
      this.cobradoPorAgenciaMesPasadoParcial.push({agencia: 'TOTAL', cobrado: total})

      total = this.cobradoPorAgenciaMesPasadoTotal.reduce((a,v) => a+v.cobrado,0)
      this.cobradoPorAgenciaMesPasadoTotal.push({agencia: 'TOTAL', cobrado: total})
    },
    totalCompras() {
      let total = 0
      total = this.cobradoPorCompraMesActual.reduce((a,v) => a+v.cobrado,0)
      this.cobradoPorCompraMesActual.push({compra: 'TOTAL', cobrado: total})

      total = this.cobradoPorCompraMesPasadoParcial.reduce((a,v) => a+v.cobrado,0)
      this.cobradoPorCompraMesPasadoParcial.push({compra: 'TOTAL', cobrado: total})

      total = this.cobradoPorCompraMesPasadoTotal.reduce((a,v) => a+v.cobrado,0)
      this.cobradoPorCompraMesPasadoTotal.push({compra: 'TOTAL', cobrado: total})
    },
    fillAgencias() {
      this.agencias.forEach(agencia => {
        if(!this.cobradoPorAgenciaMesActual.some(i => i.agencia === agencia)){
          this.cobradoPorAgenciaMesActual.push({agencia: agencia, cobrado: 0})
        }

        if(!this.cobradoPorAgenciaMesPasadoParcial.some(i => i.agencia === agencia)){
          this.cobradoPorAgenciaMesPasadoParcial.push({agencia: agencia, cobrado: 0})
        }

        if(!this.cobradoPorAgenciaMesPasadoTotal.some(i => i.agencia === agencia)){
          this.cobradoPorAgenciaMesPasadoTotal.push({agencia: agencia, cobrado: 0})
        }

      })
    },
    fillCompras() {
      this.compras.forEach(compra => {
        if(!this.cobradoPorCompraMesActual.some(i => i.compra === compra)){
          this.cobradoPorCompraMesActual.push({compra: compra, cobrado: 0})
        }

        if(!this.cobradoPorCompraMesPasadoParcial.some(i => i.compra === compra)){
          this.cobradoPorCompraMesPasadoParcial.push({compra: compra, cobrado: 0})
        }

        if(!this.cobradoPorCompraMesPasadoTotal.some(i => i.compra === compra)){
          this.cobradoPorCompraMesPasadoTotal.push({compra: compra, cobrado: 0})
        }

      })
    },
    sortAgenciaArrays() {
      this.cobradoPorAgenciaMesActual.sort((a, b) => {
        return this.agencias.indexOf(a.agencia) - this.agencias.indexOf(b.agencia);
      });

      this.cobradoPorAgenciaMesPasadoParcial.sort((a, b) =>{
        return this.agencias.indexOf(a.agencia) - this.agencias.indexOf(b.agencia);
      });

      this.cobradoPorAgenciaMesPasadoTotal.sort((a, b) => {
        return this.agencias.indexOf(a.agencia) - this.agencias.indexOf(b.agencia);
      });
    },
    sortCompraArrays() {
      this.cobradoPorCompraMesActual.sort( (a, b) => {
        return this.compras.indexOf(a.compra) - this.compras.indexOf(b.compra);
      });

      this.cobradoPorCompraMesPasadoParcial.sort((a, b) => {
        return this.compras.indexOf(a.compra) - this.compras.indexOf(b.compra);
      });

      this.cobradoPorCompraMesPasadoTotal.sort((a, b) =>{
        return this.compras.indexOf(a.compra) - this.compras.indexOf(b.compra);
      });


    },
    async fetchCobradoPorAgenciaMesActual() {
      return new Promise((resolve, reject) => {
        DashboardComparativoProvider.cobradoPorAgencia(Dayjs().startOf('month'), Dayjs())
            .then(r => {
              this.cobradoPorAgenciaMesActual = r.data.cobradoPorAgencia
              for (let item of this.cobradoPorAgenciaMesActual) {
                this.pushAgencia(item.agencia)
              }
              resolve()
            })
            .catch(e => reject(e))

      })

    },
    fetchCobradoPorAgenciaMesPasadoParcial() {
      return new Promise((resolve, reject) => {
        DashboardComparativoProvider.cobradoPorAgencia(Dayjs().startOf('month').subtract(1, 'month'), Dayjs().subtract(1, 'month'))
            .then(r => {
              this.cobradoPorAgenciaMesPasadoParcial = r.data.cobradoPorAgencia
              for (let item of this.cobradoPorAgenciaMesPasadoParcial) {
                this.pushAgencia(item.agencia)
              }
              resolve()
            })
            .catch(e => reject(e))

      })
    },
    fetchCobradoPorAgenciaMesPasadoTotal() {
      return new Promise((resolve, reject) => {
        DashboardComparativoProvider.cobradoPorAgencia(Dayjs().startOf('month').subtract(1, 'month'), Dayjs().startOf('month'))
            .then(r => {
              this.cobradoPorAgenciaMesPasadoTotal = r.data.cobradoPorAgencia
              for (let item of this.cobradoPorAgenciaMesPasadoTotal) {
                this.pushAgencia(item.agencia)
              }
              resolve()
            })
            .catch(e => reject(e))

      })
    },
    pushAgencia(nombreAgencia) {
      if (!this.agencias.includes(nombreAgencia)) {
        this.agencias.push(nombreAgencia)
      }
    },
    pushCompra(nombreCompra) {
      if (!this.compras.includes(nombreCompra)) {
        this.compras.push(nombreCompra)
      }
    },
    fetchCobradoPorCompraMesActual() {
      return new Promise((resolve, reject) => {
        DashboardComparativoProvider.cobradoPorCompra(Dayjs().startOf('month'), Dayjs())
            .then(r => {
              this.cobradoPorCompraMesActual = r.data.cobradoPorCompra
              for (let item of this.cobradoPorCompraMesActual) {
                this.pushCompra(item.compra)
              }
              resolve()
            })
            .catch(e => reject(e))

      })
    },
    fetchCobradoPorCompraMesPasadoParcial() {
      return new Promise((resolve, reject) => {
        DashboardComparativoProvider.cobradoPorCompra(Dayjs().startOf('month').subtract(1, 'month'), Dayjs().subtract(1, 'month'))
            .then(r => {
              this.cobradoPorCompraMesPasadoParcial = r.data.cobradoPorCompra
              for (let item of this.cobradoPorCompraMesPasadoParcial) {
                this.pushCompra(item.compra)
              }
              resolve()
            })
            .catch(e => reject(e))

      })
    },
    fetchCobradoPorCompraMesPasadoTotal() {
      return new Promise((resolve, reject) => {
        DashboardComparativoProvider.cobradoPorCompra(Dayjs().startOf('month').subtract(1, 'month'), Dayjs().startOf('month'))
            .then(r => {
              this.cobradoPorCompraMesPasadoTotal = r.data.cobradoPorCompra
              for (let item of this.cobradoPorCompraMesPasadoTotal) {
                this.pushCompra(item.compra)
              }
              resolve()
            })
            .catch(e => reject(e))

      })
    }
  }
}
</script>

<style scoped>

</style>
