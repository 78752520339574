<template>

  <v-text-field
      v-if="isTextField || (isDate && readonly)"
      v-model="localValue"
      :label="name"
      :rules="rules"
      :readonly="readonly"
  />

  <v-text-field
      v-else-if="isTextFieldNumber"
      v-model.number="localValue"
      type="number"
      :label="name"
      :rules="rules"
      :readonly="readonly"
  />

  <v-checkbox
      v-else-if="isCheckbox"
      v-model="localValue"
      :label="name"
      :true-value="1"
      :false-value="0"
      :readonly="readonly"

  />

  <v-select
      v-else-if="isSelect"
      v-model="localValue"
      :items="items"
      item-value="value"
      item-text="label"

      :label="name"
      :true-value="1"
      :false-value="0"
      :readonly="readonly"

  />

  <date-input
      v-else-if="isDate && !readonly"
      v-model="localValue"
      :label="name"
      :readonly="readonly"
      :clearable="!readonly"
  ></date-input>

  <media-field
      v-else-if="isLink && !readonly"
      icon="attach_file"
      :label="name"
      v-model="localValue"
      :field-name="name">
  </media-field>

  <v-btn  v-else-if="isLink && readonly && localValue" :href="localValue" target="_blank"><v-icon>attach_file</v-icon> Adjunto</v-btn>

</template>

<script>

import {DateInput} from "@dracul/dayjs-frontend";
import {MediaField} from "@dracul/media-frontend";

export default {
  name: "RedmineCustomFieldInput",
  props: {
    value: {type: [String, Number, Boolean]},
    customField: {type: Object},
    readonly: {type: Boolean, default: false}
  },
  components: {DateInput, MediaField},
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    rules() {
      let rules = []

      if (this.customField.regexp) {
        let r = new RegExp(this.customField.regexp)
        rules.push(v => r.test(v) || 'Valor Invalido')
      }

      if (this.customField.min_length) {
        rules.push(v => v && v.length >= this.customField.min_length || 'Longitud minima ' + this.customField.min_length)
      }

      if (this.customField.max_length) {
        rules.push(v => v && v.length <= this.customField.max_length || 'Longitud maxima ' + this.customField.max_length)
      }

      return rules
    },
    items() {
      return this.customField.possible_values
    },
    name() {
      return this.customField.name
    },
    isTextField() {
      if (this.customField.field_format == 'string') {
        return true
      }
      return false
    },
    isTextFieldNumber() {
      if (this.customField.field_format == 'int' || this.customField.field_format == 'float') {
        return true
      }
      return false
    },
    isCheckbox() {
      if (this.customField.field_format == 'bool') {
        return true
      }
      return false
    },
    isSelect() {
      if (this.customField.field_format == 'list') {
        return true
      }
      return false
    },
    isDate() {
      if (this.customField.field_format == 'date') {
        return true
      }
      return false
    },
    isLink() {
      if (this.customField.field_format == 'link') {
        return true
      }
      return false
    }
  },

}
</script>

<style scoped>

</style>
