
const messages = {
    en: {
       cobrox: {
          interes: { 
            name: 'Interes',
            title: 'Interes management',
            subtitle: 'View, search, create, edit and delete Interes',
            creating: 'Creating Interes',
            editing: 'Editing Interes',
            deleting: 'Deleting Interes',
            showing: 'Showing Interes',
            menu: 'Interes',
            labels: {
                fechaDesde: 'Date from',
                fechaHasta: 'Date to',
                importeDesde: 'Amount from',
                importeHasta: 'Amount to',
                tasa: 'Tasa'
            },
          
          }
       }
    },
    es: {
       cobrox: {
          interes: { 
            name: 'Interes',
            title: 'Administración de Interes',
            subtitle: 'Ver, buscar, crear, editar, y borrar Interes',
            creating: 'Creando Interes',
            editing: 'Modificando Interes',
            deleting: 'Eliminando Interes',
            showing: 'Detalles de Interes',
            menu: 'Interes',
            labels: {
                fechaDesde: 'Fecha desde',
                fechaHasta: 'Fecha hasta',
                importeDesde: 'Importe desde',
                importeHasta: 'Importe hasta',
                tasa: 'Tasa'
            },
          
          }
       }
    },
    pt: {
       cobrox: {
          interes: { 
            name: 'Interes',
            title: 'Administração de Interes',
            subtitle: 'Ver, buscar, criar, editar e usar Interes',
            creating: 'Criando Interes',
            editing: 'Edição Interes',
            deleting: 'Apagando Interes',
            showing: 'Detalhes do Interes',
            menu: 'Interes',
            labels: {
                fechaDesde: 'Fecha desde',
                fechaHasta: 'Fecha hasta',
                importeDesde: 'Importe desde',
                importeHasta: 'Importe hasta',
                tasa: 'Tasa'
            },
          
          }
       }
    }
}

export default messages
    