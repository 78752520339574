
const messages = {
    en: {
       cobrox: {
          origenDeuda: { 
            name: 'OrigenDeuda',
            title: 'OrigenDeuda management',
            subtitle: 'View, search, create, edit and delete OrigenDeuda',
            creating: 'Creating OrigenDeuda',
            editing: 'Editing OrigenDeuda',
            deleting: 'Deleting OrigenDeuda',
            showing: 'Showing OrigenDeuda',
            menu: 'OrigenDeuda',
            labels: {
                nombre: 'Nombre'
            },
          
          }
       }
    },
    es: {
       cobrox: {
          origenDeuda: { 
            name: 'OrigenDeuda',
            title: 'Administración de OrigenDeuda',
            subtitle: 'Ver, buscar, crear, editar, y borrar OrigenDeuda',
            creating: 'Creando OrigenDeuda',
            editing: 'Modificando OrigenDeuda',
            deleting: 'Eliminando OrigenDeuda',
            showing: 'Detalles de OrigenDeuda',
            menu: 'OrigenDeuda',
            labels: {
                nombre: 'Nombre'
            },
          
          }
       }
    },
    pt: {
       cobrox: {
          origenDeuda: { 
            name: 'OrigenDeuda',
            title: 'Administração de OrigenDeuda',
            subtitle: 'Ver, buscar, criar, editar e usar OrigenDeuda',
            creating: 'Criando OrigenDeuda',
            editing: 'Edição OrigenDeuda',
            deleting: 'Apagando OrigenDeuda',
            showing: 'Detalhes do OrigenDeuda',
            menu: 'OrigenDeuda',
            labels: {
                nombre: 'Nombre'
            },
          
          }
       }
    }
}

export default messages
    