import { render, staticRenderFns } from "./KinvaItemList.vue?vue&type=template&id=7981ab98&scoped=true"
import script from "./KinvaItemList.vue?vue&type=script&lang=js"
export * from "./KinvaItemList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7981ab98",
  null
  
)

export default component.exports