import graphqlClient from "../../../apollo";

class CobranzaPorAgencia {



    cobranzaPorAgenciaReport(date) {
        return graphqlClient.query({
            query: require('./gql/CobranzaPorAgencia/cobranzaPorAgenciaReport.graphql'),
            fetchPolicy: "network-only",
            variables: {date}
        })
    }

    cobranzaPorAgenciaExport(date, agencia) {
        return graphqlClient.query({
            query: require('./gql/CobranzaPorAgencia/cobranzaPorAgenciaExport.graphql'),
            fetchPolicy: "network-only",
            variables: {date, agencia}
        })
    }

    cobranzaReporteImputacion(date, agencia) {
        return graphqlClient.query({
            query: require('./gql/CobranzaPorAgencia/cobranzaReporteImputacion.graphql'),
            fetchPolicy: "network-only",
            variables: {date, agencia}
        })
    }


}

export default new CobranzaPorAgencia()


