
const messages = {
    en: {
       cobrox: {
          compra: { 
            name: 'Compra',
            title: 'Compra management',
            subtitle: 'View, search, create, edit and delete Compra',
            creating: 'Creating Compra',
            editing: 'Editing Compra',
            deleting: 'Deleting Compra',
            showing: 'Showing Compra',
            menu: 'Compra',
            labels: {
                nombre: 'Nombre',
                nombreLegal: 'Nombre legal',
                fechaCompra: 'Fecha compra',
                porcentajeCompra: 'Porcentaje compra',
                cuit: 'CUIT',
                direccionLegal: 'Dirección legal',
                orden: 'Orden'
            },
          
          }
       }
    },
    es: {
       cobrox: {
          compra: { 
            name: 'Compra',
            title: 'Administración de Compra',
            subtitle: 'Ver, buscar, crear, editar, y borrar Compra',
            creating: 'Creando Compra',
            editing: 'Modificando Compra',
            deleting: 'Eliminando Compra',
            showing: 'Detalles de Compra',
            menu: 'Compra',
            labels: {
                nombre: 'Nombre',
                nombreLegal: 'Nombre legal',
                fechaCompra: 'Fecha compra',
                porcentajeCompra: 'Porcentaje compra',
                cuit: 'CUIT',
                direccionLegal: 'Dirección legal',
                orden: 'Orden'
            },
          
          }
       }
    },
    pt: {
       cobrox: {
          compra: { 
            name: 'Compra',
            title: 'Administração de Compra',
            subtitle: 'Ver, buscar, criar, editar e usar Compra',
            creating: 'Criando Compra',
            editing: 'Edição Compra',
            deleting: 'Apagando Compra',
            showing: 'Detalhes do Compra',
            menu: 'Compra',
            labels: {
                nombre: 'Nombre',
                nombreLegal: 'Nombre legal',
                fechaCompra: 'Fecha compra',
                porcentajeCompra: 'Porcentaje compra',
                cuit: 'CUIT',
                direccionLegal: 'Dirección legal',
                orden: 'Orden'
            },
          
          }
       }
    }
}

export default messages
    