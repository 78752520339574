<template>
  <v-container fluid>
    <h3 class="primary--text text-h4">Deudas</h3>
    <v-row v-if="deudaReport">

      <v-col v-if="hasInterno" cols="12" sm="6" md="4">
        <dashboard-card
            title="Cantidad deudas"
            :value="deudaReport.cantidad"
        />
      </v-col>

      <v-col v-if="hasInterno" cols="12" sm="6" md="4">
        <dashboard-card
            title="Capital total"
            :value="deudaReport.capitalTotal"
            :symbol="currency"
        />
      </v-col>

      <v-col v-if="hasInterno" cols="12" sm="6" md="4">
        <dashboard-card
            title="Intereses"
            :value="deudaReport.intereses"
            :symbol="currency"
        />
      </v-col>

      <v-col v-if="hasInterno" cols="12" sm="6" md="4">
        <dashboard-card
            title="Capital compra"
            :value="deudaReport.capitalCompra"
            :symbol="currency"
        />
      </v-col>



      <v-col v-if="hasContabilidad" cols="12" sm="6" md="4">
        <dashboard-card
            title="Capital compra consumido"
            :value="deudaReport.capitalCompra - deudaReport.saldoCapitalCompra"
            :symbol="currency"
        />
      </v-col>

      <v-col v-if="hasContabilidad" cols="12" sm="6" md="4">
        <dashboard-card
            title="Saldo capital compra"
            :value="deudaReport.saldoCapitalCompra"
            :symbol="currency"
        />
      </v-col>

      <v-col v-if="hasInterno" cols="12" sm="6" md="4">
        <dashboard-card
            title="Monto imputado"
            :value="deudaReport.imputadoCobranzas"
            :symbol="currency"
        />
      </v-col>

      <v-col v-if="hasContabilidad" cols="12" sm="6" md="4">
        <dashboard-card
            title="Monto imputado contable"
            :value="deudaReport.imputadoContable"
            :symbol="currency"
        />
      </v-col>



    </v-row>

    <v-alert type="info" v-else>Sin Datos...</v-alert>

  </v-container>

</template>

<script>
import DashboardCard from "@/modules/cobrox/components/DashboardCard/DashboardCard";
import ReportProvider from "@/modules/cobrox/providers/ReportProvider";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import HasPermissionsMixin from "@/modules/cobrox/mixins/HasPermissionsMixin";

export default {
  name: "DeudaReport",
  mixins: [CurrencyMixin, HasPermissionsMixin],
  components: {DashboardCard},
  data() {
    return {
      deudaReport: null,
      loading: false
    }
  },
  created() {
    this.fetch()
  },

  methods: {
    fetch() {
      this.loading = true
      ReportProvider.deudaReport()
          .then(res => {
            this.deudaReport = res.data.deudaReport
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
