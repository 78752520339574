
const messages = {
    en: {
       cobrox: {
          pedidoCuenta: {
            name: 'Pedido Cuenta',
            title: 'Pedido Cuenta management',
            subtitle: 'View, search, create, edit and delete Pedido Cuenta',
            creating: 'Creating Pedido Cuenta',
            editing: 'Editing Pedido Cuenta',
            deleting: 'Deleting Pedido Cuenta',
            showing: 'Showing Pedido Cuenta',
            menu: 'Pedido Cuenta',
            labels: {
                cuil: 'CUIL',
                agenciaSolicitante: 'Agencia Solicitante',
                comentarioSolicitante: 'Comentario Solicitante',
                agenciaAutorizante: 'Agencia Autorizante',
                comentarioAutorizante: 'Comentario Autorizante',
                tipificacionAutorizante: 'Tipificación',
                comentarios: 'Comentarios',
                creadoPor: 'Creado por',
                estadoPedidoCuenta: 'Estado'
            },

          }
       }
    },
    es: {
       cobrox: {
          pedidoCuenta: {
            name: 'Pedido Cuenta',
            title: 'Administración de Pedido Cuenta',
            subtitle: 'Ver, buscar, crear, editar, y borrar Pedido Cuenta',
            creating: 'Creando Pedido Cuenta',
            editing: 'Modificando Pedido Cuenta',
            deleting: 'Eliminando Pedido Cuenta',
            showing: 'Detalles de Pedido Cuenta',
            menu: 'Pedido Cuenta',
            labels: {
                cuil: 'CUIL',
                agenciaSolicitante: 'Agencia Solicitante',
                comentarioSolicitante: 'Comentario Solicitante',
                agenciaAutorizante: 'Agencia Autorizante',
                comentarioAutorizante: 'Comentario Autorizante',
                tipificacionAutorizante: 'Tipificación',
                comentarios: 'Comentarios',
                creadoPor: 'Creado por',
                estadoPedidoCuenta: 'Estado'
            },

          }
       }
    },
    pt: {
       cobrox: {
          pedidoCuenta: {
            name: 'PedidoCuenta',
            title: 'Administração de PedidoCuenta',
            subtitle: 'Ver, buscar, criar, editar e usar PedidoCuenta',
            creating: 'Criando PedidoCuenta',
            editing: 'Edição PedidoCuenta',
            deleting: 'Apagando PedidoCuenta',
            showing: 'Detalhes do PedidoCuenta',
            menu: 'PedidoCuenta',
            labels: {
                cuil: 'CUIL',
                agenciaSolicitante: 'Agencia Solicitante',
                comentarioSolicitante: 'Comentario Solicitante',
                agenciaAutorizante: 'Agencia Autorizante',
                comentarioAutorizante: 'Comentario Autorizante',
                tipificacionAutorizante: 'Tipificación',
                comentarios: 'Comentarios',
                creadoPor: 'Creado por',
                estadoPedidoCuenta: 'Estado'
            },

          }
       }
    }
}

export default messages
