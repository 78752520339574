<template>
  <v-container>
    <v-row align="center" justify="center">

      <v-col cols="12" sm="8" md="6" class="text-center">
        <v-card>

          <v-card-title>
            Importar Cobranzas de Rapipago

            <v-btn text absolute right link small href="/files/CobranzaRapipago.txt" target="_blank" download>
              <v-icon left>download</v-icon>
              TXT de ejemplo
            </v-btn>
          </v-card-title>


          <v-card-text>
            <file-upload-express
                @fileUploaded="onFileUploaded"
                auto-submit
            ></file-upload-express>
            <template v-if="!file">
              <h4  class="subtitle-1">Subir archivo</h4>

              <div class="text-left">

                <h3 class="text-left primary--text">Estructura del TXT</h3><br>

                <v-simple-table dense>
                  <tbody>
                  <tr >
                    <th colspan="5"> Header</th>
                  </tr>
                  <tr>
                    <td colspan="5"> 0000000EXI S.A. 20220502COBRANZAS RAPIPAGO</td>
                  </tr>

                  <tr>
                    <th>FECHA</th>
                    <th>IMPORTE</th>
                    <th>?</th>
                    <th>IDRAPIPAGO</th>
                    <th>?</th>
                  </tr>

                  <tr>
                    <td>20220502</td>
                    <td>000000000312500</td>
                    <td>99900000000</td>
                    <td>28885492901</td>
                    <td>00000000000000114028</td>
                  </tr>

                  </tbody>
                </v-simple-table>

              </div>
            </template>
          </v-card-text>

          <v-card-text v-if="file && !jobId">
            <v-alert type="info">
              Archivo subido exitosamente. Generando tarea de importación en segundo plano.
            </v-alert>
            <v-progress-circular indeterminate></v-progress-circular>
          </v-card-text>

          <v-card-text v-if="jobId">
            <v-alert type="success">
              Tarea de importación en segundo plano generada con éxito.<br> Id: {{ jobId }}
            </v-alert>
          </v-card-text>

          <v-card-actions v-if="jobId">
            <v-spacer></v-spacer>
            <v-btn
                :to="{name:'JobPage',params:{id: jobId}}"
                color="indigo" dark
            >
              Supervisar tarea
            </v-btn>
          </v-card-actions>


        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import JobProvider from "@/modules/cobrox/providers/JobProvider";
import {FileUploadExpress} from "@dracul/media-frontend";

export default {
  name: "CobranzaRapipagoImportPage",
  components: {FileUploadExpress},
  data() {
    return {
      file: null,
      jobId: null,
    }
  },
  methods: {
    onFileUploaded(file) {
      this.file = file
      this.createJob(this.file.id)
    },
    createJob(fileId) {
      JobProvider.importarCobranzaRapipago(fileId)
          .then(res => {
            this.jobId = res.data.importarCobranzaRapipago
          })
    }
  }
}
</script>

<style scoped>

</style>
