<template>
  <v-container fluid>
    <h3 class="primary--text text-h4">Cobranzas</h3>
    <v-row v-if="report">



      <v-col v-if="hasInterno" cols="12" sm="6" md="4">
        <dashboard-card
            title="Cantidad positivas"
            :value="report.cantidadPositiva"
        />
      </v-col>

      <v-col v-if="hasInterno" cols="12" sm="6" md="4">
        <dashboard-card
            title="Cantidad negativas"
            :value="report.cantidadNegativa"
        />
      </v-col>

      <v-col v-if="hasInterno" cols="12" sm="6" md="4">
        <dashboard-card
            title="Cantidad total"
            :value="report.cantidad"
        />
      </v-col>


      <v-col v-if="hasInterno" cols="12" sm="6" md="4">
        <dashboard-card
            title="Monto cobrado Positivo"
            :value="report.montoCobradoPositivo"
            :symbol="currency"
        />
      </v-col>

      <v-col v-if="hasInterno" cols="12" sm="6" md="4">
        <dashboard-card
            title="Monto cobrado Negativo"
            :value="report.montoCobradoNegativo"
            :symbol="currency"
        />
      </v-col>

      <v-col v-if="hasInterno" cols="12" sm="6" md="4">
        <dashboard-card
            title="Monto cobrado Neto"
            :value="report.montoCobrado"
            :symbol="currency"
        />
      </v-col>

      <v-col v-if="hasContabilidad" cols="12" sm="6" md="4">
        <dashboard-card
            title="Cobranzas facturadas"
            :value="report.facturadas"
        />
      </v-col>

      <v-col v-if="hasContabilidad" cols="12" sm="6" md="4">
        <dashboard-card
            title="Cobranzas sin facturar"
            :value="report.cantidad - report.facturadas"
        />
      </v-col>


    </v-row>
    <div v-else>Sin Datos...</div>
  </v-container>

</template>

<script>
import DashboardCard from "@/modules/cobrox/components/DashboardCard/DashboardCard";
import ReportProvider from "@/modules/cobrox/providers/ReportProvider";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import HasPermissionsMixin from "@/modules/cobrox/mixins/HasPermissionsMixin";

export default {
  name: "CobranzaReport",
  components: {DashboardCard},
  mixins: [CurrencyMixin, HasPermissionsMixin],
  data() {
    return {
      report: null,
      loading: false
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.loading = true
      ReportProvider.cobranzaReport()
          .then(res => {
            this.report = res.data.cobranzaReport
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
