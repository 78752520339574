<template>
  <v-container fluid>

    <v-card outlined>
      <v-toolbar flat>
        <h6 class="text-h6">Deudas</h6>
        <v-spacer></v-spacer>
        <field-selector :fields="getFields" v-model="fieldsSelected"></field-selector>
      </v-toolbar>
      <v-card-text>
        <v-data-table
            dense
            :items="deudas"
            :headers="headersSelected"
            hide-default-footer
            :items-per-page="-1"
        >

          <template v-slot:item.estado="{ item }">
            <span class="font-weight-bold" :class="esEstadoActivo(item.estado) ? 'green--text' : 'red--text'">
              {{ item.estado }}
            </span>
          </template>

          <template v-slot:item.compra="{ item }">
            {{ (item.compra && item.compra.nombre) ? item.compra.nombre : '' }}
          </template>

          <template v-slot:item.porcentajeCompra="{ item }">
            %{{ (item.compra && item.compra.porcentajeCompra) ? item.compra.porcentajeCompra : '' }}
          </template>

          <template v-slot:item.fechaCompra="{ item }">
            {{ (item.compra && item.compra.fechaCompra) ? getDateFormat(item.compra.fechaCompra) : '' }}
          </template>


          <template v-slot:item.fechaMora="{ item }">
            {{ getDateFormat(item.fechaMora) }}
          </template>

          <template v-slot:item.fechaOtorgamiento="{ item }">
            {{ getDateFormat(item.fechaOtorgamiento) }}
          </template>

          <template v-slot:item.capitalCompra="{ item }">
            {{ currency }}{{ item.capitalCompra.toLocaleString('es-AR') }}
          </template>

          <template v-slot:item.saldoCapitalCompra="{ item }">
            <span
                :class="{'green--text text--darken-3': (item.saldoCapitalCompra > 0),'red--text': (item.saldoCapitalCompra == 0) }">{{
                item.saldoCapitalCompra
              }}</span>
          </template>

          <template v-slot:item.capitalTotal="{ item }">
            {{ currency }}{{ item.capitalTotal.toLocaleString('es-AR') }}
          </template>

          <template v-slot:item.intereses="{ item }">
            {{ currency }}{{ item.intereses.toLocaleString('es-AR') }}
          </template>


          <template v-slot:item.imputadoContable="{ item }">
            {{ currency }}{{ item.imputadoContable.toLocaleString('es-AR') }}
          </template>

          <template v-slot:item.imputadoCobranzas="{ item }">
            {{ currency }}{{ item.imputadoCobranzas.toLocaleString('es-AR') }}

          </template>

          <template v-slot:item.total="{ item }">
            {{ currency }}{{ (item.capitalTotal + item.intereses).toLocaleString('es-AR') }}
          </template>

          <template v-slot:item.deuda="{ item }">
            {{ currency }}{{ getDeuda(item).toLocaleString('es-AR') }}
          </template>

          <template v-slot:item.action="{ item }">
            <show-button @click="deudaShow = item"/>
            <libre-deuda-deuda-file-button v-if="item.estado === 'CANCELADO' || item.estado === 'DEVUELTO' "
                                           :deuda="item"></libre-deuda-deuda-file-button>
          </template>

          <template v-slot:item.agencia="{ item }">
            {{ item.agencia ? item.agencia.nombre : '' }}
          </template>



        </v-data-table>
      </v-card-text>


    </v-card>


    <template v-if="$store.getters.hasPermission('DEUDA_SUMMARY')">
      <v-card outlined class="mt-3">
      <!-- DEUDAS SUMARIZADAS-->
      <v-card-title>
        Deudas Sumarizadas
      </v-card-title>
      <v-card-text>
        <deuda-total :deudas="deudas"></deuda-total>
      </v-card-text>
      </v-card>
    </template>


    <deuda-show v-if="deudaShow" :item="deudaShow" @close="deudaShow = null"></deuda-show>

  </v-container>

</template>

<script>
import {DayjsMixin} from "@dracul/dayjs-frontend";
import {ShowButton} from "@dracul/common-frontend";
import DeudaShow from "@/modules/cobrox/pages/crud/DeudaPage/DeudaShow/DeudaShow";
import EstadoDeudaProvider from "@/modules/cobrox/providers/EstadoDeudaProvider";
import FieldSelector from "@/modules/cobrox/components/FieldSelector/FieldSelector";
import DeudaTotal from "@/modules/cobrox/components/DeudaTotal/DeudaTotal";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import LibreDeudaDeudaFileButton from "@/modules/cobrox/components/LibreDeudaFileButton/LibreDeudaDeudaFileButton";
import SuretyMixin from "@/modules/surety/mixins/SuretyMixin";

export default {
  name: "PersonaDeudasView",
  components: {LibreDeudaDeudaFileButton, DeudaTotal, FieldSelector, DeudaShow, ShowButton},
  mixins: [DayjsMixin, CurrencyMixin, SuretyMixin],
  props: {
    deudas: Array,
    cuil: {type: String}
  },
  data() {
    return {
      deudaShow: null,
      estados: [],
      expedientes: [],
      fields: ['idDeuda', 'estado', 'producto', 'detalleProducto', 'nroProducto', 'cbu', 'jurisdiccion', 'fechaMora', 'fechaOtorgamiento', 'compra', 'porcentajeCompra', 'fechaCompra', 'capitalCompra', 'saldoCapitalCompra', 'capitalTotal', 'intereses', 'total', 'imputadoCobranzas', 'imputadoContable', 'agencia', 'debitable', 'rotacionBloqueada'],
      fieldsSelected: ['estado', 'producto', 'detalleProducto', 'nroProducto', 'compra', 'fechaMora', 'capitalTotal', 'intereses', 'deuda', 'agencia']
    }
  },
  created() {
    this.fetchFieldPermission('Deuda')
    this.fetchEstados()
  },
  methods: {
    fetchEstados() {
      EstadoDeudaProvider.fetchEstadoDeuda()
          .then(r => {
            this.estados = r.data.fetchEstadoDeuda
          })
    }
  },
  computed: {
    getDeuda() {
      return item => {
        return item.capitalTotal + item.intereses - item.imputadoCobranzas
      }
    },
    esEstadoActivo() {
      return estado => {
        //console.log("estados", estado, this.estados)
        return this.estados.some(i => i.nombre === estado && i.inactiva === false)
      }
    },
    getFields() {
      return this.filterViewFields(this.fields)
    },
    getFieldSelected() {
      return this.filterViewFields(this.fieldsSelected)
    },
    headersSelected() {
      return this.headers.filter(h => this.getFieldSelected.includes(h.value) || h.value === 'action')
    },
    headers() {
      return [
        {text: this.$t('cobrox.deuda.labels.estado'), value: 'estado', width: '120'},
        {text: this.$t('cobrox.deuda.labels.idDeuda'), value: 'idDeuda', width: '140'},
        {text: this.$t('cobrox.deuda.labels.producto'), value: 'producto', width: '160'},
        {text: this.$t('cobrox.deuda.labels.detalleProducto'), value: 'detalleProducto', width: '160'},
        {text: this.$t('cobrox.deuda.labels.cbu'), value: 'cbu', width: '150'},
        {text: this.$t('cobrox.deuda.labels.nroProducto'), value: 'nroProducto', width: '140'},
        ...(this.$store.getters.hasPermission("CONTABILIDAD") ? [{
          text: this.$t('cobrox.deuda.labels.jurisdiccion'),
          value: 'jurisdiccion',
          width: '160'
        }] : []),
        {text: this.$t('cobrox.deuda.labels.compra'), value: 'compra', width: '120'},
        {text: this.$t('cobrox.compra.labels.porcentajeCompra'), value: 'porcentajeCompra', width: '120'},
        {text: this.$t('cobrox.deuda.labels.fechaCompra'), value: 'fechaCompra', width: '150'},
        {text: this.$t('cobrox.deuda.labels.fechaMora'), value: 'fechaMora', width: '140'},
        {text: this.$t('cobrox.deuda.labels.fechaOtorgamiento'), value: 'fechaOtorgamiento', width: '140'},
        ...(this.$store.getters.hasPermission("DEUDA_SHOW") ? [{
          text: this.$t('cobrox.deuda.labels.capitalCompra'),
          value: 'capitalCompra',
          width: '140'
        }] : []),
        ...(this.$store.getters.hasPermission("CONTABILIDAD") ? [{
          text: this.$t('cobrox.deuda.labels.saldoCapitalCompra'),
          value: 'saldoCapitalCompra',
          width: '140'
        }] : []),
        {text: this.$t('cobrox.deuda.labels.capitalTotal'), value: 'capitalTotal', width: '120'},
        {text: this.$t('cobrox.deuda.labels.intereses'), value: 'intereses', width: '120'},
        {text: this.$t('cobrox.deuda.labels.deudaTotal'), value: 'total', width: '100'},
        {text: this.$t('cobrox.deuda.labels.imputadoCobranzas'), value: 'imputadoCobranzas', width: '180'},
        ...(this.$store.getters.hasPermission("CONTABILIDAD") ? [{
          text: this.$t('cobrox.deuda.labels.imputadoContable'),
          value: 'imputadoContable',
          width: '120'
        }] : []),
        {text: this.$t('cobrox.deuda.labels.deuda'), value: 'deuda', width: '100'},
        {text: this.$t('cobrox.deuda.labels.agencia'), value: 'agencia', width: '140'},
        {text: this.$t('cobrox.deuda.labels.debitable'), value: 'debitable', width: '80'},
        {text: this.$t('cobrox.persona.labels.rotacionBloqueada'), value: 'rotacionBloqueada', width: '80'},
        {text: '', value: 'action', sortable: false, width: '40'},
      ]
    }
  },
}
</script>

<style scoped>

</style>
