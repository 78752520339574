<template>
  <v-row row wrap>

    <v-menu offset-y>

      <template v-slot:activator="{on}">
        <v-btn absolute right top color="secondary onSecondary--text" v-on="on">EXPORTS <v-icon right>more_vert</v-icon></v-btn>
      </template>

      <v-card>
        <v-list>

          <v-list-item v-if="$store.getters.hasPermission('DEUDA_EXPORT')"
                       @click="exportarDeuda"
          >Exportar
          </v-list-item>


          <v-list-item  v-if="$store.getters.hasPermission('DEUDA_EXPORT_AGENCIA')"
                        @click="exportarDeudaAgencia"
          >Exportar Agencia
          </v-list-item>

        </v-list>
      </v-card>

    </v-menu>

    <v-col cols="12">
      <v-row justify="space-between" align="center">

        <v-col cols="12" sm="6" md="8">
          <!-- FILTERS HERE -->
          <v-row align="center">


            <v-col cols="12" sm="3">
              <estado-deuda-combobox v-model="filters[0].value" clearable @input="fetch" multiple/>
            </v-col>

            <v-col cols="12" sm="3" v-if="$store.getters.hasPermission('AGENCIA_SHOW')">
              <agencia-combobox v-model="filters[1].value" clearable @input="fetch"></agencia-combobox>
            </v-col>

            <v-col cols="12" sm="2">
              <v-text-field label="ID DEUDA" v-model="filters[2].value" clearable @input="fetch" multiple/>
            </v-col>

            <v-col cols="12" sm="6" md="1" class="text-right">
              <field-selector :fields="getFields" v-model="fieldsSelected"></field-selector>
            </v-col>
          </v-row>
        </v-col>


        <v-col cols="12" sm="6" md="3">
          <search-input @search="performSearch" v-model="search"/>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">

      <v-data-table
          dense
          class="mt-3"
          :headers="headersSelected"
          :items="items"
          :search="search"
          :single-expand="false"
          :server-items-length="totalItems"
          :loading="loading || loadingPermission"
          :page.sync="pageNumber"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="orderBy"
          :sort-desc.sync="orderDesc"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
          @update:page="fetch"
          @update:sort-by="fetch"
          @update:sort-desc="fetch"
          @update:items-per-page="fetch"
      >


        <template v-slot:item.facturas="{ item }">

          <v-menu v-if="item.facturas && item.facturas.length > 0" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="purple"
                  x-small
                  dark
                  v-bind="attrs"
                  v-on="on"
              >
                F
              </v-btn>
            </template>
            <facturas-simple-table :facturas="item.facturas"></facturas-simple-table>

          </v-menu>

        </template>

        <template v-slot:item.capitalCompra="{ item }">
          {{ currency }}{{ item.capitalCompra.toLocaleString('es-AR') }}
        </template>

        <template v-slot:item.saldoCapitalCompra="{ item }">
          <span
              :class="{'green--text text--darken-3': (item.saldoCapitalCompra > 0),'red--text': (item.saldoCapitalCompra == 0) }">{{
              item.saldoCapitalCompra
            }}</span>
        </template>

        <template v-slot:item.capitalTotal="{ item }">
          {{ currency }}{{ item.capitalTotal.toLocaleString('es-AR') }}
        </template>

        <template v-slot:item.intereses="{ item }">
          {{ currency }}{{ item.intereses.toLocaleString('es-AR') }}
        </template>

        <template v-slot:item.imputadoContable="{ item }">
          {{ currency }}{{ item.imputadoContable.toLocaleString('es-AR') }}
        </template>

        <template v-slot:item.imputadoCobranzas="{ item }">
          {{ currency }}{{ item.imputadoCobranzas.toLocaleString('es-AR') }}
          <template v-if="$store.getters.hasPermission('CONTABILIDAD')">
            <v-tooltip bottom offset-overflow>
              <template v-slot:activator="{on}">
                <v-chip small v-on="on"> {{ currency }}{{ item.imputadoContable.toLocaleString('es-AR') }}</v-chip>
              </template>
              <span>Imputado Contable</span>
            </v-tooltip>
          </template>
        </template>

        <template v-slot:item.compra="{ item }">
          {{ (item.compra && item.compra.nombre) ? item.compra.nombre : '' }}
        </template>

        <template v-slot:item.porcentajeCompra="{ item }">
          %{{ (item.compra && item.compra.porcentajeCompra) ? item.compra.porcentajeCompra : '' }}
        </template>

        <template v-slot:item.fechaCompra="{ item }">
          {{ (item.compra && item.compra.fechaCompra) ? getDateFormat(item.compra.fechaCompra) : '' }}
        </template>


        <template v-slot:item.fechaMora="{ item }">
          {{ getDateFormat(item.fechaMora) }}
        </template>


        <template v-slot:item.fechaOtorgamiento="{ item }">
          {{ getDateFormat(item.fechaOtorgamiento) }}
        </template>

        <template v-slot:item.fechaUltimoEstado="{ item }">
          {{ getDateFormat(item.fechaUltimoEstado) }}
        </template>

        <template v-slot:item.total="{ item }">
          {{ currency }}{{ (item.capitalTotal + item.intereses).toLocaleString('es-AR') }}
        </template>

        <template v-slot:item.agencia="{ item }">
          {{ item.agencia ? item.agencia.nombre : '' }}
        </template>

        <template v-slot:item.deuda="{ item }">
          {{ currency }}{{ getDeuda(item).toLocaleString('es-AR') }}
        </template>


        <template slot="no-data">
          <div class="text-xs-center" v-t="'common.noData'"></div>
        </template>

        <template slot="loading">
          <div class="text-xs-center" v-t="'common.loading'"></div>
        </template>

        <template v-slot:item.action="{ item }">
          <show-button @click="$emit('show', item)"/>
          <edit-button v-if="$store.getters.hasPermission('DEUDA_UPDATE')" @click="$emit('update', item)"/>
          <delete-button v-if="$store.getters.hasPermission('DEUDA_DELETE')" @click="$emit('delete', item)"/>
          <libre-deuda-deuda-file-button v-if="item.estado === 'CANCELADO' || item.estado === 'DEVUELTO' "
                                         :deuda="item"></libre-deuda-deuda-file-button>
        </template>

      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import DeudaProvider from "../../../../providers/DeudaProvider";

import {DeleteButton, EditButton, ShowButton, SearchInput} from "@dracul/common-frontend"
import {DayjsMixin} from "@dracul/dayjs-frontend";
import JobProvider from "@/modules/cobrox/providers/JobProvider";
import FacturasSimpleTable from "@/modules/cobrox/components/FacturasSimpleTable/FacturasSimpleTable";
import EstadoDeudaCombobox from "@/modules/cobrox/combobox/EstadoDeudaCombobox/EstadoDeudaCombobox";
import AgenciaCombobox from "@/modules/cobrox/combobox/AgenciaCombobox/AgenciaCombobox";
import FieldSelector from "@/modules/cobrox/components/FieldSelector/FieldSelector";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import LibreDeudaDeudaFileButton from "@/modules/cobrox/components/LibreDeudaFileButton/LibreDeudaDeudaFileButton";
import SuretyMixin from "@/modules/surety/mixins/SuretyMixin";

export default {
  name: "DeudaListCustom",
  components: {
    LibreDeudaDeudaFileButton,
    FieldSelector,
    AgenciaCombobox,
    EstadoDeudaCombobox, FacturasSimpleTable, DeleteButton, EditButton, ShowButton, SearchInput
  },
  mixins: [DayjsMixin, CurrencyMixin, SuretyMixin],
  data() {
    return {

      items: [],
      totalItems: null,
      loading: false,
      orderBy: null,
      orderDesc: false,
      itemsPerPage: 10,
      pageNumber: 1,
      search: '',
      filters: [
        {
          field: 'estado',
          operator: 'in', //(eq|contain|regex|gt|lt|lte|gte)
          value: []
        },
        {
          field: 'agencia',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value: null
        },
        {
          field: 'idDeuda',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value: null
        }
      ],
      fields: ['idDeuda', 'estado', 'dniDeudor', 'cuilDeudor', 'producto', 'detalleProducto', 'nroProducto', 'cbu', 'jurisdiccion', 'fechaMora', 'fechaOtorgamiento', 'fechaUltimoEstado','compra', 'porcentajeCompra', 'fechaCompra', 'capitalCompra', 'saldoCapitalCompra', 'capitalTotal', 'interesBloqueado','intereses', 'total', 'imputadoCobranzas', 'imputadoContable', 'agencia', 'debitable', 'rotacionBloqueada','jobId'],
      fieldsSelected: ['estado', 'producto', 'detalleProducto', 'nroProducto', 'compra', 'fechaMora', 'capitalTotal', 'intereses', 'deuda', 'agencia']

    }
  },
  computed: {
    getDeuda() {
      return item => {
        return item.capitalTotal + item.intereses - item.imputadoCobranzas
      }
    },
    getFields(){
      return this.filterViewFields(this.fields)
    },
    getFieldSelected(){
      return this.filterViewFields(this.fieldsSelected)
    },
    headersSelected() {
      return this.headers.filter(h => this.getFieldSelected.includes(h.value) || h.value === 'action')
    },
    headers() {
      return [
        //Entity Headers
        {text: this.$t('cobrox.deuda.labels.estado'), value: 'estado', width: '120'},
        {text: this.$t('cobrox.deuda.labels.dniDeudor'), value: 'dniDeudor', width: '120'},
        {text: this.$t('cobrox.deuda.labels.cuilDeudor'), value: 'cuilDeudor', width: '120'},
        {text: this.$t('cobrox.deuda.labels.idDeuda'), value: 'idDeuda', width: '140'},
        {text: this.$t('cobrox.deuda.labels.producto'), value: 'producto', width: '160'},
        {text: this.$t('cobrox.deuda.labels.detalleProducto'), value: 'detalleProducto', width: '160'},
        {text: this.$t('cobrox.deuda.labels.cbu'), value: 'cbu', width: '150'},
        {text: this.$t('cobrox.deuda.labels.nroProducto'), value: 'nroProducto', width: '140'},
        ...(this.$store.getters.hasPermission("CONTABILIDAD") ? [{
          text: this.$t('cobrox.deuda.labels.jurisdiccion'),
          value: 'jurisdiccion',
          width: '160'
        }] : []),
        {text: this.$t('cobrox.deuda.labels.compra'), value: 'compra', width: '120'},
        {text: this.$t('cobrox.compra.labels.porcentajeCompra'), value: 'porcentajeCompra', width: '120'},
        {text: this.$t('cobrox.deuda.labels.fechaCompra'), value: 'fechaCompra', width: '150'},
        {text: this.$t('cobrox.deuda.labels.fechaMora'), value: 'fechaMora', width: '140'},
        {text: this.$t('cobrox.deuda.labels.fechaOtorgamiento'), value: 'fechaOtorgamiento', width: '150'},
        {text: this.$t('cobrox.deuda.labels.fechaUltimoEstado'), value: 'fechaUltimoEstado', width: '150'},
        ...(this.$store.getters.hasPermission("DEUDA_SHOW") ? [{
          text: this.$t('cobrox.deuda.labels.capitalCompra'),
          value: 'capitalCompra',
          width: '140'
        }] : []),
        ...(this.$store.getters.hasPermission("CONTABILIDAD") ? [{
          text: this.$t('cobrox.deuda.labels.saldoCapitalCompra'),
          value: 'saldoCapitalCompra',
          width: '140'
        }] : []),
        {text: this.$t('cobrox.deuda.labels.capitalTotal'), value: 'capitalTotal', width: '120'},
        {text: this.$t('cobrox.deuda.labels.interesBloqueado'), value: 'interesBloqueado', width: '100'},
        {text: this.$t('cobrox.deuda.labels.intereses'), value: 'intereses', width: '120'},
        {text: this.$t('cobrox.deuda.labels.deudaTotal'), value: 'total', width: '100'},
        {text: this.$t('cobrox.deuda.labels.imputadoCobranzas'), value: 'imputadoCobranzas', width: '120'},
        ...(this.$store.getters.hasPermission("CONTABILIDAD") ? [{
          text: this.$t('cobrox.deuda.labels.imputadoContable'),
          value: 'imputadoContable',
          width: '120'
        }] : []),
        {text: this.$t('cobrox.deuda.labels.deuda'), value: 'deuda', width: '100'},
        ...(this.$store.getters.hasPermission("AGENCIA_SHOW") ? [{
          text: this.$t('cobrox.deuda.labels.agencia'),
          value: 'agencia',
          width: '140'
        }] : []),
        {text: this.$t('cobrox.deuda.labels.debitable'), value: 'debitable', width: '80'},
        {text: this.$t('cobrox.persona.labels.rotacionBloqueada'), value: 'rotacionBloqueada', width: '80'},
        {text: 'jobId', value: 'jobId'},
        {text: '', value: 'action', sortable: false, width: '120'},
      ]
    },
    getOrderBy() {
      return (Array.isArray(this.orderBy)) ? this.orderBy[0] : this.orderBy
    },
    getOrderDesc() {
      return (Array.isArray(this.orderDesc)) ? this.orderDesc[0] : this.orderDesc
    },
    getSaldoDeuda() {
      return item => {
        let saldoDeuda = (item.capitalTotal + item.intereses) - item.imputadoContable
        return (saldoDeuda > 0 ? saldoDeuda : 0).toLocaleString('es-AR')
      }
    }
  },
  created() {
    this.fetchFieldPermission('Deuda')
    this.fetch()
  },
  mounted(){
    let userRole = this.$store.getters.getRole
    if (userRole === 'agencia') {
      this.filters[0].value = ["ACTIVO"]
    }
  },
  methods: {
    performSearch() {
      this.pageNumber = 1
      this.fetch()
    },
    fetch() {
      this.loading = true
      DeudaProvider.paginateDeuda(
          this.pageNumber,
          this.itemsPerPage,
          this.search,
          this.filters,
          this.getOrderBy,
          this.getOrderDesc
      ).then(r => {
        this.items = r.data.paginateDeuda.items
        this.totalItems = r.data.paginateDeuda.totalItems
      }).catch(err => {
        console.error(err)
      }).finally(() => this.loading = false)
    },
    exportarDeuda() {
      JobProvider.exportarDeuda(null, this.filters, this.search)
          .then(resp => {
            let jobId = resp.data.exportarDeuda
            this.$router.push({name: 'JobPage', params: {id: jobId}})
          })
    },
    exportarDeudaAgencia() {
      JobProvider.exportarDeudaAgencia(this.filters, this.search)
          .then(resp => {
            let jobId = resp.data.exportarDeudaAgencia
            this.$router.push({name: 'JobPage', params: {id: jobId}})
          })
    },

  }

}
</script>


