
const messages = {
    en: {
       role: {
            permissions: {
                FIELDPERMISSION_MENU: "Access to FieldPermission",
                FIELDPERMISSION_SHOW: "View FieldPermission",
                FIELDPERMISSION_CREATE: "Create FieldPermission",
                FIELDPERMISSION_UPDATE: "Modify FieldPermission",
                FIELDPERMISSION_DELETE: "Delete FieldPermission",
            }
       }
    },
    es: {
        role: {
            permissions: {
                FIELDPERMISSION_MENU: "Acceder FieldPermission",
                FIELDPERMISSION_SHOW: "Ver FieldPermission",
                FIELDPERMISSION_CREATE: "Crear FieldPermission",
                FIELDPERMISSION_UPDATE: "Modificar FieldPermission",
                FIELDPERMISSION_DELETE: "Eliminar FieldPermission",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                FIELDPERMISSION_MENU: "Acessar FieldPermission",
                FIELDPERMISSION_SHOW: "Ver FieldPermission",
                FIELDPERMISSION_CREATE: "Criar FieldPermission",
                FIELDPERMISSION_UPDATE: "Modificar FieldPermission",
                FIELDPERMISSION_DELETE: "Eliminar FieldPermission",
            }
       }
    }
}

export default messages
    