
const messages = {
    en: {
       kinva: {
          kinvaDoc: { 
            name: 'KinvaDoc',
            title: 'KinvaDoc management',
            subtitle: 'View, search, create, edit and delete KinvaDoc',
            creating: 'Creating KinvaDoc',
            editing: 'Editing KinvaDoc',
            deleting: 'Deleting KinvaDoc',
            showing: 'Showing KinvaDoc',
            menu: 'KinvaDoc',
            labels: {
                identifier: 'Identifier',
                title: 'Title',
                size: 'Paper Size'
            },
          
          }
       }
    },
    es: {
       kinva: {
          kinvaDoc: { 
            name: 'KinvaDoc',
            title: 'Administración de KinvaDoc',
            subtitle: 'Ver, buscar, crear, editar, y borrar KinvaDoc',
            creating: 'Creando KinvaDoc',
            editing: 'Modificando KinvaDoc',
            deleting: 'Eliminando KinvaDoc',
            showing: 'Detalles de KinvaDoc',
            menu: 'KinvaDoc',
            labels: {
                identifier: 'Identificador',
                title: 'Título',
                size: 'Tamaño Papel'
            },
          
          }
       }
    },
    pt: {
       kinva: {
          kinvaDoc: { 
            name: 'KinvaDoc',
            title: 'Administração de KinvaDoc',
            subtitle: 'Ver, buscar, criar, editar e usar KinvaDoc',
            creating: 'Criando KinvaDoc',
            editing: 'Edição KinvaDoc',
            deleting: 'Apagando KinvaDoc',
            showing: 'Detalhes do KinvaDoc',
            menu: 'KinvaDoc',
            labels: {
                identifier: 'Identifier',
                title: 'Title',
                size: 'Paper Size'
            },
          
          }
       }
    }
}

export default messages
    