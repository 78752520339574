import { render, staticRenderFns } from "./ResultadoCobranzasConError.vue?vue&type=template&id=01f6c17e&scoped=true"
import script from "./ResultadoCobranzasConError.vue?vue&type=script&lang=js"
export * from "./ResultadoCobranzasConError.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01f6c17e",
  null
  
)

export default component.exports