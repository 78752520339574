<template>
  <v-form ref="form" autocomplete="off">


    <v-row>

      <v-col cols="12">
        <v-card float outlined>
          <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <redmine-tracker-combobox
                  v-model="form.tracker_id"
                  default-project
                  :readonly="readonly"
                  :trackers-filter="['Pedido Cuenta']"
              ></redmine-tracker-combobox>
            </v-col>

            <v-col cols="12" sm="12">
              <v-text-field
                  prepend-icon="title"
                  name="subject"
                  v-model="form.subject"
                  :label="$t('redmine.issue.labels.subject')"
                  :placeholder="$t('redmine.issue.labels.subject')"
                  :error="hasInputErrors('subject')"
                  :error-messages="getInputErrors('subject')"
                  color="secondary"
                  :readonly="readonly"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <v-textarea
                  :rows="2"
                  prepend-icon="description"
                  name="description"
                  v-model="form.description"
                  :label="$t('redmine.issue.labels.description')"
                  :placeholder="$t('redmine.issue.labels.description')"
                  :error="hasInputErrors('description')"
                  :error-messages="getInputErrors('description')"
                  color="secondary"
                  :readonly="readonly"
              ></v-textarea>
            </v-col>

          </v-row>
          </v-card-text>
        </v-card>
      </v-col>



      <!-- CUSTOM FIELDS-->
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-title>Campos según tipo de ticket</v-card-title>
          <v-card-text>
            <v-row v-if="isCustomFieldReady">
              <v-col cols="12" sm="6" md="4" v-for="(customField,i) in form.custom_fields" :key="customField.id">
                <redmine-custom-field-input
                    :customField="getCustomFieldById(customField.id)"
                    v-model="form.custom_fields[i].value"
                    :readonly="readonly"
                ></redmine-custom-field-input>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- JOURNALS -->
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-title>Notas</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" v-for="journal in form.journals" :key="journal.id">
                <v-card float outlined>
                  <v-card-text>
                    <v-row>
                      <v-col cols="8"> {{ journal.notes }}</v-col>
                      <v-col cols="4" style="border-left: 1px solid grey">
                        {{ formatCreated(journal.created_on) }}<br>
                        {{ journal.user.name }}

                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </v-form>
</template>

<script>
import {InputErrorsByProps, RequiredRule} from "@dracul/common-frontend";
import {DayjsMixin, Dayjs} from "@dracul/dayjs-frontend";
import RedmineTrackerCombobox from "@/modules/redmine/components/RedmineTrackerCombobox/RedmineTrackerCombobox";
import RedmineCustomFieldInput from "@/modules/redmine/components/RedmineCustomFieldInput/RedmineCustomFieldInput";

export default {
  name: "RedmineIssueForm",
  components: {RedmineCustomFieldInput, RedmineTrackerCombobox},
  mixins: [InputErrorsByProps, RequiredRule, DayjsMixin],
  props: {
    value: {type: Object, required: true},
    customFields: {type: Array},
    readonly: {type: Boolean, default: false}
  },
  data() {
    return {}
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    isCustomFieldReady(){
      return (this.form.custom_fields && this.form.custom_fields.length > 0 && this.customFields.length > 0)
    },
    getCustomFieldById(){
      return id => {
        let cf =  this.customFields.find(c => c.id == id)
        //console.log("CustomField", id, cf)
        return cf
      }
    },
    formatCreated() {
      return createdOn => {
        return Dayjs(createdOn).format("YYYY-MM-DD HH:mm")
      }
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
