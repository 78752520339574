<template>
  <v-card>
    <v-card-title>
      Tickets Pedidos Cuenta
    </v-card-title>

    <v-card-text>
      <v-data-table
          class="mt-3"
          :headers="headers"
          :items="items"
          :search="search"
          :single-expand="false"
          :server-items-length="totalItems"
          :loading="loading"
          :page.sync="pageNumber"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="orderBy"
          :sort-desc.sync="orderDesc"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
          @update:page="fetch"
          @update:sort-by="fetch"
          @update:sort-desc="fetch"
          @update:items-per-page="fetch"
      >


        <template v-slot:item.agenciaSolicitante="{ item }">
          {{ item.agenciaSolicitante ? item.agenciaSolicitante.nombre : '' }}
        </template>


        <template v-slot:item.agenciaAutorizante="{ item }">
          {{ item.agenciaAutorizante ? item.agenciaAutorizante.nombre : '' }}
        </template>


        <template v-slot:item.creadoPor="{ item }">
          {{ item.creadoPor ? item.creadoPor.username : '' }}
        </template>

        <template v-slot:item.createdAt="{ item }">
          {{ getDateFormat(item.createdAt) }}
        </template>

        <template v-slot:item.tiempoRestante="{ item }">
          <span v-if="item.estadoPedidoCuenta === 'PENDIENTE' && calcularTiempoRestante(item.createdAt) > 0">
           {{ tiempoRestante(item.createdAt) }}
          </span>

        </template>

        <template v-slot:item.estadoPedidoCuenta="{ item }">
          {{ item.estadoPedidoCuenta ? item.estadoPedidoCuenta : '' }}
        </template>

        <template slot="no-data">
          <div class="text-xs-center" v-t="'common.noData'"></div>
        </template>

        <template slot="loading">
          <div class="text-xs-center" v-t="'common.loading'"></div>
        </template>

        <template v-slot:item.action="{ item }">
          <show-button @click="$emit('show', item)"/>
          <edit-button v-if="$store.getters.hasPermission('PEDIDOCUENTA_UPDATE')" @click="$emit('update', item)"/>
          <delete-button v-if="$store.getters.hasPermission('PEDIDOCUENTA_DELETE')" @click="$emit('delete', item)"/>
        </template>

      </v-data-table>
    </v-card-text>

  </v-card>
</template>

<script>
import TiempoRestanteMixin from "@/modules/cobrox/mixins/TiempoRestanteMixin";
import PedidoCuentaProvider from "@/modules/cobrox/providers/PedidoCuentaProvider";
import {DayjsMixin} from "@dracul/dayjs-frontend";

export default {
  name: "PersonaPedidosCuenta",
  mixins: [DayjsMixin, TiempoRestanteMixin],
  props: {
    cuil: {type: String, required: true}
  },
  data() {
    return {
      items: [],
      totalItems: null,
      loading: false,
      orderBy: null,
      orderDesc: false,
      itemsPerPage: 5,
      pageNumber: 1,
      search: '',
      filters: [
        {
          field: 'cuil',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value:  this.cuil
        }
      ],
      userAgencia: null
    }
  },
  computed: {
    headers() {
      return [
        //Entity Headers

        {text: '#', value: 'ticketRedmine'},


        ...(this.$store.getters.hasPermission('PEDIDOCUENTA_SHOW') ? [{
          text: this.$t('cobrox.pedidoCuenta.labels.creadoPor'),
          value: 'creadoPor'
        }] : []),

        {text: 'Fecha Pedido', value: 'createdAt'},

        ...(this.$store.getters.hasPermission('PEDIDOCUENTA_SHOW') ? [{
          text: this.$t('cobrox.pedidoCuenta.labels.agenciaSolicitante'),
          value: 'agenciaSolicitante'
        }] : []),
        ...(this.$store.getters.hasPermission('PEDIDOCUENTA_SHOW') ? [{
          text: this.$t('cobrox.pedidoCuenta.labels.comentarioSolicitante'),
          value: 'comentarioSolicitante'
        }] : []),

        {text: this.$t('cobrox.pedidoCuenta.labels.cuil'), value: 'cuil'},


        ...(this.$store.getters.hasPermission('PEDIDOCUENTA_SHOW') ? [{
          text: this.$t('cobrox.pedidoCuenta.labels.agenciaAutorizante'),
          value: 'agenciaAutorizante'
        }] : []),

        ...(this.$store.getters.hasPermission('PEDIDOCUENTA_SHOW') ? [{
          text: this.$t('cobrox.pedidoCuenta.labels.tipificacionAutorizante'),
          value: 'tipificacionAutorizante'
        }] : []),

        ...(this.$store.getters.hasPermission('PEDIDOCUENTA_SHOW') ? [{
          text: this.$t('cobrox.pedidoCuenta.labels.comentarioAutorizante'),
          value: 'comentarioAutorizante'
        }] : []),


        //{text: 'Tiempo Restante', value: 'tiempoRestante'},

        {text: this.$t('cobrox.pedidoCuenta.labels.estadoPedidoCuenta'), value: 'estadoPedidoCuenta'},

        //Actions
        //{text: this.$t('common.actions'), value: 'action', sortable: false},
      ]
    },
    getOrderBy() {
      return (Array.isArray(this.orderBy)) ? this.orderBy[0] : this.orderBy
    },
    getOrderDesc() {
      return (Array.isArray(this.orderDesc)) ? this.orderDesc[0] : this.orderDesc
    }
  },
  created() {
    this.fetch()
  },
  methods: {

    performSearch() {
      this.pageNumber = 1
      this.fetch()
    },
    fetch() {
      this.loading = true
      PedidoCuentaProvider.paginatePedidoCuenta(
          this.pageNumber,
          this.itemsPerPage,
          this.search,
          this.filters,
          this.getOrderBy,
          this.getOrderDesc
      ).then(r => {
        this.items = r.data.paginatePedidoCuenta.items
        this.totalItems = r.data.paginatePedidoCuenta.totalItems
      })
          .catch(err => {
            console.error(err)
          })
          .finally(() => this.loading = false)
    },
  }
}
</script>

<style scoped>

</style>
