<template>
  <v-simple-table v-if="estadosDeuda" :dense="dense">
    <thead>
    <tr>
      <th>Cantidad deudas</th>
      <th>Capital</th>
      <th>Intereses</th>
      <th>Capital+Intereses</th>
      <th>Imputado</th>
      <th>Deuda Actual</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>{{ cantidad }}</td>
      <td>{{currency}}{{ capital.toLocaleString('es-AR') }}</td>
      <td>{{currency}}{{ intereses.toLocaleString('es-AR') }}</td>
      <td>{{ currency }}{{ deudaTotal.toLocaleString('es-AR') }}</td>
      <td>{{currency}}{{ imputado.toLocaleString('es-AR') }}</td>
      <td>{{currency}}{{ final.toLocaleString('es-AR') }}</td>
    </tr>
    </tbody>


  </v-simple-table>

</template>

<script>
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import DeudaTotalMixin from "@/modules/cobrox/mixins/DeudaTotalMixin";

export default {
  name: "DeudaTotal",
  mixins: [CurrencyMixin, DeudaTotalMixin],
  props: {
    deudas: Array,
    dense: {type: Boolean, default: false},
  },

}
</script>

<style scoped>

</style>
