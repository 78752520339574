
const messages = {
    en: {
       surety: {
          fieldPermission: {
            name: 'FieldPermission',
            title: 'FieldPermission management',
            subtitle: 'View, search, create, edit and delete FieldPermission',
            creating: 'Creating FieldPermission',
            editing: 'Editing FieldPermission',
            deleting: 'Deleting FieldPermission',
            showing: 'Showing FieldPermission',
            menu: 'FieldPermission',
            labels: {
                role: 'Role',
                entity: 'Entity',
                view: 'View',
                edit: 'Edit'
            },

          }
       }
    },
    es: {
       surety: {
          fieldPermission: {
            name: 'P    ermisos de entidades',
            title: 'Administración de permisos de entidades',
            subtitle: 'Ver, buscar, crear, editar, y borrar permisos de entidades',
            creating: 'Creando permisos de entidades',
            editing: 'Modificando permisos de entidades',
            deleting: 'Eliminando permisos de entidades',
            showing: 'Detalles de permisos de entidades',
            menu: 'Permisos de Entidades',
            labels: {
                role: 'Rol',
                entity: 'Entidad',
                view: 'View',
                edit: 'Edit'
            },

          }
       }
    },
    pt: {
       surety: {
          fieldPermission: {
            name: 'FieldPermission',
            title: 'Administração de FieldPermission',
            subtitle: 'Ver, buscar, criar, editar e usar FieldPermission',
            creating: 'Criando FieldPermission',
            editing: 'Edição FieldPermission',
            deleting: 'Apagando FieldPermission',
            showing: 'Detalhes do FieldPermission',
            menu: 'FieldPermission',
            labels: {
                role: 'Role',
                entity: 'Entity',
                view: 'View',
                edit: 'Edit'
            },

          }
       }
    }
}

export default messages
