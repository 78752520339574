<template>
  <crud-update :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @update="update"
               @close="$emit('close')"
  >
    <factura-form ref="form" v-model="form" :input-errors="inputErrors"/>
  </crud-update>
</template>

<script>

import FacturaProvider from "../../../../providers/FacturaProvider";

import {CrudUpdate, ClientError} from '@dracul/common-frontend'

import FacturaForm from "../FacturaForm";

import {Dayjs} from "@dracul/dayjs-frontend";

export default {
  name: "FacturaUpdate",

  components: {FacturaForm, CrudUpdate},

  props: {
    open: {type: Boolean, default: true},
    item: {type: Object, required: true}
  },

  data() {
    return {
      title: 'cobrox.factura.editing',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      id: this.item.id,
      form: {
        cobranza: this.item.cobranza.id,
        deuda: this.item.deuda.id,
        monto: this.item.monto,
        montoNoFacturable: this.item.montoNoFacturable,
        montoFacturable: this.item.montoFacturable,
        estado: this.item.estado,
        tipo: this.item.tipo,
        lote: this.item.lote,
        fechaComprobante: this.item.fechaComprobante ? Dayjs(parseInt(this.item.fechaComprobante)) : null,
        fechaVencimiento: this.item.fechaVencimiento ? Dayjs(parseInt(this.item.fechaVencimiento)) : null,
        tipoComprobante: this.item.tipoComprobante,
        letra: this.item.letra,
        puntoVenta: this.item.puntoVenta,
        numeroComprobante: this.item.numeroComprobante,
        cuitCliente: this.item.cuitCliente,
        dniCliente: this.item.dniCliente,
        razonSocialCliente: this.item.razonSocialCliente,
        descripcionVenta: this.item.descripcionVenta,
        subtotalGravadoIVA105: this.item.subtotalGravadoIVA105,
        subtotalGravadoIVA21: this.item.subtotalGravadoIVA21,
        subtotalGravadoIVA27: this.item.subtotalGravadoIVA27,
        totalNetoNoGravado: this.item.totalNetoNoGravado,
        percepcionIVA: this.item.percepcionIVA,
        jurisdiccionIIBB: this.item.jurisdiccionIIBB,
        totalComprobante: this.item.totalComprobante,
        cuentaIngreso: this.item.cuentaIngreso,
        medioCobro: this.item.medioCobro,
        centroCosto1: this.item.centroCosto1,
        centroCosto2: this.item.centroCosto2,
        periodoDesde: this.item.periodoDesde ? Dayjs(parseInt(this.item.periodoDesde)) : null,
        periodoHasta: this.item.periodoHasta ? Dayjs(parseInt(this.item.periodoHasta)) : null,
        cbu: this.item.cbu,
        cae: this.item.cae,
        nroFactura: this.item.nroFactura,
        importeIva: this.item.importeIva,
        netoGravado: this.item.netoGravado,
        nombreCuenta: this.item.nombreCuenta,
        idFacturaColppy: this.item.idFacturaColppy,
        idClienteColppy: this.item.idClienteColppy
      }
    }
  },
  methods: {
    update() {
      if (this.$refs.form.validate()) {
        this.loading = true

        if(this.form.idClienteColppy === ""){
          this.form.idClienteColppy = null
        }

        FacturaProvider.updateFactura(this.id, this.form).then(r => {
              if (r) {
                this.$emit('itemUpdated', r.data.updateFactura)
                this.$emit('close')
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }
  },
}
</script>

<style scoped>

</style>

