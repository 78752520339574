<template>
    <v-row>
        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.agencia.nombre" :label="$t('cobrox.comision.labels.agencia')" icon="business"/>
                 <show-field :value="String(item.ratioHasta)" :label="$t('cobrox.comision.labels.ratioHasta')" icon="calculate"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.compra.nombre" :label="$t('cobrox.comision.labels.compra')" icon="shopping_cart"/>
                 <show-field :value="String(item.comision)" :label="$t('cobrox.comision.labels.comision')" icon="percent"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="String(item.ratioDesde)" :label="$t('cobrox.comision.labels.ratioDesde')" icon="calculate"/>
            </v-list>
        </v-col>

    </v-row>
</template>
<script>
    import {ShowField} from '@dracul/common-frontend'
     
    
    export default {
        name: 'ComisionShowData',
        components: {ShowField},
        
        props: {
            item: {type: Object, required: true}
        }
    }
</script>

