
const messages = {
    en: {
       cobrox: {
          producto: { 
            name: 'Producto',
            title: 'Producto management',
            subtitle: 'View, search, create, edit and delete Producto',
            creating: 'Creating Producto',
            editing: 'Editing Producto',
            deleting: 'Deleting Producto',
            showing: 'Showing Producto',
            menu: 'Producto',
            labels: {
                nombre: 'Nombre'
            },
          
          }
       }
    },
    es: {
       cobrox: {
          producto: { 
            name: 'Producto',
            title: 'Administración de Producto',
            subtitle: 'Ver, buscar, crear, editar, y borrar Producto',
            creating: 'Creando Producto',
            editing: 'Modificando Producto',
            deleting: 'Eliminando Producto',
            showing: 'Detalles de Producto',
            menu: 'Producto',
            labels: {
                nombre: 'Nombre'
            },
          
          }
       }
    },
    pt: {
       cobrox: {
          producto: { 
            name: 'Producto',
            title: 'Administração de Producto',
            subtitle: 'Ver, buscar, criar, editar e usar Producto',
            creating: 'Criando Producto',
            editing: 'Edição Producto',
            deleting: 'Apagando Producto',
            showing: 'Detalhes do Producto',
            menu: 'Producto',
            labels: {
                nombre: 'Nombre'
            },
          
          }
       }
    }
}

export default messages
    