
const messages = {
    en: {
       cobrox: {
          persona: {
            name: 'Persona',
            title: 'Persona management',
            subtitle: 'View, search, create, edit and delete Persona',
            creating: 'Creating Persona',
            editing: 'Editing Persona',
            deleting: 'Deleting Persona',
            showing: 'Showing Persona',
            menu: 'Persona',
            labels: {
                nombreCompleto: 'Fullname',
                tipoDocumento: 'Tipo Documento',
                nroDocumento: 'Nro Documento',
                cuil: 'CUIL',
                sexo: 'Sexo',
                nacionalidad: 'Nacionalidad',
                fechaNacimiento: 'Fecha Nacimiento',
                agencia: 'Agencia',
                idRapipago: 'IdRapipago',
                telefono: "Teléfono",
                mail: "Mail",
                tipo: "Tipo",
                calle: "Calle",
                numero: "Número",
                piso: "Piso",
                depto: "Depto",
                localidad: "Localidad",
                provincia: "Provincia",
                codigoPostal: "Código Postal",
                estado: 'Estado',
                origen: 'Origen',
                cbu: "CBU",
                descripcion:"Descripción",
                rotacionBloqueada:"Rotación bloqueada",
                rotacionBloqueadaPor:"Rotación bloqueada por",
                fechaDesbloqueoRotacion: "Fecha desbloqueo rotación",
                situacionLaboral: "Situación Laboral",
                jurisdiccion: 'Jurisdicción',
                idClienteColppy: 'idClienteColppy',
                nombre: 'Nombre',
                link: 'Link',
            },

          }
       }
    },
    es: {
       cobrox: {
          persona: {
            name: 'Persona',
            title: 'Administración de Persona',
            subtitle: 'Ver, buscar, crear, editar, y borrar Persona',
            creating: 'Creando Persona',
            editing: 'Modificando Persona',
            deleting: 'Eliminando Persona',
            showing: 'Detalles de Persona',
            menu: 'Persona',
            labels: {
                nombreCompleto: 'Nombre Completo',
                tipoDocumento: 'Tipo Documento',
                nroDocumento: 'Nro Documento',
                cuil: 'CUIL',
                sexo: 'Sexo',
                nacionalidad: 'Nacionalidad',
                fechaNacimiento: 'Fecha Nacimiento',
                agencia: 'Agencia',
                idRapipago: 'IdRapipago',
                telefono: "Teléfono",
                mail: "Mail",
                tipo: "Tipo",
                calle: "Calle",
                numero: "Número",
                piso: "Piso",
                depto: "Depto",
                localidad: "Localidad",
                provincia: "Provincia",
                codigoPostal: "Código Postal",
                estado: 'Estado',
                origen: 'Origen',
                cbu: "CBU",
                descripcion:"Descripción",
                rotacionBloqueada:"Rotación bloqueada",
                rotacionBloqueadaPor:"Rotación bloqueada por",
                fechaDesbloqueoRotacion: "Fecha desbloqueo rotación",
                situacionLaboral: "Situación Laboral",
                jurisdiccion: 'Jurisdicción',
                idClienteColppy: 'idClienteColppy',
                nombre: 'Nombre',
                link: 'Link',
            },

          }
       }
    },
    pt: {
       cobrox: {
          persona: {
            name: 'Persona',
            title: 'Administração de Persona',
            subtitle: 'Ver, buscar, criar, editar e usar Persona',
            creating: 'Criando Persona',
            editing: 'Edição Persona',
            deleting: 'Apagando Persona',
            showing: 'Detalhes do Persona',
            menu: 'Persona',
            labels: {
                nombreCompleto: 'Nome',
                tipoDocumento: 'Tipo Documento',
                nroDocumento: 'Nro Documento',
                cuil: 'CUIL',
                sexo: 'Sexo',
                nacionalidad: 'Nacionalidad',
                fechaNacimiento: 'Fecha Nacimiento',
                agencia: 'Agencia',
                idRapipago: 'IdRapipago',
                telefono: "Teléfono",
                mail: "Mail",
                tipo: "Tipo",
                calle: "Calle",
                numero: "Número",
                piso: "Piso",
                depto: "Depto",
                localidad: "Localidad",
                provincia: "Provincia",
                codigoPostal: "Código Postal",
                estado: 'Estado',
                origen: 'Origen',
                cbu: "CBU",
                descripcion:"Descripción",
                rotacionBloqueada:"Rotación bloqueada",
                rotacionBloqueadaPor:"Rotación bloqueada por",
                fechaDesbloqueoRotacion: "Fecha desbloqueo rotación",
                situacionLaboral: "Situación Laboral",
                jurisdiccion: 'Jurisdicción',
                idClienteColppy: 'idClienteColppy',
                nombre: 'Nombre',
                link: 'Link',
            },

          }
       }
    }
}

export default messages
