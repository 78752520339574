<template>
  <v-row>
    <v-col cols="12" sm="6" md="4">
      <v-list>
        <show-field :value="item.cobranza.id" :label="$t('cobrox.factura.labels.cobranza')" icon="paid"/>
        <show-field :value="String(item.montoNoFacturable)" :label="$t('cobrox.factura.labels.montoNoFacturable')"
                    icon="money_off"/>
        <show-field :value="item.lote" :label="$t('cobrox.factura.labels.lote')" icon="vpn_key"/>
        <show-field :value="item.tipoComprobante" :label="$t('cobrox.factura.labels.tipoComprobante')"
                    icon="format_list_numbered"/>
        <show-field :value="item.numeroComprobante" :label="$t('cobrox.factura.labels.numeroComprobante')" icon="pin"/>
        <show-field :value="item.descripcionVenta" :label="$t('cobrox.factura.labels.descripcionVenta')"
                    icon="description"/>
        <show-field :value="String(item.subtotalGravadoIVA27)" :label="$t('cobrox.factura.labels.subtotalGravadoIVA27')"
                    icon="request_quote"/>
        <show-field :value="item.jurisdiccionIIBB" :label="$t('cobrox.factura.labels.jurisdiccionIIBB')" icon="flag"/>
        <show-field :value="item.medioCobro" :label="$t('cobrox.factura.labels.medioCobro')" icon="payment"/>
        <show-field :value="getDateFormat(item.periodoDesde)" :label="$t('cobrox.factura.labels.periodoDesde')"
                    icon="event"/>
        <show-field :value="item.cae" :label="$t('cobrox.factura.labels.cae')" icon="fact_check"/>
        <show-field :value="String(item.netoGravado)" :label="$t('cobrox.factura.labels.netoGravado')"
                    icon="price_check"/>
      </v-list>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-list>
        <show-field :value="item.deuda.id" :label="$t('cobrox.factura.labels.deuda')" icon="local_atm"/>
        <show-field :value="String(item.montoFacturable)" :label="$t('cobrox.factura.labels.montoFacturable')"
                    icon="attach_money"/>
        <show-field :value="getDateFormat(item.fechaComprobante)" :label="$t('cobrox.factura.labels.fechaComprobante')"
                    icon="event"/>
        <show-field :value="item.letra" :label="$t('cobrox.factura.labels.letra')" icon="sort_by_alpha"/>
        <show-field :value="item.cuitCliente" :label="$t('cobrox.factura.labels.cuitCliente')" icon="badge"/>
        <show-field :value="String(item.subtotalGravadoIVA105)"
                    :label="$t('cobrox.factura.labels.subtotalGravadoIVA105')" icon="request_quote"/>
        <show-field :value="String(item.totalNetoNoGravado)" :label="$t('cobrox.factura.labels.totalNetoNoGravado')"
                    icon="monetization_on"/>
        <show-field :value="String(item.totalComprobante)" :label="$t('cobrox.factura.labels.totalComprobante')"
                    icon="local_atm"/>
        <show-field :value="item.centroCosto1" :label="$t('cobrox.factura.labels.centroCosto1')" icon="card_travel"/>
        <show-field :value="getDateFormat(item.periodoHasta)" :label="$t('cobrox.factura.labels.periodoHasta')"
                    icon="event"/>
        <show-field :value="item.nroFactura" :label="$t('cobrox.factura.labels.nroFactura')" icon="receipt"/>
        <show-field :value="item.nombreCuenta" :label="$t('cobrox.factura.labels.nombreCuenta')"
                    icon="account_balance"/>
      </v-list>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <v-list>
        <show-field :value="String(item.monto)" :label="$t('cobrox.factura.labels.monto')" icon="monetization_on"/>
        <show-field :value="item.estado" :label="$t('cobrox.factura.labels.estado')" icon="fact_check"/>
        <show-field :value="getDateFormat(item.fechaVencimiento)" :label="$t('cobrox.factura.labels.fechaVencimiento')"
                    icon="event"/>
        <show-field :value="item.puntoVenta" :label="$t('cobrox.factura.labels.puntoVenta')" icon="store"/>
        <show-field :value="item.razonSocialCliente" :label="$t('cobrox.factura.labels.razonSocialCliente')"
                    icon="person"/>
        <show-field :value="String(item.subtotalGravadoIVA21)" :label="$t('cobrox.factura.labels.subtotalGravadoIVA21')"
                    icon="request_quote"/>
        <show-field :value="String(item.percepcionIVA)" :label="$t('cobrox.factura.labels.percepcionIVA')"
                    icon="request_quote"/>
        <show-field :value="item.cuentaIngreso" :label="$t('cobrox.factura.labels.cuentaIngreso')"
                    icon="account_balance_wallet"/>
        <show-field :value="item.centroCosto2" :label="$t('cobrox.factura.labels.centroCosto2')" icon="card_travel"/>
        <show-field :value="item.cbu" :label="$t('cobrox.factura.labels.cbu')" icon="account_balance"/>
        <show-field :value="String(item.importeIva)" :label="$t('cobrox.factura.labels.importeIva')"
                    icon="request_quote"/>
        <show-field :value="item.idFacturaColppy" :label="$t('cobrox.factura.labels.idFacturaColppy')" icon="tag"/>
      </v-list>
    </v-col>




  </v-row>
</template>
<script>
import {ShowField} from '@dracul/common-frontend'
import {DayjsMixin} from "@dracul/dayjs-frontend";

export default {
  name: 'FacturaShowData',
  components: {ShowField},
  mixins: [DayjsMixin],
  props: {
    item: {type: Object, required: true}
  }
}
</script>

