<script>
import MultichannelChatbot from "@/modules/cobrox/components/MultichannelChatbot/MultichannelChatbot.vue";

export default {
  name: "McChatbotPage",
  components: {MultichannelChatbot}
}
</script>

<template>
<multichannel-chatbot></multichannel-chatbot>
</template>

<style scoped>

</style>
