<template>
    <v-row>
        <v-col cols="12" sm="6" md="6">
            <v-list>
                 <show-field :value="item.role.name" :label="$t('surety.fieldPermission.labels.role')" icon="verified_user"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="6">
            <v-list>
                 <show-field :value="item.entity" :label="$t('surety.fieldPermission.labels.entity')" icon="fingerprint"/>
            </v-list>
        </v-col>

        <v-col cols="12" >
          <v-simple-table>
            <thead>
            <tr>
              <th>Field</th>
              <th>View</th>
              <th>Edit</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="field in item.fields" :key="field.name">
              <td>{{field.name}}</td>
              <td>
                {{field.view}}
              </td>
              <td>
                {{field.edit}}
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-col>

    </v-row>
</template>
<script>
    import {ShowField} from '@dracul/common-frontend'


    export default {
        name: 'FieldPermissionShowData',
        components: {ShowField},

        props: {
            item: {type: Object, required: true}
        }
    }
</script>

