<template>
  <v-menu
      v-model="menu"
      offset-x :close-on-content-click="false" nudge-bottom="20"
  >
    <template v-slot:activator="{on}">
      <v-btn text icon v-on="on" :small="isEditing">
        <v-icon>{{ isEditing ? 'edit' : 'add' }}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-form ref="form" autocomplete="off" @submit.prevent="submit">
          <v-row>

            <v-col v-if="errorMessage" cols="12">
              <v-alert type="error">{{ errorMessage }}</v-alert>
            </v-col>

            <v-col cols="12" sm="6">
              <month-picker
                  v-model="form.fechaDesde"
                  :label="$t('cobrox.interes.labels.fechaDesde')"
                  :rules="required"
                  :error="hasInputErrors('fechaDesde')"
                  :error-messages="getInputErrors('fechaDesde')"
              ></month-picker>
            </v-col>


            <v-col cols="12" sm="6">
              <month-picker
                  v-model="form.fechaHasta"
                  :label="$t('cobrox.interes.labels.fechaHasta')"
                  :rules="required"
                  :error="hasInputErrors('fechaHasta')"
                  :error-messages="getInputErrors('fechaHasta')"
              ></month-picker>
            </v-col>

            <v-col cols="12" class="text-right">
              <v-btn class="red darken-2 white--text" @click="remove">Eliminar</v-btn>
              <submit-button @click="submit"></submit-button>
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>

import {InputErrorsByProps, RequiredRule, SubmitButton} from '@dracul/common-frontend'

import {DayjsMixin, MonthPicker} from "@dracul/dayjs-frontend";
import InteresProvider from "@/modules/cobrox/providers/InteresProvider";


export default {
  name: "InteresFechaForm",
  data() {
    return {
      menu: false,
      errorMessage: null,
      form: {
        fechaDesde: this.currentFechaDesde,
        fechaHasta: this.currentFechaHasta
      },
      fechaDesdeDateMenu: false,
      fechaHastaDateMenu: false
    }
  },
  props: {
    isEditing: {type: Boolean, default: false},
    currentFechaDesde: {type: Object},
    currentFechaHasta: {type: Object},
  },
  mixins: [InputErrorsByProps, RequiredRule, DayjsMixin],
  components: {MonthPicker, SubmitButton},
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    submit() {
      this.errorMessage = null
      if (this.isEditing) {
        this.update()
      } else {
        this.create()
      }
    },
    create() {
      InteresProvider.createInteresByFecha(this.form)
          .then(r => {
            this.$emit("updated", r.data.createInteresByFecha)
            this.menu = false
          })
          .catch(e => {
            this.errorMessage = e.message.replace('GraphQL error:','')
          })
    },
    update() {
      InteresProvider.updateInteresByFecha({
        currentFechaDesde: this.currentFechaDesde,
        currentFechaHasta: this.currentFechaHasta,
        newFechaDesde: this.form.fechaDesde,
        newFechaHasta: this.form.fechaHasta
      })
          .then(r => {
            this.$emit("updated", r.data.updateInteresByFecha)
            this.menu = false
          })
          .catch(e => {
            this.errorMessage = e.message.replace('GraphQL error:','')
          })
    },
    remove() {
      InteresProvider.deleteInteresByFecha({fechaDesde: this.currentFechaDesde, fechaHasta: this.currentFechaHasta})
          .then(r => {
            this.$emit("updated", r.data.deleteInteresByFecha)
            this.menu = false
          })
          .catch(e => {
            this.errorMessage = e.message.replace('GraphQL error:','')
          })
    },
  },

}
</script>

<style scoped>

</style>

