<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">
    <v-row>

      <v-col cols="12" sm="6">
        <v-text-field
            :readonly="isDisabled('cuilDeudor')"
            :background-color="isDisabled('cuilDeudor') ? 'indigo lighten-5' : ''  "
            prepend-icon="contact_page"
            name="cuilDeudor"
            v-model="form.cuilDeudor"
            :label="$t('cobrox.convenio.labels.cuilDeudor')"
            :placeholder="$t('cobrox.convenio.labels.cuilDeudor')"
            :error="hasInputErrors('cuilDeudor')"
            :error-messages="getInputErrors('cuilDeudor')"
            color="secondary"
            :rules="required"
            @input="onInputCuilDeudor"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
            :readonly="isDisabled('dniDeudor')"
            :background-color="isDisabled('dniDeudor') ? 'indigo lighten-5' : ''  "
            prepend-icon="badge"
            name="dniDeudor"
            v-model="form.dniDeudor"
            :label="$t('cobrox.convenio.labels.dniDeudor')"
            :placeholder="$t('cobrox.convenio.labels.dniDeudor')"
            :error="hasInputErrors('dniDeudor')"
            :error-messages="getInputErrors('dniDeudor')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>





      <v-col cols="12" sm="4">
        <v-text-field
            :readonly="isDisabled('cuotas')"
            :background-color="isDisabled('cuotas') ? 'indigo lighten-5' : ''  "
            prepend-icon="format_list_numbered_rtl"
            name="cuotas"
            v-model.number="form.cuotas"
            type="number"
            :label="$t('cobrox.convenio.labels.cuotas')"
            :placeholder="$t('cobrox.convenio.labels.cuotas')"
            :error="hasInputErrors('cuotas')"
            :error-messages="getInputErrors('cuotas')"
            color="secondary"
            :rules="cuotaRules"
            @input="obtenerMontoTotal"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="4">
        <v-text-field
            :readonly="!canEditMontoTotal"
            :background-color="!canEditMontoTotal ? 'indigo lighten-5' : ''  "
            prepend-icon="paid"
            name="montoTotal"
            v-model.number="form.montoTotal"
            type="number"
            :label="$t('cobrox.convenio.labels.montoTotal')"
            :placeholder="$t('cobrox.convenio.labels.montoTotal')"
            :error="hasInputErrors('montoTotal')"
            :error-messages="getInputErrors('montoTotal')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="4">
        <v-text-field
            :readonly="isDisabled('montoCuota')"
            :background-color="isDisabled('montoCuota') ? 'indigo lighten-5' : ''  "
            prepend-icon="paid"
            name="montoCuota"
            v-model.number="form.montoCuota"
            type="number"
            :label="$t('cobrox.convenio.labels.montoCuota')"
            :placeholder="$t('cobrox.convenio.labels.montoCuota')"
            :error="hasInputErrors('montoCuota')"
            :error-messages="getInputErrors('montoCuota')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <date-input
            v-model="form.fechaPrimerPago"
            :label="$t('cobrox.convenio.labels.fechaPrimerPago')"
            :error="hasInputErrors('fechaPrimerPago')"
            :error-messages="getInputErrors('fechaPrimerPago')"
        />
      </v-col>


      <v-col cols="12" sm="6" v-if="$store.getters.hasPermission('CONVENIO_FUERA_DE_PAUTA')">
        <v-checkbox
            prepend-icon="history_edu"
            name="fueraDePauta"
            v-model="form.fueraDePauta"
            :label="$t('cobrox.convenio.labels.fueraDePauta')"
            :error="hasInputErrors('fueraDePauta')"
            :error-messages="getInputErrors('fueraDePauta')"
            color="secondary"
            @change="onInputFueraDePauta"
        ></v-checkbox>
      </v-col>

      <v-col cols="12" sm="12">
        <calculadora-quita
            v-if="$store.getters.hasPermission('QUITA_CALCULADOR') && deudasSelected.length > 0"
            ref="calculadora"
            :deudas="deudasSelected"
            card-view
        >
        </calculadora-quita>
      </v-col>


      <v-col cols="12" sm="12" >
        <v-card outlined>
          <v-card-title>Deudas</v-card-title>
          <v-card-text>
            <deuda-combobox v-model="form.deudas"
                            :input-errors="inputErrors"
                            multiple
                            :dni-deudor="form.dniDeudor"
                            :cuil-deudor="form.cuilDeudor"
                            table-view
                            @inputObject="setDeudasSelected"
                            only-active
                            :all-selected="autoSelectDeudas"
                            :not-selectable="deudasNotSelectable"
            />
          </v-card-text>
          <v-card-text v-if="hasInputErrors('deudas')">
            <v-alert type="error">{{getInputErrors('deudas').join(',')}}</v-alert>
          </v-card-text>
        </v-card>

      </v-col>



      <v-col cols="12" sm="12" >
        <v-card outlined>
          <v-card-title>Cobranzas</v-card-title>
          <v-card-subtitle>Cobranzas existentes que deben imputarse al convenio</v-card-subtitle>
          <v-card-text>
            <cobranza-combobox v-model="form.cobranzas"
                               :input-errors="inputErrors"
                               multiple
                               :dni-deudor="form.dniDeudor"
                               :cuil-deudor="form.cuilDeudor"
                               table-view
                               :all-selected="autoSelectCobranzas"
                               :not-selectable="cobranzasNotSelectable"
                               :propias="true"

            />
          </v-card-text>
          <v-card-text v-if="hasInputErrors('cobranzas')">
            <v-alert type="error">{{getInputErrors('cobranzas').join(',')}}</v-alert>
          </v-card-text>
        </v-card>
      </v-col>


    </v-row>
  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'

import DeudaCombobox from "../../combobox/DeudaCombobox";
import CobranzaCombobox from "../../combobox/CobranzaCombobox";

import {DayjsMixin, DateInput} from "@dracul/dayjs-frontend";
import CalculadoraQuita from "@/modules/cobrox/components/CalculadoraQuita/CalculadoraQuita.vue";
import PersonaProvider from "@/modules/cobrox/providers/PersonaProvider";
import ConvenioProvider from "@/modules/cobrox/providers/ConvenioProvider";


export default {
  name: "ConvenioForm",
  mixins: [InputErrorsByProps, RequiredRule, DayjsMixin],
  components: {
    CalculadoraQuita,
    DeudaCombobox,
    CobranzaCombobox,
    DateInput
  },
  props: {
    value: {type: Object, required: true},
    disableFields: {type: Array, default: () => []},
    autoSelectDeudas: {type: Boolean, default: false},
    autoSelectCobranzas: {type: Boolean, default: false},
    deudasNotSelectable: {type: Array, default: () => []},
    cobranzasNotSelectable: {type: Array, default: () => []},
    editing: {type: Boolean, default: false},
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    isDisabled() {
      return field => this.disableFields.includes(field)
    },
    canEditMontoTotal(){
      return this.$store.getters.hasPermission('CONVENIO_FUERA_DE_PAUTA') && this.form.fueraDePauta === true
    },
    cuotaRules(){
      return [...this.required, ...[ v => (this.hasPlanForCuota(v) || this.form.fueraDePauta) || 'No hay plan para estas cuotas' ]]
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
        this.$nextTick(() => {
          this.calculateMontoCuota()
        })
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    save() {
      this.$emit('save', this.form)
    },
    calculateMontoCuota() {
      this.form.montoCuota = (this.form.montoTotal > 0 && this.form.cuotas > 0) ? Math.round(this.form.montoTotal / this.form.cuotas) : 0
    },
    setDeudasSelected(deudas) {
      console.log('setDeudasSelected', this.editing, this.firstSelect)
      this.deudasSelected = deudas
      if(this.editing && this.firstSelect){
        this.firstSelect = false
        return
      }
      this.form.cuotas = null
      this.form.montoTotal = 0
    },
    hasPlanForCuota(cuota){
      return this.$refs.calculadora && this.$refs.calculadora.hasPlanForCuota(cuota)
    },
    obtenerMontoTotal(){
      this.form.montoTotal =  this.$refs.calculadora.getMontoForCuota(this.form.cuotas)
    },
    onInputCuilDeudor(){
      this.$nextTick(() => {
        if(this.form.cuilDeudor.length >= 11){
          this.findPersonaByCuil()
        }
      })

    },
    findPersonaByCuil(){
      this.loadingPersona = true
      PersonaProvider.findPersonaByCuil(this.form.cuilDeudor)
          .then(r => {
            if(r.data.findPersonaByCuil){
              this.persona = r.data.findPersonaByCuil
              this.form.dniDeudor = this.persona.nroDocumento
            }
          })
          .catch(e => console.error(e))
          .finally(() => {this.loadingPersona = false})
    },
    fetchConvenioByCuil(){
      this.loadingConvenios = true
      ConvenioProvider.fetchConvenioByCuil(this.form.cuilDeudor)
          .then(r => {
            this.convenios = r.data.fetchConvenioByCuil
          })
          .catch(e => console.error(e))
          .finally(() => {this.loadingConvenios = false})
    },
    onInputFueraDePauta(){
      this.$nextTick(() => {
        this.form.cuotas = null
        this.form.montoTotal = 0
      })

    }
  },
  data() {
    return {
      fechaPrimerPagoDateMenu: false,
      deudasSelected:[],
      persona: null,
      loadingPersona: false,
      loadingConvenios: false,
      convenios: [],
      firstSelect: true
    }
  }
}
</script>

<style scoped>

</style>

