<template>
  <v-row row wrap>

    <v-menu offset-y>

      <template v-slot:activator="{on}">
        <v-btn absolute right top color="secondary onSecondary--text" v-on="on">EXPORTS
          <v-icon right>more_vert</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>

          <v-list-item v-if="$store.getters.hasPermission('COBRANZA_EXPORT')"
                       @click="exportarCobranza"
          >Exportar
          </v-list-item>

          <v-list-item v-if="$store.getters.hasPermission('COBRANZA_EXPORT_AGENCIA')"
                       @click="exportarCobranzaAgencia"
          >Exportar Agencia
          </v-list-item>


          <v-list-item v-if="$store.getters.hasPermission('COBRANZA_EXPORT_IMPUTATION')"
                       @click="exportarCobranzaDeuda"
          >Exportar Imputación
          </v-list-item>

        </v-list>
      </v-card>

    </v-menu>


    <v-col cols="12">
      <v-row justify="space-between">
        <v-col cols="12" sm="6" md="10">
          <!-- FILTERS HERE -->

          <v-row align="center">

            <v-col v-if="$store.getters.hasPermission('COBRANZA_ESTADO')" cols="12" md="3">
              <estado-cobranza-combobox

                  v-model="filters[0].value"
                  @change="fetch"
              ></estado-cobranza-combobox>
            </v-col>

            <v-col v-if="$store.getters.hasPermission('AGENCIA_SHOW')" cols="12" md="3">
              <agencia-combobox
                  v-model="filters[1].value"
                  @input="fetch"
                  clearable
              ></agencia-combobox>
            </v-col>

            <v-col v-if="$store.getters.hasPermission('COBRANZA_FILTRO_FECHA_INGRESO')" cols="12" md="3">
              <date-input
                  label="Fecha desde"
                  v-model="filters[2].value"
                  @input="fetch"

              ></date-input>
            </v-col>

            <v-col v-if="$store.getters.hasPermission('COBRANZA_FILTRO_FECHA_INGRESO')" cols="12" md="3">
              <date-input
                  label="Fecha hasta"
                  v-model="filters[3].value"
                  @input="fetch"
              ></date-input>
            </v-col>

            <v-col cols="12" md="3">
              <date-input
                  label="Imputado desde"
                  v-model="filters[4].value"
                  @input="fetch"

              ></date-input>
            </v-col>

            <v-col cols="12" md="3">
              <date-input
                  label="Imputado hasta"
                  v-model="filters[5].value"
                  @input="fetch"
              ></date-input>
            </v-col>

            <v-col cols="12" md="2">
              <field-selector :fields="getFields" v-model="fieldsSelected"></field-selector>
            </v-col>

          </v-row>

        </v-col>
        <v-col cols="12" sm="6" md="2">

          <search-input @search="performSearch" v-model="search"/>

        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">

      <v-data-table
          dense
          class="mt-3"
          :headers="headersSelected"
          :items="items"
          :search="search"
          :single-expand="false"
          :server-items-length="totalItems"
          :loading="loading"
          :page.sync="pageNumber"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="orderBy"
          :sort-desc.sync="orderDesc"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
          @update:page="fetch"
          @update:sort-by="fetch"
          @update:sort-desc="fetch"
          @update:items-per-page="fetch"
      >


        <template v-slot:item.facturas="{ item }">

          <v-menu v-if="item.facturas && item.facturas.length > 0" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="purple"
                  x-small
                  dark
                  v-bind="attrs"
                  v-on="on"
              >
                facturas
              </v-btn>
            </template>
            <cobranza-detail :enable-details="false" :cobranza-id="item.id"></cobranza-detail>
          </v-menu>

        </template>

        <template v-slot:item.estado="{ item }">
          <v-btn v-if="item.estado === 'IMPOSIBLE_IMPUTAR'" color="red" icon text @click="imputarCobranza(item.id)">
            <v-icon>sync_problem</v-icon>
          </v-btn>
          {{ item.estado }}

        </template>


        <template v-slot:item.montoCobrado="{ item }">
          {{ currency }}{{ item.montoCobrado }}
        </template>

        <template v-slot:item.agencia="{ item }">
          {{ item.agencia ? item.agencia.nombre : '' }}
        </template>

        <template v-slot:item.fechaCobranza="{ item }">
          {{ getDateFormat(item.fechaCobranza) }}
        </template>

        <template v-slot:item.fechaImputacion="{ item }">
          {{ getDateFormat(item.fechaImputacion) }}
        </template>

        <template v-slot:item.createdAt="{ item }">
          {{ getDateFormat(item.createdAt) }}
        </template>

        <template v-slot:item.facturar="{ item }">
          <v-btn v-if="item.montoCobrado > 0" :disabled="(item.facturas && item.facturas.length > 0)"
                 :to="{name:'GenerarFacturasPage',params: {id: item.id}}">
            facturar
          </v-btn>
        </template>


        <template slot="no-data">
          <div class="text-xs-center" v-t="'common.noData'"></div>
        </template>

        <template slot="loading">
          <div class="text-xs-center" v-t="'common.loading'"></div>
        </template>

        <template v-slot:item.tipificacion="{ item }">
          <div v-if="item.tipificacion" class="text-xs-center">
            {{ item.tipificacion }}
            <v-btn
                x-small icon
                color="indigo"
                @click="tipificarCobranza(item)">
              <v-icon>edit_note</v-icon>
            </v-btn>
          </div>

          <v-btn v-else x-small outlined color="indigo"  @click="tipificarCobranza(item)">
            TIPIFICAR
          </v-btn>
        </template>

        <template v-slot:item.action="{ item }">
          <show-button @click="$emit('show', item)"/>
          <edit-button
              v-if="$store.getters.hasPermission('COBRANZA_UPDATE')  && ['IMPOSIBLE_IMPUTAR', 'IMPUTADO'].includes(item.estado)"
              @click="$emit('update', item)"/>
          <delete-button
              v-if="$store.getters.hasPermission('COBRANZA_DELETE') && ['IMPOSIBLE_IMPUTAR', 'IMPUTADO', 'PENDIENTE'].includes(item.estado)"
              @click="$emit('delete', item)"/>
        </template>

      </v-data-table>
    </v-col>


    <v-dialog v-model="dialogTipificarCobranza" fullscreen>
      <v-toolbar color="primary onPrimary--text">

        <v-toolbar-title>
          Tipificar Cobranza
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="dialogTipificarCobranza = false">
          <v-icon>close</v-icon>
        </v-btn>

      </v-toolbar>
      <tipificar-cobranza
          v-if="dialogTipificarCobranza"
          :cobranza="cobranzaPorTipificar"
          @cobranzaTipificada="onCobranzaTipificada"
      />
    </v-dialog>

    <v-snackbar v-model="snackbarTipificar" :timeout="3000" color="success" class="text-center" >
      Cobranza tipificada correctamente
    </v-snackbar>

    <v-dialog v-model="dialogErrorImputacion">
      <v-card>
        <v-card-title>Error al imputar</v-card-title>
        <v-card-text>
          <v-alert type="error"> {{ errorImputacion }}</v-alert>
        </v-card-text>

      </v-card>

    </v-dialog>

  </v-row>
</template>

<script>
import CobranzaProvider from "../../../../providers/CobranzaProvider";

import {DeleteButton, EditButton, ShowButton, SearchInput} from "@dracul/common-frontend"
import {DayjsMixin, DateInput} from "@dracul/dayjs-frontend";
import CobranzaDetail from "@/modules/cobrox/components/CobranzaDetail/CobranzaDetail";
import EstadoCobranzaCombobox from "@/modules/cobrox/combobox/EstadoCobranzaCombobox/EstadoCobranzaCombobox";
import JobProvider from "@/modules/cobrox/providers/JobProvider";
import AgenciaCombobox from "@/modules/cobrox/combobox/AgenciaCombobox/AgenciaCombobox";
import FieldSelector from "@/modules/cobrox/components/FieldSelector/FieldSelector";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import SuretyMixin from "@/modules/surety/mixins/SuretyMixin";
import CobranzaCustomProvider from "@/modules/cobrox/providers/CobranzaCustomProvider";
import TipificarCobranza from "@/modules/cobrox/components/TipificarCobranza/TipificarCobranza.vue";

export default {
  name: "CobranzaListCustom",
  components: {
    TipificarCobranza,
    FieldSelector,
    AgenciaCombobox,
    EstadoCobranzaCombobox,
    CobranzaDetail,
    DeleteButton,
    EditButton,
    ShowButton,
    SearchInput,
    DateInput
  },
  mixins: [DayjsMixin, CurrencyMixin, SuretyMixin],
  data() {
    return {
      snackbarTipificar: false,
      items: [],
      totalItems: null,
      loading: false,
      orderBy: null,
      orderDesc: false,
      itemsPerPage: 10,
      pageNumber: 1,
      search: '',
      fields: ['tipificacion', 'estado', 'errorImputacion', 'dniDeudor', 'cuilDeudor', 'createdAt', 'fechaCobranza', 'fechaImputacion', 'montoCobrado', 'canalCobro', 'cbu', 'nroProducto', 'idRapipago', 'agencia', 'jobId'],
      fieldsSelected: ['estado', 'dniDeudor', 'fechaCobranza', 'fechaImputacion', 'montoCobrado', 'canalCobro', 'agencia', 'tipificacion'],
      filters: [
        {
          field: 'estado',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value: null
        },
        {
          field: 'agencia',
          operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
          value: null
        },
        {
          field: 'fechaCobranza',
          operator: 'gte', //(eq|contain|regex|gt|lt|lte|gte)
          value: null
        },
        {
          field: 'fechaCobranza',
          operator: 'lte', //(eq|contain|regex|gt|lt|lte|gte)
          value: null
        },
        {
          field: 'fechaImputacion',
          operator: 'gte', //(eq|contain|regex|gt|lt|lte|gte)
          value: null
        },
        {
          field: 'fechaImputacion',
          operator: 'lte', //(eq|contain|regex|gt|lt|lte|gte)
          value: null
        }
      ],
      cobranzaImputada: null,
      dialogErrorImputacion: false,
      errorImputacion: null,
      dialogTipificarCobranza: false,
      cobranzaPorTipificar: null
    }
  },
  computed: {
    getFields() {
      return this.filterViewFields(this.fields)
    },
    getFieldSelected() {
      return this.filterViewFields(this.fieldsSelected)
    },
    headersSelected() {
      return this.headers.filter(h => this.getFieldSelected.includes(h.value) || h.value === 'action')
    },
    headers() {
      return [
        //Entity Headers
        {text: this.$t('cobrox.cobranza.labels.estado'), value: 'estado'},
        {text: this.$t('cobrox.cobranza.labels.errorImputacion'), value: 'errorImputacion'},
        ...(this.$store.getters.hasPermission("FACTURA_SHOW") ? [{
          text: this.$t('cobrox.cobranza.labels.facturas'),
          value: 'facturas'
        }] : []),
        {text: this.$t('cobrox.cobranza.labels.fechaCobranza'), value: 'fechaCobranza'},
        {text: this.$t('cobrox.cobranza.labels.fechaImputacion'), value: 'fechaImputacion'},
        {text: this.$t('cobrox.cobranza.labels.fechaCreacion'), value: 'createdAt'},
        {text: this.$t('cobrox.cobranza.labels.montoCobrado'), value: 'montoCobrado'},
        {text: this.$t('cobrox.cobranza.labels.dniDeudor'), value: 'dniDeudor'},
        {text: this.$t('cobrox.cobranza.labels.cuilDeudor'), value: 'cuilDeudor'},
        //{text: this.$t('cobrox.cobranza.labels.nombreDeudor'), value: 'nombreDeudor'},
        {text: this.$t('cobrox.cobranza.labels.canalCobro'), value: 'canalCobro'},
        {text: this.$t('cobrox.cobranza.labels.cbu'), value: 'cbu'},
        {text: this.$t('cobrox.cobranza.labels.nroProducto'), value: 'nroProducto'},
        {text: this.$t('cobrox.cobranza.labels.idRapipago'), value: 'idRapipago'},
        {text: this.$t('cobrox.cobranza.labels.agencia'), value: 'agencia'},
        {text: 'jobId', value: 'jobId'},
        ...(this.$store.getters.hasPermission("FACTURA_CREATE") ? [{
          text: this.$t('cobrox.cobranza.labels.facturar'),
          value: 'facturar'
        }] : []),

        ...(this.$store.getters.hasPermission("COBRANZA_TIPIFICAR") ? [{text: 'Tipificacion', value: 'tipificacion', sortable: false}] : []),

        //Actions

        {text: this.$t('common.actions'), value: 'action', sortable: false},
      ]
    },
    getOrderBy() {
      return (Array.isArray(this.orderBy)) ? this.orderBy[0] : this.orderBy
    },
    getOrderDesc() {
      return (Array.isArray(this.orderDesc)) ? this.orderDesc[0] : this.orderDesc
    }
  },
  created() {
    this.fetchFieldPermission('Cobranza')
    this.fetch()
  },
  /*  watch:{
      filters: {
        deep: true,
        handler(){
          this.fetch()
        }
      }
    },*/
  methods: {
    tipificarCobranza(cobranza) {
      this.cobranzaPorTipificar = cobranza
      this.dialogTipificarCobranza = true
    },
    onCobranzaTipificada() {
      this.dialogTipificarCobranza = false
      this.snackbarTipificar = true
      this.fetch()
    },
    performSearch() {
      this.pageNumber = 1
      this.fetch()
    },
    fetch() {
      this.loading = true
      CobranzaProvider.paginateCobranza(
          this.pageNumber,
          this.itemsPerPage,
          this.search,
          this.filters,
          this.getOrderBy,
          this.getOrderDesc
      ).then(r => {
        this.items = r.data.paginateCobranza.items
        this.totalItems = r.data.paginateCobranza.totalItems
      }).catch(err => {
        console.error(err)
      }).finally(() => this.loading = false)
    },
    exportarCobranza() {
      JobProvider.exportarCobranza(this.filters, this.search)
          .then(resp => {
            let jobId = resp.data.exportarCobranza
            this.$router.push({name: 'JobPage', params: {id: jobId}})
          })
    },
    exportarCobranzaAgencia() {
      let agencyFormat = true
      JobProvider.exportarCobranza(this.filters, this.search, agencyFormat)
          .then(resp => {
            let jobId = resp.data.exportarCobranza
            this.$router.push({name: 'JobPage', params: {id: jobId}})
          })
    },
    exportarCobranzaDeuda() {
      JobProvider.exportarCobranzaDeuda(this.filters, this.search)
          .then(resp => {
            let jobId = resp.data.exportarCobranzaDeuda
            this.$router.push({name: 'JobPage', params: {id: jobId}})
          })
    },
    imputarCobranza(id) {
      this.dialogErrorImputacion = false
      CobranzaCustomProvider.imputarCobranza(id)
          .then(r => {
            this.cobranzaImputada = r.data.imputarCobranza
            console.log("imputarCobranza", r)
            this.fetch()
          })
          .catch(e => {
            this.errorImputacion = e.message.replace("GraphQL error:", "")
            this.dialogErrorImputacion = true
          })
    }
  }

}
</script>


