import graphqlClient from "../../../apollo";

class RedmineProvider {

    fetchRedmineIssues(offset, limit, orderBy, orderDesc, status, tracker_id) {
        return graphqlClient.query({
            query: require('./gql/fetchRedmineIssues.graphql'),
            variables: {offset, limit, orderBy, orderDesc, status, tracker_id},
            fetchPolicy: 'network-only'
        })
    }

    fetchRedmineIssuesByAuthor(offset, limit, orderBy, orderDesc, status, authorUsername, tracker_id) {
        return graphqlClient.query({
            query: require('./gql/fetchRedmineIssuesByAuthor.graphql'),
            variables: {offset, limit,  orderBy, orderDesc, status, authorUsername, tracker_id},
            fetchPolicy: 'network-only'
        })
    }

    fetchRedmineIssuesByCustomField(customField, customFieldValue, tracker_id, offset, limit,  orderBy, orderDesc) {
        return graphqlClient.query({
            query: require('./gql/fetchRedmineIssuesByCustomField.graphql'),
            variables: {customField, customFieldValue, tracker_id, offset, limit,  orderBy, orderDesc},
            fetchPolicy: 'network-only'
        })
    }

    findRedmineIssueById(id) {
        return graphqlClient.query({
            query: require('./gql/findRedmineIssueById.graphql'),
            variables: {id},
            fetchPolicy: 'network-only'
        })
    }

    fetchRedmineTrackers() {
        return graphqlClient.query({
            query: require('./gql/fetchRedmineTrackers.graphql'),
            fetchPolicy: 'network-only'
        })
    }

    fetchRedmineDefaultProjectTrackers() {
        return graphqlClient.query({
            query: require('./gql/fetchRedmineDefaultProjectTrackers.graphql'),
            fetchPolicy: 'network-only'
        })
    }

    fetchRedmineProjectTrackers(id) {
        return graphqlClient.query({
            query: require('./gql/fetchRedmineProjectTrackers.graphql'),
            fetchPolicy: 'network-only',
            variables: {id}
        })
    }

    fetchRedmineCustomFieldsByTracker(trackerId) {
        return graphqlClient.query({
            query: require('./gql/fetchRedmineCustomFieldsByTracker.graphql'),
            fetchPolicy: 'network-only',
            variables: {trackerId}
        })
    }

    createRedmineIssue(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/createRedmineIssue.graphql'),
            variables: {input}
        })
    }
}

export default new RedmineProvider()


