
const messages = {
    en: {
       role: {
            permissions: {
                CONVENIO_MENU: "Access to Convenio",
                CONVENIO_SHOW: "View Convenio",
                CONVENIO_CREATE: "Create Convenio",
                CONVENIO_UPDATE: "Modify Convenio",
                CONVENIO_DELETE: "Delete Convenio",
            }
       }
    },
    es: {
        role: {
            permissions: {
                CONVENIO_MENU: "Acceder Convenio",
                CONVENIO_SHOW: "Ver Convenio",
                CONVENIO_CREATE: "Crear Convenio",
                CONVENIO_UPDATE: "Modificar Convenio",
                CONVENIO_DELETE: "Eliminar Convenio",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                CONVENIO_MENU: "Acessar Convenio",
                CONVENIO_SHOW: "Ver Convenio",
                CONVENIO_CREATE: "Criar Convenio",
                CONVENIO_UPDATE: "Modificar Convenio",
                CONVENIO_DELETE: "Eliminar Convenio",
            }
       }
    }
}

export default messages
    