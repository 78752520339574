<template>
  <v-card outlined>
    <v-card-title>
      Historial de estados
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <thead>
        <tr>
          <th>Estado</th>
          <th>Fecha</th>
          <th>Fecha Fin</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(estadoHistorico, index) in estadoHistorico" :key="index">
          <td>{{ estadoHistorico.estado }}</td>
          <td>{{ getDateTimeFormat(estadoHistorico.fecha) }}</td>
          <td>{{ estadoHistorico.fechaFin ? getDateTimeFormat(estadoHistorico.fechaFin) : '' }}</td>

        </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {DayjsMixin} from "@dracul/dayjs-frontend";
export default {
  name: "EstadoHistoricoSimpleTable",
  mixins: [DayjsMixin],
  props: {
    estadoHistorico: {type: Array}
  }
}
</script>

<style scoped>

</style>
