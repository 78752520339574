import graphqlClient from "../../../apollo";

class FacturaProvider {

    findFactura(id) {
        return graphqlClient.query({
            query: require('./gql/Factura/findFactura.graphql'),
            variables: {id:id}
        })
    }

    fetchFactura() {
        return graphqlClient.query({query: require('./gql/Factura/fetchFactura.graphql')})
    }
    
    paginateFactura(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Factura/paginateFactura.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }
    
    

    createFactura(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Factura/createFactura.graphql'),
            variables: {input}
        })
    }
    
    updateFactura(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Factura/updateFactura.graphql'),
            variables: {id, input}
        })
    }
    
     deleteFactura(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Factura/deleteFactura.graphql'),
            variables: {id}
        })
    }

}

export default new FacturaProvider()


