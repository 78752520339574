<template>
<v-container>

  <v-card>
    <v-card-title>
      Reporte BCRA
    </v-card-title>
    <v-card-text>
      <v-form ref="form">

      <v-row>
        <v-col cols="12" md="4">
          <compra-combobox v-model="formFilter.compras" clearable multiple></compra-combobox>
        </v-col>

        <v-col cols="12" md="4">
          <date-input
              label="Fecha de mora desde"
              v-model="formFilter.fechaMora.desde"
          ></date-input>
        </v-col>
        <v-col cols="12" md="4">
          <date-input
              label="Fecha de mora hasta"
              v-model="formFilter.fechaMora.hasta"
          ></date-input>
        </v-col>
        <v-col cols="12" md="4">
          <date-input
              label="Fecha Objetivo"
              v-model="formFilter.fechaObjetivo"
              :rules="required"
          ></date-input>
        </v-col>
      </v-row>
      </v-form>

    </v-card-text>

    <v-card-actions v-if="jobId">
      <v-spacer></v-spacer>
      <v-btn
          :to="{name:'JobPage',params:{id: jobId}}"
          color="green" dark
      >
        Supervisar tarea
      </v-btn>
    </v-card-actions>

    <v-card-actions v-else>
      <v-spacer></v-spacer>
      <v-btn color="primary onPrimary--text" @click="generarReporte">Generar</v-btn>
    </v-card-actions>
  </v-card>

</v-container>
</template>

<script>
import CompraCombobox from "@/modules/cobrox/combobox/CompraCombobox/CompraCombobox"
import JobProvider from "@/modules/cobrox/providers/JobProvider";
import {DateInput} from "@dracul/dayjs-frontend";
import {RequiredRule} from "@dracul/common-frontend";

export default {
  name: "ReporteBcraPage",
  components: {CompraCombobox, DateInput},
  mixins: [RequiredRule],
  data(){
    return {
      jobId: null,
      formFilter: {
        compras: [],
        fechaMora: {
          desde: null,
          hasta: null
        },
        fechaObjetivo: null
      }
    }
  },
  methods: {
    generarReporte(){
      if(this.$refs.form.validate()){
        JobProvider.bcraReporte(this.formFilter)
            .then(res => {
              this.jobId = res.data.bcraReporte
            })
      }

    }
  }
}
</script>

<style scoped>

</style>
