<template>
    <v-row>
        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.idDeuda" :label="$t('cobrox.deuda.labels.idDeuda')" icon="grid_4x4"/>
                 <show-field :value="getDateFormat(item.fechaMora)" :label="$t('cobrox.deuda.labels.fechaMora')" icon="date_range"/>
                 <show-field :value="item.cuilDeudor" :label="$t('cobrox.deuda.labels.cuilDeudor')" icon="contact_page"/>
                 <show-field :value="String(item.intereses)" :label="$t('cobrox.deuda.labels.intereses')" icon="attach_money"/>
                 <show-field :value="String(item.imputadoContable)" :label="$t('cobrox.deuda.labels.imputadoContable')" icon="money_off"/>
                 <show-field :value="String(item.idClienteColppy)" :label="$t('cobrox.deuda.labels.idClienteColppy')" icon="badge"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.cartera" :label="$t('cobrox.deuda.labels.cartera')" icon="shop"/>
                 <show-field :value="item.nombreDeudor" :label="$t('cobrox.deuda.labels.nombreDeudor')" icon="face"/>
                 <show-field :value="String(item.capitalCompra)" :label="$t('cobrox.deuda.labels.capitalCompra')" icon="request_quote"/>
                 <show-field :value="item.facturas.map(i => i.id).join(', ')" :label="$t('cobrox.deuda.labels.facturas')" icon="Facturas"/>
                 <show-field :value="item.estado" :label="$t('cobrox.deuda.labels.estado')" icon="fact_check"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="getDateFormat(item.fechaCompra)" :label="$t('cobrox.deuda.labels.fechaCompra')" icon="event"/>
                 <show-field :value="item.dniDeudor" :label="$t('cobrox.deuda.labels.dniDeudor')" icon="badge"/>
                 <show-field :value="String(item.capitalTotal)" :label="$t('cobrox.deuda.labels.capitalTotal')" icon="paid"/>
                 <show-field :value="String(item.saldoCapitalCompra)" :label="$t('cobrox.deuda.labels.saldoCapitalCompra')" icon="price_check"/>
                 <show-field :value="item.jurisdiccion" :label="$t('cobrox.deuda.labels.jurisdiccion')" icon="flag"/>
            </v-list>
        </v-col>

    </v-row>
</template>
<script>
    import {ShowField} from '@dracul/common-frontend'
    import {DayjsMixin} from "@dracul/dayjs-frontend";

    export default {
        name: 'DeudaShowData',
        components: {ShowField},
        mixins: [DayjsMixin],
        props: {
            item: {type: Object, required: true}
        }
    }
</script>

