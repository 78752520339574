import graphqlClient from "../../../apollo";

class FieldPermissionProvider {

    findFieldPermission(id) {
        return graphqlClient.query({
            query: require('./gql/FieldPermission/findFieldPermission.graphql'),
            variables: {id:id},
            fetchPolicy: "network-only"
        })
    }

    findFieldPermissionByEntityWithMyRole(entity) {
        return graphqlClient.query({
            query: require('./gql/FieldPermission/findFieldPermissionByEntityWithMyRole.graphql'),
            variables: {entity:entity},
            fetchPolicy: "network-only"
        })
    }

    fetchFieldPermission() {
        return graphqlClient.query({query: require('./gql/FieldPermission/fetchFieldPermission.graphql')})
    }

    paginateFieldPermission(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/FieldPermission/paginateFieldPermission.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }



    createFieldPermission(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/FieldPermission/createFieldPermission.graphql'),
            variables: {input}
        })
    }

    updateFieldPermission(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/FieldPermission/updateFieldPermission.graphql'),
            variables: {id, input}
        })
    }

     deleteFieldPermission(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/FieldPermission/deleteFieldPermission.graphql'),
            variables: {id}
        })
    }

}

export default new FieldPermissionProvider()


