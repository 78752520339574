<template>
  <v-container fluid>
    <v-card>
      <v-card-title>Eficiencia de Agencias</v-card-title>
      <v-card-text>
        <v-form ref="form" autocomplete="off">
          <v-row align="center">
            <v-col cols="12" md="3">
              <month-picker v-model="mes" label="Mes Imputado" :rules="required"></month-picker>
            </v-col>
            <v-col cols="12" md="7">
              <reporte-stock-combobox v-model="reporteStock" clearable></reporte-stock-combobox>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn class="primary OnPrimary--text" @click="fetchEficiencia">Consultar</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text>
        <v-data-table
            :items="items"
            :headers="headers"
            :loading="loading"
            :items-per-page="10"
        >

          <template v-slot:item.Capital="{item}">
            {{currency}}{{ item.Capital.toLocaleString('es-AR') }}
          </template>

          <template v-slot:item.Intereses="{item}">
            {{currency}}{{ item.Intereses.toLocaleString('es-AR') }}
          </template>

          <template v-slot:item.Total="{item}">
            {{currency}}{{ item.Total.toLocaleString('es-AR') }}
          </template>

          <template v-slot:item.Recupero="{item}">
            {{currency}}{{ item.Recupero.toLocaleString('es-AR') }}
          </template>

          <template v-slot:item.EficienciaCapital="{item}">
            {{ (item.Recupero / item.Capital  * 100).toFixed(2) }}%
          </template>

          <template v-slot:item.EficienciaTotal="{item}">
            {{ (item.Recupero / item.Total * 100).toFixed(2) }}%
          </template>


        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {MonthPicker} from "@dracul/dayjs-frontend"
import {RequiredRule} from "@dracul/common-frontend"
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import ReporteStockCombobox from "@/modules/cobrox/combobox/ReporteStockCombobox/ReporteStockCombobox";
import EficienciaProvider from "@/modules/cobrox/providers/EficienciaProvider";

export default {
  name: "EficienciaAgenciaPage",
  components: {ReporteStockCombobox, MonthPicker},
  mixins: [RequiredRule, CurrencyMixin],
  data() {
    return {
      loading: false,
      mes: null,
      reporteStock: null,
      items: [],
      headers: [
        {text: 'Agencia', value: 'Agencia'},
        {text: 'Compra', value: 'Compra'},
        {text: 'Capital', value: 'Capital'},
        {text: 'Intereses', value: 'Intereses'},
        {text: 'Total', value: 'Total'},
        {text: 'Recupero', value: 'Recupero'},
        {text: 'Eficiencia Capital', value: 'EficienciaCapital'},
        {text: 'Eficiencia Total', value: 'EficienciaTotal'},
      ]
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    fetchEficiencia() {
      if(!this.validate()){
        return
      }
      this.loading = true
      EficienciaProvider.eficienciaAgencia(this.mes, this.reporteStock)
          .then(r => {
            this.items = r.data.eficienciaAgencia
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
