<template>

  <v-card flat>

    <v-card-text>
      <v-row>


        <v-col cols="12" sm="6">
          <v-chip class="fwidth" >
            <b>IdConvenio: </b> <span class="ml-1">{{pago.idConvenio}}</span>
          </v-chip>
        </v-col>

        <v-col cols="12" sm="6">
          <v-chip class="fwidth" >
            <b>IdPago: </b> <span class="ml-1">{{pago._id}}</span>
          </v-chip>
        </v-col>

        <v-col cols="12" sm="6">
          <v-chip class="fwidth" >
            <b>DNI: </b> <span class="ml-1">{{pago.dniDeudor}}</span>
          </v-chip>
        </v-col>

        <v-col cols="12" sm="6">
          <v-chip class="fwidth" >
            <b>CUIL: </b> <span class="ml-1">{{pago.cuilDeudor}}</span>
          </v-chip>

        </v-col>

        <v-col cols="12" sm="6">
          <v-chip class="fwidth" >
            <b>Fecha Pago: </b> <span class="ml-1">{{getDateFormat(pago.fechaPago)}}</span>
          </v-chip>

        </v-col>

        <v-col cols="12" sm="6">
          <v-chip class="fwidth" >
            <b>Fecha Comprobante: </b> <span class="ml-1">{{getDateFormat(pago.fechaComprobante)}}</span>
          </v-chip>
        </v-col>

        <v-col cols="12" sm="6">
          <v-chip class="fwidth" >
            <b>Cuota: </b> <span class="ml-1">{{pago.cuota}}</span>
          </v-chip>
        </v-col>


        <v-col cols="12" sm="6">
          <v-chip class="fwidth" >
            <b>Pago Parcial: </b> <span class="ml-1"> {{pago.parcial ? 'Si' : 'No'}}</span>
          </v-chip>
        </v-col>


        <v-col cols="12" sm="6">
          <v-chip class="fwidth" >
            <b>Monto: </b> <span class="ml-1"> {{currency}} {{pago.monto.toLocaleString('es-AR')}}</span>
          </v-chip>

        </v-col>

        <v-col cols="12" sm="6">
          <v-chip class="fwidth" >
            <b>Canal de Pago: </b> <span class="ml-1"> {{pago.canalPago}}</span>
          </v-chip>
        </v-col>


        <v-col cols="12" sm="6">
          <v-chip class="fwidth" >
            <b>Cuenta Terceros: </b> <span class="ml-1"> {{pago.cuentaTerceros ? 'Si' : 'No'}}</span>
          </v-chip>
        </v-col>

        <v-col cols="12" sm="6">
          <v-chip class="fwidth" >
            <b>DNI Terceros: </b> <span class="ml-1"> {{pago.dniTerceros}}</span>
          </v-chip>
        </v-col>

        <v-col cols="12" sm="6">

          <v-card outlined v-if="pago.canalPago === 'MERCADO PAGO'">
            <v-card-title>Mercado Pago</v-card-title>
            <v-card-text :style="{maxHeight: '200px', overflow: 'scroll'}">
              <pre>{{JSON.stringify(pago.mercadoPago, null, 4)}}</pre>

            </v-card-text>
          </v-card>

          <v-card outlined v-else>
            <v-card-title>Comprobante</v-card-title>
            <v-img max-height="200"  contain :src="pago.comprobante">

            </v-img>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn icon :href="pago.comprobante" target="_blank"><v-icon>link</v-icon></v-btn>
            </v-card-actions>
          </v-card>


        </v-col>

        <v-col cols="12" sm="6" class="">
          <v-checkbox v-model="pago.verificado" label="Verificado"></v-checkbox>
        </v-col>



      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          large color="primary"
          :loading="loading"
          @click="verificarPago"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>

import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import ConvenioProvider from "@/modules/cobrox/providers/ConvenioProvider";
import {DayjsMixin} from "@dracul/dayjs-frontend";

export default {
  name: "VerificarPago",
  components: {},
  props: {
    pago: {type: Object, required: true}
  },
  mixins: [CurrencyMixin, DayjsMixin],
  data: () => ({
    loading: false,
  }),
  created() {
  },
  computed: {},
  methods: {
    async verificarPago() {

      try {
        this.loading = true
        let r = await ConvenioProvider.verificarPagoConvenio(this.pago._id,
            {verificado: this.pago.verificado}
        )
        this.$emit('updatePago', r.data.verificarPagoConvenio)
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        this.loading = false
      }


    }
  }
}
</script>

<style scoped>
.fwidth{
  width: 100%!important;
}
</style>
