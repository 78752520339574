<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12" class="pb-0">
          <cobranza-detail-mini
              title="Cobranza por tipificar"
              :cobranza="cobranza"
              highlight-monto-cobrado
          ></cobranza-detail-mini>
        </v-col>


        <!--LOGICA CONVENIOS-->
        <template v-if="loadingConvenios === false">


          <!--SIN CONVENIOS-->
          <v-col cols="12" v-if="convenios.length === 0">
            <v-card>
              <v-card-text class="d-flex flex-column justify-center">
                <v-alert type="warning">No hay convenios vigentes por lo que no es posible tipificar la cobranza. Debe
                  registrar un convenio para poder tipificar.
                </v-alert>
                <v-btn
                    color="primary onPrimary--text"
                    :href="linkPersona" outlined
                >REGISTRAR CONVENIO
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>



          <v-col cols="12" v-if="convenios.length > 0">
            <v-card outlined class="mt-3">
              <v-card-text >
                <v-row no-gutters>
                  <v-col cols="12" class="text-center">
                    <v-select v-model="tipificacion"
                              label="Tipificación"
                              class="d-inline-block mr-3"
                              hide-details outlined
                              :style="{maxWidth: '300px'}"
                              :items="['CUOTA','CANCELATORIO','PARCIAL']"
                              persistent-hint
                    >

                    </v-select>

                    <v-btn large color="primary" :disabled="!tipificacion || !convenioVinculado" @click="tipificarCobranza" :loading="loading">Tipificar</v-btn>

                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-checkbox
                        v-if="$store.getters.hasPermission('COBRANZA_TIPIFICAR_FORZADO') && errorTipificacion"
                        v-model="forzar"
                        label="Forzar tipificación"
                        hide-details
                        class="d-inline-block"
                    ></v-checkbox>
                  </v-col>
                </v-row>


              </v-card-text>
              <v-card-text v-if="errorTipificacion">
                <v-alert type="error">{{errorTipificacion}}</v-alert>
              </v-card-text>
            </v-card>
          </v-col>

          <!--UNICO CONVENIO-->
          <v-col cols="12" v-if="convenios.length === 1">
            <convenio-card :convenio="convenios[0]"></convenio-card>
          </v-col>


          <v-col cols="12" v-if="convenios.length > 1">
                <v-alert type="info" dense v-if="!convenioVinculado">
                    Multiples convenios registrados. Selecciona un convenio para tipificar la cobranza.
                </v-alert>

            <v-radio-group v-model="convenioVinculado">
            <v-expansion-panels>

              <v-expansion-panel v-for="convenio in convenios" :key="convenio.id">
                <v-expansion-panel-header>
                  <v-radio  :value="convenio.id">
                    <template v-slot:label>
                      <b>Convenio</b>
                      <v-chip class="mx-1">Creado: {{ getDateFormat(convenio.createdAt) }}</v-chip>
                      <v-chip class="mx-1">Deudas: {{ convenio.deudas.length }}</v-chip>
                      <v-chip class="mx-1">Cuotas: {{ convenio.cuotas }}</v-chip>
                      <v-chip class="mx-1">Monto Total: {{currency}}{{ convenio.montoTotal }}</v-chip>
                      <v-chip class="mx-1" color="yellow lighten-3">Monto Cuota: {{currency}}{{ convenio.montoCuota }}</v-chip>
                    </template>
                  </v-radio>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <convenio-card :convenio="convenio"></convenio-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

            </v-expansion-panels>
            </v-radio-group>

          </v-col>

        </template>

      </v-row>
    </v-card-text>



  </v-card>
</template>

<script>
import CobranzaProvider from "@/modules/cobrox/providers/CobranzaProvider";
import CobranzaDetailMini from "@/modules/cobrox/components/CobranzaDetailMini/CobranzaDetailMini.vue";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import DeudaTotalMixin from "@/modules/cobrox/mixins/DeudaTotalMixin";
import ConvenioProvider from "@/modules/cobrox/providers/ConvenioProvider";
import ConvenioCard from "@/modules/cobrox/components/ConvenioCard/ConvenioCard.vue";
import {DayjsMixin} from "@dracul/dayjs-frontend";

export default {
  name: "TipificarCobranza",
  components: {CobranzaDetailMini, ConvenioCard},
  props: {
    cobranza: {type: Object, required: true}
  },
  mixins: [CurrencyMixin, DeudaTotalMixin, DayjsMixin],
  data: () => ({
    tab: 0,
    loading: false,
    loadingConvenios: false,
    loadingDeudas: false,
    loadingCobranzas: false,
    tipificacion: null,
    convenioVinculado: null,
    forzar: false,
    errorTipificacion: null,
    convenios: [],
    cobranzas: [],
    deudas: []
  }),
  created() {
    this.fetchConvenio()
  },
  computed: {
    cobranzasTotal() {
      return this.cobranzas.reduce((a, v) => a + v.montoCobrado, 0)
    },
    deudasActivas() {
      return this.deudas.filter(d => d.estado == 'ACTIVO')
    },
    deudasTotal() {
      return this.deudasActivas.reduce((a, v) => a + v.monto, 0)
    },
    linkPersona() {
      if (this.cobranza) {
        if (this.cobranza.cuilDeudor) {
          return `/persona/cuil/${this.cobranza.cuilDeudor}`
        } else if (this.cobranza.dniDeudor) {
          return `/persona/dni/${this.cobranza.dniDeudor}`
        }
      }
      return ''
    }
  },
  methods: {
    fetchConvenio() {
      if (this.cobranza) {
        if (this.cobranza.cuilDeudor) {
          this.loadingConvenios = true
          ConvenioProvider.fetchConvenioByCuil(this.cobranza.cuilDeudor)
              .then(r => {
                this.convenios = r.data.fetchConvenioByCuil
                if (this.convenios.length === 1) {
                  this.convenioVinculado = this.convenios[0].id
                }
              })
              .catch((e) => console.error(e))
              .finally(() => this.loadingConvenios = false)
        }
      }
    },
    tipificarCobranza() {
      this.loading = true
      CobranzaProvider.tipificarCobranza(this.cobranza.id, this.tipificacion, this.convenioVinculado, this.forzar)
          .then(r => {
            this.$emit('cobranzaTipificada', r.data.tipificarCobranza)
          })
          .catch(e => {
            this.errorTipificacion = e.message.replace('GraphQL error:', '')
            console.error(e)
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
