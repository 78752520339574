<template>
    <v-form ref="form" autocomplete="off" @submit.prevent="save" >
        <v-row>

                   <v-col cols="12" sm="6">
                        <agencia-combobox v-model="form.agencia" :input-errors="inputErrors" />
                   </v-col>


                   <v-col cols="12" sm="6">
                        <compra-combobox v-model="form.compra" :input-errors="inputErrors" />
                   </v-col>


                    <v-col cols="12" sm="6">
                        <v-text-field

                                prepend-icon="calculate"
                                name="ratioDesde"
                                v-model.number="form.ratioDesde"
                                type="number"
                                :label="$t('cobrox.comision.labels.ratioDesde')"
                                :placeholder="$t('cobrox.comision.labels.ratioDesde')"
                                :error="hasInputErrors('ratioDesde')"
                                :error-messages="getInputErrors('ratioDesde')"
                                color="secondary"
                                :rules="required"
                        ></v-text-field>
                    </v-col>


                    <v-col cols="12" sm="6">
                        <v-text-field

                                prepend-icon="calculate"
                                name="ratioHasta"
                                v-model.number="form.ratioHasta"
                                type="number"
                                :label="$t('cobrox.comision.labels.ratioHasta')"
                                :placeholder="$t('cobrox.comision.labels.ratioHasta')"
                                :error="hasInputErrors('ratioHasta')"
                                :error-messages="getInputErrors('ratioHasta')"
                                color="secondary"
                                :rules="required"
                        ></v-text-field>
                    </v-col>


                    <v-col cols="12" sm="6">
                        <v-text-field

                                prepend-icon="percent"
                                name="comision"
                                v-model.number="form.comision"
                                type="number"
                                :label="$t('cobrox.comision.labels.comision')"
                                :placeholder="$t('cobrox.comision.labels.comision')"
                                :error="hasInputErrors('comision')"
                                :error-messages="getInputErrors('comision')"
                                color="secondary"
                                :rules="required"
                        ></v-text-field>
                    </v-col>

        </v-row>
    </v-form>
</template>

<script>

    import {InputErrorsByProps, RequiredRule } from '@dracul/common-frontend'

    import AgenciaCombobox from "../../../../combobox/AgenciaCombobox";
import CompraCombobox from "../../../../combobox/CompraCombobox";





    export default {
        name: "ComisionForm",
        mixins: [InputErrorsByProps, RequiredRule    ],
        components: {
          AgenciaCombobox,
          CompraCombobox,
        },
        props:{
            value: {
                type: Object,
                required: true
            },
        },
        computed: {
           form: {
                get() { return this.value },
                set(val) {this.$emit('input', val)}
            }
        },
         watch: {
            form: {
                handler(newVal) {
                    this.$emit('input', newVal)
                },
                deep: true
            }
        },
        methods: {
            validate(){
              return this.$refs.form.validate()
            }
        },
        data(){
            return {

            }
        }
    }
</script>

<style scoped>

</style>

