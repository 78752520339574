import graphqlClient from "../../../apollo";

class CobranzaCustomProvider {

    findCobranzaPopulated(id) {
        return graphqlClient.query({
            query: require('./gql/Cobranza/cobranzaFindPopulated.graphql'),
            variables: {id:id}
        })
    }

    imputarCobranza(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Cobranza/imputarCobranza.graphql'),
            variables: {id:id}
        })
    }

    tipificarCobranza(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Cobranza/tipificarCobranza.graphql'),
            variables: {id:id}
        })
    }


}

export default new CobranzaCustomProvider()


