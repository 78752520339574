<template>
    <v-form ref="form" autocomplete="off" @submit.prevent="save" >
        <v-row>

                   <v-col cols="12" sm="12">

                     <show-field label="FECHA" :value="getDateTimeCustomFormat(form.fecha, 'YYYY-MM','America/Argentina/Buenos_Aires')" icon="event" ></show-field>

                    </v-col>


                    <v-col cols="12" sm="12">
                        <v-checkbox
                                prepend-icon="lock"
                                name="cerrada"
                                v-model="form.cerrada"
                                :label="$t('cobrox.cajaMensual.labels.cerrada')"
                                :error="hasInputErrors('cerrada')"
                                :error-messages="getInputErrors('cerrada')"
                                color="secondary"

                        ></v-checkbox>
                    </v-col>

        </v-row>
    </v-form>
</template>

<script>

    import {InputErrorsByProps, RequiredRule } from '@dracul/common-frontend'



    import {DayjsMixin} from "@dracul/dayjs-frontend";
    import {ShowField} from "@dracul/common-frontend";



    export default {
        name: "CajaMensualForm",
        mixins: [InputErrorsByProps, RequiredRule , DayjsMixin   ],
        components: { ShowField },
        props:{
            value: {
                type: Object,
                required: true
            },
        },
        computed: {
           form: {
                get() { return this.value },
                set(val) {this.$emit('input', val)}
            }
        },
         watch: {
            form: {
                handler(newVal) {
                    this.$emit('input', newVal)
                },
                deep: true
            }
        },
        methods: {
            validate(){
              return this.$refs.form.validate()
            }
        },
        data(){
            return {
                fechaDateMenu: false
            }
        }
    }
</script>

<style scoped>

</style>

