
const messages = {
    en: {
       role: {
            permissions: {
                COMISION_MENU: "Access to Comision",
                COMISION_SHOW: "View Comision",
                COMISION_CREATE: "Create Comision",
                COMISION_UPDATE: "Modify Comision",
                COMISION_DELETE: "Delete Comision",
            }
       }
    },
    es: {
        role: {
            permissions: {
                COMISION_MENU: "Acceder Comision",
                COMISION_SHOW: "Ver Comision",
                COMISION_CREATE: "Crear Comision",
                COMISION_UPDATE: "Modificar Comision",
                COMISION_DELETE: "Eliminar Comision",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                COMISION_MENU: "Acessar Comision",
                COMISION_SHOW: "Ver Comision",
                COMISION_CREATE: "Criar Comision",
                COMISION_UPDATE: "Modificar Comision",
                COMISION_DELETE: "Eliminar Comision",
            }
       }
    }
}

export default messages
    