<template>
  <v-container fluid>
    <deuda-report></deuda-report>
    <cobranza-report></cobranza-report>
    <factura-report></factura-report>
  </v-container>
</template>

<script>
import DeudaReport from "@/modules/cobrox/components/DeudaReport/DeudaReport";
import CobranzaReport from "@/modules/cobrox/components/CobranzaReport/CobranzaReport";
import FacturaReport from "@/modules/cobrox/components/FacturaReport/FacturaReport";

export default {
  name: "DashboardPage",
  components: {FacturaReport, CobranzaReport, DeudaReport}
}
</script>

<style scoped>

</style>
