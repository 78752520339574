
const messages = {
    en: {
       role: {
            permissions: {
                QUITA_MENU: "Access to Quita",
                QUITA_SHOW: "View Quita",
                QUITA_CREATE: "Create Quita",
                QUITA_UPDATE: "Modify Quita",
                QUITA_DELETE: "Delete Quita",
                QUITA_CALCULADOR: "View Quita Calculator",
                QUITA_CALCULADOR_DETALLE: "View Quita Calculator detail",
            }
       }
    },
    es: {
        role: {
            permissions: {
                QUITA_MENU: "Acceder Quita",
                QUITA_SHOW: "Ver Quita",
                QUITA_CREATE: "Crear Quita",
                QUITA_UPDATE: "Modificar Quita",
                QUITA_DELETE: "Eliminar Quita",
                QUITA_CALCULADOR: "Ver calculador de quita",
                QUITA_CALCULADOR_DETALLE: "Ver detalle de calculador de quita",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                QUITA_MENU: "Acessar Quita",
                QUITA_SHOW: "Ver Quita",
                QUITA_CREATE: "Criar Quita",
                QUITA_UPDATE: "Modificar Quita",
                QUITA_DELETE: "Eliminar Quita",
                QUITA_CALCULADOR: "Ver Quita Calculator",
                QUITA_CALCULADOR_DETALLE: "Ver Quita Calculator detail",
            }
       }
    }
}

export default messages
