import { render, staticRenderFns } from "./QuitaForm.vue?vue&type=template&id=46382203&scoped=true"
import script from "./QuitaForm.vue?vue&type=script&lang=js"
export * from "./QuitaForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46382203",
  null
  
)

export default component.exports