export const darkColors = [
    "#1A237E",
    "#B71C1C",
    "#1B5E20",
    "#4A148C",
    "#F57F17",
    "#880E4F",
    "#004D40",
    "#1A237E",
    "#E65100",
    "#006064",
    "#827717",
    "#311B92",
    "#FF6F00",
    "#263238",
    "#3E2723"
]

/*

 */

/*
blue:               #2196F3
blue-darken:        #0D47A1

red:                #F44336
red darken-4        #B71C1C

green:              #4CAF50
green-darken:       #1B5E20

purple:             #9C27B0
purple-darken:      #4A148C

yellow:             #FFEB3B
yellow-darken:      #F57F17

indigo:             #3F51B5
indigo-darken:      #1A237E

cyan:               #00BCD4
cyan-darken:        #006064

teal:               #009688
teal-darken         #004D40



deep-purple:        #673AB7
deep-purple-darken: #311B92



pink:               #E91E63
pink-accent:        #C51162
pink-darken:        #880E4F

lime-darken:        #827717

amber-darken:       #FF6F00



orange:             #FF9800
orange-darken:      #E65100

deep-orange:        #FF5722
deep-orange-darken  #BF360C



blue-grey:          #607D8B
blue-grey-darken:   #263238

brown-darken:       #3E2723


 */
