<template>
  <v-row row wrap>

    <v-btn absolute right top color="green white--text" @click="exportarConvenio">
      EXPORTAR
    </v-btn>

    <v-col cols="12">
      <v-row justify="space-between">
        <v-col cols="12" sm="6" md="8">
          <!-- FILTERS HERE -->
          <v-row>
            <v-col cols="12" sm="4">
              <v-select v-model="filters[0].value"
                        :items="[{text: 'Vigente', value:true}, {text: 'Vencido', value:false}]"
                        label="Estado" @change="fetch" clearable
              />
            </v-col>
          </v-row>

        </v-col>
        <v-col cols="12" sm="6" md="4">
          <search-input @search="performSearch" v-model="search"/>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">

      <v-data-table
          class="mt-3"
          :headers="headers"
          :items="items"
          :search="search"
          :single-expand="false"
          :server-items-length="totalItems"
          :loading="loading"
          :page.sync="pageNumber"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="orderBy"
          :sort-desc.sync="orderDesc"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
          @update:page="fetch"
          @update:sort-by="fetch"
          @update:sort-desc="fetch"
          @update:items-per-page="fetch"
      >

        <template v-slot:item.montoTotal="{ item }">
         {{currency}} {{ item.montoTotal.toLocaleString('es-AR') }}
        </template>

        <template v-slot:item.montoCuota="{ item }">
          {{currency}} {{ item.montoCuota.toLocaleString('es-AR') }}
        </template>

        <template v-slot:item.deudas="{ item }">
          {{ item.deudas.length }}
        </template>


        <template v-slot:item.cobranzas="{ item }">
          {{ item.cobranzas.length }}
        </template>

        <template v-slot:item.fueraDePauta="{ item }">
          {{ item.fueraDePauta ? 'Si' : 'No' }}
        </template>


        <template v-slot:item.fechaPrimerPago="{ item }">
          {{ getDateFormat(item.fechaPrimerPago) }}
        </template>


        <template slot="no-data">
          <div class="text-xs-center" v-t="'common.noData'"></div>
        </template>

        <template slot="loading">
          <div class="text-xs-center" v-t="'common.loading'"></div>
        </template>

        <template v-slot:item.action="{ item }">
          <show-button @click="$emit('show', item)"/>
          <edit-button v-if="$store.getters.hasPermission('CONVENIO_UPDATE')" @click="$emit('update', item)"/>
          <delete-button v-if="$store.getters.hasPermission('CONVENIO_DELETE')" @click="$emit('delete', item)"/>
        </template>

      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import ConvenioProvider from "../../../../providers/ConvenioProvider";

import {DeleteButton, EditButton, ShowButton, SearchInput} from "@dracul/common-frontend"
import {DayjsMixin} from "@dracul/dayjs-frontend";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import JobProvider from "@/modules/cobrox/providers/JobProvider";

export default {
  name: "ConvenioList",
  components: {DeleteButton, EditButton, ShowButton, SearchInput},
  mixins: [DayjsMixin, CurrencyMixin],
  data() {
    return {
      items: [],
      totalItems: null,
      loading: false,
      orderBy: null,
      orderDesc: false,
      itemsPerPage: 5,
      pageNumber: 1,
      search: '',
      filters: [
        {
            field: 'vigente',
            operator: 'eq', //(eq|contain|regex|gt|lt|lte|gte)
            value: ''
        }
      ]
    }
  },
  computed: {
    headers() {
      return [
        //Entity Headers
        {text: this.$t('cobrox.convenio.labels.vigente'), value: 'vigente'},
        {text: this.$t('cobrox.convenio.labels.cuilDeudor'), value: 'cuilDeudor'},
        {text: this.$t('cobrox.convenio.labels.dniDeudor'), value: 'dniDeudor'},
        {text: this.$t('cobrox.convenio.labels.cuotas'), value: 'cuotas'},
        {text: this.$t('cobrox.convenio.labels.montoTotal'), value: 'montoTotal'},
        {text: this.$t('cobrox.convenio.labels.montoCuota'), value: 'montoCuota'},
        {text: this.$t('cobrox.convenio.labels.fechaPrimerPago'), value: 'fechaPrimerPago'},
        {text: this.$t('cobrox.convenio.labels.fueraDePauta'), value: 'fueraDePauta'},
        {text: this.$t('cobrox.convenio.labels.deudas'), value: 'deudas'},
        {text: this.$t('cobrox.convenio.labels.cobranzas'), value: 'cobranzas'},
        {text: this.$t('cobrox.convenio.labels.origen'), value: 'origen'},
        //Actions
        {text: this.$t('common.actions'), value: 'action', sortable: false},
      ]
    },
    getOrderBy() {
      return (Array.isArray(this.orderBy)) ? this.orderBy[0] : this.orderBy
    },
    getOrderDesc() {
      return (Array.isArray(this.orderDesc)) ? this.orderDesc[0] : this.orderDesc
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    exportarConvenio(){
      JobProvider.exportarConvenio( this.filters, this.search)
          .then(resp => {
            let jobId = resp.data.exportarConvenio
            this.$router.push({name: 'JobPage', params: {id: jobId}})
          })
    },
    performSearch() {
      this.pageNumber = 1
      this.fetch()
    },
    fetch() {
      this.loading = true
      ConvenioProvider.paginateConvenio(
          this.pageNumber,
          this.itemsPerPage,
          this.search,
          this.filters,
          this.getOrderBy,
          this.getOrderDesc
      ).then(r => {
        this.items = r.data.paginateConvenio.items
        this.totalItems = r.data.paginateConvenio.totalItems
      }).catch(err => {
        console.error(err)
      }).finally(() => this.loading = false)
    }
  }

}
</script>


