<template>

  <v-select
      prepend-icon="assignment"
      :items="items"
      v-model="item"
      :label="$t('cobrox.persona.labels.estado')"
      :loading="loading"
      :error="hasInputErrors('estado')"
      :error-messages="getInputErrors('estado')"
      color="secondary"
      item-color="secondary"

  ></v-select>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


export default {
  name: "EstadoPersonaCombobox",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {
      type: [String, Array]
    },
  },
  data() {
    return {
      items: ['ACTIVO', 'QUIEBRA', 'FALLECIDO'],
      loading: false
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style scoped>

</style>

