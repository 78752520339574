<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">
    <v-row>

      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="short_text"
            name="cuil"
            v-model="form.cuil"
            :label="$t('cobrox.pedidoCuenta.labels.cuil')"
            :placeholder="$t('cobrox.pedidoCuenta.labels.cuil')"
            :error="hasInputErrors('cuil')"
            :error-messages="getInputErrors('cuil')"
            color="secondary"

        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="description"
            name="comentarioSolicitante"
            v-model="form.comentarioSolicitante"
            :label="$t('cobrox.pedidoCuenta.labels.comentarioSolicitante')"
            :placeholder="$t('cobrox.pedidoCuenta.labels.comentarioSolicitante')"
            :error="hasInputErrors('comentarioSolicitante')"
            :error-messages="getInputErrors('comentarioSolicitante')"
            color="secondary"

        ></v-text-field>
      </v-col>

    </v-row>
  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


export default {
  name: "PedidoCuentaForm",
  mixins: [InputErrorsByProps, RequiredRule],
  components: {},
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>

