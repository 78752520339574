<template>
  <v-row>

    <v-col cols="12" sm="6">
      <v-text-field

          prepend-icon="short_text"
          name="nombre"
          v-model="form.nombre"
          :label="$t('cobrox.persona.labels.nombre')"
          :placeholder="$t('cobrox.persona.labels.nombre')"
          :error="hasInputErrors('nombre')"
          :error-messages="getInputErrors('nombre')"
          color="secondary"
          :rules="required"
      ></v-text-field>
    </v-col>


    <v-col cols="12" sm="6">

      <media-field icon="link"
                   :label="$t('cobrox.persona.labels.link')"
                   :placeholder="$t('cobrox.persona.labels.link')"
                   v-model="form.link"
                   field-name="link"
                   :error="hasInputErrors('link')"
                   :error-messages="getInputErrors('link')"
      ></media-field>

    </v-col>




  </v-row>
</template>

<script>
import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'
import {MediaField} from '@dracul/media-frontend'

export default {
  name: "PersonaDocumentoForm",
  mixins: [InputErrorsByProps, RequiredRule],
  components: {MediaField},
  props: {
    value: {
      type: Object,
      required: true
    },
    readonly: {type: Boolean, default:false}
  },
  data(){
    return {
      search: ''
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },

  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
}
</script>

<style scoped>

</style>
