
const messages = {
    en: {
       cobrox: {
          situacionLaboral: {
            name: 'SituacionLaboral',
            title: 'SituacionLaboral management',
            subtitle: 'View, search, create, edit and delete SituacionLaboral',
            creating: 'Creating SituacionLaboral',
            editing: 'Editing SituacionLaboral',
            deleting: 'Deleting SituacionLaboral',
            showing: 'Showing SituacionLaboral',
            menu: 'SituacionLaboral',
            labels: {
                nombre: 'Situación Laboral'
            },

          }
       }
    },
    es: {
       cobrox: {
          situacionLaboral: {
            name: 'Situación Laboral',
            title: 'Administración de Situación Laboral',
            subtitle: 'Ver, buscar, crear, editar, y borrar Situación Laboral',
            creating: 'Creando Situación Laboral',
            editing: 'Modificando Situación Laboral',
            deleting: 'Eliminando Situación Laboral',
            showing: 'Detalles de Situación Laboral',
            menu: 'Situación Laboral',
            labels: {
                nombre: 'Situación Laboral'
            },

          }
       }
    },
    pt: {
       cobrox: {
          situacionLaboral: {
            name: 'SituacionLaboral',
            title: 'Administração de SituacionLaboral',
            subtitle: 'Ver, buscar, criar, editar e usar SituacionLaboral',
            creating: 'Criando SituacionLaboral',
            editing: 'Edição SituacionLaboral',
            deleting: 'Apagando SituacionLaboral',
            showing: 'Detalhes do SituacionLaboral',
            menu: 'SituacionLaboral',
            labels: {
                nombre: 'Situación Laboral'
            },

          }
       }
    }
}

export default messages
