<template>
  <crud-create :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @create="create"
               @close="$emit('close')"
  >
    <factura-form ref="form" v-model="form" :input-errors="inputErrors"/>
  </crud-create>
</template>

<script>

import FacturaProvider from "../../../../providers/FacturaProvider";

import {CrudCreate, ClientError} from '@dracul/common-frontend'

import FacturaForm from "../FacturaForm";


export default {
  name: "FacturaCreate",

  components: {FacturaForm, CrudCreate},

  props: {
    open: {type: Boolean, default: true}
  },

  data() {
    return {
      title: 'cobrox.factura.creating',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      form: {
        cobranza: null,
        deuda: null,
        monto: null,
        montoNoFacturable: 0,
        montoFacturable: 0,
        estado: '',
        tipo: '',
        lote: '',
        fechaComprobante: null,
        fechaVencimiento: null,
        tipoComprobante: 'FVC',
        letra: 'B',
        puntoVenta: '',
        numeroComprobante: '',
        cuitCliente: '',
        dniCliente: '',
        razonSocialCliente: '',
        descripcionVenta: '',
        subtotalGravadoIVA105: null,
        subtotalGravadoIVA21: null,
        subtotalGravadoIVA27: null,
        totalNetoNoGravado: null,
        percepcionIVA: null,
        jurisdiccionIIBB: '',
        totalComprobante: null,
        cuentaIngreso: '',
        medioCobro: '',
        centroCosto1: '',
        centroCosto2: '',
        periodoDesde: null,
        periodoHasta: null,
        cbu: '',
        cae: '',
        nroFactura: '',
        importeIva: null,
        netoGravado: null,
        nombreCuenta: '',
        idFacturaColppy: '',
        idClienteColppy: ''
      }
    }
  },

  methods: {
    create() {
      if (this.$refs.form.validate()) {
        this.loading = true
        FacturaProvider.createFactura(this.form).then(r => {
              if (r) {
                this.$emit('itemCreated', r.data.createFactura)
                this.$emit('close')
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }

  },
}
</script>

<style scoped>

</style>

