import Vue from "vue";
import VueI18n from 'vue-i18n'
import merge from 'deepmerge'

import baseMessages from '../modules/base/i18n'
import cobroxMessages from '../modules/cobrox/i18n'
import redmineMessages from '../modules/redmine/i18n'
import kinvaMessages from '../modules/kinva/i18n'
import suretyMessages from '../modules/surety/i18n'
import menuMessages from '../menu-config/menu-i18n'
import {i18nMessages as i18nMessagesCommon} from '@dracul/common-frontend'
import {i18nMessages as i18nMessagesUser} from '@dracul/user-frontend'
import {i18nMessages as i18nMessagesCustom} from '@dracul/customize-frontend'
import {i18nMessages as i18nMessagesNotification} from '@dracul/notification-frontend'
import {i18nMessages as i18nMessagesQueue} from '@dracul/queue-frontend'
import {i18nMessages as i18nMessagesMedia} from '@dracul/media-frontend'
import {i18nMessages as i18nMessagesSettings} from '@dracul/settings-frontend'

const messages = merge.all([
    baseMessages,
    cobroxMessages,
    redmineMessages,
    kinvaMessages,
    menuMessages,
    i18nMessagesCommon,
    i18nMessagesUser,
    i18nMessagesCustom,
    i18nMessagesNotification,
    i18nMessagesQueue,
    i18nMessagesMedia,
    i18nMessagesSettings,
    suretyMessages,
])

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'es',
    messages,
})

export default i18n
