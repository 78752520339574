<template>
        <crud-update :open="open"
                 :loading="loading"
                 :title="title"
                 :errorMessage="errorMessage"
                 @update="update"
                 @close="$emit('close')"
    >
         <retencion-form ref="form" v-model="form" :input-errors="inputErrors" />
    </crud-update>
</template>

<script>

    import RetencionProvider from "../../../../providers/RetencionProvider";
    
    import {CrudUpdate, ClientError} from '@dracul/common-frontend'
    
    import RetencionForm from "../RetencionForm";
  
    

    export default {
        name: "RetencionUpdate",
        
        components: { RetencionForm, CrudUpdate },
        
        props:{
          open: {type: Boolean, default: true},
          item: {type: Object, required: true}
        },

        data() {
            return {
                title: 'cobrox.retencion.editing',
                errorMessage: '',
                inputErrors: {},
                loading: false,
                id: this.item.id,
                form: {
                    agencia: this.item.agencia ? this.item.agencia.id : null,
                    estado: this.item.estado,
                    procesado: this.item.procesado,
                    archivo: this.item.archivo,
                    comentario: this.item.comentario,
                    retenidas: this.item.retenidas,
                    solicitadas: this.item.solicitadas,
                    rechazadas: this.item.rechazadas,
                    creadoPor: this.item.creadoPor ? this.item.creadoPor.id : null
                }
            }
        },
        methods: {
            update() {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    RetencionProvider.updateRetencion(this.id, this.form).then(r => {
                            if (r) {
                                this.$emit('itemUpdated',r.data.updateRetencion)
                                this.$emit('close')
                            }
                        }
                    ).catch(error => {
                         let clientError = new ClientError(error)
                         this.inputErrors = clientError.inputErrors
                         this.errorMessage = clientError.i18nMessage
                    }).finally(() => this.loading = false)
                }

            }
        },
    }
</script>

<style scoped>

</style>

