<template>
  <v-select
      v-model="model"
      :items="getItems"
      :item-text="'text'"
      :item-value="'value'"
      label="Ocultar Menus"
      multiple chips
  ></v-select>
</template>

<script>
import menuconfig from "@/menu-config/menu-config"

export default {
  name: "HideMenus",
  props: {
    value: {type: [String, Array]}
  },
  data() {
    return {
      menu: menuconfig
    }
  },
  created() {

  },
  methods: {
    getTranslatedTextIfExist(text) {
      return this.$te(text)? this.$t(text) : text
    },

  },
  computed: {
    model:{
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    getItems() {
      return this.menu.reduce((ac, item) => {
        ac.push({text: this.getTranslatedTextIfExist(item.text), value: item.text})

        if (item.children) {
          item.children.forEach(child => {
            ac.push({text: this.getTranslatedTextIfExist(child.text), value: child.text})
          })
        }


        return ac
      }, [])
    }
  }
}
</script>

<style scoped>

</style>
