<template>
  <v-row>

    <v-col cols="12" sm="4">
      <v-text-field
          name="cuotaDesde"
          v-model.number="form.cuotaDesde"
          type="number"
          :label="$t('cobrox.quita.labels.cuotaDesde')"
          :placeholder="$t('cobrox.quita.labels.cuotaDesde')"
          :error="hasInputErrors('cuotaDesde')"
          :error-messages="getInputErrors('cuotaDesde')"
          color="secondary"
          :rules="required"
      >
        <template v-slot:prepend>
          <v-chip v-html="'>='"></v-chip>
        </template>

      </v-text-field>
    </v-col>

    <v-col cols="12" sm="4">
      <v-text-field
          prepend-icon="arrow_forward_ios"
          name="cuotaHasta"
          v-model.number="form.cuotaHasta"
          type="number"
          :label="$t('cobrox.quita.labels.cuotaHasta')"
          :placeholder="$t('cobrox.quita.labels.cuotaHasta')"
          :error="hasInputErrors('cuotaHasta')"
          :error-messages="getInputErrors('cuotaHasta')"
          color="secondary"
          :rules="required"
      >
        <template v-slot:prepend>
          <v-chip  v-html="'<='"></v-chip>
        </template>

      </v-text-field>
    </v-col>

    <v-col cols="12" sm="4">
      <v-text-field
          append-icon="percent"
          name="quita"
          v-model.number="form.quita"
          type="number"
          :label="$t('cobrox.quita.labels.quita')"
          :placeholder="$t('cobrox.quita.labels.quita')"
          :error="hasInputErrors('quita')"
          :error-messages="getInputErrors('quita')"
          color="secondary"
          :rules="required"
      ></v-text-field>
    </v-col>

  </v-row>
</template>

<script>
import {InputErrorsByProps, RequiredRule} from "@dracul/common-frontend";

export default {
  name: "QuitaPlanForm",
  mixins: [InputErrorsByProps, RequiredRule],
  components: {},
  props: {
    value: {
      type: Object,
      required: true
    },
    agencia: String,
    compra: String,
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {},
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>
