<template>
  <v-form ref="form" autocomplete="off">
    <v-row>

      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('dniDeudor')"
            :readonly="!canEdit('dniDeudor') && !!dniDeudor"
            prepend-icon="badge"
            name="dniDeudor"
            v-model="form.dniDeudor"
            :label="$t('cobrox.cobranza.labels.dniDeudor')"
            :placeholder="$t('cobrox.cobranza.labels.dniDeudor')"
            :error="hasInputErrors('dniDeudor')"
            :error-messages="getInputErrors('dniDeudor')"
            color="secondary"
            :rules="required"
            @focusout="emitDniWasWritten"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('cuilDeudor')"
            :readonly="!canEdit('cuilDeudor') && !!cuil"
            prepend-icon="contact_page"
            name="cuilDeudor"
            v-model="form.cuilDeudor"
            :label="$t('cobrox.cobranza.labels.cuilDeudor')"
            :placeholder="$t('cobrox.cobranza.labels.cuilDeudor')"
            :error="hasInputErrors('cuilDeudor')"
            :error-messages="getInputErrors('cuilDeudor')"
            color="secondary"

        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <date-input
            v-if="canView('fechaCobranza')"
            :readonly="!canEdit('fechaCobranza')"
            v-model="form.fechaCobranza"
            :label="$t('cobrox.cobranza.labels.fechaCobranza')"
            :rules="required"
            :error="hasInputErrors('fechaCobranza')"
            :error-messages="getInputErrors('fechaCobranza')"
        ></date-input>

      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('montoCobrado')"
            :readonly="!canEdit('montoCobrado') && id ? true : false "
            prepend-icon="payments"
            name="montoCobrado"
            v-model.number="form.montoCobrado"
            type="number"
            :label="$t('cobrox.cobranza.labels.montoCobrado')"
            :placeholder="$t('cobrox.cobranza.labels.montoCobrado')"
            :error="hasInputErrors('montoCobrado')"
            :error-messages="getInputErrors('montoCobrado')"
            color="secondary"
            :rules="greaterOrLessThanZero"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('idRapipago')"
            :readonly="!canEdit('idRapipago')"
            prepend-icon="contact_page"
            name="idRapipago"
            v-model="form.idRapipago"
            :label="$t('cobrox.cobranza.labels.idRapipago')"
            :placeholder="$t('cobrox.cobranza.labels.idRapipago')"
            :error="hasInputErrors('idRapipago')"
            :error-messages="getInputErrors('idRapipago')"
            color="secondary"

        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('cbu')"
            :readonly="!canEdit('cbu')"
            prepend-icon="account_balance_wallet"
            name="cbu"
            v-model="form.cbu"
            :label="$t('cobrox.cobranza.labels.cbu')"
            :placeholder="$t('cobrox.cobranza.labels.cbu')"
            :error="hasInputErrors('cbu')"
            :error-messages="getInputErrors('cbu')"
            color="secondary"

        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
            v-if="canView('nroProducto')"
            :readonly="!canEdit('nroProducto')"
            prepend-icon="copyright"
            name="nroProducto"
            v-model="form.nroProducto"
            :label="$t('cobrox.cobranza.labels.nroProducto')"
            :placeholder="$t('cobrox.cobranza.labels.nroProducto')"
            :error="hasInputErrors('nroProducto')"
            :error-messages="getInputErrors('nroProducto')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <agencia-combobox
            v-if="canView('agencia')"
            :readonly="!canEdit('agencia') && agencyFieldIsReadOnly"
            v-model="form.agencia"
            :label="$t('cobrox.cobranza.labels.agencia')"
            :placeholder="$t('cobrox.cobranza.labels.agencia')"
            :error="hasInputErrors('agencia')"
            :error-messages="getInputErrors('agencia')"
        ></agencia-combobox>

      </v-col>


      <v-col cols="12" sm="6">
        <canal-cobro-combobox
            v-if="canView('canalCobro')"
            :readonly="!canEdit('canalCobro')"
            v-model="form.canalCobro"
            :input-errors="inputErrors"
        ></canal-cobro-combobox>
      </v-col>


      <v-col cols="12" sm="6">
        <cuenta-combobox
            v-if="canView('cuentaDestino')"
            :readonly="!canEdit('cuentaDestino')"
            v-model="form.cuentaDestino"
            :input-errors="inputErrors"
            :item-value="'nombre'"
        ></cuenta-combobox>
      </v-col>


    </v-row>
  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


import {DayjsMixin, DateInput} from "@dracul/dayjs-frontend";
import CanalCobroCombobox from "@/modules/cobrox/combobox/CanalCobroCombobox/CanalCobroCombobox";
import CuentaCombobox from "@/modules/cobrox/combobox/CuentaCombobox/CuentaCombobox";
import AgenciaCombobox from "@/modules/cobrox/combobox/AgenciaCombobox/AgenciaCombobox";
import SuretyMixin from "@/modules/surety/mixins/SuretyMixin";


export default {
  name: "CobranzaForm",
  mixins: [InputErrorsByProps, RequiredRule, DayjsMixin, SuretyMixin],
  components: {AgenciaCombobox, CuentaCombobox, CanalCobroCombobox, DateInput},
  props: {
    value: {type: Object, required: true},
    dniDeudor: {type: String},
    cuil: {type: String},
    id: {type: String},
    agencyFieldIsReadOnly: {type: Boolean, default: false},
  },
  created() {
    this.fetchFieldPermission('Cobranza')
    if (!this.form.dniDeudor) {
      this.form.dniDeudor = this.dniDeudor
    }
    if (!this.form.cuilDeudor) {
      this.form.cuilDeudor = this.cuil
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    greaterThanZero() {
      return [
        v => !!v || this.$t('common.required'),
        v => v > 0 || this.$t('common.required'),
      ];
    },
    greaterOrLessThanZero() {
      return [
        v => !!v || this.$t('common.required'),
        v => (v > 0 || v < 0) || this.$t('common.required'),
      ];
    },
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    emitDniWasWritten() {
      if (this.agencyFieldIsReadOnly) {// agencyFieldIsReadOnly == true when form is used in CobranzaCreate
        this.$emit('dniWasWritten')
      }
    }
  }
}
</script>

<style scoped>

</style>

