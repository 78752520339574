
const messages = {
    en: {
       role: {
            permissions: {
                COMPRA_MENU: "Access to Compra",
                COMPRA_SHOW: "View Compra",
                COMPRA_CREATE: "Create Compra",
                COMPRA_UPDATE: "Modify Compra",
                COMPRA_DELETE: "Delete Compra",
            }
       }
    },
    es: {
        role: {
            permissions: {
                COMPRA_MENU: "Acceder Compra",
                COMPRA_SHOW: "Ver Compra",
                COMPRA_CREATE: "Crear Compra",
                COMPRA_UPDATE: "Modificar Compra",
                COMPRA_DELETE: "Eliminar Compra",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                COMPRA_MENU: "Acessar Compra",
                COMPRA_SHOW: "Ver Compra",
                COMPRA_CREATE: "Criar Compra",
                COMPRA_UPDATE: "Modificar Compra",
                COMPRA_DELETE: "Eliminar Compra",
            }
       }
    }
}

export default messages
    