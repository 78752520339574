<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">
    <v-row>

      <v-col cols="12" sm="6">
        <date-input
            v-model="form.moraDesde"
            :label="$t('cobrox.quita.labels.moraDesde')"
            :error="hasInputErrors('moraDesde')"
            :error-messages="getInputErrors('moraDesde')"
        />
      </v-col>


      <v-col cols="12" sm="6">
        <date-input
            v-model="form.moraHasta"
            :label="$t('cobrox.quita.labels.moraHasta')"
            :error="hasInputErrors('moraHasta')"
            :error-messages="getInputErrors('moraHasta')"
        />
      </v-col>

      <v-col cols="12" sm="12" class="d-flex justify-center">
        <v-card outlined :style="{maxWidth: '800px'}">
          <v-card-text>
            <form-list :new-item="{cuotaDesde: null, cuotaHasta: null, quita: 0}"  v-model="form.planes">

              <template v-slot:default="{item,index}">
                <quita-plan-form v-model="form.planes[index]"></quita-plan-form>
              </template>
            </form-list>
          </v-card-text>
        </v-card>

      </v-col>

    </v-row>
  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule, FormList} from '@dracul/common-frontend'


import {DayjsMixin, DateInput} from "@dracul/dayjs-frontend";
import QuitaPlanForm from "@/modules/cobrox/pages/crud/QuitaCrudPage/QuitaForm/QuitaPlanForm/QuitaPlanForm.vue";


export default {
  name: "QuitaForm",
  mixins: [InputErrorsByProps, RequiredRule, DayjsMixin],
  components: {QuitaPlanForm, DateInput, FormList},
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {

    validate() {
      return this.$refs.form.validate()
    },
    save() {
      this.$emit('save', this.form)
    }
  },
  data() {
    return {
      moraDesdeDateMenu: false,
      moraHastaDateMenu: false
    }
  }
}
</script>

<style scoped>

</style>

