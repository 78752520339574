<template>
  <v-container>
    <v-card v-if="!jobid" class="mb-2">
      <v-card-title>Reporte para rotación de agencias</v-card-title>
      <v-card-text>
        <rotacion-agencia-form v-model="filters"/>
      </v-card-text>

      <v-card-actions >
        <v-spacer></v-spacer>
        <v-btn :loading="loading" color="primary OnPrimary--text" @click="fetchReport">Consultar</v-btn>
      </v-card-actions>
    </v-card>

    <rotacion-asistida-agencia v-if="result" :filters="filters" :result="result"></rotacion-asistida-agencia>

    <rotacion-asistida-job v-if="jobid" ref="job" :filters="filters" :jobid="jobid"></rotacion-asistida-job>


  </v-container>
</template>

<script>
import ReportProvider from "@/modules/cobrox/providers/ReportProvider";
import RotacionAsistidaAgencia from "@/modules/cobrox/components/RotacionAsistidaAgencia/RotacionAsistidaAgencia";
import RotacionAgenciaForm from "@/modules/cobrox/pages/reports/RotacionAgenciaReportPage/RotacionAgenciaForm";
import {QueueProvider} from "@dracul/queue-frontend";
import RotacionAsistidaJob from "@/modules/cobrox/components/RotacionAsistidaJob/RotacionAsistidaJob";

export default {
  name: "RotacionAgenciaReportPage",
  components: {
    RotacionAsistidaJob,
    RotacionAgenciaForm,
    RotacionAsistidaAgencia
  },
  computed:{
    jobid(){
      return this.$route.params.jobid
    }
  },
  data() {
    return {
      loading: false,
      result: null,
      filters: {
        estados: ['ACTIVO'],
        compras: [],
        agencias: [],
        fechaUltimaRotacion: {
          desde: null,
          hasta: null
        },
        capitalIndividual: {
          desde: 0,
          hasta: 0
        },
        capitalTotal: {
          desde: 0,
          hasta: 0
        },
        cantidad: {
          desde: 0,
          hasta: 0
        },
        fechaUltimaCobranza: {
          desde: null,
          hasta: null
        },
        cobranza: null,
        rotacionBloqueada: false,
        fechaMora: {
          desde: null,
          hasta: null
        },
      }
    }
  },
  mounted() {
    this.checkJob()
  },
  methods: {
    fetchReport() {
      this.loading = true
      ReportProvider.rotacionAgenciasReport(this.filters, false)
          .then(r => {
            this.result = r.data.rotacionAgenciasReport
          })
          .catch(e => {
            console.error("fetchReport error", e)
          })
          .finally(() => this.loading = false)
    },
    checkJob() {
      QueueProvider.queueFindByTopicAndState('RotacionAsistida', 'WORKING').then(res => {
        let rjob = res.data.queueFindByTopicAndState
        if (rjob) {
          this.$router.replace({name: 'RotacionAgenciaReportPageJob', params: {jobid: rjob.id}})
          this.$ref.job.fetchJob()
        }
      })
    },
  }
}
</script>

