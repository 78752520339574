<template>
  <crud-layout :title="title" :subtitle="subtitle">

    <template v-slot:list>
      <compra-list
          ref="list"
          @update="update"
          @delete="remove"
          @show="show"

      />
    </template>

    <add-button v-if="$store.getters.hasPermission('COMPRA_CREATE')" @click="create"></add-button>


    <compra-create v-if="creating"
                   :open="creating"
                   v-on:itemCreated="onItemCreated"
                   v-on:close="creating=false"
    />

    <compra-update v-if="updating"
                   :open="updating"
                   :item="itemToEdit"
                   v-on:itemUpdated="onItemUpdated"
                   v-on:close="updating=false"
    />

    <compra-show v-if="showing"
                 :open="showing"
                 :item="itemToShow"
                 v-on:close="showing=false"
    />

    <compra-delete v-if="deleting"
                   :open="deleting"
                   :item="itemToDelete"
                   v-on:itemDeleted="onItemDeleted"
                   v-on:close="deleting=false"
    />

    <snackbar v-model="flash"/>

  </crud-layout>
</template>

<script>

import CompraCreate from "../CompraCreate";
import CompraUpdate from "../CompraUpdate";
import CompraDelete from "../CompraDelete";
import CompraShow from "../CompraShow";
import CompraList from "../CompraList";

import {CrudLayout, AddButton, Snackbar} from "@dracul/common-frontend"

export default {
  name: "CompraCrud",
  components: {
    CrudLayout, AddButton, Snackbar,
    CompraCreate,
    CompraUpdate,
    CompraDelete,
    CompraShow,
    CompraList
  },
  data() {
    return {
      title: 'cobrox.compra.title',
      subtitle: 'cobrox.compra.subtitle',
      flash: null,
      creating: false,
      updating: false,
      deleting: false,
      showing: false,
      itemToEdit: null,
      itemToDelete: null,
      itemToShow: null,
    }
  },
  methods: {
    //On
    onItemCreated() {
      this.$refs.list.fetch()
      this.flash = "common.created"
    },
    onItemUpdated() {
      this.$refs.list.fetch()
      this.flash = "common.updated"
    },
    onItemDeleted() {
      this.$refs.list.fetch()
      this.flash = "common.deleted"
    },
    //Open
    create() {
      this.creating = true
    },
    update(item) {
      this.updating = true
      this.itemToEdit = item
    },
    show(item) {
      this.showing = true
      this.itemToShow = item
    },
    remove(item) {
      this.deleting = true
      this.itemToDelete = item
    }
  }

}
</script>


