<template>
  <v-container>

    <v-tabs
        v-model="tab"
        align-with-title
    >
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <v-tab

          key="principal"
      >
        Principal
      </v-tab>
      <v-tab
          v-for="item in getTabItems"
          :key="item"
      >
        {{ item }}
      </v-tab>
    </v-tabs>


    <v-tabs-items v-model="tab">
      <v-tab-item
          key="principal"
      >

        <v-form ref="form" autocomplete="off">
          <v-row>

            <v-col cols="12" sm="6">
              <v-text-field
                  v-if="canView('nombreCompleto')"
                  :readonly="!canEdit('nombreCompleto')"
                  prepend-icon="face"
                  name="nombreCompleto"
                  v-model="form.nombreCompleto"
                  :label="$t('cobrox.persona.labels.nombreCompleto')"
                  :placeholder="$t('cobrox.persona.labels.nombreCompleto')"
                  :error="hasInputErrors('nombreCompleto')"
                  :error-messages="getInputErrors('nombreCompleto')"
                  color="secondary"
                  :rules="required"
              ></v-text-field>
            </v-col>


            <v-col cols="12" sm="6">
              <sexo-combobox
                  v-if="canView('sexo')"
                  :readonly="!canEdit('sexo')"
                  v-model="form.sexo"
                  :input-errors="inputErrors"
              />
            </v-col>


            <v-col cols="12" sm="6">
              <tipo-documento-combobox
                  v-if="canView('tipoDocumento')"
                  :readonly="!canEdit('tipoDocumento')"
                  v-model="form.tipoDocumento"
                  :input-errors="inputErrors"
              />
            </v-col>


            <v-col cols="12" sm="6">
              <v-text-field
                  v-if="canView('nroDocumento')"
                  :readonly="!canEdit('nroDocumento')"
                  prepend-icon="badge"
                  name="nroDocumento"
                  v-model="form.nroDocumento"
                  :label="$t('cobrox.persona.labels.nroDocumento')"
                  :placeholder="$t('cobrox.persona.labels.nroDocumento')"
                  :error="hasInputErrors('nroDocumento')"
                  :error-messages="getInputErrors('nroDocumento')"
                  color="secondary"
                  :rules="required"
              ></v-text-field>
            </v-col>


            <v-col cols="12" sm="6">
              <v-text-field
                  v-if="canView('cuil')"
                  :readonly="!canEdit('cuil')"
                  prepend-icon="folder_shared"
                  name="cuil"
                  v-model="form.cuil"
                  :label="$t('cobrox.persona.labels.cuil')"
                  :placeholder="$t('cobrox.persona.labels.cuil')"
                  :error="hasInputErrors('cuil')"
                  :error-messages="getInputErrors('cuil')"
                  color="secondary"

              ></v-text-field>
            </v-col>


            <v-col cols="12" sm="6">
              <v-text-field
                  v-if="canView('nacionalidad')"
                  :readonly="!canEdit('nacionalidad')"
                  prepend-icon="flag"
                  name="nacionalidad"
                  v-model="form.nacionalidad"
                  :label="$t('cobrox.persona.labels.nacionalidad')"
                  :placeholder="$t('cobrox.persona.labels.nacionalidad')"
                  :error="hasInputErrors('nacionalidad')"
                  :error-messages="getInputErrors('nacionalidad')"
                  color="secondary"

              ></v-text-field>
            </v-col>


            <v-col cols="12" sm="6">

              <date-input
                  v-if="canView('fechaNacimiento')"
                  :readonly="!canEdit('fechaNacimiento')"
                  v-model="form.fechaNacimiento"
                  :label="$t('cobrox.persona.labels.fechaNacimiento')"
                  :placeholder="$t('cobrox.persona.labels.fechaNacimiento')"
                  :error="hasInputErrors('fechaNacimiento')"
                  :error-messages="getInputErrors('fechaNacimiento')"
              ></date-input>

            </v-col>


            <v-col cols="12" sm="6">
              <estado-persona-combobox
                  v-if="canView('estado')"
                  :readonly="!canEdit('estado')"
                  v-model="form.estado"
                  :input-errors="inputErrors"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <agencia-combobox
                  v-if="canView('agencia')"
                  :readonly="!canEdit('agencia') && form.rotacionBloqueada"
                  v-model="form.agencia"
                  :input-errors="inputErrors"
              />
            </v-col>


            <v-col cols="12" sm="6">
              <v-text-field
                  v-if="canView('idRapipago')"
                  :readonly="!canEdit('idRapipago')"
                  prepend-icon="credit_score"
                  name="idRapipago"
                  v-model="form.idRapipago"
                  :label="$t('cobrox.persona.labels.idRapipago')"
                  :placeholder="$t('cobrox.persona.labels.idRapipago')"
                  :error="hasInputErrors('idRapipago')"
                  :error-messages="getInputErrors('idRapipago')"
                  color="secondary"

              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                  v-if="canView('idClienteColppy')"
                  :readonly="!canEdit('idClienteColppy')"
                  prepend-icon="badge"
                  name="idClienteColppy"
                  v-model.number="form.idClienteColppy"
                  type="number"
                  :label="$t('cobrox.deuda.labels.idClienteColppy')"
                  :placeholder="$t('cobrox.deuda.labels.idClienteColppy')"
                  :error="hasInputErrors('idClienteColppy')"
                  :error-messages="getInputErrors('idClienteColppy')"
                  color="secondary"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <jurisdiccion-combobox
                  v-if="canView('jurisdiccion')"
                  :readonly="!canEdit('jurisdiccion')"
                  v-model="form.jurisdiccion"
                  :input-errors="inputErrors"
              ></jurisdiccion-combobox>
            </v-col>

            <v-col cols="12" sm="6">
              <v-checkbox
                  v-if="canView('rotacionBloqueada')"
                  :readonly="!canEdit('rotacionBloqueada')"
                  prepend-icon="block"
                  name="rotacionBloqueada"
                  v-model="form.rotacionBloqueada"
                  :label="$t('cobrox.persona.labels.rotacionBloqueada')"
                  :error="hasInputErrors('rotacionBloqueada')"
                  :error-messages="getInputErrors('rotacionBloqueada')"
                  color="secondary"

              ></v-checkbox>
            </v-col>

            <v-col cols="12" sm="6">
              <situacion-laboral-combobox
                  v-if="canView('situacionLaboral')"
                  :readonly="!canEdit('situacionLaboral')"
                  v-model="form.situacionLaboral"
                  :input-errors="inputErrors"
              />
            </v-col>

          </v-row>
        </v-form>
      </v-tab-item>

      <v-tab-item
          key="telefonos"
          v-if="canView('telefonos')"
      >

        <form-list

            v-model="form.telefonos"
            :new-item="{telefono:'', tipo:'', origen:''}"
        >

          <template v-slot:default="{item,index}">
            <persona-telefono-form
                :readonly="!canEdit('telefonos')"
                v-model="form.telefonos[index]"
                :key="index">

            </persona-telefono-form>
          </template>


        </form-list>
      </v-tab-item>

      <v-tab-item
          v-if="canView('emails')"
          key="emails"
      >
        <form-list
            v-model="form.emails"
            :new-item="{mail:'',tipo:'', origen:''}"
        >

          <template v-slot:default="{item,index}">
            <persona-email-form
                :readonly="!canEdit('emails')"
                v-model="form.emails[index]"
                :key="index"
            ></persona-email-form>
          </template>


        </form-list>
      </v-tab-item>


      <v-tab-item
          v-if="canView('domicilios')"
          key="domicilios"
      >
        <form-list
            v-model="form.domicilios"
            :new-item="{calle:'',numero:'',piso:'',depto:'', localidad:'',provincia:'',codigoPostal:'', origen:''}"
        >

          <template v-slot:default="{item,index}">
            <persona-domicilio-form
                :readonly="!canEdit('domicilios')"
                v-model="form.domicilios[index]"
                :key="index"
            ></persona-domicilio-form>
          </template>


        </form-list>
      </v-tab-item>


      <v-tab-item
          v-if="canView('cbus')"
          key="cbus"
      >
        <form-list
            v-model="form.cbus"
            :new-item="{cbu:'',descripcion:'', origen:''}"
        >

          <template v-slot:default="{item,index}">
            <persona-cbu-form
                :readonly="!canEdit('cbus')"
                v-model="form.cbus[index]"
                :key="index"
            ></persona-cbu-form>
          </template>


        </form-list>
      </v-tab-item>

      <v-tab-item
          v-if="canView('documentos')"
          key="documentos"
      >
        <form-list
            v-model="form.documentos"
            :new-item="{nombre:'',link:''}"
        >

          <template v-slot:default="{item,index}">
            <persona-documento-form
                :readonly="!canEdit('documentos')"
                v-model="form.documentos[index]"
                :key="index"
            ></persona-documento-form>
          </template>


        </form-list>
      </v-tab-item>

    </v-tabs-items>

  </v-container>
</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'

import TipoDocumentoCombobox from "../../../../combobox/TipoDocumentoCombobox";
import SexoCombobox from "../../../../combobox/SexoCombobox";
import FormList from "@/modules/cobrox/components/FormList/FormList";
import PersonaDomicilioForm
  from "@/modules/cobrox/pages/crud/PersonaPage/PersonaForm/PersonaDomicilioForm/PersonaDomicilioForm";
import AgenciaCombobox from "@/modules/cobrox/combobox/AgenciaCombobox/AgenciaCombobox";
import EstadoPersonaCombobox from "@/modules/cobrox/combobox/EstadoPersonaCombobox/EstadoPersonaCombobox";
import PersonaTelefonoForm
  from "@/modules/cobrox/pages/crud/PersonaPage/PersonaForm/PersonaTelefonoForm/PersonaTelefonoForm";
import PersonaEmailForm from "@/modules/cobrox/pages/crud/PersonaPage/PersonaForm/PersonaEmailForm/PersonaEmailForm";
import PersonaCbuForm from "@/modules/cobrox/pages/crud/PersonaPage/PersonaForm/PersonaCbuForm/PersonaCbuForm";

import {DateInput} from "@dracul/dayjs-frontend"
import SuretyMixin from "@/modules/surety/mixins/SuretyMixin";
import SituacionLaboralCombobox from "@/modules/cobrox/combobox/SituacionLaboralCombobox/SituacionLaboralCombobox";
import JurisdiccionCombobox from "@/modules/cobrox/combobox/JurisdiccionCombobox/JurisdiccionCombobox";
import PersonaDocumentoForm
  from "@/modules/cobrox/pages/crud/PersonaPage/PersonaForm/PersonaDocumentoForm/PersonaDocumentoForm";


export default {
  name: "PersonaForm",
  mixins: [InputErrorsByProps, RequiredRule, SuretyMixin],
  created() {
    this.fetchFieldPermission('Persona')
  },
  components: {
    PersonaDocumentoForm,
    JurisdiccionCombobox,
    SituacionLaboralCombobox,
    PersonaCbuForm,
    PersonaEmailForm,
    PersonaTelefonoForm,
    EstadoPersonaCombobox,
    AgenciaCombobox,
    PersonaDomicilioForm,
    FormList,
    TipoDocumentoCombobox,
    SexoCombobox,
    DateInput
  },
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      tab: null,
      tabItems: ['telefonos', 'emails', 'domicilios', 'cbus', 'documentos'],
    }
  },
  computed: {
    getTabItems() {
      return this.filterViewFields(this.tabItems)
    },
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style scoped>

</style>

