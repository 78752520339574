<template>
  <v-container fluid>
    <h3 class="primary--text text-h4">Generar Facturas</h3>

    <v-row justify="center">

      <v-col cols="12" sm="12" md="4" class="text-center">
        <v-card elevation="4">
          <v-card-title>Distribución</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="12">
                <h3>%{{ distributionPercentage }}</h3>
              </v-col>
              <v-col cols="12" sm="12">
                <v-slider min="0" max="100" v-model="distributionPercentage"></v-slider>
              </v-col>
              <v-col cols="12" sm="12">
                <v-switch v-model="netear" label="netear cobranzas" readonly></v-switch>
              </v-col>
              <v-col cols="12" sm="12">
                <v-switch v-model="sumarizar" label="sumarizar cobranzas" readonly></v-switch>
              </v-col>
              <v-col cols="12" sm="12">
                <v-switch v-model="simular" label="simular"></v-switch>
              </v-col>

            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="facturar" :disabled="!isReady" color="primary onPrimary--text">Generar Facturas</v-btn>
          </v-card-actions>
        </v-card>

        <v-card elevation="4" class="mt-2">
          <v-card-title>Historial simulaciones</v-card-title>
          <v-btn icon absolute right top @click="limpiarHistorial"><v-icon>delete</v-icon></v-btn>
          <v-card-text class="px-0">
            <v-simple-table dense>
              <thead>
              <tr>
                <th>Porcentaje</th>
                <th>NoFacturable</th>
                <th>Facturable</th>
                <th>Facturas</th>
                <th>NoFacturas</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(dist,index) in historial" :key="index">
                <td>{{ dist.porcentajeDistribucion }}%</td>
                <td>
                  {{ currency }}{{
                    (Math.round((dist.montoNoFacturable + Number.EPSILON) * 100) / 100).toLocaleString('es-Ar')
                  }}
                </td>
                <td>
                  {{ currency }}{{
                    (Math.round((dist.montoFacturable + Number.EPSILON) * 100) / 100).toLocaleString('es-Ar')
                  }}
                </td>
                <td>
                  {{ dist.facturasFacturables }}
                </td>
                <td>
                  {{ dist.facturasNoFacturables }}
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="8" class="text-right">
        <template v-if="error">
          <v-alert type="error" class="text-left">{{ error }}</v-alert>
        </template>
        <template v-else>

          <div v-if="job" class="text-center">
            <v-progress-circular
                color="primary"
                :rotate="360"
                :size="100"
                :width="15"
                :value="job.progress"
            >
              {{ job.progress }}
            </v-progress-circular>
            <div>
              Job progress
            </div>

          </div>

          <div v-if="job && job.error" class="pa-4">
            <v-alert type="error" class="text-left">
              ERROR: {{ job.error }}
            </v-alert>
          </div>

          <div v-if="distribucion">

            <resultado-neteo :distribucion="distribucion"></resultado-neteo>
            <resultado-sumarizado :distribucion="distribucion"></resultado-sumarizado>
            <resultado-nota-credito :distribucion="distribucion"></resultado-nota-credito>
            <resultado-factura :distribucion="distribucion"></resultado-factura>
            <resultado-cobranzas-con-error :distribucion="distribucion"></resultado-cobranzas-con-error>
            <facturas-list v-if="distribucion.facturasList.length > 0"
                           :items="distribucion.facturasList"></facturas-list>

            <v-row>

              <!--    <v-col cols="12" class="py-0  ">
                    <v-alert v-if="simular && distribucion" type="info" class="text-left">Simulación exitosa</v-alert>
                    <v-alert v-if="!simular && distribucion" type="success" class="text-left">Generación exitosa</v-alert>
                  </v-col>-->

              <v-col cols="12" sm="6" md="6">
                <dashboard-card
                    title="Cobranzas sin deuda"
                    :value="distribucion.cobranzasSinDeuda"
                    error
                />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-card>
                  <v-card-title>DNI de cobranzas sin deuda</v-card-title>
                  <v-card-text>
                    <v-simple-table dense height="200" fixed-header>
                      <thead>
                      <tr>
                        <th>DNI</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(dni,index) in distribucion.dniCobranzasSinDeuda" :key="index">
                        <td>
                          {{ dni }}
                        </td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-text>
                </v-card>

              </v-col>

            </v-row>
          </div>

        </template>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import DashboardCard from "@/modules/cobrox/components/DashboardCard/DashboardCard";
import JobProvider from "@/modules/cobrox/providers/JobProvider";
import {QueueProvider} from "@dracul/queue-frontend"
import ResultadoNeteo from "@/modules/cobrox/pages/processes/GenerarFacturasMasivoPage/ResultadoNeteo/ResultadoNeteo";
import ResultadoSumarizado
  from "@/modules/cobrox/pages/processes/GenerarFacturasMasivoPage/ResultadoSumarizado/ResultadoSumarizado";
import ResultadoNotaCredito
  from "@/modules/cobrox/pages/processes/GenerarFacturasMasivoPage/ResultadoNotaCredito/ResultadoNotaCredito";
import ResultadoFactura
  from "@/modules/cobrox/pages/processes/GenerarFacturasMasivoPage/ResultadoFactura/ResultadoFactura";
import ResultadoCobranzasConError
  from "@/modules/cobrox/pages/processes/GenerarFacturasMasivoPage/ResultadoCobranzasConError/ResultadoCobranzasConError";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import FacturasList from "@/modules/cobrox/pages/processes/GenerarFacturasMasivoPage/FacturasList/FacturasList";

export default {
  name: "GenerarFacturasMasivoPage",
  components: {
    FacturasList,
    ResultadoCobranzasConError,
    ResultadoFactura,
    ResultadoNotaCredito,
    ResultadoSumarizado,
    ResultadoNeteo,
    DashboardCard
  },
  mixins: [CurrencyMixin],
  data() {
    return {
      loading: false,
      distributionPercentage: 0,
      distribucion: null,
      error: null,
      simular: true,
      netear: true,
      sumarizar: true,
      jobid: null,
      job: null,
      timeout: null,
      jobDone: false
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },
  created() {
    console.log("CREATED")
    if (this.getJobId) {
      this.fetchJob()
    } else {
      this.checkJob()
    }
  },
  computed: {
    getJobId() {
      return this.$route.params.jobid ? this.$route.params.jobid : this.jobid
    },
    historial() {
      return this.$store.getters.getHitorialDistribuciones
    },
    isReady(){
      return this.jobDone
    }
  },
  methods: {
    limpiarHistorial(){
      this.$store.commit('cleanHitorialDistribuciones')
    },
    goToJob(jobId) {
      this.$router.replace({name: 'GenerarFacturasMasivoJobPage', params: {jobid: jobId}})
          .catch(err => {
            if(!(err.name !== 'NavigationDuplicated')){
              throw err
            }
          })
    },
    facturar() {
      this.loading = true
      this.error = null
      this.distribucion = null
      this.jobDone = false
      JobProvider.distribuirMasivo(this.distributionPercentage, this.simular, this.netear, this.sumarizar)
          .then(res => {
            this.jobid = res.data.distribuirMasivo
            this.goToJob(this.jobid)

          })
          .catch(e => {
            console.error(e)
            this.error = e.message.replace("GraphQL error: Unexpected error value:", "")
          })
          .finally(() => {
            this.loading = false
          })
    },
    checkJob() {
      QueueProvider.queueFindByTopicAndState('DistribuirMasivo', 'WORKING').then(res => {
        let rjob = res.data.queueFindByTopicAndState
        if (rjob) {
          this.this.goToJob(rjob.id)
          this.fetchJob()
        }else{
          this.jobDone = true
        }
      })
    },
    fetchJob() {
      QueueProvider.findQueue(this.getJobId)
          .then(res => {
            this.job = res.data.queueFind
            this.distribucion = JSON.parse(res.data.queueFind.data)

            if (this.job.state === 'DONE') {
              this.$store.commit('addHitorialDistribuciones', this.distribucion)
              this.jobid = null
              this.jobDone = true
            }else{
              setTimeout(this.fetchJob, 5000)
            }

          })
          .catch(() => {
            if (this.job.state !== 'DONE') {
              setTimeout(this.fetchJob, 5000)
            }
          })
    }

  }
}
</script>

<style scoped>

</style>
