<template>
  <v-container>

    <template v-if="isRestoreSystemEnable">
      <h3>Restaurar sistema</h3>
      <p>Esta función elimina todos los registros de deuda, cobranza y facturas, siendo util para utilizar en el entorno
        de pruebas</p>

      <v-btn
          @click="confirm=true"
          :loading="loading"
          :disabled="systemRestored"
      >
        Restaurar
      </v-btn>

      <v-alert v-if="systemRestored" type="success">
        Sistema restaurado
      </v-alert>

      <v-alert v-if="error" type="error">
        {{error}}
      </v-alert>

      <confirm-dialog
          v-model="confirm"
          @confirmed="onConfirm"
          description="¿Esta realmente seguro de destruir todos los registros?"
      >
      </confirm-dialog>
    </template>

    <v-alert v-else>El servicio de restaurar el sistema esta deshabilitado</v-alert>


  </v-container>
</template>

<script>
import {ConfirmDialog} from "@dracul/common-frontend"
import RestoreSystemProvider from "@/modules/cobrox/providers/RestoreSystemProvider";

export default {
  name: "RemoveAllPage",
  components: {ConfirmDialog},
  data() {
    return {
      confirm: false,
      systemRestored: false,
      loading: false,
      error: null
    }
  },
  computed:{
    isRestoreSystemEnable(){
      return this.$store.getters.isRestoreSystemEnable
    }
  },
  methods: {
    onConfirm() {
      this.loading = true
      RestoreSystemProvider.restoreSystem()
          .then(() => {
            this.systemRestored = true
          })
          .catch(e => {
            this.error = e.message
          })
          .finally(() => {
            this.loading = false
          })
    }
  }
}
</script>

<style scoped>

</style>
