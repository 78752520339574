<template>

  <v-row>

    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="4">
          <agencia-combobox v-model="agencia" item-value="nombre" label="Filtrar por Agencia" clearable></agencia-combobox>
        </v-col>
        <v-col cols="12" md="4">
          <compra-combobox v-model="compra" item-value="nombre" label="Filtrar por Compra" clearable></compra-combobox>
        </v-col>
      </v-row>

    </v-col>
    <v-col cols="12">
      <v-data-table
          :items="getItems"
          :headers="headers"
          :loading="loading"
          :items-per-page="10"
      >

        <template v-slot:item.Capital="{item}">
          {{ currency }}{{ item.Capital.toLocaleString('es-AR') }}
        </template>

        <template v-slot:item.Intereses="{item}">
          {{ currency }}{{ item.Intereses.toLocaleString('es-AR') }}
        </template>

        <template v-slot:item.Total="{item}">
          {{ currency }}{{ item.Total.toLocaleString('es-AR') }}
        </template>

        <template v-slot:item.Recupero="{item}">
          {{ currency }}{{ item.Recupero.toLocaleString('es-AR') }}
        </template>

        <template v-slot:item.EficienciaCapital="{item}">
          {{ (item.Recupero / item.Capital * 100).toFixed(2) }}%
        </template>

        <template v-slot:item.EficienciaTotal="{item}">
          {{ (item.Recupero / item.Total * 100).toFixed(2) }}%
        </template>
      </v-data-table>
    </v-col>
  </v-row>


</template>
<script>
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import AgenciaCombobox from "@/modules/cobrox/combobox/AgenciaCombobox/AgenciaCombobox";
import CompraCombobox from "@/modules/cobrox/combobox/CompraCombobox/CompraCombobox";

export default {
  name: 'stock-report-main-table',
  components: {CompraCombobox, AgenciaCombobox},
  props: {
    items: {type: Array},
    loading: {type: Boolean},
    recupero: {type: Boolean},
  },
  mixins: [CurrencyMixin],
  data(){
    return {
      agencia: null,
      compra: null,
    }
  },
  computed: {
    getItems(){
      return this.items.filter(i => {
        if(this.agencia === null && this.compra  === null) return true
        if(this.agencia && i.Agencia != this.agencia) return false
        if(this.compra && i.Compra != this.compra) return false
        return true
      })
    },
    headers() {
      return [
        {text: 'Agencia', value: 'Agencia'},
        {text: 'Compra', value: 'Compra'},
        {text: 'Cantidad Personas', value: 'CantidadPersonas'},
        {text: 'Cantidad Deudas', value: 'CantidadDeudas'},
        {text: 'Capital', value: 'Capital'},
        {text: 'Intereses', value: 'Intereses'},
        {text: 'Total', value: 'Total'},

        ...(this.recupero ? [{text: 'Recupero', value: 'Recupero'}] : [{}]),
        ...(this.recupero ? [{text: 'Eficiencia Capital', value: 'EficienciaCapital'}] : [{}]),
        ...(this.recupero ? [{text: 'Eficiencia Total', value: 'EficienciaTotal'}] : [{}]),
      ]
    }
  },
}
</script>
