
const messages = {
    en: {
       role: {
            permissions: {
                PERSONA_MENU: "Access to Persona",
                PERSONA_SHOW: "View Persona",
                PERSONA_CREATE: "Create Persona",
                PERSONA_UPDATE: "Modify Persona",
                PERSONA_DELETE: "Delete Persona",
                PERSONA_IMPORT: "Importar Persona",
                PERSONA_ADD_NOTA: "Importar Persona",
                PERSONA_ROTAR_AGENCIA: "Rotar agencia Persona",
                PERSONA_AGENCIA_SHOW: "Ver Persona de mi agencia",
            }
       }
    },
    es: {
        role: {
            permissions: {
                PERSONA_MENU: "Acceder Persona",
                PERSONA_SHOW: "Ver Persona",
                PERSONA_CREATE: "Crear Persona",
                PERSONA_UPDATE: "Modificar Persona",
                PERSONA_DELETE: "Eliminar Persona",
                PERSONA_IMPORT: "Importar Persona",
                PERSONA_ADD_NOTA: "Importar Persona",
                PERSONA_ROTAR_AGENCIA: "Rotar agencia Persona",
                PERSONA_AGENCIA_SHOW: "Ver Persona de mi agencia",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                PERSONA_MENU: "Acessar Persona",
                PERSONA_SHOW: "Ver Persona",
                PERSONA_CREATE: "Criar Persona",
                PERSONA_UPDATE: "Modificar Persona",
                PERSONA_DELETE: "Eliminar Persona",
                PERSONA_IMPORT: "Importar Persona",
                PERSONA_ADD_NOTA: "Importar Persona",
                PERSONA_ROTAR_AGENCIA: "Rotar agencia Persona",
                PERSONA_AGENCIA_SHOW: "Ver Persona de mi agencia",
            }
       }
    }
}

export default messages
