import graphqlClient from "../../../apollo";

class ManualProvider {

    findManual(id) {
        return graphqlClient.query({
            query: require('./gql/Manual/findManual.graphql'),
            variables: {id:id}
        })
    }

    fetchManual() {
        return graphqlClient.query({query: require('./gql/Manual/fetchManual.graphql')})
    }
    
    paginateManual(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Manual/paginateManual.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }
    
    

    createManual(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Manual/createManual.graphql'),
            variables: {input}
        })
    }
    
    updateManual(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Manual/updateManual.graphql'),
            variables: {id, input}
        })
    }
    
     deleteManual(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Manual/deleteManual.graphql'),
            variables: {id}
        })
    }

}

export default new ManualProvider()


