<template>

        <v-select


                prepend-icon="fact_check"
                :items="items"
                v-model="item"
                :label="$t('cobrox.factura.labels.estado')"
                :loading="loading"
                :error="hasInputErrors('estado')"
                :error-messages="getInputErrors('estado')"
                color="secondary"
                item-color="secondary"

        ></v-select>

</template>

<script>

    import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'



    export default {
        name: "EstadoCombobox",
        mixins: [InputErrorsByProps, RequiredRule],
        props:{
            value: {
               type: [String, Array]
            },
        },
        data() {
            return {
                items: ['PROVISORIO','APROBADO','PROCESADO'],
                loading: false
            }
        },
        computed: {
           item: {
                get() { return this.value },
                set(val) {this.$emit('input', val)}
            }
        },
        methods: {
            validate(){
              return this.$refs.form.validate()
            }
        }
    }
</script>

<style scoped>

</style>

