<template>
  <crud-layout :title="title" :subtitle="subtitle">

    <template v-slot:list>
      <estado-deuda-list
          ref="list"
          @update="update"
          @delete="remove"
          @show="show"

      />
    </template>

    <add-button v-if="$store.getters.hasPermission('ESTADODEUDA_CREATE')" @click="create"></add-button>

    <estado-deuda-create v-if="creating"
                         :open="creating"
                         v-on:itemCreated="onItemCreated"
                         v-on:close="creating=false"
    />

    <estado-deuda-update v-if="updating"
                         :open="updating"
                         :item="itemToEdit"
                         v-on:itemUpdated="onItemUpdated"
                         v-on:close="updating=false"
    />

    <estado-deuda-show v-if="showing"
                       :open="showing"
                       :item="itemToShow"
                       v-on:close="showing=false"
    />

    <estado-deuda-delete v-if="deleting"
                         :open="deleting"
                         :item="itemToDelete"
                         v-on:itemDeleted="onItemDeleted"
                         v-on:close="deleting=false"
    />

    <snackbar v-model="flash"/>

  </crud-layout>
</template>

<script>

import EstadoDeudaCreate from "../EstadoDeudaCreate";
import EstadoDeudaUpdate from "../EstadoDeudaUpdate";
import EstadoDeudaDelete from "../EstadoDeudaDelete";
import EstadoDeudaShow from "../EstadoDeudaShow";
import EstadoDeudaList from "../EstadoDeudaList";

import {CrudLayout, AddButton, Snackbar} from "@dracul/common-frontend"

export default {
  name: "EstadoDeudaCrud",
  components: {
    CrudLayout, AddButton, Snackbar,
    EstadoDeudaCreate,
    EstadoDeudaUpdate,
    EstadoDeudaDelete,
    EstadoDeudaShow,
    EstadoDeudaList
  },
  data() {
    return {
      title: 'cobrox.estadoDeuda.title',
      subtitle: 'cobrox.estadoDeuda.subtitle',
      flash: null,
      creating: false,
      updating: false,
      deleting: false,
      showing: false,
      itemToEdit: null,
      itemToDelete: null,
      itemToShow: null,
    }
  },
  methods: {
    //On
    onItemCreated() {
      this.$refs.list.fetch()
      this.flash = this.$t("common.created")
    },
    onItemUpdated() {
      this.$refs.list.fetch()
      this.flash = this.$t("common.updated")
    },
    onItemDeleted() {
      this.$refs.list.fetch()
      this.flash = this.$t("common.deleted")
    },
    //Open
    create() {
      this.creating = true
    },
    update(item) {
      this.updating = true
      this.itemToEdit = item
    },
    show(item) {
      this.showing = true
      this.itemToShow = item
    },
    remove(item) {
      this.deleting = true
      this.itemToDelete = item
    }
  }

}
</script>


