import graphqlClient from "../../../apollo";

class PedidoCuentaProvider {

    findPedidoCuenta(id) {
        return graphqlClient.query({
            query: require('./gql/PedidoCuenta/findPedidoCuenta.graphql'),
            variables: {id:id}
        })
    }

    fetchPedidoCuenta() {
        return graphqlClient.query({query: require('./gql/PedidoCuenta/fetchPedidoCuenta.graphql')})
    }

    paginatePedidoCuenta(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/PedidoCuenta/paginatePedidoCuenta.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }



    createPedidoCuenta(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/PedidoCuenta/createPedidoCuenta.graphql'),
            variables: {input}
        })
    }

    updatePedidoCuenta(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/PedidoCuenta/updatePedidoCuenta.graphql'),
            variables: {id, input}
        })
    }

    updatePedidoCuentaEstado(id,estado, comentarioAutorizante, tipificacionAutorizante) {
        return graphqlClient.mutate({
            mutation: require('./gql/PedidoCuenta/updatePedidoCuentaEstado.graphql'),
            variables: {id, estado, comentarioAutorizante, tipificacionAutorizante}
        })
    }

     deletePedidoCuenta(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/PedidoCuenta/deletePedidoCuenta.graphql'),
            variables: {id}
        })
    }

}

export default new PedidoCuentaProvider()


