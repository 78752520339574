<template>
  <crud-update :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @update="update"
               @close="$emit('close')"
  >
    <compra-form ref="form" v-model="form" :input-errors="inputErrors"/>
  </crud-update>
</template>

<script>

import CompraProvider from "../../../../providers/CompraProvider";

import {CrudUpdate, ClientError} from '@dracul/common-frontend'

import CompraForm from "../CompraForm";

import {Dayjs} from "@dracul/dayjs-frontend";

export default {
  name: "CompraUpdate",

  components: {CompraForm, CrudUpdate},

  props: {
    open: {type: Boolean, default: true},
    item: {type: Object, required: true}
  },

  data() {
    return {
      title: 'cobrox.compra.editing',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      id: this.item.id,
      form: {
        nombre: this.item.nombre,
        nombreLegal: this.item.nombreLegal,
        fechaCompra: this.item.fechaCompra ? Dayjs(parseInt(this.item.fechaCompra)) : null,
        porcentajeCompra: this.item.porcentajeCompra,
        cuit: this.item.cuit,
        direccionLegal: this.item.direccionLegal,
        orden: this.item.orden,
        debitable: this.item.debitable
      }
    }
  },
  methods: {
    update() {
      if (this.$refs.form.validate()) {
        this.loading = true
        CompraProvider.updateCompra(this.id, this.form).then(r => {
              if (r) {
                this.$emit('itemUpdated', r.data.updateCompra)
                this.$emit('close')
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }
  },
}
</script>

<style scoped>

</style>

