<template>
  <v-card>
    <v-card-title>
      Tickets
      <template v-if="status">({{ status }})</template>
    </v-card-title>

    <v-card-text>

      <v-row justify="space-between" align="center">
        <!-- FILTERS HERE -->
        <v-col cols="12" sm="6" md="3">
          <redmine-tracker-combobox v-if="!this.customField" v-model="tracker_id" @input="fetchIssues"
                                    clearable default-project></redmine-tracker-combobox>
        </v-col>
      </v-row>


      <v-data-table
          :items="issues"
          :headers="headers"
          :server-items-length="total_count"

          :page.sync="pageNumber"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50, 100] }"
          :items-per-page.sync="itemsPerPage"
          @update:page="fetchIssues"
          @update:items-per-page="fetchIssues"

          :sort-by.sync="orderBy"
          :sort-desc.sync="orderDesc"
          @update:sort-by="fetchIssues"
          @update:sort-desc="fetchIssues"
      >

        <template v-slot:item.status="{ item }">
          {{ item.status.name }}
        </template>

        <template v-slot:item.created_on="{ item }">
          {{ formatDate(item.created_on) }}
        </template>

        <template v-slot:item.tracker="{ item }">
          {{ item.tracker.name }}
        </template>

        <template v-slot:item.dni="{ item }">
          <a :href="'/persona/dni/'+getDni(item)" target="_blank">{{ getDni(item) }}</a>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn color="primary--text" :to="{name: 'RedmineIssueViewPage', params: {id: item.id}}">
            <v-icon left>preview</v-icon>
            ver
          </v-btn>
        </template>

        <template v-slot:item.docs="{ item }">
          <certificado-convenio-file-button :ticket="item"></certificado-convenio-file-button>
          <certificado-precancelacion-file-button :ticket="item"></certificado-precancelacion-file-button>
        </template>

      </v-data-table>
    </v-card-text>
  </v-card>

</template>

<script>
import RedmineProvider from "@/modules/redmine/providers/RedmineProvider";
import RedmineTrackerCombobox from "@/modules/redmine/components/RedmineTrackerCombobox/RedmineTrackerCombobox";
import CertificadoConvenioFileButton
  from "@/modules/cobrox/components/CertificadoConvenioFileButton/CertificadoConvenioFileButton";
import CertificadoPrecancelacionFileButton
  from "@/modules/cobrox/components/CertificadoPrecancelacionFileButton/CertificadoPrecancelacionFileButton";
import {Dayjs} from "@dracul/dayjs-frontend"
export default {
  name: "RedmineIssuesList",
  components: {CertificadoPrecancelacionFileButton, CertificadoConvenioFileButton, RedmineTrackerCombobox},
  props: {
    status: {type: String},
    customField: {type: Number, default: null},
    customFieldValue: {default: null},
  },
  data() {
    return {
      tracker_id: null,
      issues: [],
      total_count: null,

      orderBy: null,
      orderDesc: false,

      itemsPerPage: 5,
      pageNumber: 1,
    }
  },
  mounted() {
      this.fetchIssues()
  },
  computed: {
    formatDate(){
      return date => {
        return Dayjs(date).format("YYYY-MM-DD")
      }
    },
    offset(){
      return this.pageNumber > 1 ?  this.itemsPerPage * (this.pageNumber - 1) : 0
    },
    limit(){
      return this.itemsPerPage
    },
    headers() {
      return [
        {text: '#', value: 'id'},
        {text: 'Estado', value: 'status'},
        {text: 'Fecha creación', value: 'created_on'},
        {text: 'Tipo', value: 'tracker'},
        {text: 'Asunto', value: 'subject'},
        {text: 'DNI', value: 'dni', sortable: false},
        {text: 'Visualizar', value: 'actions', sortable: false},
        {text: 'Docs', value: 'docs', sortable: false},
      ]
    },
    getStatus() {
      switch (this.status) {
        case 'cerrado':
          return 'closed'
        case 'abierto':
          return 'open'
        default:
          return this.status
      }
    },
    getDni() {
      return item => {

        if(!item.custom_fields) return null

        let cf = item.custom_fields.find(c => c.name === 'DNI')
        if (cf && cf.value) {
          return cf.value
        }
        return null
      }
    },

  },
  methods: {
    fetchIssues() {

      if (this.customField) {
        this.fetchIssuesByCustomField(this.customField, this.customFieldValue)

      }else if (this.$store.getters.hasPermission('REDMINE_VIEW_TICKETS')) {
        this.fetchIssuesAll(
            this.pageNumber,
            this.itemsPerPage,
            this.orderBy,
            this.orderDesc
        )
      } else if (this.$store.getters.hasPermission('REDMINE_VIEW_MY_TICKETS')) {
        this.fetchIssuesByAuthor(
            this.pageNumber,
            this.itemsPerPage,
            this.orderBy,
            this.orderDesc
        )
      }else{
        console.log("SIN PERMISOS REDMINE")
      }
    },
    fetchIssuesByAuthor() {
      let authorUsername = this.$store.getters.me.username
      RedmineProvider.fetchRedmineIssuesByAuthor(this.offset, this.limit, this.orderBy, this.orderDesc, this.getStatus, authorUsername, this.tracker_id).then(r => {
        this.issues = r.data.fetchRedmineIssuesByAuthor.issues
        this.total_count = r.data.fetchRedmineIssuesByAuthor.total_count
        //this.offset = r.data.fetchRedmineIssuesByAuthor.offset
        //this.limit = r.data.fetchRedmineIssuesByAuthor.limit
      })
    },
    fetchIssuesAll() {
      RedmineProvider.fetchRedmineIssues(this.offset, this.limit, this.orderBy, this.orderDesc, this.getStatus, this.tracker_id).then(r => {
        this.issues = r.data.fetchRedmineIssues.issues
        this.total_count = r.data.fetchRedmineIssues.total_count
        //this.offset = r.data.fetchRedmineIssues.offset
        //this.limit = r.data.fetchRedmineIssues.limit
      })
    },
    fetchIssuesByCustomField(customField, customFieldValue) {
      RedmineProvider.fetchRedmineIssuesByCustomField(customField, customFieldValue, this.tracker_id, this.offset, this.limit, this.orderBy, this.orderDesc).then(r => {
        this.issues = r.data.fetchRedmineIssuesByCustomField.issues
        this.total_count = r.data.fetchRedmineIssuesByCustomField.total_count
        //this.offset = r.data.fetchRedmineIssuesByCustomField.offset
        //this.limit = r.data.fetchRedmineIssuesByCustomField.limit
      })
    }
  }
}
</script>

<style scoped>

</style>
