const messages = {
    en: {
        redmine: {
            issue: {
                labels: {
                    tracker: 'Tracker',
                    subject: 'Subject',
                    description: 'Description',
                },
                menu: {
                    main: 'Tickets',
                    open: 'Open',
                    closed: 'Closed',
                    new: 'New'
                }
            }
        }
    },
    es: {
        redmine: {
            issue: {
                labels: {
                    main: 'Tickets',
                    tracker: 'Tipo de Ticket',
                    subject: 'Asunto',
                    description: 'Descripción',
                },
                menu: {
                    open: 'Tickets abiertos',
                    closed: 'Tickets cerrados',
                    new: 'Ticket nuevo',
                    main: 'Tickets',
                }

            }
        }
    },
    pt: {
        redmine: {
            issue: {
                labels: {
                    tracker: 'Tracker',
                    subject: 'Subject',
                    description: 'Description',
                },
                menu: {
                    main: 'Tickets',
                    open: 'Open',
                    closed: 'Closed',
                    new: 'New'
                }

            }
        }
    }
}

export default messages
