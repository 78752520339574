<template>
  <v-container fluid>

    <v-card outlined>
      <v-toolbar flat>
        <h6 class="text-h6">Cobranzas</h6>
        <v-spacer></v-spacer>
        <field-selector :fields="getFields" v-model="fieldsSelected"></field-selector>
        <v-btn v-if="createEnable && $store.getters.hasPermission('COBRANZA_CREATE')" @click="creating = true" color="primary OnPrimary--text"><v-icon>add</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-data-table
            :items="cobranzas"
            :headers="headersSelected"
            hide-default-footer
            :items-per-page="-1"
        >

          <template v-slot:item.estado="{ item }">
            <span class="font-weight-bold" :class="item.estado === 'PROCESADO' ? 'green--text' : 'purple--text'">
              {{item.estado}}
            </span>
          </template>

          <template v-slot:item.montoCobrado="{ item }">
            {{currency}}{{ item.montoCobrado }}
          </template>

          <template v-slot:item.agencia="{ item }">
            {{ item.agencia ? item.agencia.nombre : '' }}
          </template>

          <template v-slot:item.fechaCobranza="{ item }">
            {{ getDateFormat(item.fechaCobranza) }}
          </template>

          <template v-slot:item.action="{ item }">
            <show-button @click="itemToShow = item"/>
            <edit-button v-if="$store.getters.hasPermission('COBRANZA_UPDATE')  && item.estado == 'PENDIENTE'" @click="update(item)"/>
            <delete-button v-if="$store.getters.hasPermission('COBRANZA_DELETE') && item.estado == 'PENDIENTE'" @click="remove(item)"/>
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>


    <cobranza-create v-if="createEnable && creating"
                     :open="creating"
                     v-on:itemCreated="onItemCreated"
                     v-on:close="creating=false"
                     :dni-deudor="dniDeudor"
                     :cuil="cuil"
    />

    <cobranza-update v-if="updateEnable && updating"
                     :open="updating"
                     :item="itemToEdit"
                     v-on:itemUpdated="onItemUpdated"
                     v-on:close="updating=false"
                     :dni-deudor="dniDeudor"
                     :cuil="cuil"
    />

    <cobranza-delete v-if="deleteEnable && deleting"
                     :open="deleting"
                     :item="itemToDelete"
                     v-on:itemDeleted="onItemDeleted"
                     v-on:close="deleting=false"
    />

    <cobranza-show v-if="!!itemToShow"
                   :item="itemToShow"
                   @close="itemToShow = null"
    />

  </v-container>

</template>

<script>
import {DayjsMixin} from "@dracul/dayjs-frontend";
import CobranzaCreate from "@/modules/cobrox/pages/crud/CobranzaPage/CobranzaCreate/CobranzaCreate";
import CobranzaShow from "@/modules/cobrox/pages/crud/CobranzaPage/CobranzaShow";
import {ShowButton, DeleteButton, EditButton} from "@dracul/common-frontend";
import CobranzaUpdate from "@/modules/cobrox/pages/crud/CobranzaPage/CobranzaUpdate/CobranzaUpdate";
import CobranzaDelete from "@/modules/cobrox/pages/crud/CobranzaPage/CobranzaDelete/CobranzaDelete";
import FieldSelector from "@/modules/cobrox/components/FieldSelector/FieldSelector";
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import SuretyMixin from "@/modules/surety/mixins/SuretyMixin";

export default {
  name: "PersonaCobranzasView",
  components: {
    FieldSelector,
    CobranzaDelete, CobranzaUpdate, CobranzaShow, CobranzaCreate,ShowButton, DeleteButton, EditButton},
  created() {
    this.fetchFieldPermission('Cobranza')
  },
  mixins: [DayjsMixin, CurrencyMixin, SuretyMixin],
  props: {
    cobranzas: {type: Array},
    dniDeudor: {type: String},
    cuil: {type: String},
    createEnable: {type: Boolean, default: true},
    updateEnable: {type: Boolean, default: true},
    deleteEnable: {type: Boolean, default: true}
  },
  data(){
    return {
      creating: false,
      updating: false,
      deleting: false,
      showing: false,
      itemToEdit: null,
      itemToDelete: null,
      itemToShow: null,
      fields: ['estado','fechaCobranza','montoCobrado','canalCobro','cbu','nroProducto','idRapipago','agencia','tipificacion'],
      fieldsSelected: ['estado','fechaCobranza','montoCobrado','canalCobro','agencia','tipificacion']

    }
  },
  computed: {
    getFields(){
      return this.filterViewFields(this.fields)
    },
    getFieldSelected(){
      return this.filterViewFields(this.fieldsSelected)
    },
    headersSelected() {
      return this.headers.filter(h => this.getFieldSelected.includes(h.value) || h.value === 'action')
    },
    headers() {
      return [
        {text: this.$t('cobrox.cobranza.labels.estado'), value: 'estado'},
        {text: this.$t('cobrox.cobranza.labels.fechaCobranza'), value: 'fechaCobranza'},
        {text: this.$t('cobrox.cobranza.labels.montoCobrado'), value: 'montoCobrado'},
        {text: this.$t('cobrox.cobranza.labels.canalCobro'), value: 'canalCobro'},
        {text: this.$t('cobrox.cobranza.labels.cbu'), value: 'cbu'},
        {text: this.$t('cobrox.cobranza.labels.nroProducto'), value: 'nroProducto'},
        {text: this.$t('cobrox.cobranza.labels.idRapipago'), value: 'idRapipago'},
        {text: this.$t('cobrox.cobranza.labels.agencia'), value: 'agencia'},
        {text: this.$t('cobrox.cobranza.labels.tipificacion'), value: 'tipificacion'},
        {text: '', value: 'action', sortable: false},
      ]
    }
  },
  methods: {
    onItemCreated(){
      this.$emit("itemCreated")
    },
    onItemUpdated(){
      this.$emit("itemUpdated")
    },
    onItemDeleted(){
      this.$emit("itemDeleted")
    },
    update(item) {
      this.updating = true
      this.itemToEdit = item
    },
    show(item) {
      this.showing = true
      this.itemToShow = item
    },
    remove(item) {
      this.deleting = true
      this.itemToDelete = item
    }
  }
}
</script>

<style scoped>

</style>
