import graphqlClient from "../../../apollo";

class DeudaProvider {

    findDeuda(id) {
        return graphqlClient.query({
            query: require('./gql/Deuda/findDeuda.graphql'),
            variables: {id:id}
        })
    }

    fetchDeuda() {
        return graphqlClient.query({
            query: require('./gql/Deuda/fetchDeuda.graphql')
        })
    }

    fetchDeudaByCuil(cuil) {
        return graphqlClient.query({
            query: require('./gql/Deuda/fetchDeudaByCuil.graphql'),
            fetchPolicy: "network-only",
            variables: {cuil}
        })
    }

    fetchDeudaByCuils(cuils) {
        return graphqlClient.query({
            query: require('./gql/Deuda/fetchDeudaByCuils.graphql'),
            fetchPolicy: "network-only",
            variables: {cuils}
        })
    }

    fetchDeudaByDni(dni) {
        return graphqlClient.query({
            query: require('./gql/Deuda/fetchDeudaByDni.graphql'),
            fetchPolicy: "network-only",
            variables: {dni}
        })
    }

    hayDeudasPendientes(dni) {
        return graphqlClient.query({
            query: require('./gql/Deuda/hayDeudasPendientes.graphql'),
            fetchPolicy: "network-only",
            variables: {dni}
        })
    }


    paginateDeuda(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Deuda/paginateDeuda.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }



    createDeuda(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Deuda/createDeuda.graphql'),
            variables: {input}
        })
    }

    updateDeuda(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Deuda/updateDeuda.graphql'),
            variables: {id, input}
        })
    }

     deleteDeuda(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Deuda/deleteDeuda.graphql'),
            variables: {id}
        })
    }

}

export default new DeudaProvider()


