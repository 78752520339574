<template>
  <v-container fluid>
    <v-card>
      <v-card-title>Liquidación de Agencias</v-card-title>
      <v-card-text>
        <v-form ref="form" autocomplete="off">
          <v-row align="center">
            <v-col cols="12" md="3">
              <month-picker v-model="mes" label="Mes Imputado" :rules="required" @input="clear"></month-picker>
            </v-col>
            <v-col cols="12" md="3">
              <reporte-stock-combobox v-model="reporteStock" clearable @input="clear"></reporte-stock-combobox>
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox v-model="tipificado" label="Cobranzas Tipificadas"></v-checkbox>
            </v-col>
            <v-col cols="12" md="12" class="d-flex justify-end">
              <v-btn class="primary OnPrimary--text mr-6" @click="fetchEficiencia" :loading="loading">Consultar</v-btn>
              <v-btn v-if="!exportUrl" class="indigo--text grey lighten-4" @click="fetchEficienciaExport"
                     :loading="loading">Generar Archivo
              </v-btn>
              <v-btn v-if="exportUrl" :href="exportUrl" class="teal--text grey lighten-4" target="_blank" download>
                <v-icon left>download</v-icon>
                Descargar Archivo
              </v-btn>

            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text>
        <v-data-table
            :items="items"
            :headers="headers"
            :loading="loading"
            :items-per-page="10"
        >

          <template v-slot:item.Capital="{item}">
            <span v-if="item.Capital">
               {{ currency }}{{ item.Capital.toLocaleString('es-AR') }}
            </span>

          </template>

          <template v-slot:item.Intereses="{item}">
             <span v-if="item.Intereses">
                {{ currency }}{{ item.Intereses.toLocaleString('es-AR') }}
             </span>
          </template>

          <template v-slot:item.Total="{item}">
               <span v-if="item.Total">
                {{ currency }}{{ item.Total.toLocaleString('es-AR') }}
               </span>
          </template>

          <template v-slot:item.Recupero="{item}">
               <span v-if="item.Recupero">
                {{ currency }}{{ item.Recupero.toLocaleString('es-AR') }}
               </span>
          </template>

          <template v-slot:item.EficienciaCapital="{item}">
            {{ (item.Recupero / item.Capital * 100).toFixed(2) }}%
          </template>

          <template v-slot:item.EficienciaTotal="{item}">
            {{ (item.Recupero / item.Total * 100).toFixed(2) }}%
          </template>


          <template v-slot:item.PorcComision="{item}">
            {{ (item.Comision ? item.Comision.comision : '') }}%
            <v-chip v-if="item.Comision">
              {{ item.Comision.ratioDesde }}-{{ item.Comision.ratioHasta }}
            </v-chip>
          </template>

          <template v-slot:item.ComisionNeta="{item}">
             <span v-if="item.ComisionNeta">
                {{ currency }}{{ (item.ComisionNeta).toLocaleString('es-AR') }}
             </span>
          </template>

          <template v-slot:item.ComisionIVA="{item}">
             <span v-if="item.ComisionIVA">
                {{ currency }}{{ (item.ComisionIVA).toLocaleString('es-AR') }}
             </span>
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {MonthPicker} from "@dracul/dayjs-frontend"
import {RequiredRule} from "@dracul/common-frontend"
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import ReporteStockCombobox from "@/modules/cobrox/combobox/ReporteStockCombobox/ReporteStockCombobox";
import LiquidacionProvider from "@/modules/cobrox/providers/LiquidacionProvider";

export default {
  name: "LiquidacionAgenciaPage",
  components: {ReporteStockCombobox, MonthPicker},
  mixins: [RequiredRule, CurrencyMixin],
  data() {
    return {
      loading: false,
      mes: null,
      reporteStock: null,
      tipificado: true,
      items: [],
      headers: [
        {text: 'Agencia', value: 'Agencia'},
        {text: 'Compra', value: 'Compra'},
        {text: 'Monto Asignado', value: 'Total'},
        {text: 'Monto Recuperado', value: 'Recupero'},
        {text: 'Eficiencia', value: 'EficienciaTotal'},
        {text: 'Porc. Comisión', value: 'PorcComision'},
        {text: 'Comisión', value: 'ComisionNeta'},
        {text: 'Comisión + IVA', value: 'ComisionIVA'},
      ],
      exportUrl: null
    }
  },
  methods: {
    clear() {
      this.items = [],
          this.exportUrl = null
    },
    validate() {
      return this.$refs.form.validate()
    },
    fetchEficiencia() {
      if (!this.validate()) {
        return
      }
      this.loading = true
      LiquidacionProvider.liquidacionAgencia(this.mes, this.reporteStock, this.tipificado)
          .then(r => {
            this.items = r.data.liquidacionAgencia
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => this.loading = false)
    },
    fetchEficienciaExport() {
      if (!this.validate()) {
        return
      }
      this.loading = true
      LiquidacionProvider.liquidacionAgenciaExport(this.mes, this.reporteStock, this.tipificado)
          .then(r => {
            this.exportUrl = r.data.liquidacionAgenciaExport
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
