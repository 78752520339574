
const messages = {
    en: {
       role: {
            permissions: {
                AUTOGESTIONLOG_MENU: "Access to AutogestionLog",
                AUTOGESTIONLOG_SHOW: "View AutogestionLog",
                AUTOGESTIONLOG_CREATE: "Create AutogestionLog",
                AUTOGESTIONLOG_UPDATE: "Modify AutogestionLog",
                AUTOGESTIONLOG_DELETE: "Delete AutogestionLog",
            }
       }
    },
    es: {
        role: {
            permissions: {
                AUTOGESTIONLOG_MENU: "Acceder AutogestionLog",
                AUTOGESTIONLOG_SHOW: "Ver AutogestionLog",
                AUTOGESTIONLOG_CREATE: "Crear AutogestionLog",
                AUTOGESTIONLOG_UPDATE: "Modificar AutogestionLog",
                AUTOGESTIONLOG_DELETE: "Eliminar AutogestionLog",
            }
       }
    },
    pt: {
       role: {
            permissions: {
                AUTOGESTIONLOG_MENU: "Acessar AutogestionLog",
                AUTOGESTIONLOG_SHOW: "Ver AutogestionLog",
                AUTOGESTIONLOG_CREATE: "Criar AutogestionLog",
                AUTOGESTIONLOG_UPDATE: "Modificar AutogestionLog",
                AUTOGESTIONLOG_DELETE: "Eliminar AutogestionLog",
            }
       }
    }
}

export default messages
    