<template>
  <v-row v-if="persona">
    <v-col cols="12" sm="6" md="4">
      <field-view v-if="canView('nombreCompleto')" :value="persona.nombreCompleto" :label="$t('cobrox.persona.labels.nombreCompleto')" icon="face"/>
      <field-view v-if="canView('tipoDocumento')" :value="persona.tipoDocumento" :label="$t('cobrox.persona.labels.tipoDocumento')" icon="fact_check"/>
      <field-view v-if="canView('fechaNacimiento')" :value="persona.fechaNacimiento ? getDateFormat(persona.fechaNacimiento) : ''" :label="$t('cobrox.persona.labels.fechaNacimiento')" icon="event"/>
      <field-view v-if="canView('estado')" :value="persona.estado" :label="$t('cobrox.persona.labels.estado')" icon="assignment"/>
      <field-view v-if="canView('situacionLaboral')" :value="persona.situacionLaboral ? persona.situacionLaboral.nombre : ''" :label="$t('cobrox.persona.labels.situacionLaboral')" icon="work_outline"/>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <field-view v-if="canView('sexo')" :value="persona.sexo" :label="$t('cobrox.persona.labels.sexo')" icon="wc"/>
      <field-view v-if="canView('nroDocumento')" :value="persona.nroDocumento" :label="$t('cobrox.persona.labels.nroDocumento')" icon="badge"/>
      <field-view v-if="canView('agencia')" :value="persona.agencia ? persona.agencia.nombre : ''" :label="$t('cobrox.persona.labels.agencia')" icon="business"/>
      <field-view v-if="canView('rotacionBloqueada')" :value="persona.rotacionBloqueada ? 'Si ('+ persona.rotacionBloqueadaPor+')': 'No'" :label="$t('cobrox.persona.labels.rotacionBloqueada')" icon="block"/>
      <field-view v-if="canView('jurisdiccion')" :value="persona.jurisdiccion" :label="$t('cobrox.persona.labels.jurisdiccion')" icon="map"/>
    </v-col>

    <v-col cols="12" sm="6" md="4">
      <field-view v-if="canView('nacionalidad')" :value="persona.nacionalidad" :label="$t('cobrox.persona.labels.nacionalidad')" icon="flag"/>
      <field-view v-if="canView('cuil')" :value="persona.cuil" :label="$t('cobrox.persona.labels.cuil')" icon="folder_shared"/>
      <field-view v-if="canView('idRapipago')" :value="persona.idRapipago" :label="$t('cobrox.persona.labels.idRapipago')" icon="credit_score"/>
      <field-view v-if="canView('fechaDesbloqueoRotacion') && persona.fechaDesbloqueoRotacion"  :value="getDateFormat(persona.fechaDesbloqueoRotacion)" :label="$t('cobrox.persona.labels.fechaDesbloqueoRotacion')" icon="block"/>
      <field-view v-if="canView('idClienteColppy')" :value="persona.idClienteColppy" :label="$t('cobrox.persona.labels.idClienteColppy')" icon="badge"/>
    </v-col>

  </v-row>
</template>

<script>
import FieldView from "@/modules/cobrox/components/FieldView/FieldView";
import {DayjsMixin} from "@dracul/dayjs-frontend"
import SuretyMixin from "@/modules/surety/mixins/SuretyMixin";

export default {
  name: "PersonaMainView",
  components: {FieldView},
  mixins: [DayjsMixin, SuretyMixin],
  props: {
    persona: Object
  },
  created() {
    this.fetchFieldPermission('Persona')
  }
}
</script>

<style scoped>

</style>
