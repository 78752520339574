<template>
  <v-tooltip bottom>
    <template v-slot:activator="{on}">
      <v-btn
          v-on="on"
          v-if="isVisible"
          small icon
          color="green white--text"
          @click="fetchDoc" class="mb-2">
        <v-icon>file_open</v-icon>
      </v-btn>
    </template>
    Certificado Convenio
  </v-tooltip>

</template>

<script>
import FileBuilderProvider from "@/modules/cobrox/providers/FileBuilderProvider";

export default {
  name: "CertificadoConvenioFileButton",
  props: {
    ticket: {type: Object}
  },
  computed: {
    isVisible() {
      if (this.ticket.tracker.name === 'Certificado Convenio' && ['Aprobado'].includes(this.ticket.status.name)) {
        return true
      }
      return false
    }
  },
  methods: {
    fetchDoc() {
      FileBuilderProvider.buildCertificadoConvenioPdf(this.ticket.id)
          .then(r => {
            setTimeout(() => window.open(r.data.buildCertificadoConvenioPdf), 300)
          })
    },

  }
}
</script>

<style scoped>

</style>
