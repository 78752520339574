
const messages = {
    en: {
        menu: {
            dashboard: 'dashboard',
            userdashboard: 'Users Dashboard',
            customization: 'Customization',
            administration: 'Administration',
            people: 'People',
            debts: 'Debts',
            collections: 'Collections',
            receipt: 'Receipt',
            entities: 'Entities',
            jobs: 'Jobs',
            restore: 'Restore system',

            importDebts: 'Import Debts',
            importCollections: 'Import Collections',
            importAgencias: "Import Agencia",
            importPersonas: "Import People",

            netearCobranzas: "Netear cobranzas",
            generarNotaCredito: "Generar NC",
            generarFacturas: "Procesar Cobranzas (Generar FC)",
            facturar: "Facturar",
            generarPersonas:"Generar Personas",
            procesarIntereses: "Recalcular Intereses",
            importPurchaseDebts: "Importar Compra Deuda",
            rotarAgencia: 'Rotar agencia',
            bloquearAgencia: 'Bloquear agencia',
            importCobrazaRapipago: 'Import Collections Rapipago',
            reprocesarImputacionCobranzas: 'Reprocesar imputación Cobranzas',
            settings: 'Settings',
            kinvaDoc: 'Plantillas Documentos',
            rapipagoArchivo: 'Archivo Rapipago',
            surety: 'Permisos de Entidad',
            revertJob: 'Revertir Job',
            hideMenu: 'Ocultar menú',
            wolke:'Wolke',
            convenioPagos: 'Pagos de Convenio',

        }
    },
    es: {
        menu: {
            dashboard: 'dashboard',
            userdashboard: 'Tablero de Usuarios',
            customization: 'Personalización',
            administration: 'Administración',
            people: 'Personas',
            debts: 'Deudas',
            collections: 'Cobranzas',
            receipt: 'Facturas',
            entities: 'Gestion',
            jobs: 'Jobs',
            restore: 'Restaurar sistema',

            importDebts: 'Importar Deudas',
            importCollections: 'Importar Cobranzas',
            importAgencias: "Importar Agencias",
            importPersonas: "Importar Personas",

            netearCobranzas: "Netear cobranzas",
            generarNotaCredito: "Generar NC",
            generarFacturas: "Procesar Cobranzas (Generar FC)",
            facturar: "Facturar",
            generarPersonas:"Generar Personas",
            procesarIntereses: "Recalcular Intereses",
            importPurchaseDebts: "Importar Compra Deuda",
            rotarAgencia: 'Rotar agencia',
            bloquearAgencia: 'Bloquear agencia',
            importCobrazaRapipago: 'Importar Cobranzas Rapipago',
            reprocesarImputacionCobranzas: 'Reprocesar imputación Cobranzas',
            settings: 'Ajustes',
            kinvaDoc: 'Plantillas Documentos',
            rapipagoArchivo: 'Archivo Rapipago',
            surety: 'Permisos de Entidad',
            revertJob: 'Revertir Job',
            hideMenu: 'Ocultar menú',
            wolke:'Wolke',
            convenioPagos: 'Pagos de Convenio',
        }
    },
    pt: {
        menu: {
            dashboard: 'dashboard',
            userdashboard: 'Painel de controle',
            customization: 'Costumização',
            administration: 'administração',
            people: 'Personas',
            debts: 'Deudas',
            collections: 'Cobranzas',
            receipt: 'Receipt',
            entities: 'Entities',
            jobs: 'Jobs',
            restore: 'Restaurar sistema',

            importDebts: 'Importar Deudas',
            importCollections: 'Importar Cobranzas',
            importAgencias: "Importar Agencias",
            importPersonas: "Import People",

            netearCobranzas: "Netear cobranzas",
            generarNotaCredito: "Generar NC",
            generarFacturas: "Procesar Cobranzas (Generar FC)",
            facturar: "Facturar",
            generarPersonas:"Generar Personas",
            procesarIntereses: "Recalcular Intereses",
            importPurchaseDebts: "Importar Compra Deuda",
            rotarAgencia: 'Rotar agencia',
            bloquearAgencia: 'Bloquear agencia',
            importCobrazaRapipago: 'Importar Cobranzas Rapipago',
            reprocesarImputacionCobranzas: 'Reprocesar imputación Cobranzas',
            settings: 'Settings',
            kinvaDoc: 'Plantillas Documentos',
            rapipagoArchivo: 'Archivo Rapipago',
            surety: 'Permisos de Entidad',
            revertJob: 'Revertir Job',
            hideMenu: 'Ocultar menú',
            wolke:'Wolke',
            convenioPagos: 'Pagos de Convenio',
        }
    }
}

export default messages
