
import merge from 'deepmerge'        
import FieldPermissionMessages from './messages/FieldPermissionMessages'

import FieldPermissionPermissionMessages from './permissions/FieldPermissionPermissionMessages'


const messages = merge.all([
    FieldPermissionMessages, 
    
    FieldPermissionPermissionMessages
])

export default messages;
