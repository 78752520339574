<template>
    <crud-show :title="title" :open="open"  @close="$emit('close')">

        <v-card-text>
            <comision-show-data :item="item" />
        </v-card-text>

  </crud-show>
</template>

<script>
    import ComisionShowData from "./ComisionShowData";
    import {CrudShow} from '@dracul/common-frontend'
 
    export default {
        name: "ComisionShow",
        components: {CrudShow, ComisionShowData},
        props: {
            open: {type: Boolean, default: true},
            item: {type: Object, required: true}
        },
        data() {
            return {
                title: 'cobrox.comision.showing',
            }
        },
    }
</script>

