<template>
  <v-container>
    <v-card>
      <v-card-title>Crear personas desde deudas</v-card-title>
      <v-card-subtitle>Este job crear personas a partir de las deudas cargadas</v-card-subtitle>

      <v-card-text class="text-center">
        <v-btn color="primary OnPrimary--text" @click="generar">Generar</v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import JobProvider from "@/modules/cobrox/providers/JobProvider";

export default {
  name: "CrearPersonaDesdeDeudaPage",
  data(){
    return {
      jobId: null
    }
  },
  methods: {
    generar() {
      JobProvider.crearPersonaDesdeDeuda()
          .then(r => {
            this.jobId = r.data.crearPersonaDesdeDeuda
            this.$router.push({name: "JobPage", params: {id: this.jobId}})
          })
    }
  }
}
</script>

<style scoped>

</style>
