<template>
  <v-card class="mb-3">
    <v-card-title>Generación de Facturas</v-card-title>
    <v-card-text>
      <v-simple-table>

        <tbody>
        <tr>
          <th>% Distribución</th>
          <td>{{distribucion.porcentajeDistribucion}}</td>
        </tr>
        <tr>
          <th>Lote</th>
          <td>{{distribucion.lote}}</td>
        </tr>

        <tr>
          <th>Cobranzas procesadas</th>
          <td>{{distribucion.cobranzas}}</td>
        </tr>


        <tr>
          <th>Facturas Generadas</th>
          <td>{{distribucion.facturas}}</td>
        </tr>

        <tr>
          <th>Facturas Deudor</th>
          <td>{{distribucion.facturasDeudor}}</td>
        </tr>

        <tr>
          <th>Facturas Distribuidas</th>
          <td>{{distribucion.facturasDistribuidas}}</td>
        </tr>

        <tr>
          <th>Cobranzas sin deuda</th>
          <td>{{distribucion.cobranzasSinDeuda}}</td>
        </tr>

        <tr>
          <th>Cobranzas con error</th>
          <td>{{distribucion.cobranzasConError}}</td>
        </tr>

        <tr>
          <th>Cobranzas sin distribuir</th>
          <td>{{distribucion.cobranzasSinDistribuir}}</td>
        </tr>

        <tr>
          <th>Registros no facturables</th>
          <td>{{distribucion.facturasNoFacturables}}</td>
        </tr>


        <tr>
          <th>Registros facturables</th>
          <td>{{distribucion.facturasFacturables}}</td>
        </tr>


        <tr>
          <th>Monto no facturable</th>
          <td>{{currency}}{{distribucion.montoNoFacturable.toLocaleString('es-Ar')}}</td>
        </tr>

        <tr>
          <th>Monto facturable</th>
          <td>{{currency}}{{distribucion.montoFacturable.toLocaleString('es-Ar')}}</td>
        </tr>

        <tr>
          <th>Monto procesado</th>
          <td>{{currency}}{{distribucion.montoProcesado.toLocaleString('es-Ar')}}</td>
        </tr>

        <tr>
          <th>Monto no procesado</th>
          <td>{{currency}}{{distribucion.montoNoProcesado.toLocaleString('es-Ar')}}</td>
        </tr>

        <tr>
          <th>Monto total cobranzas</th>
          <td>{{currency}}{{distribucion.montoCobranzas.toLocaleString('es-Ar')}}</td>
        </tr>



        <!--<tr>
          <th>xxxx</th>
          <td>{{distribucion.xxxx}}</td>
        </tr>-->

        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";

export default {
  name: "ResultadoFactura",
  props: {
    distribucion: Object
  },
  mixins: [CurrencyMixin]
}
</script>

<style scoped>

</style>
