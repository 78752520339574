<template>
  <v-container fluid>

    <v-toolbar class="mb-2">
      <v-tabs
          v-model="mainTab"
          background-color="teal--text"
          centered
          icons-and-text
      >

        <v-tab >
          Nuevo
          <v-icon>add_a_photo</v-icon>
        </v-tab>

        <v-tab >
          Historico
          <v-icon>manage_search</v-icon>
        </v-tab>


      </v-tabs>
    </v-toolbar>


    <v-tabs-items  v-model="mainTab"  >
      <v-tab-item active-class="pa-2"   >
        <v-card>
          <v-card-title>Nuevo informe de Stock</v-card-title>
          <v-card-text>
            <v-form ref="form" autocomplete="off">
              <v-row align="center">

                <v-col cols="12" md="3">
                  <estado-deuda-combobox hide-details  v-model="estadosDeuda" multiple></estado-deuda-combobox>
                </v-col>

                <v-col cols="12" md="3">
                  <date-input v-model="desde" label="Desde" hide-details></date-input>
                </v-col>

                <v-col cols="12" md="3">
                  <date-input v-model="hasta" label="Hasta" hide-details></date-input>
                </v-col>

                <v-col cols="12" md="3">
                  <v-btn class="primary OnPrimary--text" @click="fetchReporteStock" :loading="loading">Consultar</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

        </v-card>

        <v-card class="mt-2" v-if="items.length > 0">
          <v-toolbar outlined flat>
            <v-tabs v-model="tab">
              <v-tab>Informe</v-tab>
              <v-tab>Agencia</v-tab>
              <v-tab>Compra</v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
            <v-text-field v-if="items.length > 0 && !url" v-model="description" label="Descripción" outlined hide-details dense></v-text-field>
            <v-btn v-if="items.length > 0 && !url" class="indigo--text grey lighten-4" @click="generarArchivo" :loading="loading">Generar foto y archivo</v-btn>
            <v-btn v-else-if="url" :href="url" class="teal--text grey lighten-4" target="_blank" download> <v-icon left>download</v-icon>Descargar Archivo</v-btn>
          </v-toolbar>


          <v-card-text >

            <v-tabs-items :value="tab">
              <v-tab-item>
                <stock-report-main-table :items="items" :loading="loading" :recupero="recupero"/>
              </v-tab-item>

              <v-tab-item>
                <stock-report-agencia-table :items="agenciaItems" :loading="loading"></stock-report-agencia-table>
              </v-tab-item>

              <v-tab-item>
                <stock-report-compra-table :items="compraItems" :loading="loading"></stock-report-compra-table>
              </v-tab-item>

            </v-tabs-items>


          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card>
          <v-card-title>Historial de informes de Stock</v-card-title>
          <v-card-text>
            <reporte-stock-list ref="reporteStockList"></reporte-stock-list>
          </v-card-text>
        </v-card>
      </v-tab-item>

    </v-tabs-items>


  </v-container>
</template>

<script>
import {RequiredRule} from "@dracul/common-frontend"
import {DateInput} from "@dracul/dayjs-frontend"
import CurrencyMixin from "@/modules/cobrox/mixins/CurrencyMixin";
import EstadoDeudaCombobox from "@/modules/cobrox/combobox/EstadoDeudaCombobox/EstadoDeudaCombobox";
import StockReportMainTable from "@/modules/cobrox/pages/reports/StockReportPage/StockReportMainTable";
import StockReportAgenciaTable from "@/modules/cobrox/pages/reports/StockReportPage/StockReportAgenciaTable";
import StockReportCompraTable from "@/modules/cobrox/pages/reports/StockReportPage/StockReportCompraTable";
import ReporteStockList from "@/modules/cobrox/pages/crud/ReporteStockPage/ReporteStockList/ReporteStockList";
import StockReportProvider from "@/modules/cobrox/providers/StockReportProvider";

export default {
  name: "StockReportPage",
  components: {
    ReporteStockList,
    StockReportCompraTable,
    StockReportAgenciaTable,
    StockReportMainTable,
    EstadoDeudaCombobox,
    DateInput},
  mixins: [RequiredRule, CurrencyMixin],
  data() {
    return {
      loading: false,
      fecha: null,
      desde: null,
      hasta: null,
      estadosDeuda: [],
      recupero: false,
      items: [],
      mainTab:0,
      tab: 0,
      url: null,
      description: ""

    }
  },
  computed: {
    agenciaItems() {
      return this.items.reduce((a, v) => {
        let item = a.find(i => i.Agencia === v.Agencia)
        if (item) {
          item.CantidadPersonas += v.CantidadPersonas
          item.CantidadDeudas += v.CantidadDeudas
          item.Capital += v.Capital
          item.Intereses += v.Intereses
          item.Total += v.Total
        } else {
          a.push({...v})
        }
        return a
      }, [])
    },
    compraItems() {
      return this.items.reduce((a, v) => {
        let item = a.find(i => i.Compra === v.Compra)
        if (item) {
          item.CantidadPersonas += v.CantidadPersonas
          item.CantidadDeudas += v.CantidadDeudas
          item.Capital += v.Capital
          item.Intereses += v.Intereses
          item.Total += v.Total
        } else {
          a.push({...v})
        }
        return a
      }, [])
    }
  },
  methods: {
    clearData() {
      this.items = []
    },
    validate() {
      return this.$refs.form.validate()
    },
    fetchReporteStock() {
      if (!this.validate()) {
        return
      }
      this.loading = true
      StockReportProvider.stockReport(this.desde,this.hasta, this.estadosDeuda, this.recupero)
          .then(r => {
            this.items = r.data.stockReport.items
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => this.loading = false)
    },
    generarArchivo() {
      console.log("generarArchivo")
      this.loading = true
      StockReportProvider.stockReportFile(this.desde,this.hasta, this.estadosDeuda, this.description)
          .then(r => {
            console.log("URL", r.data.stockReportFile)
            this.url = r.data.stockReportFile
            this.$refs.reporteStockList.fetch()
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => this.loading = false)
    }
  }
}
</script>

