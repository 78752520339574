import { render, staticRenderFns } from "./PersonaPedidosCuenta.vue?vue&type=template&id=32c52400&scoped=true"
import script from "./PersonaPedidosCuenta.vue?vue&type=script&lang=js"
export * from "./PersonaPedidosCuenta.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32c52400",
  null
  
)

export default component.exports