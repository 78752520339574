import graphqlClient from "../../../apollo";

class CajaMensualProvider {

    findCajaMensual(id) {
        return graphqlClient.query({
            query: require('./gql/CajaMensual/findCajaMensual.graphql'),
            variables: {id:id}
        })
    }

    fetchCajaMensual() {
        return graphqlClient.query({query: require('./gql/CajaMensual/fetchCajaMensual.graphql')})
    }
    
    paginateCajaMensual(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/CajaMensual/paginateCajaMensual.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }
    
    

    createCajaMensual(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/CajaMensual/createCajaMensual.graphql'),
            variables: {input}
        })
    }
    
    updateCajaMensual(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/CajaMensual/updateCajaMensual.graphql'),
            variables: {id, input}
        })
    }
    
     deleteCajaMensual(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/CajaMensual/deleteCajaMensual.graphql'),
            variables: {id}
        })
    }

}

export default new CajaMensualProvider()


