<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">
    <v-row>



      <v-col cols="12" sm="6" md="6">
        <v-list>
          <show-field :value="role.name" :label="$t('surety.fieldPermission.labels.role')" icon="verified_user"/>
        </v-list>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-list>
          <show-field :value="form.entity" :label="$t('surety.fieldPermission.labels.entity')" icon="fingerprint"/>
        </v-list>
      </v-col>

      <v-col cols="12">

        <v-simple-table>
          <thead>
          <tr>
            <th>Field</th>
            <th>View</th>
            <th>Edit</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="field in form.fields" :key="field.name">
            <td>{{field.name}}</td>
            <td>
              <v-checkbox
                prepend-icon="visibility"
                name="entity"
                v-model="field.view"
                color="secondary"
            ></v-checkbox>
            </td>
            <td>
              <v-checkbox
                  prepend-icon="edit"
                  name="entity"
                  v-model="field.edit"
                  color="secondary"
              ></v-checkbox>
            </td>
          </tr>
          </tbody>
        </v-simple-table>

      </v-col>


    </v-row>
  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule, ShowField} from '@dracul/common-frontend'

export default {
  name: "FieldPermissionForm",
  mixins: [InputErrorsByProps, RequiredRule],
  components: {ShowField},
  props: {
    value: {type: Object, required: true},
    role: {type: Object},
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>

