import PersonaPage from '../pages/crud/PersonaPage'
import DeudaPage from '../pages/crud/DeudaPage'
import CobranzaPage from '../pages/crud/CobranzaPage'
import FacturaPage from '../pages/crud/FacturaPage'
import CanalCobroPage from '../pages/crud/CanalCobroPage'
import CuentaPage from '../pages/crud/CuentaPage'
import EstadoDeudaPage from '../pages/crud/EstadoDeudaPage'
import AgenciaPage from '../pages/crud/AgenciaPage'
import OrigenDeudaPage from '../pages/crud/OrigenDeudaPage'
import CompraPage from '../pages/crud/CompraPage'
import InteresPage from '../pages/crud/InteresPage'
import ProductoPage from '../pages/crud/ProductoPage'
import ReporteStockPage from '../pages/crud/ReporteStockPage'
import RetencionPage from '../pages/crud/RetencionPage'
import SituacionLaboralPage from '../pages/crud/SituacionLaboralPage'
import CajaMensualPage from '../pages/crud/CajaMensualPage'
import ComisionPage from '../pages/crud/ComisionPage'
import ManualPage from '../pages/crud/ManualPage'
import PedidoCuentaPage from '../pages/crud/PedidoCuentaPage'
import QuitaCrudPage from '../pages/crud/QuitaCrudPage'
import ConvenioCrudPage from '../pages/crud/ConvenioCrudPage'
import AutogestionLogCrudPage from "@/modules/cobrox/pages/crud/AutogestionLogCrudPage";

const routes = [

    {
        name: 'PersonaPage',
        path: '/crud/persona',
        component: PersonaPage,
        meta: {
            requiresAuth: true,
            permission: "PERSONA_MENU"
        }
    },

    {
        name: 'DeudaPage',
        path: '/crud/deuda',
        component: DeudaPage,
        meta: {
            requiresAuth: true,
            permission: "DEUDA_MENU"
        }
    },

    {
        name: 'CobranzaPage',
        path: '/crud/cobranza',
        component: CobranzaPage,
        meta: {
            requiresAuth: true,
            permission: "COBRANZA_MENU"
        }
    },

    {
        name: 'FacturaPage',
        path: '/crud/factura',
        component: FacturaPage,
        meta: {
            requiresAuth: true,
            permission: "FACTURA_MENU"
        }
    },

    {
        name: 'CanalCobroPage',
        path: '/crud/canalcobro',
        component: CanalCobroPage,
        meta: {
            requiresAuth: true,
            permission: "CANALCOBRO_MENU"
        }
    },

    {
        name: 'CuentaPage',
        path: '/crud/cuenta',
        component: CuentaPage,
        meta: {
            requiresAuth: true,
            permission: "CUENTA_MENU"
        }
    },

    {
        name: 'EstadoDeudaPage',
        path: '/crud/estadodeuda',
        component: EstadoDeudaPage,
        meta: {
            requiresAuth: true,
            permission: "ESTADODEUDA_MENU"
        }
    },

    {
        name: 'AgenciaPage',
        path: '/crud/agencia',
        component: AgenciaPage,
        meta: {
            requiresAuth: true,
            permission: "AGENCIA_MENU"
        }
    },

    {
        name: 'OrigenDeudaPage',
        path: '/crud/origendeuda',
        component: OrigenDeudaPage,
        meta: {
            requiresAuth: true,
            permission: "ORIGENDEUDA_MENU"
        }
    },

    {
        name: 'CompraPage',
        path: '/crud/compra',
        component: CompraPage,
        meta: {
            requiresAuth: true,
            permission: "COMPRA_MENU"
        }
    },

    {
        name: 'InteresPage',
        path: '/crud/interes',
        component: InteresPage,
        meta: {
            requiresAuth: true,
            permission: "INTERES_MENU"
        }
    },

    {
        name: 'ProductoPage',
        path: '/crud/producto',
        component: ProductoPage,
        meta: {
            requiresAuth: true,
            permission: "PRODUCTO_MENU"
        }
    },

    {
        name: 'ReporteStockPage',
        path: '/crud/reportestock',
        component: ReporteStockPage,
        meta: {
            requiresAuth: true,
            permission: "REPORTESTOCK_MENU"
        }
    },

    {
        name: 'RetencionPage',
        path: '/crud/retencion',
        component: RetencionPage,
        meta: {
            requiresAuth: true,
            permission: "RETENCION_MENU"
        }
    },

    {
        name: 'SituacionLaboralPage',
        path: '/crud/situacionlaboral',
        component: SituacionLaboralPage,
        meta: {
            requiresAuth: true,
            permission: "SITUACIONLABORAL_MENU"
        }
    },

    {
        name: 'CajaMensualPage',
        path: '/crud/cajamensual',
        component: CajaMensualPage,
        meta: {
            requiresAuth: true,
            permission: "CAJAMENSUAL_MENU"
        }
    },

    {
        name: 'ComisionPage',
        path: '/crud/comision',
        component: ComisionPage,
        meta: {
            requiresAuth: true,
            permission: "COMISION_MENU"
        }
    },

    {
        name: 'ManualPage',
        path: '/crud/manual',
        component: ManualPage,
        meta: {
            requiresAuth: true,
            permission: "MANUAL_MENU"
        }
    },

    {
        name: 'PedidoCuentaPage',
        path: '/crud/pedidocuenta',
        component: PedidoCuentaPage,
        meta: {
            requiresAuth: true,
            permission: "PEDIDOCUENTA_MENU"
        }
    },

    {
        name: 'QuitaCrudPage',
        path: '/crud/quita',
        component: QuitaCrudPage,
        meta: {
            requiresAuth: true,
            permission: "QUITA_MENU"
        }
    },

    {
        name: 'ConvenioCrudPage',
        path: '/crud/convenio',
        component: ConvenioCrudPage,
        meta: {
            requiresAuth: true,
            permission: "CONVENIO_MENU"
        }
    },

    {
        name: 'AutogestionLogCrudPage',
        path: '/crud/autogestionlog',
        component: AutogestionLogCrudPage,
        meta: {
            requiresAuth: true,
            permission: "AUTOGESTIONLOG_MENU"
        }
    }
]

export default routes;
