<template>
    <v-row>
        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.nombre" :label="$t('cobrox.compra.labels.nombre')" icon="description"/>
                 <show-field :value="String(item.porcentajeCompra)" :label="$t('cobrox.compra.labels.porcentajeCompra')" icon="donut_small"/>
                 <show-field :value="String(item.orden)" :label="$t('cobrox.compra.labels.orden')" icon="sort"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.nombreLegal" :label="$t('cobrox.compra.labels.nombreLegal')" icon="verified"/>
                 <show-field :value="item.cuit" :label="$t('cobrox.compra.labels.cuit')" icon="business"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="getDateFormat(item.fechaCompra)" :label="$t('cobrox.compra.labels.fechaCompra')" icon="event"/>
                 <show-field :value="item.direccionLegal" :label="$t('cobrox.compra.labels.direccionLegal')" icon="location_on"/>
            </v-list>
        </v-col>

    </v-row>
</template>
<script>
    import {ShowField} from '@dracul/common-frontend'
    import {DayjsMixin} from "@dracul/dayjs-frontend"; 
    
    export default {
        name: 'CompraShowData',
        components: {ShowField},
        mixins: [DayjsMixin],
        props: {
            item: {type: Object, required: true}
        }
    }
</script>

