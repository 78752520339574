import { render, staticRenderFns } from "./CanalCobroUpdate.vue?vue&type=template&id=09b17918&scoped=true"
import script from "./CanalCobroUpdate.vue?vue&type=script&lang=js"
export * from "./CanalCobroUpdate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09b17918",
  null
  
)

export default component.exports