import graphqlClient from "../../../apollo";

class InteresProvider {

    findInteres(id) {
        return graphqlClient.query({
            query: require('./gql/Interes/findInteres.graphql'),
            fetchPolicy: "network-only",
            variables: {id:id}
        })
    }

    fetchInteres() {
        return graphqlClient.query({
            query: require('./gql/Interes/fetchInteres.graphql'),
            fetchPolicy: "network-only"
        })
    }

    paginateInteres(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Interes/paginateInteres.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }



    createInteres(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Interes/createInteres.graphql'),
            variables: {input}
        })
    }

    createInteresByFecha(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Interes/createInteresByFecha.graphql'),
            variables: {input}
        })
    }

    deleteInteresByFecha(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Interes/deleteInteresByFecha.graphql'),
            variables: {input}
        })
    }

    updateInteresByFecha(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Interes/updateInteresByFecha.graphql'),
            variables: {input}
        })
    }

    createInteresByImporte(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Interes/createInteresByImporte.graphql'),
            variables: {input}
        })
    }

    deleteInteresByImporte(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Interes/deleteInteresByImporte.graphql'),
            variables: {input}
        })
    }

    updateInteresByImporte(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Interes/updateInteresByImporte.graphql'),
            variables: {input}
        })
    }

    updateInteresTasa(id, tasa) {
        return graphqlClient.mutate({
            mutation: require('./gql/Interes/updateInteresTasa.graphql'),
            variables: {id, tasa}
        })
    }

    updateInteres(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Interes/updateInteres.graphql'),
            variables: {id, input}
        })
    }

     deleteInteres(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Interes/deleteInteres.graphql'),
            variables: {id}
        })
    }

}

export default new InteresProvider()


