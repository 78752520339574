
const messages = {
    en: {
       cobrox: {
          canalCobro: { 
            name: 'CanalCobro',
            title: 'CanalCobro management',
            subtitle: 'View, search, create, edit and delete CanalCobro',
            creating: 'Creating CanalCobro',
            editing: 'Editing CanalCobro',
            deleting: 'Deleting CanalCobro',
            showing: 'Showing CanalCobro',
            menu: 'CanalCobro',
            labels: {
                nombre: 'Nombre',
                cuentaDestinoDefault: 'Cuenta destino default',
                tipoCanal: 'Tipo',
                visiblePorAgencia: 'Visible por agencia'
            },
          
          }
       }
    },
    es: {
       cobrox: {
          canalCobro: { 
            name: 'CanalCobro',
            title: 'Administración de CanalCobro',
            subtitle: 'Ver, buscar, crear, editar, y borrar CanalCobro',
            creating: 'Creando CanalCobro',
            editing: 'Modificando CanalCobro',
            deleting: 'Eliminando CanalCobro',
            showing: 'Detalles de CanalCobro',
            menu: 'CanalCobro',
            labels: {
                nombre: 'Nombre',
                cuentaDestinoDefault: 'Cuenta destino default',
                tipoCanal: 'Tipo',
                visiblePorAgencia: 'Visible por agencia'
            },
          
          }
       }
    },
    pt: {
       cobrox: {
          canalCobro: { 
            name: 'CanalCobro',
            title: 'Administração de CanalCobro',
            subtitle: 'Ver, buscar, criar, editar e usar CanalCobro',
            creating: 'Criando CanalCobro',
            editing: 'Edição CanalCobro',
            deleting: 'Apagando CanalCobro',
            showing: 'Detalhes do CanalCobro',
            menu: 'CanalCobro',
            labels: {
                nombre: 'Nombre',
                cuentaDestinoDefault: 'Cuenta destino default',
                tipoCanal: 'Tipo',
                visiblePorAgencia: 'Visible por agencia'
            },
          
          }
       }
    }
}

export default messages
    