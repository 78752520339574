import graphqlClient from "../../../apollo";

class ReportProvider {


    deudaReport() {
        return graphqlClient.query({
            query: require('./gql/Reports/deudaReport.graphql'),
            fetchPolicy: "network-only"
        })
    }

    cobranzaReport() {
        return graphqlClient.query({
            query: require('./gql/Reports/cobranzaReport.graphql'),
            fetchPolicy: "network-only"
        })
    }

    facturaReport() {
        return graphqlClient.query({
            query: require('./gql/Reports/facturaReport.graphql'),
            fetchPolicy: "network-only"
        })
    }


    rotacionAgenciasReport(input, csv = false) {
        return graphqlClient.query({
            query: require('./gql/RotacionAgencia/rotacionAgenciasReport.graphql'),
            fetchPolicy: "network-only",
            variables: {input, csv}
        })
    }
}

export default new ReportProvider()


