
const messages = {
    en: {
       cobrox: {
          cajaMensual: {
            name: 'CajaMensual',
            title: 'CajaMensual management',
            subtitle: 'View, search, create, edit and delete Caja Mensual',
            creating: 'Creating Caja Mensual',
            editing: 'Editing Caja Mensual',
            deleting: 'Deleting Caja Mensual',
            showing: 'Showing Caja Mensual',
            menu: 'Caja Mensual',
            labels: {
                fecha: 'Date',
                cerrada: 'Closed',
                estado: 'Estado'
            },

          }
       }
    },
    es: {
       cobrox: {
          cajaMensual: {
            name: 'Caja Mensual',
            title: 'Administración de Caja Mensual',
            subtitle: 'Ver, buscar, crear, editar, y borrar Caja Mensual',
            creating: 'Creando Caja Mensual',
            editing: 'Modificando Caja Mensual',
            deleting: 'Eliminando Caja Mensual',
            showing: 'Detalles de Caja Mensual',
            menu: 'Caja Mensual',
            labels: {
                fecha: 'Fecha',
                cerrada: 'Cerrada',
                estado: 'Estado'
            },

          }
       }
    },
    pt: {
       cobrox: {
          cajaMensual: {
            name: 'CajaMensual',
            title: 'Administração de CajaMensual',
            subtitle: 'Ver, buscar, criar, editar e usar CajaMensual',
            creating: 'Criando CajaMensual',
            editing: 'Edição CajaMensual',
            deleting: 'Apagando CajaMensual',
            showing: 'Detalhes do CajaMensual',
            menu: 'CajaMensual',
            labels: {
                fecha: 'Fecha',
                cerrada: 'Cerrada',
                estado: 'Estado'
            },

          }
       }
    }
}

export default messages
