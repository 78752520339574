import graphqlClient from "../../../apollo";

class FacturarProvider {

    fetchLoteStatus() {
        return graphqlClient.query({
            query: require('./gql/loteEstadoFetch.graphql'),
            fetchPolicy: "network-only"
        })
    }

    loteAprobar(lote) {
        return graphqlClient.mutate({
            mutation: require('./gql/loteAprobar.graphql'),
            variables: {lote}
        })
    }

    loteEliminar(lote) {
        return graphqlClient.mutate({
            mutation: require('./gql/loteEliminar.graphql'),
            variables: {lote}
        })
    }

    emitirFactura(facturaId) {
        return graphqlClient.mutate({
            mutation: require('./gql/emitirFactura.graphql'),
            variables: {facturaId}
        })
    }

    exportarFacturas(lote, filters){
        return graphqlClient.query({
            query: require('./gql/facturaExportar.graphql'),
            variables: {lote,filters},
            fetchPolicy: "network-only"
        })
    }

    netearCobranzas() {
        return graphqlClient.mutate({
            mutation: require('./gql/netearCobranzas.graphql'),
        })
    }

    generarNotasDeCredito(){
        return graphqlClient.mutate({
            mutation: require('./gql/generarNotasDeCredito.graphql'),
        })
    }
}

export default new FacturarProvider()


