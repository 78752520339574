import graphqlClient from "../../../apollo";

class ConvenioProvider {

    findConvenioByCuil(cuil) {
        return graphqlClient.query({
            query: require('./gql/Convenio/findConvenioByCuil.graphql'),
            variables: {cuil},
            fetchPolicy: "network-only"
        })
    }

    fetchConvenioByCuil(cuil) {
        return graphqlClient.query({
            query: require('./gql/Convenio/fetchConvenioByCuil.graphql'),
            variables: {cuil},
            fetchPolicy: "network-only"
        })
    }
    findConvenio(id) {
        return graphqlClient.query({
            query: require('./gql/Convenio/findConvenio.graphql'),
            variables: {id:id},
            fetchPolicy: "network-only"
        })
    }

    fetchConvenio() {
        return graphqlClient.query({
        query: require('./gql/Convenio/fetchConvenio.graphql'),
        fetchPolicy: "network-only"
        })
    }

    paginateConvenio(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Convenio/paginateConvenio.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }

    paginateConvenioPagos(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Convenio/paginateConvenioPagos.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }


    createConvenio(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Convenio/createConvenio.graphql'),
            variables: {input}
        })
    }

    updateConvenio(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Convenio/updateConvenio.graphql'),
            variables: {id, input}
        })
    }

    updatePagoConvenio(idPago,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Convenio/updatePagoConvenio.graphql'),
            variables: {idPago, input}
        })
    }

    verificarPagoConvenio(idPago,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Convenio/verificarPagoConvenio.graphql'),
            variables: {idPago, input}
        })
    }


     deleteConvenio(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Convenio/deleteConvenio.graphql'),
            variables: {id}
        })
    }

}

export default new ConvenioProvider()


