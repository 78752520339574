<template>
  <compra-deuda-import></compra-deuda-import>
</template>

<script>
import CompraDeudaImport from "@/modules/cobrox/components/CompraDeudaImport/CompraDeudaImport";

export default {
  name: "CompraDeudaImportPage",
  components: {CompraDeudaImport}
}
</script>

<style scoped>

</style>
