import graphqlClient from "../../../apollo";

class AutogestionLogProvider {

    findAutogestionLog(id) {
        return graphqlClient.query({
            query: require('./gql/AutogestionLog/findAutogestionLog.graphql'),
            variables: {id:id},
            fetchPolicy: "network-only"
        })
    }

    fetchAutogestionLog() {
        return graphqlClient.query({
        query: require('./gql/AutogestionLog/fetchAutogestionLog.graphql'),
        fetchPolicy: "network-only"
        })
    }
    
    paginateAutogestionLog(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/AutogestionLog/paginateAutogestionLog.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }
    
    

    createAutogestionLog(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/AutogestionLog/createAutogestionLog.graphql'),
            variables: {input}
        })
    }
    
    updateAutogestionLog(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/AutogestionLog/updateAutogestionLog.graphql'),
            variables: {id, input}
        })
    }
    
     deleteAutogestionLog(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/AutogestionLog/deleteAutogestionLog.graphql'),
            variables: {id}
        })
    }

}

export default new AutogestionLogProvider()


