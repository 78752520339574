<template>
    <v-form ref="form" autocomplete="off" @submit.prevent="save" >
        <v-row>

                    <v-col cols="12" sm="6">
                        <v-text-field

                                prepend-icon="account_balance"
                                name="nombre"
                                v-model="form.nombre"
                                :label="$t('cobrox.cuenta.labels.nombre')"
                                :placeholder="$t('cobrox.cuenta.labels.nombre')"
                                :error="hasInputErrors('nombre')"
                                :error-messages="getInputErrors('nombre')"
                                color="secondary"
                                :rules="required"

                        ></v-text-field>
                    </v-col>


                    <v-col cols="12" sm="6">
                        <v-text-field

                                prepend-icon="tag"
                                name="numero"
                                v-model.number="form.numero"
                                type="number"
                                :label="$t('cobrox.cuenta.labels.numero')"
                                :placeholder="$t('cobrox.cuenta.labels.numero')"
                                :error="hasInputErrors('numero')"
                                :error-messages="getInputErrors('numero')"
                                color="secondary"
                                :rules="required"
                        ></v-text-field>
                    </v-col>

        </v-row>
    </v-form>
</template>

<script>

    import {InputErrorsByProps, RequiredRule } from '@dracul/common-frontend'







    export default {
        name: "CuentaForm",
        mixins: [InputErrorsByProps, RequiredRule    ],

        props:{
            value: {
                type: Object,
                required: true
            },
        },
        computed: {
           form: {
                get() { return this.value },
                set(val) {this.$emit('input', val)}
            }
        },
         watch: {
            form: {
                handler(newVal) {
                    this.$emit('input', newVal)
                },
                deep: true
            }
        },
        methods: {
            validate(){
              return this.$refs.form.validate()
            }
        },
        data(){
            return {

            }
        }
    }
</script>

<style scoped>

</style>

