<template>
    <v-row>
        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.agencia.nombre" :label="$t('cobrox.retencion.labels.agencia')" icon="corporate_fare"/>
                 <show-field :value="item.archivo" :label="$t('cobrox.retencion.labels.archivo')" icon="upload_file"/>
                 <show-field :value="String(item.solicitadas)" :label="$t('cobrox.retencion.labels.solicitadas')" icon="add_task"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="item.estado" :label="$t('cobrox.retencion.labels.estado')" icon="gamepad"/>
                 <show-field :value="item.comentario" :label="$t('cobrox.retencion.labels.comentario')" icon="description"/>
                 <show-field :value="String(item.rechazadas)" :label="$t('cobrox.retencion.labels.rechazadas')" icon="block"/>
            </v-list>
        </v-col>

        <v-col cols="12" sm="6" md="4">
            <v-list>
                 <show-field :value="String(item.procesado)" :label="$t('cobrox.retencion.labels.procesado')" icon="more"/>
                 <show-field :value="String(item.retenidas)" :label="$t('cobrox.retencion.labels.retenidas')" icon="person_add"/>
                 <show-field :value="item.creadoPor.username" :label="$t('cobrox.retencion.labels.creadoPor')" icon="person"/>
            </v-list>
        </v-col>

    </v-row>
</template>
<script>
    import {ShowField} from '@dracul/common-frontend'
     
    
    export default {
        name: 'RetencionShowData',
        components: {ShowField},
        
        props: {
            item: {type: Object, required: true}
        }
    }
</script>

