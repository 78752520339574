
const messages = {
    en: {
       cobrox: {
          cobranza: {
            name: 'Cobranza',
            title: 'Cobranza management',
            subtitle: 'View, search, create, edit and delete Cobranza',
            creating: 'Creating Cobranza',
            editing: 'Editing Cobranza',
            deleting: 'Deleting Cobranza',
            showing: 'Showing Cobranza',
            menu: 'Cobranza',
            labels: {
                fechaCreacion: 'Fecha Creación',
                fechaCobranza: 'Fecha Cobranza',
                fechaImputacion: 'Fecha Imputación',
                montoCobrado: 'Monto Cobrado',
                dniDeudor: 'DNI Deudor',
                nombreDeudor: 'Debt Name',
                cuilDeudor: 'Cuil Deudor',
                agencia: 'Agencia',
                facturas: 'Facturas',
                canalCobro: 'Canal cobro',
                cuentaDestino: 'Cuenta destino',
                origenCreacion: 'Origen',
                estado: 'Estado',
                idRapipago: 'IdRapipago',
                cbu: 'CBU',
                nroProducto: 'nroProducto',
                facturar: "Facturar",
                errorImputacion: 'Error de Imputación',
                tipificacion: 'Tipificación'

            },

          }
       }
    },
    es: {
       cobrox: {
          cobranza: {
            name: 'Cobranza',
            title: 'Administración de Cobranza',
            subtitle: 'Ver, buscar, crear, editar, y borrar Cobranza',
            creating: 'Creando Cobranza',
            editing: 'Modificando Cobranza',
            deleting: 'Eliminando Cobranza',
            showing: 'Detalles de Cobranza',
            menu: 'Cobranza',
            labels: {
                fechaCreacion: 'Fecha Creación',
                fechaCobranza: 'Fecha Cobranza',
                fechaImputacion: 'Fecha Imputación',
                montoCobrado: 'Monto Cobrado',
                dniDeudor: 'DNI Deudor',
                nombreDeudor: 'Nombre Deudor',
                cuilDeudor: 'Cuil Deudor',
                agencia: 'Agencia',
                facturas: 'Facturas',
                canalCobro: 'Canal cobro',
                cuentaDestino: 'Cuenta destino',
                origenCreacion: 'Origen',
                estado: 'Estado',
                idRapipago: 'IdRapipago',
                cbu: 'CBU',
                nroProducto: 'nroProducto',
                facturar: "Facturar",
                errorImputacion: 'Error de Imputación',
                tipificacion: 'Tipificación'
            },

          }
       }
    },
    pt: {
       cobrox: {
          cobranza: {
            name: 'Cobranza',
            title: 'Administração de Cobranza',
            subtitle: 'Ver, buscar, criar, editar e usar Cobranza',
            creating: 'Criando Cobranza',
            editing: 'Edição Cobranza',
            deleting: 'Apagando Cobranza',
            showing: 'Detalhes do Cobranza',
            menu: 'Cobranza',
            labels: {
                fechaCreacion: 'Fecha Creación',
                fechaCobranza: 'Fecha Cobranza',
                fechaImputacion: 'Fecha Imputación',
                montoCobrado: 'Monto Cobrado',
                dniDeudor: 'DNI Deudor',
                nombreDeudor: 'Nombre Deudor',
                cuilDeudor: 'Cuil Deudor',
                agencia: 'Agencia',
                facturas: 'Facturas',
                canalCobro: 'Canal cobro',
                cuentaDestino: 'Cuenta destino',
                origenCreacion: 'Origen',
                estado: 'Estado',
                idRapipago: 'IdRapipago',
                cbu: 'CBU',
                nroProducto: 'nroProducto',
                facturar: "Facturar",
                errorImputacion: 'Error de Imputación',
                tipificacion: 'Tipificación'
            },

          }
       }
    }
}

export default messages
