<template>
  <v-form ref="form" autocomplete="off" @submit.prevent="save">
    <v-row>

      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="description"
            name="nombre"
            v-model="form.nombre"
            :label="$t('cobrox.compra.labels.nombre')"
            :placeholder="$t('cobrox.compra.labels.nombre')"
            :error="hasInputErrors('nombre')"
            :error-messages="getInputErrors('nombre')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="verified"
            name="nombreLegal"
            v-model="form.nombreLegal"
            :label="$t('cobrox.compra.labels.nombreLegal')"
            :placeholder="$t('cobrox.compra.labels.nombreLegal')"
            :error="hasInputErrors('nombreLegal')"
            :error-messages="getInputErrors('nombreLegal')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <date-input
            v-model="form.fechaCompra"
            :label="$t('cobrox.compra.labels.fechaCompra')"
            :error="hasInputErrors('fechaCompra')"
            :error-messages="getInputErrors('fechaCompra')"
        ></date-input>

      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="donut_small"
            name="porcentajeCompra"
            v-model.number="form.porcentajeCompra"
            type="number"
            :label="$t('cobrox.compra.labels.porcentajeCompra')"
            :placeholder="$t('cobrox.compra.labels.porcentajeCompra')"
            :error="hasInputErrors('porcentajeCompra')"
            :error-messages="getInputErrors('porcentajeCompra')"
            color="secondary"
            :rules="required"
        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="business"
            name="cuit"
            v-model="form.cuit"
            :label="$t('cobrox.compra.labels.cuit')"
            :placeholder="$t('cobrox.compra.labels.cuit')"
            :error="hasInputErrors('cuit')"
            :error-messages="getInputErrors('cuit')"
            color="secondary"

        ></v-text-field>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="location_on"
            name="direccionLegal"
            v-model="form.direccionLegal"
            :label="$t('cobrox.compra.labels.direccionLegal')"
            :placeholder="$t('cobrox.compra.labels.direccionLegal')"
            :error="hasInputErrors('direccionLegal')"
            :error-messages="getInputErrors('direccionLegal')"
            color="secondary"

        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-checkbox
            prepend-icon="credit_score"
            name="debitable"
            v-model="form.debitable"
            :label="$t('cobrox.deuda.labels.debitable')"
            :error="hasInputErrors('debitable')"
            :error-messages="getInputErrors('debitable')"
            color="secondary"

        ></v-checkbox>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field

            prepend-icon="sort"
            name="orden"
            v-model.number="form.orden"
            type="number"
            :label="$t('cobrox.compra.labels.orden')"
            :placeholder="$t('cobrox.compra.labels.orden')"
            :error="hasInputErrors('orden')"
            :error-messages="getInputErrors('orden')"
            color="secondary"

        ></v-text-field>
      </v-col>

    </v-row>
  </v-form>
</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'
import {DayjsMixin, DateInput} from "@dracul/dayjs-frontend";

export default {
  name: "CompraForm",
  mixins: [InputErrorsByProps, RequiredRule, DayjsMixin],
  components: {DateInput},
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  },
  data() {
    return {
      fechaCompraDateMenu: false
    }
  }
}
</script>

<style scoped>

</style>

