
const messages = {
    en: {
       role: {
            permissions: {
                AGENCIA_MENU: "Access to Agencia",
                AGENCIA_SHOW: "View Agencia",
                AGENCIA_CREATE: "Create Agencia",
                AGENCIA_UPDATE: "Modify Agencia",
                AGENCIA_DELETE: "Delete Agencia",
                MY_AGENCIA_SHOW: "View my Agencia"
            }
       }
    },
    es: {
        role: {
            permissions: {
                AGENCIA_MENU: "Acceder Agencia",
                AGENCIA_SHOW: "Ver Agencias",
                AGENCIA_CREATE: "Crear Agencia",
                AGENCIA_UPDATE: "Modificar Agencia",
                AGENCIA_DELETE: "Eliminar Agencia",
                MY_AGENCIA_SHOW: "Ver mis Agencias"
            }
       }
    },
    pt: {
       role: {
            permissions: {
                AGENCIA_MENU: "Acessar Agencia",
                AGENCIA_SHOW: "Ver Agencia",
                AGENCIA_CREATE: "Criar Agencia",
                AGENCIA_UPDATE: "Modificar Agencia",
                AGENCIA_DELETE: "Eliminar Agencia",
                MY_AGENCIA_SHOW: "Ver mi Agencia"
            }
       }
    }
}

export default messages
