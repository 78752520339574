<template>
  <v-container>
    <v-card>
      <v-card-title>
        <div>
          <h3>Ticket #{{ issueId }}</h3>
          <v-chip small color="grey lighten-4" v-if="issue && issue.created_on">{{ formatDate(issue.created_on) }}</v-chip>
        </div>

        <v-spacer></v-spacer>
        <v-chip v-if="issue && issue.status">Estado: {{ issue.status.name }}</v-chip>

      </v-card-title>
      <v-card-text v-if="issue">
        <redmine-issue-form
            :readonly="true"
            v-model="issue"
            :custom-fields=" this.customFields"
        ></redmine-issue-form>
      </v-card-text>

      <v-card-actions v-if="false">
        <v-spacer></v-spacer>
        <submit-button @click="findIssue"></submit-button>
      </v-card-actions>
    </v-card>

  </v-container>
</template>

<script>

import RedmineProvider from "@/modules/redmine/providers/RedmineProvider";
import {SubmitButton} from "@dracul/common-frontend";
import RedmineIssueForm from "@/modules/redmine/components/RedmineIssueForm/RedmineIssueForm";
import {Dayjs} from "@dracul/dayjs-frontend";

export default {
  name: "RedmineViewIssuePage",
  components: {RedmineIssueForm, SubmitButton},
  data() {
    return {
      issue: null,
      customFields: []
    }
  },
  computed: {
    issueId() {
      return this.$route.params.id
    },
    formatDate(){
      return date => {
        return Dayjs(date).format("YYYY-MM-DD")
      }
    },
  },
  mounted() {
    this.findIssue()

  },
  methods: {
    findIssue() {
      RedmineProvider.findRedmineIssueById(this.issueId)
          .then(r => {
            this.issue = r.data.findRedmineIssueById
            this.issue.tracker_id = this.issue.tracker.id
            this.fetchCustomFields()
          })
    },
    fetchCustomFields() {
      RedmineProvider.fetchRedmineCustomFieldsByTracker(this.issue.tracker.id)
          .then(r => {
            this.customFields = r.data.fetchRedmineCustomFieldsByTracker.custom_fields
          })
    }

  }
}
</script>

<style scoped>

</style>
