const messages = {
    en: {
        kinva: {
            labels: {
                title: 'Title',
                x: 'X',
                y: 'Y',
                width: 'Width',
                height: 'Height',
                text: 'Text'
            },
        }
    },
    es: {
        kinva: {
            labels: {
                title: 'Title',
                x: 'X',
                y: 'Y',
                width: 'Width',
                height: 'Height',
                text: 'Text'
            },
        }
    },
    pt: {
        kinva: {
            labels: {
                title: 'Title',
                x: 'X',
                y: 'Y',
                width: 'Width',
                height: 'Height',
                text: 'Text'
            },
        }
    }
}

export default messages
