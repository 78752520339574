<template>
  <v-container fluid>
    <loading v-if="loading"></loading>
    <template v-else-if="cobranza">
      <cobranza-view :cobranza="cobranza"></cobranza-view>
      <v-divider></v-divider>
      <v-row justify="center">

        <v-col cols="12" sm="12" md="4" class="text-center">
          <v-card elevation="4">
            <v-card-title>Distribución</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12">
                  <h3>%{{ distributionPercentage }}</h3>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-slider min="0" max="100" v-model="distributionPercentage"></v-slider>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-switch v-model="simular" label="simular" @change="distribucion = null"></v-switch>
                </v-col>

              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="facturar" color="primary onPrimary--text">Generar Facturas</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>


        <v-col cols="12" sm="12" md="8" class="text-right">
          <template v-if="error">
            <v-alert type="error" class="text-left">{{ error }}</v-alert>
          </template>
          <template v-else>

            <v-alert v-if="simular && distribucion" type="info" class="text-left">Simulación exitosa</v-alert>
            <v-alert v-if="!simular && distribucion" type="success" class="text-left">Generación exitosa</v-alert>

            <distribucion-facturas :distribucion="distribucion"/>
          </template>
        </v-col>


      </v-row>

    </template>
  </v-container>
</template>

<script>
import {Loading} from '@dracul/common-frontend'
import CobranzaProvider from "@/modules/cobrox/providers/CobranzaProvider";
import CobranzaView from "@/modules/cobrox/components/CobranzaView/CobranzaView";
import DistribucionProvider from "@/modules/cobrox/providers/DistribucionProvider";
import DistribucionFacturas from "@/modules/cobrox/components/DistribucionFacturas/DistribucionFacturas";

export default {
  name: "GenerarFacturasPage",
  components: {DistribucionFacturas, CobranzaView, Loading},
  data() {
    return {
      cobranza: null,
      loading: false,
      operationLoading: false,
      distributionPercentage: 0,
      distribucion: null,
      error: null,
      simular: true
    }
  },
  computed: {
    getCobranzaId() {
      return this.$route.params.id
    },
  },
  mounted() {
    this.fetchCobranza()
  },
  methods: {
    fetchCobranza() {
      this.loading = true
      CobranzaProvider.findCobranza(this.getCobranzaId)
          .then(r => {
            this.cobranza = r.data.findCobranza
          })
          .catch(e => console.error(e))
          .finally(() => this.loading = false)
    },
    facturar() {
      this.operationLoading = true
      this.error = null
      DistribucionProvider.distribuir(this.getCobranzaId, this.distributionPercentage, this.simular)
          .then(res => {
            this.distribucion = res.data.distribuir
          })
          .catch(e => {
            console.error(e)
            this.error = e.message.replace("GraphQL error: Unexpected error value:","")
          })
          .finally(() => {
            this.operationLoading = false
          })
    },

  }
}
</script>

<style scoped>

</style>
