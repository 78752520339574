<template>
  <v-form ref="form">
    <v-row  >

      <!-- TITLE-->
      <v-col cols="12">
        <v-text-field
            name="title"
            v-model.number="form.title"
            :label="$t('kinva.labels.title')"
            :placeholder="$t('kinva.labels.title')"
            color="secondary"
            hide-details outlined dense
        ></v-text-field>
      </v-col>

      <!--  TEXT-->
      <v-col cols="12" sm="12"  v-if="form.text != undefined">
        <v-textarea

            name="width"
            v-model.number="form.text"
            :label="$t('kinva.labels.text')"
            :placeholder="$t('kinva.labels.text')"
            color="secondary"
            :rows="2"
            hide-details hide-spin-buttons
            outlined dense
        ></v-textarea>
      </v-col>

      <!--  IMAGE -->
      <v-col cols="12" sm="12"  v-if="form.image != undefined">
        <media-field

            icon="image"
            field-name="image"
            name="width"
            v-model="form.image.src"
            :label="$t('kinva.labels.image')"
            color="secondary"
        ></media-field>
      </v-col>


      <!-- COORDS  X & Y-->
      <v-col cols="12" sm="6">
        <v-text-field
            name="x"
            v-model.number="form.x"
            :label="$t('kinva.labels.x')"
            :placeholder="$t('kinva.labels.x')"
            color="secondary"
            hide-details outlined dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
            name="y"
            v-model.number="form.y"
            :label="$t('kinva.labels.y')"
            :placeholder="$t('kinva.labels.y')"
            color="secondary"
            hide-details outlined dense
        ></v-text-field>
      </v-col>

      <!--  WIDTH & HEIGHT-->
      <v-col cols="12" sm="6">
        <v-text-field
            v-if="form.width != undefined"
            name="width"
            v-model.number="form.width"
            :label="$t('kinva.labels.width')"
            :placeholder="$t('kinva.labels.width')"
            color="secondary"
            hide-details outlined dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
            v-if="form.height != undefined"
            name="height"
            v-model.number="form.height"
            :label="$t('kinva.labels.height')"
            :placeholder="$t('kinva.labels.height')"
            color="secondary"
            hide-details outlined dense
        ></v-text-field>
      </v-col>


    </v-row>
  </v-form>
</template>

<script>
import {MediaField} from "@dracul/media-frontend";

export default {
  name: "KinvaItemForm",
  components: {MediaField},
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  },

}
</script>

<style scoped>

</style>
