import graphqlClient from "../../../apollo";

class PersonaProvider {

    findPersona(id) {
        return graphqlClient.query({
            query: require('./gql/Persona/findPersona.graphql'),
            variables: {id: id},
            fetchPolicy: "network-only"
        })
    }

    findPersonaByNroDocumento(dni) {
        return graphqlClient.query({
            query: require('./gql/Persona/findPersonaByNroDocumento.graphql'),
            variables: {dni: dni},
            fetchPolicy: "network-only"
        })
    }

    findPersonaByCuil(cuil) {
        return graphqlClient.query({
            query: require('./gql/Persona/findPersonaByCuil.graphql'),
            variables: {cuil: cuil},
            fetchPolicy: "network-only"
        })
    }

    fetchPersona() {
        return graphqlClient.query({
            query: require('./gql/Persona/fetchPersona.graphql')
        })
    }

    paginatePersona(pageNumber, itemsPerPage, search = null, filters = null, orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/Persona/paginatePersona.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }


    createPersona(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Persona/createPersona.graphql'),
            variables: {input}
        })
    }

    updatePersona(id, input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Persona/updatePersona.graphql'),
            variables: {id, input}
        })
    }

    deletePersona(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/Persona/deletePersona.graphql'),
            variables: {id}
        })
    }

    addNotaToPersona(id, input) {
        return graphqlClient.mutate({
            mutation: require('./gql/Persona/addNotaToPersona.graphql'),
            variables: {id, input}
        })
    }

}

export default new PersonaProvider()


