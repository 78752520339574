export default {
    created() {
      this.$store.dispatch('loadSettings')
    },
    computed:{
        currency(){
            return this.$store.getters.getSettingValue('currency') ? this.$store.getters.getSettingValue('currency') : '$'
        }
    }
}
