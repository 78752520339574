<template>
<crud-layout :title="title" :subtitle="subtitle">

        <template v-slot:list>
            <field-permission-list
                       ref="list"
                       @update="update"
                       @delete="remove"
                       @show="show"

            />
        </template>


        <field-permission-update v-if="updating"
                        :open="updating"
                        :item="itemToEdit"
                        v-on:itemUpdated="onItemUpdated"
                        v-on:close="updating=false"
        />

        <field-permission-show v-if="showing"
                           :open="showing"
                           :item="itemToShow"
                           v-on:close="showing=false"
         />

        <field-permission-delete v-if="deleting"
                         :open="deleting"
                         :item="itemToDelete"
                         v-on:itemDeleted="onItemDeleted"
                         v-on:close="deleting=false"
        />

        <snackbar v-model="flash"/>

</crud-layout>
</template>

<script>

    import FieldPermissionUpdate from "../FieldPermissionUpdate";
    import FieldPermissionDelete from "../FieldPermissionDelete";
    import FieldPermissionShow from "../FieldPermissionShow";
    import FieldPermissionList from "../FieldPermissionList";

     import {CrudLayout, Snackbar} from "@dracul/common-frontend"

    export default {
        name: "FieldPermissionCrud",
        components: {
            CrudLayout, Snackbar,
            FieldPermissionUpdate,
            FieldPermissionDelete,
            FieldPermissionShow,
            FieldPermissionList
        },
        data() {
            return {
                title: 'surety.fieldPermission.title',
                subtitle: 'surety.fieldPermission.subtitle',
                flash: null,
                creating: false,
                updating: false,
                deleting: false,
                showing: false,
                itemToEdit: null,
                itemToDelete: null,
                itemToShow: null,
            }
        },
        methods: {
            onItemUpdated() {
                this.$refs.list.fetch()
                this.flash= this.$t("common.updated")
            },
            onItemDeleted() {
                this.$refs.list.fetch()
                this.flash= this.$t("common.deleted")
            },
            //Open
            create() {
                this.creating = true
            },
            update(item) {
                this.updating = true
                this.itemToEdit = item
            },
            show(item) {
                this.showing = true
                this.itemToShow = item
            },
            remove(item) {
                this.deleting = true
                this.itemToDelete = item
            }
        }

    }
</script>


