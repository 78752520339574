<template>
  <crud-update :open="open"
               :loading="loading"
               :title="title"
               :errorMessage="errorMessage"
               @update="update"
               @close="$emit('close')"
  >
    <cobranza-form
        ref="form"
        v-model="form"
        :input-errors="inputErrors"
        :dni-deudor="dniDeudor"
        :cuil="cuil"
        :id="id"
    />
  </crud-update>
</template>

<script>

import CobranzaProvider from "../../../../providers/CobranzaProvider";

import {CrudUpdate, ClientError} from '@dracul/common-frontend'

import CobranzaForm from "../CobranzaForm";

import {Dayjs} from "@dracul/dayjs-frontend";

export default {
  name: "CobranzaUpdate",

  components: {CobranzaForm, CrudUpdate},

  props: {
    open: {type: Boolean, default: true},
    item: {type: Object, required: true},
    dniDeudor: {type: String},
    cuil: {type: String},
  },
  data() {
    return {
      title: 'cobrox.cobranza.editing',
      errorMessage: '',
      inputErrors: {},
      loading: false,
      id: this.item.id,
      form: {
        fechaCobranza: this.item.fechaCobranza ? Dayjs(parseInt(this.item.fechaCobranza)) : null,
        montoCobrado: this.item.montoCobrado,
        dniDeudor: this.item.dniDeudor,
        nombreDeudor: this.item.nombreDeudor,
        cuilDeudor: this.item.cuilDeudor,
        agencia: this.item.agencia ? this.item.agencia.id : null,
       // facturas: this.item.facturas.map(i => i.id ? i.id : i),
        canalCobro: this.item.canalCobro,
        cuentaDestino: this.item.cuentaDestino,
        cbu: this.item.cbu,
        idRapipago: this.item.idRapipago,
        nroProducto: this.item.nroProducto,
      }
    }
  },
  methods: {
    update() {
      if (this.$refs.form.validate()) {
        this.loading = true
        CobranzaProvider.updateCobranza(this.id, this.form).then(r => {
              if (r) {
                this.$emit('itemUpdated', r.data.updateCobranza)
                this.$emit('close')
              }
            }
        ).catch(error => {
          let clientError = new ClientError(error)
          this.inputErrors = clientError.inputErrors
          this.errorMessage = clientError.i18nMessage
        }).finally(() => this.loading = false)
      }

    }
  },
}
</script>

<style scoped>

</style>

