<template>
  <v-card outlined>
    <v-card-title>
      Facturas
    </v-card-title>
    <v-card-text>
      <v-simple-table class="text-left">
        <thead>
        <tr>
          <th>FacturaId</th>
          <th>Tipo</th>
          <th>DniDeudor</th>
          <th>MontoNoFacturable</th>
          <th>MontoFacturable</th>
          <th>Monto</th>
        </tr>
        </thead>
        <tbody>
        <template v-if="facturas">
          <tr v-for="(factura, index) in facturas" :key="index">
            <td>{{ factura.id }}</td>
            <td>{{ factura.tipoComprobante }}</td>
            <td>{{ factura.dniCliente }}</td>
            <td>${{ factura.montoNoFacturable }}</td>
            <td>${{ factura.montoFacturable }}</td>
            <td>${{ factura.monto }}</td>
          </tr>
        </template>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "FacturasSimpleTable",
  props: {
    facturas: Array
  }
}
</script>

<style scoped>

</style>
