<template>
  <v-container fluid>
    <deuda-report></deuda-report>
  </v-container>
</template>

<script>
import DeudaReport from "@/modules/cobrox/components/DeudaReport/DeudaReport";

export default {
  name: "DeudaReportPage",
  components: {DeudaReport}
}
</script>

<style scoped>

</style>
