import merge from 'deepmerge'
import KinvaItemMessages from "@/modules/kinva/i18n/messages/KinvaItemMessages";
import KinvaDocMessages from "@/modules/kinva/i18n/messages/KinvaDocMessages";


const messages = merge.all([
    KinvaItemMessages,
    KinvaDocMessages
])

export default messages
