<template>

  <v-select
      prepend-icon="fact_check"
      :items="items"
      v-model="item"
      :label="$t('cobrox.persona.labels.tipoDocumento')"
      :loading="loading"
      :error="hasInputErrors('tipoDocumento')"
      :error-messages="getInputErrors('tipoDocumento')"
      color="secondary"
      item-color="secondary"
      :rules="required"
      :disabled="disabled"
      :readonly="readonly"
  ></v-select>

</template>

<script>

import {InputErrorsByProps, RequiredRule} from '@dracul/common-frontend'


export default {
  name: "TipoDocumentoCombobox",
  mixins: [InputErrorsByProps, RequiredRule],
  props: {
    value: {
      type: [String, Array]
    },
    disabled: {type: Boolean, default: false},
    readonly: {type: Boolean, default: false},
  },
  data() {
    return {
      items: ['DNI', 'CUIL'],
      loading: false
    }
  },
  computed: {
    item: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style scoped>

</style>

