import graphqlClient from "../../../apollo";

class OrigenDeudaProvider {

    findOrigenDeuda(id) {
        return graphqlClient.query({
            query: require('./gql/OrigenDeuda/findOrigenDeuda.graphql'),
            variables: {id:id}
        })
    }

    fetchOrigenDeuda() {
        return graphqlClient.query({query: require('./gql/OrigenDeuda/fetchOrigenDeuda.graphql')})
    }
    
    paginateOrigenDeuda(pageNumber, itemsPerPage, search = null, filters = null,  orderBy = null, orderDesc = false) {
        return graphqlClient.query({
            query: require('./gql/OrigenDeuda/paginateOrigenDeuda.graphql'),
            variables: {pageNumber, itemsPerPage, search, filters, orderBy, orderDesc},
            fetchPolicy: "network-only"
        })
    }
    
    

    createOrigenDeuda(input) {
        return graphqlClient.mutate({
            mutation: require('./gql/OrigenDeuda/createOrigenDeuda.graphql'),
            variables: {input}
        })
    }
    
    updateOrigenDeuda(id,input) {
        return graphqlClient.mutate({
            mutation: require('./gql/OrigenDeuda/updateOrigenDeuda.graphql'),
            variables: {id, input}
        })
    }
    
     deleteOrigenDeuda(id) {
        return graphqlClient.mutate({
            mutation: require('./gql/OrigenDeuda/deleteOrigenDeuda.graphql'),
            variables: {id}
        })
    }

}

export default new OrigenDeudaProvider()


